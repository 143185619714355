import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import $ from 'jquery'
//css
import '../../assets/css/DatosEntrega.css';
//components
import LugarEntrega from "./componentes/Checkout/DireccionEntrega/ListadoDirecciones";
import FormaDeEntrega from "./componentes/FormaEntrega";
import HoraEntrega from "./componentes/HoraEntrega";
import DatosContacto from "./componentes/DatosContacto";
import FormaDePago from "./componentes/FormaDePago";
import Observaciones from "./componentes/Observaciones"
import CheckOut from "./CheckOut";
import Loader from "./layout/Loader"
import CuponDescuento from "./CodigoPromocional";
//actions
import {
    createPedido,
    errorCreatePedido,
    fetchPedido,
    resetUpdatePedido,
    saveCreatePedido,
    saveUpdatePedido,
    updatePedido
} from "../../actions/PedidoActions"
import {resetUpdateDetallesPedido} from "../../actions/DetallePedidoActions"
import {resetCreateDireccionEntrega, resetDireccionesEntrega} from "../../actions/DireccionEntregaActions"
//contants
import * as FormaEntrega from "../../constants/FormaEntrega"

class DatosEntrega extends Component {

    constructor(props) {
        super(props);
        this.mensajeDireccionRequired = {};
        this.idPedido = props.match.params.idPedido;
        this.formaEntrega = React.createRef();
        this.formaPago = React.createRef();
        this.direccionEntrega = React.createRef();
        this.fueraZona = React.createRef();
    }

    componentDidMount() {
        //Subo página
        window.scrollTo(0, 0);
        if (this.idPedido)
            this.props.fetchPedido(this.idPedido);
        else {
            let cambio = {};
            let usrLoged = this.props.auth.usuarioLogueado;
            if (usrLoged && usrLoged.idUsuario) {
                cambio.idUsuario = usrLoged.idUsuario;
            } else {
                cambio.idUsuario = null;
            }
            cambio.idDireccionEntrega = null;
            this.props.createPedido(cambio);
        }

        let delay = 0;
        let offset = 125;
        document.addEventListener('invalid', function (e) {
            $(e.target).addClass("invalid");
            $('html, body').animate({scrollTop: $($(".invalid")[0]).offset().top - offset}, delay);
        }, true);
        document.addEventListener('change', function (e) {
            $(e.target).removeClass("invalid")
        }, true);
    }

    componentWillUnmount() {
        this.props.resetUpdatePedido();
        this.props.resetUpdateDetallesPedido();
        this.props.resetCreateDireccionEntrega();
        this.props.resetDireccionesEntrega();
    }

    componentDidUpdate() {
        if ((!this.props.pedidos.create.nuevo.detalle_pedido || this.props.pedidos.create.nuevo.detalle_pedido.length == 0) && !this.idPedido)
            this.props.history.push("/");
    }

    handleChangePedido(e) {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        if (e.target.id == "idFormaEntrega") {
            cambio.totalEnvio = 0;
        }
        if (e.target.id == "fechaEntrega")
            cambio.horaEntrega = null;
        if (!this.idPedido)
            this.props.createPedido(cambio);
        else
            this.props.updatePedido(cambio);
    }

    guardar(e) {
        e.preventDefault();
        const tienda = this.props.tiendas.update.activo;
        let pedido = this.idPedido ? this.props.pedidos.update.activo : this.props.pedidos.create.nuevo;
        let direccionEntrega = null;
        if (pedido.idDireccionEntrega)
            direccionEntrega = this.props.direccionesEntrega.byId.direccionesEntrega[pedido.idDireccionEntrega];

        if (!pedido.idFormaEntrega || pedido.idFormaEntrega === "") {
            this.formaEntrega.current.classList.add("visible");
            this.formaEntrega.current.scrollIntoView({
                behavior: "smooth", block: "center"
            });
        } else if (!pedido.idFormaPago || pedido.idFormaPago === "") {
            this.formaPago.current.classList.add("visible");
            this.formaPago.current.scrollIntoView({
                behavior: "smooth", block: "center"
            });
        } else if (pedido.idFormaEntrega == FormaEntrega.ENVIO_A_DOMICILIO_CON_PAGO_EN_DESTINO_ID && (!pedido.idDireccionEntrega || pedido.idDireccionEntrega === "")) {
            this.direccionEntrega.current.classList.add("visible");
            this.direccionEntrega.current.scrollIntoView({
                behavior: "smooth", block: "center"
            });
        } else if (tienda && tienda.permitirPedidosFueraZona === 0 && pedido.idFormaEntrega == FormaEntrega.ENVIO_A_DOMICILIO_CON_PAGO_EN_DESTINO_ID
            && pedido.idDireccionEntrega && direccionEntrega && (!direccionEntrega.idZonaEntrega || direccionEntrega.idZonaEntrega === "")) {
            this.fueraZona.current.classList.add("visible");
            this.fueraZona.current.scrollIntoView({
                behavior: "smooth", block: "center"
            });
        } else {
            if (!this.idPedido)
                this.props.saveCreatePedido();
            else
                this.props.saveUpdatePedido();
        }


    }

    setRef(ref) {
        this.mensajeDireccionRequired = ref;
    }

    getRef() {
        return this.mensajeDireccionRequired;
    }

    render() {
        let pedido = {};
        if (!this.idPedido)
            pedido = this.props.pedidos.create.nuevo;
        else
            pedido = this.props.pedidos.update.activo;
        return (
            <section className="datosEntrega">
                <div className="container-limit2">
                    <CheckOut pedido={pedido}/>
                    <CuponDescuento/>
                    {!this.idPedido ?
                        <h2 className="titulo-seccion">Completa tus datos</h2>
                        :
                        <h2 className="titulo-seccion">Confirma fecha y hora de entrega</h2>
                    }
                    <form onSubmit={(e) => this.guardar(e)} autoComplete="false">
                        <HoraEntrega disabled={false} handleChangePedido={this.handleChangePedido.bind(this)}
                                     pedido={pedido}/>
                        <FormaDeEntrega disabled={false} handleChangePedido={this.handleChangePedido.bind(this)}
                                        pedido={pedido} refFormaEntrega={this.formaEntrega}/>
                        {
                            pedido.idFormaEntrega == FormaEntrega.ENVIO_A_DOMICILIO_CON_PAGO_EN_DESTINO_ID &&
                            <LugarEntrega handleChangePedido={this.handleChangePedido.bind(this)} pedido={pedido}
                                          refDireccionEntrega={this.direccionEntrega} refFueraZona={this.fueraZona}/>
                        }
                        {
                            pedido.idFormaEntrega && pedido.idFormaEntrega != FormaEntrega.ENVIO_A_DOMICILIO_CON_PAGO_EN_DESTINO_ID &&
                            <DatosContacto handleChangePedido={this.handleChangePedido.bind(this)} pedido={pedido}/>
                        }
                        <FormaDePago disabled={false} handleChangePedido={this.handleChangePedido.bind(this)}
                                     pedido={pedido} refFormaPago={this.formaPago}/>
                        <Observaciones handleChangePedido={this.handleChangePedido.bind(this)} pedido={pedido}/>
                        <div className="container-botones">
                            {
                                this.props.pedidos.create.isCreating || this.props.pedidos.update.isUpdating ?
                                    <Loader/>
                                    : <button type="submit" className="finalizar"
                                              disabled={this.props.formasPago.byId.isFetching || this.props.formasEntrega.byId.isFetching || this.props.direccionesEntrega.byId.isFetching}>
                                        {!this.idPedido ? (pedido.formaPago === "Mercado pago" || pedido.formaPago === "Mercado Pago" ? "PAGAR" : "FINALIZAR") : "CONFIRMAR ENTREGA"}
                                    </button>
                            }
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        direccionesEntrega: state.direccionesEntrega,
        pedidos: state.pedidos,
        formasEntrega: state.formasEntrega,
        formasPago: state.formasPago,
        auth: state.auth,
        tiendas: state.tiendas


    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveCreatePedido: () => {
            dispatch(saveCreatePedido())
        },
        saveUpdatePedido: () => {
            dispatch(saveUpdatePedido())
        },
        createPedido: (pedido) => {
            dispatch(createPedido(pedido))
        },
        updatePedido: (pedido) => {
            dispatch(updatePedido(pedido))
        },
        fetchPedido: (idPedido) => {
            dispatch(fetchPedido(idPedido))
        },
        resetUpdatePedido: () => {
            dispatch(resetUpdatePedido())
        },
        resetUpdateDetallesPedido: () => {
            dispatch(resetUpdateDetallesPedido())
        },
        resetCreateDireccionEntrega: () => {
            dispatch(resetCreateDireccionEntrega())
        },
        resetDireccionesEntrega: () => {
            dispatch(resetDireccionesEntrega())
        },
        errorCreatePedido: (error) => {
            dispatch(errorCreatePedido(error))
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatosEntrega));