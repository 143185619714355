import c from '../constants/Constants';

require('es6-promise').polyfill();
require('isomorphic-fetch');


var packingList = {

    saveCreate(packingList) {

        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(packingList)
        };

        return fetch(c.BASE_URL + '/packingList', defaultOptions);
    },

    saveUpdate(packingList) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(packingList)
        };

        return fetch(c.BASE_URL + '/packingList/1', defaultOptions);
    },

    saveDelete(packingList) {

        let defaultOptions = {
            url: '',
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(packingList)
        };

        return fetch(c.BASE_URL + '/packingList/1', defaultOptions);
    },
    imprimir(idRemito) {

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL + '/impresion/' + idRemito, defaultOptions);
    },
};

export default packingList;