import jwt_decode from 'jwt-decode';

var authUtil = {
    getUser() {
        return localStorage.token ? jwt_decode(localStorage.token) : "";
    },
    isLoggedIn() {
        return !!localStorage.token;
    }
}

export default authUtil;