//api
import formasEntregaApi from "../api/formasEntregaApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeFormasEntrega";

//FORMASENTREGA
export const REQUEST_FORMASENTREGA = 'REQUEST_FORMASENTREGA';
export const RECEIVE_FORMASENTREGA = 'RECEIVE_FORMASENTREGA';
export const INVALIDATE_FORMASENTREGA = 'INVALIDATE_FORMASENTREGA';
export const ERROR_FORMASENTREGA = "ERROR_FORMASENTREGA";
export const RESET_FORMASENTREGA = "RESET_FORMASENTREGA";


export function invalidateFormasEntrega() {
    return {
        type: INVALIDATE_FORMASENTREGA
    }
}

function requestFormasEntrega() {
    return {
        type: REQUEST_FORMASENTREGA,
    }
}

function receiveFormasEntrega(json) {
    return {
        type: RECEIVE_FORMASENTREGA,
        formasEntrega: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorFormasEntrega(error) {
    return {
        type: ERROR_FORMASENTREGA,
        error: error,
    }
}

export function resetFormasEntrega() {
    return {
        type: RESET_FORMASENTREGA
    }
}

export function fetchFormasEntrega(filtros) {
    return dispatch => {
        dispatch(requestFormasEntrega());
        return formasEntregaApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveFormasEntrega(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorFormasEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorFormasEntrega(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchFormasEntrega(state) {
    const formasEntrega = state.formasEntrega.byId;
    if (!formasEntrega) {
        return true
    } else if (formasEntrega.isFetching) {
        return false
    } else {
        return formasEntrega.didInvalidate;
    }
}

export function fetchFormasEntregaIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchFormasEntrega(getState())) {
            return dispatch(fetchFormasEntrega(filtros))
        }
    }
}


//MODEL
export const REQUEST_FORMAENTREGA = 'REQUEST_FORMAENTREGA';
export const RECEIVE_FORMAENTREGA = 'RECEIVE_FORMAENTREGA';
export const INVALIDATE_FORMAENTREGA = 'INVALIDATE_FORMAENTREGA';
export const ERROR_FORMAENTREGA = "ERROR_FORMAENTREGA";
export const RESET_FORMAENTREGA = "RESET_FORMAENTREGA";


export function invalidateFormaEntrega() {
    return {
        type: INVALIDATE_FORMAENTREGA
    }
}

function requestFormaEntrega() {
    return {
        type: REQUEST_FORMAENTREGA,
    }
}

function receiveFormaEntrega(json) {
    return {
        type: RECEIVE_FORMAENTREGA,
        formaEntrega: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorFormaEntrega(error) {
    return {
        type: ERROR_FORMAENTREGA,
        error: error,
    }
}

export function fetchFormaEntrega(idFormaEntrega) {
    return dispatch => {
        dispatch(requestFormaEntrega());
        return formasEntregaApi.getOne(idFormaEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveFormaEntrega(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorFormaEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorFormaEntrega(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_FORMAENTREGA = 'UPDATE_FORMAENTREGA';
export const REQUEST_UPDATE_FORMAENTREGA = "REQUEST_UPDATE_FORMAENTREGA";
export const SUCCESS_UPDATE_FORMAENTREGA = "SUCCESS_UPDATE_FORMAENTREGA";
export const ERROR_UPDATE_FORMAENTREGA = "ERROR_UPDATE_FORMAENTREGA";
export const RESET_UPDATE_FORMAENTREGA = "RESET_UPDATE_FORMAENTREGA";

function requestUpdateFormaEntrega() {
    return {
        type: REQUEST_UPDATE_FORMAENTREGA,
    }
}

function receiveUpdateFormaEntrega(formaEntrega) {
    return {
        type: SUCCESS_UPDATE_FORMAENTREGA,
        receivedAt: Date.now(),
        formaEntrega: normalizeDato(formaEntrega)
    }
}

function errorUpdateFormaEntrega(error) {
    return {
        type: ERROR_UPDATE_FORMAENTREGA,
        error: error,
    }
}

export function resetUpdateFormaEntrega() {
    return {
        type: RESET_UPDATE_FORMAENTREGA,
    }
}

export function updateFormaEntrega(formaEntrega) {
    return {
        type: UPDATE_FORMAENTREGA,
        formaEntrega
    }
}

export function saveUpdateFormaEntrega() {
    return (dispatch, getState) => {
        dispatch(requestUpdateFormaEntrega());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let formaEntrega = denormalizeDato(getState().formasEntrega.update.activo, store);

        return formasEntregaApi.saveUpdate(formaEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateFormaEntrega(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateFormaEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorUpdateFormaEntrega(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorUpdateFormaEntrega(JSON.parse(error.message)));
                                    if (error.data && error.data.length > 0)
                                        dispatch(receiveUpdateFormaEntrega(error.data));
                                });
                            } catch {
                                dispatch(errorUpdateFormaEntrega(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}

//UPDATE FORMASENTREGA
export const REQUEST_UPDATE_FORMASENTREGA = "REQUEST_UPDATE_FORMASENTREGA";
export const SUCCESS_UPDATE_FORMASENTREGA = "SUCCESS_UPDATE_FORMASENTREGA";
export const ERROR_UPDATE_FORMASENTREGA = "ERROR_UPDATE_FORMASENTREGA";
export const RESET_UPDATE_FORMASENTREGA = "RESET_UPDATE_FORMASENTREGA";

function requestUpdateFormasEntrega() {
    return {
        type: REQUEST_UPDATE_FORMASENTREGA,
    }
}

function receiveUpdateFormasEntrega(formasEntrega) {
    return {
        type: SUCCESS_UPDATE_FORMASENTREGA,
        receivedAt: Date.now(),
        formasEntrega: normalizeDatos(formasEntrega)
    }
}

function errorUpdateFormasEntrega(error) {
    return {
        type: ERROR_UPDATE_FORMASENTREGA,
        error: error,
    }
}

export function resetUpdateFormasEntrega() {
    return {
        type: RESET_UPDATE_FORMASENTREGA,
    }
}

export function saveUpdateFormasEntrega() {
    return (dispatch, getState) => {
        dispatch(requestUpdateFormasEntrega());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let formasEntrega = getState().formasEntrega.update.activos.map((idFormaEntrega) => {
            return denormalizeDato(getState().formasEntrega.byId.formasEntrega[idFormaEntrega], store);
        });

        return formasEntregaApi.saveUpdateFormasEntrega(formasEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateFormasEntrega(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateFormasEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorUpdateFormasEntrega(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorUpdateFormasEntrega(JSON.parse(error.message)));
                                });
                            } catch {
                                dispatch(errorUpdateFormasEntrega(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}


//ALTA FORMAENTREGA
export const CREATE_FORMAENTREGA = 'CREATE_FORMAENTREGA';
export const REQUEST_CREATE_FORMAENTREGA = "REQUEST_CREATE_FORMAENTREGA";
export const SUCCESS_CREATE_FORMAENTREGA = "SUCCESS_CREATE_FORMAENTREGA";
export const ERROR_CREATE_FORMAENTREGA = "ERROR_CREATE_FORMAENTREGA";
export const RESET_CREATE_FORMAENTREGA = "RESET_CREATE_FORMAENTREGA";


//ALTA FORMAENTREGA
function requestCreateFormaEntrega() {
    return {
        type: REQUEST_CREATE_FORMAENTREGA,
    }
}

function receiveCreateFormaEntrega(formaEntrega) {
    return {
        type: SUCCESS_CREATE_FORMAENTREGA,
        receivedAt: Date.now(),
        formaEntrega: normalizeDato(formaEntrega)
    }
}

export function errorCreateFormaEntrega(error) {
    return {
        type: ERROR_CREATE_FORMAENTREGA,
        error: error,
    }
}

export function resetCreateFormaEntrega() {
    return {
        type: RESET_CREATE_FORMAENTREGA
    }
}

export function createFormaEntrega(formaEntrega) {
    return {
        type: CREATE_FORMAENTREGA,
        formaEntrega
    }
}

export function saveCreateFormaEntrega() {
    return (dispatch, getState) => {
        dispatch(requestCreateFormaEntrega());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let formaEntrega = denormalizeDato(getState().formasEntrega.create.nuevo, store);

        return formasEntregaApi.saveCreate(formaEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateFormaEntrega(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateFormaEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorCreateFormaEntrega(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorCreateFormaEntrega(JSON.parse(error.message)));
                                    if (error.data)
                                        dispatch(receiveCreateFormaEntrega(error.data));
                                });
                            } catch {
                                dispatch(errorCreateFormaEntrega(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}

//DELETE FORMAENTREGA
export const DELETE_FORMAENTREGA = 'DELETE_FORMAENTREGA';
export const REQUEST_DELETE_FORMAENTREGA = "REQUEST_DELETE_FORMAENTREGA";
export const SUCCESS_DELETE_FORMAENTREGA = "SUCCESS_DELETE_FORMAENTREGA";
export const ERROR_DELETE_FORMAENTREGA = "ERROR_DELETE_FORMAENTREGA";
export const RESET_DELETE_FORMAENTREGA = "RESET_DELETE_FORMAENTREGA";

function requestDeleteFormaEntrega() {
    return {
        type: REQUEST_DELETE_FORMAENTREGA,
    }
}

function receiveDeleteFormaEntrega(formaEntrega) {
    return {
        type: SUCCESS_DELETE_FORMAENTREGA,
        receivedAt: Date.now(),
        formaEntrega: normalizeDato(formaEntrega)
    }
}

function errorDeleteFormaEntrega(error) {
    return {
        type: ERROR_DELETE_FORMAENTREGA,
        error: error,
    }
}

export function resetDeleteFormaEntrega(error) {
    return {
        type: RESET_DELETE_FORMAENTREGA,
        error: error,
    }
}

export function deleteFormaEntrega(formaEntrega) {
    return {
        type: DELETE_FORMAENTREGA,
        formaEntrega
    }
}

export function saveDeleteFormaEntrega(formaEntrega) {
    return dispatch => {
        dispatch(requestDeleteFormaEntrega());
        return formasEntregaApi.saveDelete(formaEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(resetDeleteFormaEntrega());
                dispatch(receiveDeleteFormaEntrega(data));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorDeleteFormaEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorDeleteFormaEntrega(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorDeleteFormaEntrega(JSON.parse(error.message)));
                                });
                            } catch {
                                dispatch(errorDeleteFormaEntrega(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}