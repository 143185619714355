import React, {useEffect, useRef} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import "../../../../../assets/css/ABMDireccionEntrega.css";
//Hooks
//Actions
import {
    createDireccionEntrega,
    fetchDireccionEntrega,
    invalidateDireccionEntrega,
    resetCreateDireccionEntrega, resetDireccionesEntrega,
    resetUpdateDireccionEntrega,
    saveCreateDireccionEntrega,
    saveUpdateDireccionEntrega,
    updateDireccionEntrega,
} from "../../../../../actions/DireccionEntregaActions";
import CampoTexto from "../../CampoTexto";
import Loader from "../../../layout/Loader";
import {ReactComponent as Lapiz} from "../../../../../assets/images/pencil.svg";
import CampoTextArea from "../../CampoTextArea";
import {GoogleApiWrapper} from 'google-maps-react';
import {fetchZonasEntregaIfNeeded} from "../../../../../actions/ZonasEntregaActions";
import AutosuggestProvincia from "./AutosuggestProvincia";
import AutosuggestLocalidad from "./AutosuggestLocalidad";
import zonaEntregaUtil from "../../../../../utils/calculoZonaEntrega";

//lodash

//Constants


const ABMDireccionEntrega = (props) => {
    const dispatch = useDispatch();
    let {idDireccionEntrega} = useParams();
    if (idDireccionEntrega === 'nuevo') idDireccionEntrega = null;
    const mounted = useRef();
    //Store
    const direccionesEntrega = useSelector(state => state.direccionesEntrega);
    const direccionEntrega = idDireccionEntrega ? direccionesEntrega.update.activo : direccionesEntrega.create.nuevo;
    const zonasEntrega = useSelector(state => state.zonasEntrega);
    const guardandoLoading = idDireccionEntrega ? direccionesEntrega.update.isUpdating : direccionesEntrega.create.isCreating;
    const error = idDireccionEntrega ? direccionesEntrega.update.error : direccionesEntrega.create.error;


    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(invalidateDireccionEntrega());
        if (idDireccionEntrega)
            dispatch(fetchDireccionEntrega(idDireccionEntrega));
        dispatch(fetchZonasEntregaIfNeeded());

        return function cleanup() {
            dispatch(resetCreateDireccionEntrega());
            dispatch(resetUpdateDireccionEntrega());
            dispatch(resetDireccionesEntrega());
        }
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (!direccionesEntrega.create.isCreating && !direccionesEntrega.create.error) {
                dispatch(resetCreateDireccionEntrega());
                props.history.push("/datosEntrega");
                window.scrollTo(0, 0);
            }
        }
    }, [direccionesEntrega.create.isCreating]);

//Hooks


    const handleChangeDireccionEntrega = (e) => {
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        cambio.principal = 1;

        if (idDireccionEntrega) {
            dispatch(updateDireccionEntrega(cambio));
        } else {
            dispatch(createDireccionEntrega(cambio));
        }
    };


    const onSubmit = async (e) => {
        e.preventDefault();
        if (idDireccionEntrega)
            dispatch(saveUpdateDireccionEntrega());
        else
            dispatch(saveCreateDireccionEntrega());

    };


// endregion

    return (
        <section className="direcionEntrega">
            <div className="container-limit">
                <div className="container-direccionEntrega">
                    <div>
                        <h2 className="titulo-seccion subtitulo-checkout">Agregá una dirección de entrega</h2>
                    </div>
                    <form onSubmit={onSubmit} autoComplete="off">
                        <div className="container-direccionEntrega-form">
                            <div className="fila">
                                <CampoTexto id="calle" nombre="Calle"
                                            value={direccionEntrega.calle ? direccionEntrega.calle : ""}
                                            handleChange={handleChangeDireccionEntrega.bind(this)}
                                            autoComplete="off" required={true}/>
                                <CampoTexto id="numero" nombre="Número"
                                            value={direccionEntrega.numero ? direccionEntrega.numero : ""}
                                            handleChange={handleChangeDireccionEntrega.bind(this)}
                                            autoComplete="off" required={true}/>
                            </div>
                            <div className="fila">
                                <CampoTexto id="detalles" nombre="Piso, departamento (opcional)"
                                            value={direccionEntrega.detalles ? direccionEntrega.detalles : ""}
                                            handleChange={handleChangeDireccionEntrega.bind(this)}
                                            autoComplete="new-password"/>
                            </div>
                            <div className="fila">
                                <CampoTexto id="codPostal" nombre="Código postal"
                                            value={direccionEntrega.codPostal ? direccionEntrega.codPostal : ""}
                                            handleChange={handleChangeDireccionEntrega.bind(this)}
                                            autoComplete="off" required={true}/>
                            </div>
                            <div className="fila">
                                <AutosuggestProvincia id="provincia"
                                                      value={direccionEntrega.provincia ? direccionEntrega.provincia : ""}
                                                      handleChange={handleChangeDireccionEntrega.bind(this)}
                                                      nombre="Provincia"/>
                                <AutosuggestLocalidad id="localidad"
                                                      value={direccionEntrega.localidad ? direccionEntrega.localidad : ""}
                                                      handleChange={handleChangeDireccionEntrega.bind(this)}
                                                      nombre="Localidad"/>
                            </div>
                            <div className="fila">
                                <CampoTextArea required={false} id="observaciones"
                                               nombre="Indicaciones adicionales para entregar tus compras en esta dirección (opcional)"
                                               img={<Lapiz style={{width: "15px", height: "15px"}}/>} type="text"
                                               value={direccionEntrega.observaciones ? direccionEntrega.observaciones : ""}
                                               handleChange={handleChangeDireccionEntrega.bind(this)}/>
                            </div>
                            <br/>
                            <h3>Datos de contacto</h3>
                            <div className="fila">
                                <CampoTexto id="nombre" nombre="Nombre"
                                            value={direccionEntrega.nombre ? direccionEntrega.nombre : ""}
                                            handleChange={handleChangeDireccionEntrega.bind(this)}
                                            autoComplete="off" required={true}/>
                                <CampoTexto id="apellido" nombre="Apellido"
                                            value={direccionEntrega.apellido ? direccionEntrega.apellido : ""}
                                            handleChange={handleChangeDireccionEntrega.bind(this)}
                                            autoComplete="off" required={true}/>
                            </div>
                            <div className="fila">
                                <CampoTexto id="celular" nombre="Teléfono de contacto"
                                            value={direccionEntrega.celular ? direccionEntrega.celular : ""}
                                            handleChange={handleChangeDireccionEntrega.bind(this)}
                                            autoComplete="off" required={true}/>
                            </div>
                        </div>
                        <div className="container-botones">
                            {
                                direccionesEntrega.create.isCreating || direccionesEntrega.update.isUpdating ?
                                    <Loader/>
                                    :
                                    <button type="submit" className="finalizar" disabled={zonasEntrega.byId.isFetching}>
                                        {!idDireccionEntrega ? "Agregar" : "Modificar"}
                                    </button>
                            }
                        </div>
                    </form>
                </div>

            </div>
        </section>
    );
};

export default GoogleApiWrapper({
    apiKey: ("AIzaSyANSH-395EpmTpki-222stF4V76Rp8KcZY"),
    libraries: ['places', 'geometry'],
})(ABMDireccionEntrega);
