import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {
    const formaPagoListaPrecio = new schema.Entity('formasPagoListasPrecio', {}, {idAttribute: "id"});
    const formaPago = new schema.Entity('formasPago', {forma_pago_lista_precio: [formaPagoListaPrecio]}, {idAttribute: "id"});

    const mySchema = {formasPago: [formaPago]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const formaPago = new schema.Entity('formasPago', {}, {idAttribute: "id"});

    const mySchema = formaPago;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

