import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, withRouter} from 'react-router-dom';
//Components
import Home from "../../plataforma/Plataforma";
import MensajeError from "./MensajeError"
//Utils
import NavSocial from "../componentes/NavSocial/NavSocial";
import CartelUpdate from "../CartelUpdate";
import {saveCreateAnalitica} from "../../../actions/AnaliticaActions";
import * as TipoAnalitica from "../../../constants/TipoAnalitica";
import {fetchZonasEntregaIfNeeded} from "../../../actions/ZonasEntregaActions";
import Configuracion from "../../../utils/Configuracion";
import {ConfiguracionContext} from "../../../utils/Context";
import {fetchTienda} from "../../../actions/TiendaActions";


class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        let locationHostNameSinWWW = window.location.hostname.replace('www.','');
        this.props.fetchTienda(locationHostNameSinWWW);
        this.props.saveCreateAnalitica(null, TipoAnalitica.VISITAPAGINA_ID);
        this.props.fetchZonasEntregaIfNeeded({});
        this.setState(await Configuracion.getConfiguracion());
    }

    render() {
        return (
            <div>
                <ConfiguracionContext.Provider value={this.state}>
                    <MensajeError/>
                    <CartelUpdate/>
                    <NavSocial/>
                    <Switch>
                        {/*<Route path="/" component={Home}/>*/}
                        <Route path="/"
                               render={props => <Home saveCreateAnalitica={this.props.saveCreateAnalitica.bind(this)}
                                                      zonasEntrega={this.props.zonasEntrega}/>}/>
                    </Switch>
                </ConfiguracionContext.Provider>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        zonasEntrega: store.zonasEntrega
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
        fetchZonasEntregaIfNeeded: (filtros) => {
            dispatch(fetchZonasEntregaIfNeeded(filtros))
        },
        fetchTienda: (idTienda) => {
            dispatch(fetchTienda(idTienda))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
