import {RECEIVE_DETALLESPEDIDO} from '../actions/DetallePedidoActions';
import {
    ERROR_PRODUCTO,
    ERROR_PRODUCTOS,
    INVALIDATE_PRODUCTOS,
    RECEIVE_PRODUCTO,
    RECEIVE_PRODUCTOS,
    REQUEST_PRODUCTO,
    REQUEST_PRODUCTOS,
    RESET_PRODUCTO,
    RESET_PRODUCTOS
} from '../actions/ProductoActions';
import {RECEIVE_CATEGORIAS, RECEIVE_CATEGORIAS_DESTACADAS} from "../actions/CategoriaActions";
import {RECEIVE_BUSQUEDA, RESET_BUSQUEDA} from "../actions/BusquedaActions"
import {combineReducers} from 'redux';
import {loadFromLS} from "../localeStorage/ls"

import pickBy from "lodash/pickBy"
import merge from "lodash/merge";
import {RECEIVE_PROMOCIONES} from "../actions/PromocionActions";
import {RECEIVE_MARCAS} from "../actions/MarcaActions";

function getInitialStateById() {
    let productosGuardados = loadFromLS("productos");
    let detallesPedidoGuardados = loadFromLS("detallesPedido");
    if (productosGuardados) {
        productosGuardados.byId.didInvalidate = true;
        productosGuardados.byId.error = null;
        productosGuardados.byId.isFetching = false;
        //Solo cargo los productos de los detalles para evitar mantener en memoria productos desactualizados
        let idsProducto = Object.values(detallesPedidoGuardados.create.detallesPedido).map((detallePedido) => {
            return detallePedido.idProducto;
        });
        productosGuardados.byId.productos = pickBy(productosGuardados.byId.productos, (producto) => {
            return idsProducto.indexOf(producto.id) !== -1;
        });
        return productosGuardados.byId;
    } else
        return {
            isFetching: false,
            didInvalidate: true,
            productos: {},
            //productosBuscados: {}
        }
}

function productosById(state = getInitialStateById(), action) {
    let dato = null
    switch (action.type) {
        case RECEIVE_DETALLESPEDIDO:
            let datos = action.detallesPedido.entities.producto;
            return Object.assign({}, state, {
                productos: datos ? merge({}, state.productos, datos) : state.productos,
            });
        case RECEIVE_CATEGORIAS:
            dato = action.categorias.entities.productos;
            return Object.assign({}, state, {
                productos: merge({}, state.productos, dato),
            });
        case RECEIVE_CATEGORIAS_DESTACADAS:
            dato = action.categorias.entities.productos;
            return Object.assign({}, state, {
                productos: merge({}, state.productos, dato),
            });
        case RECEIVE_MARCAS:
            let datoProductosMarcas = action.marcas.entities.productos;
            return Object.assign({}, state, {
                productos: merge({}, state.productos, datoProductosMarcas),
            });
        case RECEIVE_PROMOCIONES:
            return Object.assign({}, state, {
                productos: merge({}, state.productos, action.promociones.entities.productos),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_BUSQUEDA:
            let datosBusqueda = action.busqueda.entities.productos;
            return Object.assign({}, state, {
                productos: merge({}, state.productos, datosBusqueda),
            });


        case INVALIDATE_PRODUCTOS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PRODUCTOS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PRODUCTOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                productos: {}
            });
        case RECEIVE_PRODUCTOS:
            dato = action.productos.entities.productos;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productos: merge({}, state.productos, dato),
                lastUpdated: action.receivedAt
            });


        case REQUEST_PRODUCTO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PRODUCTO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RECEIVE_PRODUCTO:
            dato = action.producto.entities.productos;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productos: merge({}, state.productos, dato),
            });

        default:
            return state
    }
}


function allProductos(state = {
    allIds: [],
    allIdsBuscados: [],
    allIdsSugeridos: [],
    totalAllIdsBuscados: null
}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            return Object.assign({}, state, {
                allIds: action.categorias.entities.productos ? Object.keys(action.categorias.entities.productos) : state.allIds,
            });
        case RECEIVE_CATEGORIAS_DESTACADAS:
            return Object.assign({}, state, {
                allIds: action.categorias.entities.productos ? Object.keys(action.categorias.entities.productos) : state.allIds,
            });
        case RECEIVE_PRODUCTOS:
            return Object.assign({}, state, {
                allIdsBuscados: action.productos.result && action.productos.result.productos ? action.productos.result.productos : (action.productos.result ? action.productos.result : state.allIdsBuscados)
            });
        case RECEIVE_BUSQUEDA:
            return Object.assign({}, state, {
                allIdsSugeridos: action.busqueda && action.busqueda.result.productos ? action.busqueda.result.productos : state.allIdsSugeridos,
                totalAllIdsBuscados: action.busqueda && action.busqueda.result.total ? action.busqueda.result.total : state.totalAllIdsBuscados,
            });
        case RESET_BUSQUEDA:
            return Object.assign({}, state, {
                allIdsBuscados: [],
                allIdsSugeridos: [],
                totalAllIdsBuscados: null
            });

        default:
            return state
    }
}

function update(state = {
    isFetching: false,
    didInvalidate: true,
    producto: {}
}, action) {
    switch (action.type) {
        case REQUEST_PRODUCTO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_PRODUCTO:
            let dato = action.producto.entities.productos;
            let producto = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                producto: producto ? producto : [],
                lastUpdated: action.receivedAt
            });
        case ERROR_PRODUCTO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                producto: {}
            });
        default:
            return state
    }
}


const productos = combineReducers({
    byId: productosById,
    allIds: allProductos,
    update: update,
});

export default productos;