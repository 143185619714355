import {normalize, schema, denormalize} from 'normalizr';

export function normalizeDatos(myData) {
    const formaEntrega = new schema.Entity('formasEntrega', {}, {idAttribute: "id"});

    const mySchema = {formasEntrega: [formaEntrega]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const formaEntrega = new schema.Entity('formasEntrega', {}, {idAttribute: "id"});

    const mySchema = formaEntrega;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const formaEntrega = new schema.Entity('formasEntrega', {}, {idAttribute: "id"});

    const mySchema = formaEntrega;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}


