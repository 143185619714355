import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {PrivateRoute} from "../../utils/PrivateRoute"

import '../../assets/css/Index.css';
import '../../assets/css/Plataforma.css';
import Nav from "./Nav";
import DatosEntrega from "./DatosEntrega";
import Home from "./Home";
import Footer from "./Footer";
import BarraDerecha from "./BarraDerecha";
import CompraExitosa from "./CompraExitosa";
import pagoMP from "./PagoMP";
import CompraConfirmada from "./CompraConfirmada";
import QuienesSomos from "./QuienesSomos";
import ProductoPresentacion from "./ProductoPresentacion";
import Buscador from "./Buscador";
import MiCuenta from "./MiCuenta";
import ProductoPresentacionCombo from "./ProductoPresentacionCombo";
import ABMDireccionEntrega from "./componentes/Checkout/DireccionEntrega/ABMDireccionEntrega";


class Plataforma extends Component {

    constructor(props) {
        super(props);
        this.state = {barraCarritoOpen: false};
    }

    componentDidMount() {
        //Subo página
        window.scrollTo(0, 0);
    }

    toogleBarraCarrito(preferencia) {
        if (!preferencia)
            this.setState({barraCarritoOpen: !this.state.barraCarritoOpen});
        else
            this.setState({barraCarritoOpen: preferencia});
    }


    render() {
        return (
            <div>
                <div className="container-plataforma">
                    <div id="containerHome"
                         className={`container-home ${this.state.barraCarritoOpen ? 'barra-activa' : ''}`}>
                        {/*<MiniNav/>*/}
                        <Nav toogleBarraCarrito={this.toogleBarraCarrito.bind(this)}/>

                        <Switch>
                            {/*<Route path="/checkout" component={CheckOut}/>*/}
                            <Route path="/producto/combo/:idPromocion" render={props => <ProductoPresentacionCombo
                                toogleBarraCarrito={this.toogleBarraCarrito.bind(this)}/>}/>
                            <Route path="/producto/:idProducto" render={props => <ProductoPresentacion
                                toogleBarraCarrito={this.toogleBarraCarrito.bind(this)}/>}/>

                            <PrivateRoute path="/datosEntrega/:idPedido" component={DatosEntrega}/>
                            <PrivateRoute path="/datosEntrega" component={DatosEntrega}/>
                            <PrivateRoute path="/direccionesEntrega/nuevo" component={ABMDireccionEntrega}/>
                            <PrivateRoute path="/direccionesEntrega/:idDireccionEntrega" component={ABMDireccionEntrega}/>
                            <PrivateRoute path="/miCuenta" component={MiCuenta}/>
                            <Route path="/search" exact render={props => <Buscador
                                toogleBarraCarrito={this.toogleBarraCarrito.bind(this)}/>}/>
                            <Route path="/compraExitosa" component={CompraExitosa}/>
                            <Route path="/pagoMP/:idPedido" component={pagoMP}/>
                            <Route path="/estadoCompra/:estado:/:idPagoTP?" component={CompraConfirmada}/>
                            <Route path="/quienesSomos" component={QuienesSomos}/>
                            <Route path="/:token?" render={
                                (props) => <Home {...props}
                                                 toogleBarraCarrito={this.toogleBarraCarrito.bind(this)}
                                                 saveCreateAnalitica={this.props.saveCreateAnalitica.bind(this)}
                                                 zonasEntrega={this.props.zonasEntrega}/>
                            }/>
                            <Route path="/" render={
                                (props) => <Home {...props}
                                                 toogleBarraCarrito={this.toogleBarraCarrito.bind(this)}
                                                 saveCreateAnalitica={this.props.saveCreateAnalitica.bind(this)}
                                                 zonasEntrega={this.props.zonasEntrega}/>
                            }/>
                        </Switch>
                        <Footer/>
                    </div>
                    <BarraDerecha estado={this.state.barraCarritoOpen}
                                  toogleBarraCarrito={this.toogleBarraCarrito.bind(this)}/>
                </div>
            </div>

        );
    }
}

export default Plataforma;
