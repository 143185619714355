import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom'
import {NavHashLink as NavLink} from 'react-router-hash-link'

//css
import '../../assets/css/SuperMercado.css';

//Actions
import {fetchMarcasIfNeeded} from "../../actions/MarcaActions"
import {createDetallePedido, deleteDetallePedido} from "../../actions/DetallePedidoActions";

//Components
import ProductoUnitario from "./componentes/Productos/ProductoUnitario";

import CarrouselDestacados from "./Carruseles/CarrouselDestacados";
import Skelleton from "./componentes/Skelleton/Skelleton";


class MarcasDestacadas extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchMarcasIfNeeded({destacados: true});
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     this.props.fetchMarcasIfNeeded({destacados: true});
    // }

    render() {
        let innerWidth = window.innerWidth;


        return (
            <section className="supermercado" id="marcas">
                {
                    innerWidth >= 769 &&
                    <React.Fragment>
                        <div className="productos">
                            {
                                this.props.marcas.byId.isFetching ?
                                    <Skelleton tipo="destacados"/>
                                    :
                                    this.props.marcas.allIds.allIds.map((idMarca, i) => {
                                        let marca = this.props.marcas.byId.marcas[idMarca];
                                        let producto = [];
                                        let detallePedido = [];
                                        if (marca.destacada === 1 && marca.producto)
                                            return (
                                                <div className="row-categoria" key={idMarca}>
                                                    <div className="container-limit">
                                                        <h4>{`${marca.descripcion}`}</h4>
                                                    </div>
                                                    <CarrouselDestacados idCategoria={idMarca}>
                                                        {
                                                            marca.producto.map((idProducto) => {
                                                                producto = this.props.productos.byId.productos[idProducto];
                                                                detallePedido = this.props.detallesPedido.create.detallesPedido[producto.id + "-"];
                                                                let promocion = null;
                                                                let productoOrigenPromocion = null;
                                                                producto.producto_origen_promocion.some((idProductoOrigenPromocion) => {
                                                                    productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                                                                    let promocionProducto = null;
                                                                    if (productoOrigenPromocion)
                                                                        promocionProducto = this.props.promociones.byId.promociones[productoOrigenPromocion.idPromocion];
                                                                    if (promocionProducto && promocionProducto.estado === 1 && promocionProducto.formatoPromocion !== "Combo") {
                                                                        promocion = promocionProducto;
                                                                        return true;
                                                                    } else
                                                                        return false;
                                                                });
                                                                return (
                                                                    <div key={idProducto}>
                                                                        <ProductoUnitario
                                                                            key={idProducto}
                                                                            producto={producto}
                                                                            promocion={promocion}
                                                                            productoOrigenPromocion={productoOrigenPromocion}
                                                                            detallePedido={detallePedido}
                                                                            toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}
                                                                            createDetallePedido={this.props.createDetallePedido.bind(this)}
                                                                            deleteDetallePedido={this.props.deleteDetallePedido.bind(this)}
                                                                        />
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </CarrouselDestacados>

                                                </div>
                                            );
                                    })
                            }
                        </div>
                    </React.Fragment>
                }
                {
                    innerWidth < 769 &&
                    <React.Fragment>
                        <div className="container-limit">
                            {
                                this.props.marcas.byId.isFetching ?
                                    <Skelleton tipo="destacados"/>
                                    :
                                    this.props.marcas.allIds.allIds.map((idCategoria, i) => {
                                        let marca = this.props.marcas.byId.marcas[idCategoria];
                                        let producto = [];
                                        let detallePedido = [];
                                        if (marca.destacada === 1 && marca.producto)
                                            return (
                                                <div className="row-categoria">
                                                    <div className="container-limit">
                                                        <h4>{marca.descripcion}</h4>
                                                    </div>
                                                    <div className="productos-hm">
                                                        {
                                                            marca.producto.map((idProducto) => {
                                                                producto = this.props.productos.byId.productos[idProducto];
                                                                detallePedido = this.props.detallesPedido.create.detallesPedido[producto.id + "-"];
                                                                let promocion = null;
                                                                let productoOrigenPromocion = null;
                                                                producto.producto_origen_promocion.some((idProductoOrigenPromocion) => {
                                                                    productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                                                                    let promocionProducto = this.props.promociones.byId.promociones[productoOrigenPromocion.idPromocion];
                                                                    if (promocionProducto.estado === 1 && promocionProducto.formatoPromocion !== "Combo") {
                                                                        promocion = promocionProducto;
                                                                        return true;
                                                                    } else
                                                                        return false;
                                                                });
                                                                return (
                                                                    <div>
                                                                        <ProductoUnitario
                                                                            key={idProducto}
                                                                            producto={producto}
                                                                            promocion={promocion}
                                                                            productoOrigenPromocion={productoOrigenPromocion}
                                                                            detallePedido={detallePedido}
                                                                            toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}
                                                                            createDetallePedido={this.props.createDetallePedido.bind(this)}
                                                                            deleteDetallePedido={this.props.deleteDetallePedido.bind(this)}
                                                                        />
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            );
                                    })
                            }

                        </div>
                    </React.Fragment>
                }
            </section>

        );
    }
}

function

mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        comprasComunitarias: state.comprasComunitarias,
        categorias: state.categorias,
        promociones: state.promociones,
        productosOrigenPromocion: state.productosOrigenPromocion,
        marcas: state.marcas

    };
}

const
    mapDispatchToProps = (dispatch) => {
        return {
            fetchMarcasIfNeeded: (filtros) => {
                dispatch(fetchMarcasIfNeeded(filtros))
            },
            createDetallePedido: (detalle) => {
                dispatch(createDetallePedido(detalle))
            },
            deleteDetallePedido: (detalle) => {
                dispatch(deleteDetallePedido(detalle))
            },
        }
    };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MarcasDestacadas));

