import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom'
//css
import '../../assets/css/Buscador.css';
//Actions
import {fetchProductos, fetchProductosIfNeeded} from "../../actions/ProductoActions"
import {fetchBusquedaProductos, resetBusquedaProductos} from "../../actions/BusquedaActions"
import {createDetallePedido, deleteDetallePedido} from "../../actions/DetallePedidoActions";
import {fetchCategoriasIfNeeded} from "../../actions/CategoriaActions"
//Components
import ProductoUnitario from "./componentes/Productos/ProductoUnitario";
import BarraCategorias from "./BarraCategorias";
//img
import {ReactComponent as List} from "../../assets/images/list.svg";
import {ReactComponent as Squares} from "../../assets/images/squares.svg";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import ModalTools from "./componentes/ModalTools";
import clone from "lodash/clone";
import Skelleton from "./componentes/Skelleton/Skelleton";
import {fetchPromocionesIfNeeded} from "../../actions/PromocionActions";

class Buscador extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModalFilter: false,
            showModalOrder: false,
            filterCateg: false,
            filterMarcas: false,
            filterOfertas: false,
            filterPresentacion: false,
        }
    }

    componentDidMount() {
        let params = new URLSearchParams(this.props.location.search);
        let tienda = this.props.tiendas.update.activo;
        let filtroDefecto = tienda && tienda.filtroDefault ? tienda.filtroDefault : "";

        let filtros = {
            searchPhase: params.get("sp") ? params.get("sp") : "",
            categoria: params.get("cat") ? params.get("cat") : "",
            marca: params.get("marca") ? params.get("marca") : "",
            pres: params.get("pres") ? params.get("pres") : "",
            ofer: params.get("ofer") ? params.get("ofer") : "",
            order: params.get("order") ? params.get("order") : filtroDefecto,
            page: params.get("page") ? params.get("page") : "",
            publicar: true,
        };

        if (window.innerWidth < 321) {
            let paramsMarca = new URLSearchParams(this.props.location.search);
            paramsMarca.set("view", "lista");
            this.props.history.push("/search?" + paramsMarca.toString());
        }

        this.props.resetBusquedaProductos();
        this.props.fetchCategoriasIfNeeded({orden: 1, publicar: true});
        this.props.fetchPromocionesIfNeeded({estado: true});
        this.props.fetchProductos(filtros);
        this.props.fetchBusquedaProductos(filtros);
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        // this.props.resetProductos();
        this.props.resetBusquedaProductos();
    }

    componentDidUpdate(prevProps, prevState) {
        let params = new URLSearchParams(this.props.location.search);
        let tienda = this.props.tiendas.update.activo;
        let filtroDefecto = tienda && tienda.filtroDefault ? tienda.filtroDefault : "";

        let filtros = {
            searchPhase: params.get("sp") ? params.get("sp") : "",
            categoria: params.get("cat") ? params.get("cat") : "",
            marca: params.get("marca") ? params.get("marca") : "",
            pres: params.get("pres") ? params.get("pres") : "",
            ofer: params.get("ofer") ? params.get("ofer") : "",
            order: params.get("order") ? params.get("order") : filtroDefecto,
            page: params.get("page") ? params.get("page") : "",
            publicar: true
        };

        if (this.props.location.search !== prevProps.location.search || this.props.tiendas.update.activo.id !== prevProps.tiendas.update.activo.id) {
            // if (!view) {
            //     let paramsMarca = new URLSearchParams(this.props.location.search);
            //     let prevParamsMarca = new URLSearchParams(prevProps.location.search);
            //     let lastView = prevParamsMarca.get("view");
            //     paramsMarca.set("view", lastView);
            //     this.props.history.push("/search?" + paramsMarca.toString());
            // }
            this.props.resetBusquedaProductos();
            this.props.fetchProductos(filtros);
            this.props.fetchBusquedaProductos(filtros);
            window.scrollTo(0, 0);
        }
        fetchProductosIfNeeded(filtros);
    }

    handleChangeBorrarFiltro(filtro) {
        let paramsMarca = new URLSearchParams(this.props.location.search);
        paramsMarca.set("page", "1");
        paramsMarca.delete(filtro);
        this.props.history.push("/search?" + paramsMarca.toString());
    }

    handleChangeCambiarOrden(e, valor = null) {
        let paramsMarca = new URLSearchParams(this.props.location.search);
        paramsMarca.set("order", valor ? valor : e.target.value);
        this.props.history.push("/search?" + paramsMarca.toString());
        if (valor && this.state.showModalOrder === true) {
            this.setState({showModalOrder: false})
        }
    }

    toggleView() {
        let params = new URLSearchParams(this.props.location.search);
        let view = params.get("view");
        if (view === 'lista') {
            params.set("view", "square");
        } else {
            params.set("view", "lista");
        }
        this.props.history.push("/search?" + params.toString());

    }

    handleShowModalOrder() {
        this.setState({showModalOrder: true});
        document.body.classList.add("no-scroll");

    }

    handleCloseModalOrder() {
        this.setState({showModalOrder: false});
        document.body.classList.remove("no-scroll");
    }

    handleShowModalFilter() {
        this.setState({showModalFilter: true});
        document.body.classList.add("no-scroll");
    }

    handleCloseModalFilter() {
        this.setState({showModalFilter: false});
        document.body.classList.remove("no-scroll");
    }

    handleAgregarCantidad(idProducto) {

        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        let producto = this.props.productos.byId.productos[idProducto];

        //Cantidad
        let nuevo = {};
        if (!detalle) {
            detalle = {};
            detalle["idProducto"] = idProducto;
            detalle["cantidad"] = 1;
        } else {
            detalle.cantidad = detalle.cantidad + 1;
        }

        detalle.precioUnitario = producto.precio;

        nuevo[idProducto + "-"] = detalle;

        this.props.createDetallePedido(nuevo);
        if (!this.mobilecheck())
            this.props.toogleBarraCarrito(true);
    }

    handleQuitarCantidad(idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        if (detalle) {
            if (detalle.cantidad > 1) {
                var cambio = {};
                detalle.cantidad = detalle.cantidad - 1;
                //Cálculo de precio
                let producto = this.props.productos.byId.productos[idProducto];
                detalle.precioUnitario = producto.precio;

                cambio[idProducto + "-"] = detalle;
                this.props.createDetallePedido(cambio);
            } else {
                var cambio = {};
                cambio[idProducto + "-"] = detalle;
                this.props.deleteDetallePedido(cambio);
            }
        }
    }

    handleChangeCantidad(e, idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        if (detalle) {
            var cambio = {};
            detalle.cantidad = parseInt(e.target.value);
            //Cálculo de precio
            let producto = this.props.productos.byId.productos[idProducto];
            detalle.precioUnitario = producto.precio;
            cambio[idProducto + "-"] = detalle;
            this.props.createDetallePedido(cambio);
        }
    }

    mobilecheck() {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    toggleCollapse(e) {
        switch (e.currentTarget.dataset.id) {
            case 'categ':
                this.setState(prevState => ({
                    filterCateg: !prevState.filterCateg
                }));
                break;
            case 'marcas':
                this.setState(prevState => ({
                    filterMarcas: !prevState.filterMarcas
                }));
                break;
            case 'ofertas':
                this.setState(prevState => ({
                    filterOfertas: !prevState.filterOfertas
                }));
                break;
            case 'presentacion':
                this.setState(prevState => ({
                    filterPresentacion: !prevState.filterPresentacion
                }));
                break;
        }
    }

    render() {
        let params = new URLSearchParams(this.props.location.search);
        let tienda = this.props.tiendas.update.activo;
        let filtroDefecto = tienda && tienda.filtroDefault ? tienda.filtroDefault : "";
        let searchPhase = params.get("sp");
        let searchCategoria = params.get("cat");
        let searchMarca = params.get("marca");
        let searchOferta = params.get("ofer");
        let searchPresentacion = params.get("pres");
        let view = params.get("view");
        let order = params.get("order") ? params.get("order") : filtroDefecto;
        let page = params.get("page");

        //Filtros
        let totalFiltros = 0;
        let primerFiltro = null;
        for (let valor of params) {
            totalFiltros++;
            if (totalFiltros === 1)
                primerFiltro = valor[0];
        }

        //Páginas
        let totalProductos = this.props.productos.allIds.totalAllIdsBuscados;
        let totalPaginas = Math.ceil(totalProductos / 51);


        let Ofertas = [];
        let Presentacion = [];


        let marcas = [];
        let categorias = [];
        let presentaciones = [];
        let ofertas = [];
        let Productos = this.props.productos.allIds.allIdsBuscados.map((idProducto) => {
            let producto = this.props.productos.byId.productos[idProducto];
            //Presentaciones
            if (presentaciones.indexOf(producto.presentacion) == -1) {
                let paramsMarca = new URLSearchParams(this.props.location.search);
                paramsMarca.set("pres", producto.presentacion);
                Presentacion.push(
                    <li key={producto.presentacion}>
                        <Link
                            to={{pathname: "/search", search: "?" + paramsMarca.toString()}}
                            onClick={() => {
                                this.state.showModalFilter && this.setState({showModalFilter: false})
                            }}>
                            {producto.presentacion}
                        </Link>
                    </li>);
                presentaciones.push(producto.presentacion);
            }

            //Ofertas
            if (ofertas.indexOf("descuento") == -1 && producto.producto_origen_promocion.length > 0) {
                let paramsMarca = new URLSearchParams(this.props.location.search);
                paramsMarca.set("ofer", "descuento");
                Ofertas.push(
                    <li key={"descuento"}>
                        <Link
                            to={{pathname: "/search", search: "?" + paramsMarca.toString()}}
                            onClick={() => {
                                this.state.showModalFilter && this.setState({showModalFilter: false})
                            }}>
                            Promociones
                        </Link>
                    </li>);
                ofertas.push("descuento");
            }
            let detallePedido = this.props.detallesPedido.create.detallesPedido[idProducto + "-"];
            let promocion = null;
            let productoOrigenPromocion = null;
            producto.producto_origen_promocion.some((idProductoOrigenPromocion) => {
                productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                let promocionProducto = null;
                if (productoOrigenPromocion)
                    promocionProducto = this.props.promociones.byId.promociones[productoOrigenPromocion.idPromocion];
                if (promocionProducto && promocionProducto.estado === 1 && promocionProducto.formatoPromocion !== "Combo") {
                    promocion = promocionProducto;
                    return true;
                } else
                    return false;
            });
            return <ProductoUnitario key={idProducto}
                                     producto={producto}
                                     productoOrigenPromocion={productoOrigenPromocion}
                                     promocion={promocion}
                                     detallePedido={detallePedido}
                                     toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}
                                     createDetallePedido={this.props.createDetallePedido.bind(this)}
                                     deleteDetallePedido={this.props.deleteDetallePedido.bind(this)}/>
        });

        //Marca
        let Marcas = this.props.marcas.allIds.allIdsBuscados.map((idMarca) => {
            let marca = this.props.marcas.byId.marcas[idMarca];
            let paramsMarca = new URLSearchParams(this.props.location.search);
            paramsMarca.set("marca", marca ? marca.descripcion : "");
            paramsMarca.set("page", "1");
            return (
                <li key={marca ? marca.id : null}>
                    <Link
                        to={{
                            pathname: "/search",
                            search: "?" + paramsMarca.toString()
                        }}
                        onClick={() => {
                            this.state.showModalFilter && this.setState({showModalFilter: false})
                        }}>
                        {marca ? marca.descripcion : ""}
                    </Link>
                </li>);
        });

        //Categorias
        let Categorias = [];
        if (searchPhase)
            Categorias = this.props.categorias.allIds.allIdsBuscados.map((idCategoria) => {
                let categoria = this.props.categorias.byId.categorias[idCategoria];
                console.log("categoria", categoria);
                let params = new URLSearchParams(this.props.location.search);
                params.set("cat", categoria ? categoria.id : "");
                params.set("page", "1");
                return (
                    <li key={categoria ? categoria.id : ""}>
                        <Link
                            to={{
                                pathname: "/search",
                                search: "?" + params.toString()
                            }}
                            onClick={() => {
                                this.state.showModalFilter && this.setState({showModalFilter: false})
                            }}>
                            {categoria ? categoria.desCategoria : ""}
                        </Link>
                    </li>);
            });


        //Selección de categoria
        let linkCategorias = [];
        let categoriaSeleccionada = {};
        if (searchCategoria && this.props.categorias.byId.categorias[searchCategoria])
            categoriaSeleccionada = this.props.categorias.byId.categorias[searchCategoria];
        if (searchCategoria && !searchPhase) {
            //Navegador de categoria seleccioanda
            var idCategoriaPadre = categoriaSeleccionada.idCategoriaPadre;
            while (idCategoriaPadre != null) {
                let categoria = this.props.categorias.byId.categorias[idCategoriaPadre];
                let paramsMarca = new URLSearchParams(this.props.location.search);
                if (categoria) {
                    paramsMarca.set("cat", categoria.id);
                    linkCategorias.push(<li><Link
                        to={{pathname: "/search", search: "?" + paramsMarca.toString()}}>{categoria.desCategoria}</Link>
                    </li>);
                }
                idCategoriaPadre = categoria ? categoria.idCategoriaPadre : null;
            }
            linkCategorias.reverse();

            //Filtros de categoria
            let categorias = [];
            if (categoriaSeleccionada && categoriaSeleccionada.categoria_hija && categoriaSeleccionada.categoria_hija.length > 0)
                categorias = categoriaSeleccionada.categoria_hija;
            else {
                let categoriaPadre = this.props.categorias.byId.categorias[categoriaSeleccionada.idCategoriaPadre];
                if (categoriaPadre)
                    categorias = categoriaPadre.categoria_hija;
            }

            if (categorias && categorias.length > 0)
                Categorias.push(categorias.map((idCategoriaHija) => {
                    let categoria = this.props.categorias.byId.categorias[idCategoriaHija];
                    let paramsMarca = new URLSearchParams(this.props.location.search);
                    paramsMarca.set("page", "1");
                    if (categoria) {
                        paramsMarca.set("cat", categoria.id);
                        return <li key={categoria.id}><Link
                            to={{
                                pathname: "/search",
                                search: "?" + paramsMarca.toString()
                            }}
                            onClick={() => {
                                this.state.showModalFilter && this.setState({showModalFilter: false})
                            }}>{categoria.desCategoria}</Link>
                        </li>
                    }
                }))
        }


        return (
            <div className="buscador-section">
                <ModalTools titulo="Ordenar por" show={this.state.showModalOrder}
                            onHide={() => this.handleCloseModalOrder()}>
                    {
                        !searchPhase &&
                        <div>
                            <ul className="lista-filtros-modal">
                                <li
                                    onClick={(e) => this.handleChangeCambiarOrden(e, "relevantes")}
                                    className={order === "relevantes" ? "selected" : ""}
                                >
                                    Más relevantes
                                </li>
                                <li
                                    onClick={(e) => this.handleChangeCambiarOrden(e, "menor_precio")}
                                    className={order === "menor_precio" ? "selected" : ""}
                                >
                                    Menor precio
                                </li>
                                <li
                                    onClick={(e) => this.handleChangeCambiarOrden(e, "mayor_precio")}
                                    className={order === "mayor_precio" ? "selected" : ""}
                                >
                                    Mayor precio
                                </li>
                                <li
                                    onClick={(e) => this.handleChangeCambiarOrden(e, "nomProducto")}
                                    className={order === "nomProducto" ? "selected" : ""}
                                >
                                    A-Z
                                </li>
                            </ul>
                        </div>
                    }

                </ModalTools>

                <ModalTools titulo="Filtrar por" show={this.state.showModalFilter}
                            onHide={() => this.handleCloseModalFilter()}>
                    <div className="filtros-responsive">
                        {
                            (!searchCategoria || (searchCategoria && !searchPhase)) &&
                            <ul className={`lista-filtros-modal ${this.state.filterCateg && "down"}`}>
                                <li data-id="categ" onClick={(e) => {
                                    this.toggleCollapse(e)
                                }}>
                                    Categorias
                                </li>
                                <ul className="sublista-filtros-modal"
                                    style={{display: this.state.filterCateg ? "block" : "none"}}>
                                    {Categorias}
                                </ul>
                            </ul>

                        }
                        {
                            !searchMarca &&
                            <ul className={`lista-filtros-modal ${this.state.filterMarcas && "down"}`}>
                                <li data-id="marcas" onClick={(e) => {
                                    this.toggleCollapse(e)
                                }}>
                                    Marcas
                                </li>
                                <ul className="sublista-filtros-modal"
                                    style={{display: this.state.filterMarcas ? "block" : "none"}}>
                                    {Marcas}
                                </ul>
                            </ul>
                        }
                        {
                            !searchOferta && ofertas.length > 0 &&
                            <ul className={`lista-filtros-modal ${this.state.filterOfertas && "down"}`}>
                                <li data-id="ofertas" onClick={(e) => {
                                    this.toggleCollapse(e)
                                }}>
                                    Ofertas
                                </li>
                                <ul className="sublista-filtros-modal"
                                    style={{display: this.state.filterOfertas ? "block" : "none"}}>
                                    {Ofertas}
                                </ul>
                            </ul>
                        }
                        {
                            !searchPresentacion &&
                            <ul className={`lista-filtros-modal ${this.state.filterPresentacion && "down"}`}>
                                <li data-id="presentacion" onClick={(e) => {
                                    this.toggleCollapse(e)
                                }}>
                                    Presentación
                                </li>
                                <ul className="sublista-filtros-modal"
                                    style={{display: this.state.filterPresentacion ? "block" : "none"}}>
                                    {Presentacion}
                                </ul>
                            </ul>
                        }
                    </div>
                </ModalTools>
                <BarraCategorias/>
                <section style={{backgroundColor: "#EEEEEE"}}>
                    <div className="filter-nav">
                        <div className="filter-tools">
                            <div className="tool-container">
                                <button onClick={() => this.handleShowModalOrder()}>
                                    <span className="tool-icon ordenar"></span>
                                    <span>Ordenar</span>
                                </button>
                            </div>
                            <div className="tool-container">
                                <button onClick={() => this.toggleView()}>
                                            <span
                                                className={`tool-icon ${view !== 'lista' ? "listado" : "square"}`}></span>
                                    <span>{view !== 'lista' ? "Listado" : "Tarjetas"}</span>
                                </button>
                            </div>
                            <div className="tool-container">
                                <button onClick={() => this.handleShowModalFilter()}>
                                    <span className="tool-icon filtrado"></span>
                                    <span>Filtrar</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="filtros-atributos responsive">
                        {
                            searchCategoria && searchPhase &&
                            <div className="tag-close">
                                <span>{categoriaSeleccionada ? categoriaSeleccionada.desCategoria : ""}</span>
                                <a onClick={() => this.handleChangeBorrarFiltro("cat")}>
                                    <FontAwesomeIcon icon={faTimes} color="#EEEEEE"/>
                                </a>
                            </div>
                        }
                        {
                            searchMarca &&
                            <div className="tag-close">
                                <span>{searchMarca}</span>
                                <a onClick={() => this.handleChangeBorrarFiltro("marca")}>
                                    <FontAwesomeIcon icon={faTimes} color="#EEEEEE"/>
                                </a>
                            </div>
                        }
                        {
                            searchOferta &&
                            <div className="tag-close">
                                <span>{searchOferta}</span>
                                <a onClick={() => this.handleChangeBorrarFiltro("ofer")}>
                                    <FontAwesomeIcon icon={faTimes} color="#EEEEEE"/>
                                </a>
                            </div>
                        }
                        {
                            searchPresentacion &&
                            <div className="tag-close">
                                <span>{searchPresentacion}</span>
                                <a onClick={() => this.handleChangeBorrarFiltro("pres")}>
                                    <FontAwesomeIcon icon={faTimes} color="#EEEEEE"/>
                                </a>
                            </div>
                        }
                    </div>
                    <div className="filtros responsive">
                        <div className="datos-filtros">
                            {
                                searchPhase &&
                                <h4>{searchPhase}</h4>
                            }
                            {
                                searchMarca && primerFiltro === "marca" &&
                                <h4>{searchMarca}</h4>
                            }
                            {
                                searchCategoria && primerFiltro === "cat" &&
                                <h4>{categoriaSeleccionada ? categoriaSeleccionada.desCategoria : ""}</h4>
                            }
                            <p>{totalProductos ? totalProductos + " resultados" : ""}</p>
                        </div>
                    </div>
                    {
                        searchCategoria &&
                        <div className="container-limit buscador-breadcrum">
                            <ul className="breadcrum">
                                {linkCategorias}
                            </ul>

                        </div>
                    }
                    <div
                        className={`buscador container-limit ${window.innerWidth <= 320 || view === 'lista' ? 'grilla' : ""}`}>
                        <div className="filtros">
                            <div className="datos-filtros">
                                {
                                    searchPhase &&
                                    <h4>{searchPhase}</h4>
                                }
                                {
                                    searchMarca && primerFiltro === "marca" &&
                                    <h4>{searchMarca}</h4>
                                }
                                {
                                    searchCategoria && primerFiltro === "cat" &&
                                    <h4>{categoriaSeleccionada ? categoriaSeleccionada.desCategoria : ""}</h4>
                                }
                                <p>{totalProductos ? totalProductos + " resultados" : ""}</p>
                            </div>
                            <div className="filtros-atributos">
                                {
                                    searchCategoria && primerFiltro !== "cat" &&
                                    <span className="tag-close">
                                <span>{categoriaSeleccionada ? categoriaSeleccionada.desCategoria : ""}</span>
                                <a onClick={() => this.handleChangeBorrarFiltro("cat")}>
                                    <FontAwesomeIcon icon={faTimes} color="#EEEEEE"/>
                                </a>
                            </span>
                                }
                                {
                                    searchMarca && primerFiltro !== "marca" &&
                                    <span className="tag-close">
                                <span>{searchMarca}</span>
                                <a onClick={() => this.handleChangeBorrarFiltro("marca")}>
                                    <FontAwesomeIcon icon={faTimes} color="#EEEEEE"/>
                                </a>
                            </span>
                                }
                                {
                                    searchOferta &&
                                    <span className="tag-close">
                                <span>{searchOferta}</span>
                                <a onClick={() => this.handleChangeBorrarFiltro("ofer")}>
                                    <FontAwesomeIcon icon={faTimes} color="#EEEEEE"/>
                                </a>
                            </span>
                                }
                                {
                                    searchPresentacion &&
                                    <span className="tag-close">
                                <span>{searchPresentacion}</span>
                                <a onClick={() => this.handleChangeBorrarFiltro("pres")}>
                                    <FontAwesomeIcon icon={faTimes} color="#EEEEEE"/>
                                </a>
                            </span>
                                }
                            </div>
                            <div className="filtros-opciones">
                                <br/>
                                <br/>
                                {
                                    !searchPhase &&
                                    <b>Ordenar productos</b>
                                }
                                <div className="ordenar" onChange={(e) => this.handleChangeCambiarOrden(e)}>
                                    {
                                        !searchPhase &&
                                        <select value={order}>
                                            <option value="relevantes">Más relevantes</option>
                                            <option value="menor_precio">Menor precio</option>
                                            <option value="mayor_precio">Mayor precio</option>
                                            <option value="nomProducto">A-Z</option>
                                        </select>
                                    }
                                    <Squares style={{cursor: "pointer"}} onClick={() => {
                                        let paramsMarca = new URLSearchParams(this.props.location.search);
                                        paramsMarca.set("view", "square");
                                        this.props.history.push("/search?" + paramsMarca.toString());

                                    }}
                                             width="15px" height="15px"/>
                                    <List style={{cursor: "pointer"}} onClick={() => {
                                        let paramsMarca = new URLSearchParams(this.props.location.search);
                                        paramsMarca.set("view", "lista");
                                        this.props.history.push("/search?" + paramsMarca.toString());
                                    }}
                                          width="15px" height="15px"/>
                                </div>
                                <br/><br/>
                                {
                                    (Categorias.length > 0 &&
                                        (!searchCategoria || (searchCategoria && !searchPhase))) &&
                                    <div>
                                        <b>Categorias</b>
                                        {Categorias}
                                    </div>

                                }
                                {
                                    !searchMarca &&
                                    <div>
                                        <b>Marcas</b>
                                        {Marcas}
                                    </div>
                                }
                                {
                                    !searchOferta && ofertas.length > 0 &&
                                    <div>
                                        <b>Ofertas</b>
                                        {Ofertas}
                                    </div>
                                }
                                {
                                    !searchPresentacion &&
                                    <div>
                                        <b>Presentación</b>
                                        {Presentacion}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="productos">
                            <div className="container-productos">
                                {
                                    this.props.productos.byId.isFetching ?
                                        <Skelleton tipo="productos"/>
                                        :
                                        Productos.length > 0 ? Productos : <p>No hay resultados</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container-limit">
                        {
                            totalPaginas !== 0 &&
                            <div className="paginas">
                                {
                                    totalPaginas && totalPaginas > 1 &&
                                    [...Array(totalPaginas).keys()].map((numero, i) => {
                                        let pagina = numero + 1;
                                        let paramsPagina = new URLSearchParams(this.props.location.search);
                                        paramsPagina.set("page", pagina ? pagina : "1");
                                        return <Link key={"pag-" + i} to={{
                                            pathname: "/search",
                                            search: "?" + paramsPagina.toString()
                                        }}
                                                     className={"numero-pagina " + ((pagina.toString()) === page || (!page && pagina === 1) ? "active" : "")}
                                                     onClick={() => window.scrollTo(0, 0)}>
                                            {numero + 1}
                                        </Link>
                                    })
                                }
                            </div>
                        }
                    </div>

                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        categorias: state.categorias,
        marcas: state.marcas,
        promociones: state.promociones,
        productosOrigenPromocion: state.productosOrigenPromocion,
        tiendas: state.tiendas,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProductos: (filtros) => {
            dispatch(fetchProductos(filtros))
        },
        createDetallePedido: (detalle) => {
            dispatch(createDetallePedido(detalle))
        },
        fetchBusquedaProductos: (filtros) => {
            return dispatch(fetchBusquedaProductos(filtros))
        },
        fetchCategoriasIfNeeded: (filtros) => {
            dispatch(fetchCategoriasIfNeeded(filtros))
        },
        resetBusquedaProductos: () => {
            dispatch(resetBusquedaProductos())
        },
        fetchPromocionesIfNeeded: (filtros) => {
            dispatch(fetchPromocionesIfNeeded(filtros))
        },
        deleteDetallePedido: (detalle) => {
            dispatch(deleteDetallePedido(detalle))
        },
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Buscador));

