import * as errorMessages from '../constants/MessageConstants';
//Actions
import {fetchDetallesPedidoIfNeeded, invalidateDetallesPedido} from "./DetallePedidoActions";
import {logout} from "../actions/AuthActions";
//api
import estadosDetallePedidoU from "../api/estadosDetallePedido";
//import auth from "../api/auth";

//ESTADODETALLEPEDIDO
export const CREATE_ESTADODETALLEPEDIDO = 'CREATE_ESTADODETALLEPEDIDO';
export const REQUEST_CREATE_ESTADODETALLEPEDIDO = "REQUEST_CREATE_ESTADODETALLEPEDIDO";
export const SUCCESS_CREATE_ESTADODETALLEPEDIDO = "SUCCESS_CREATE_ESTADODETALLEPEDIDO";
export const ERROR_CREATE_ESTADODETALLEPEDIDO = "ERROR_CREATE_ESTADODETALLEPEDIDO";
export const RESET_CREATE_ESTADODETALLEPEDIDO = "RESET_CREATE_ESTADODETALLEPEDIDO";

//UPDATE
export const UPDATE_ESTADODETALLEPEDIDO = 'UPDATE_ESTADODETALLEPEDIDO';
export const REQUEST_UPDATE_ESTADODETALLEPEDIDO = "REQUEST_UPDATE_ESTADODETALLEPEDIDO";
export const SUCCESS_UPDATE_ESTADODETALLEPEDIDO = "SUCCESS_UPDATE_ESTADODETALLEPEDIDO";
export const ERROR_UPDATE_ESTADODETALLEPEDIDO = "ERROR_UPDATE_ESTADODETALLEPEDIDO";
export const RESET_UPDATE_ESTADODETALLEPEDIDO = "RESET_UPDATE_ESTADODETALLEPEDIDO";


//CREATE
function requestEstadoDetallePedido() {
    return {
        type: REQUEST_CREATE_ESTADODETALLEPEDIDO,
    }
}

function receiveEstadoDetallePedido() {
    return {
        type: SUCCESS_CREATE_ESTADODETALLEPEDIDO,
        receivedAt: Date.now()
    }
}

function errorEstadoDetallePedido(error) {
    return {
        type: ERROR_CREATE_ESTADODETALLEPEDIDO,
        error: error,
    }
}

export function resetEstadoDetallePedido(error) {
    return {
        type: RESET_CREATE_ESTADODETALLEPEDIDO,
        error: error,
    }
}

export function createEstadoDetallePedido(idDetallePedido, estadoDetallePedido) {
    return {
        type: CREATE_ESTADODETALLEPEDIDO,
        id: idDetallePedido,
        estadoDetallePedido
    }
}

function saveCreateEstadoDetallePedido(idPedido) {
    return (dispatch, getState) => {
        dispatch(requestEstadoDetallePedido());
        //Tomo los nuevos packing
        var estadosDetallePedido = getState().estadosDetallePedido.create.estadosDetallePedido.map((id) => {
            return (getState().estadosDetallePedido.byId.estadosDetallePedido[id]);
        });
        return estadosDetallePedidoU.saveCreate(estadosDetallePedido)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveEstadoDetallePedido());
                    // dispatch(invalidateDetallesPedido());
                    // dispatch(fetchDetallesPedidoIfNeeded(idPedido));
                    //Refresco token
                    //auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorEstadoDetallePedido(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorEstadoDetallePedido(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE
function requestUpdateEstadoDetallePedido() {
    return {
        type: REQUEST_UPDATE_ESTADODETALLEPEDIDO,
    }
}

function receiveUpdateEstadoDetallePedido() {
    return {
        type: SUCCESS_UPDATE_ESTADODETALLEPEDIDO,
        receivedAt: Date.now()
    }
}

function errorUpdateEstadoDetallePedido(error) {
    return {
        type: ERROR_UPDATE_ESTADODETALLEPEDIDO,
        error: error,
    }
}

export function resetUpdateEstadoDetallePedido() {
    return {
        type: RESET_UPDATE_ESTADODETALLEPEDIDO
    }
}

export function updateEstadoDetallePedido(id, estadoDetallePedido) {
    return {
        type: UPDATE_ESTADODETALLEPEDIDO,
        id,
        estadoDetallePedido
    }
}

function saveUpdateEstadoDetallePedido(idPedido) {
    return (dispatch, getState) => {
        dispatch(requestUpdateEstadoDetallePedido());

        //Tomo los nuevos packing
        var packing = getState().estadosDetallePedido.update.estadosDetallePedido.map((id) => {
            return (getState().estadosDetallePedido.byId.estadosDetallePedido[id]);
        });

        return estadosDetallePedidoU.saveUpdate(packing)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdateEstadoDetallePedido());
                    // dispatch(invalidateDetallesPedido());
                    // dispatch(fetchDetallesPedidoIfNeeded(idPedido));
                    //Refresco token
                    //auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateEstadoDetallePedido(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorUpdateEstadoDetallePedido(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

export function changeEstadoDetallePedido(id, estadosDetallePedido) {
    return (dispatch, getState) => {
        //Decidir si actualizar o modificar
        if (getState().estadosDetallePedido.create.estadosDetallePedido.indexOf(id) != -1 || !getState().estadosDetallePedido.byId.estadosDetallePedido[id])
            dispatch(createEstadoDetallePedido(id, estadosDetallePedido));
        else
            dispatch(updateEstadoDetallePedido(id, estadosDetallePedido));
    }
}

export function saveEstadoDetallePedido(idPedido) {
    return (dispatch, getState) => {
        getState().estadosDetallePedido.create.estadosDetallePedido.length > 0 ? dispatch(saveCreateEstadoDetallePedido()) : Promise.resolve()
            .then(() =>
                getState().estadosDetallePedido.update.estadosDetallePedido.length > 0 ? dispatch(saveUpdateEstadoDetallePedido()) : Promise.resolve())
            .then(() =>
                dispatch(invalidateDetallesPedido()))
            .then(() =>
                dispatch(fetchDetallesPedidoIfNeeded(idPedido))
            );
    }
}
