import c from '../constants/Constants';

require('es6-promise').polyfill();
require('isomorphic-fetch');


var analiticas = {
    saveCreate(analitica) {
        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8"
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(analitica)
        };
        if (!!localStorage.token) {
            defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
        }

        return fetch(c.BASE_URL + '/analiticas/', defaultOptions);
    }

};

export default analiticas;