import React from "react";

import c from '../constants/Constants';

require('es6-promise').polyfill();
require('isomorphic-fetch');

var estadosPedido = {
    save(estado) {
        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(estado)
        };

        return fetch(c.BASE_URL + '/estadosPedido/', defaultOptions);
    }
};

export default estadosPedido;