import {RECEIVE_DETALLESREMITIDOS, RESET_DETALLESREMITIDOS} from '../actions/DetalleRemitidoActions';

import {
    CREATE_PACKINGLIST,
    DELETE_PACKINGLIST,
    ERROR_CREATE_PACKINGLIST,
    ERROR_DELETE_PACKINGLIST,
    ERROR_IMPRIMIR_PACKINGLIST,
    ERROR_UPDATE_PACKINGLIST,
    REQUEST_CREATE_PACKINGLIST,
    REQUEST_DELETE_PACKINGLIST,
    REQUEST_IMPRIMIR_PACKINGLIST,
    REQUEST_PREGUNTAR_IMPRIMIR_PACKINGLIST,
    REQUEST_UPDATE_PACKINGLIST,
    RESET_IMPRIMIR_PACKINGLIST,
    RESET_PACKINGLIST,
    SUCCESS_CREATE_PACKINGLIST,
    SUCCESS_DELETE_PACKINGLIST,
    SUCCESS_IMPRIMIR_PACKINGLIST,
    SUCCESS_PREGUNTAR_IMPRIMIR_PACKINGLIST,
    SUCCESS_UPDATE_PACKINGLIST,
    UPDATE_PACKINGLIST
} from "../actions/PackingListActions";

import {combineReducers} from 'redux';
import normalizeDatos from "../normalizers/normalizeDetallesRemitidos";

import merge from "lodash/merge";
import pickBy from "lodash/pickBy";
import union from "lodash/union";
import remove from "lodash/remove";
import difference from "lodash/difference";

function packingListById(state = {
    isFetching: false,
    didInvalidate: true,
    packingList: {}
}, action) {
    switch (action.type) {
        case RECEIVE_DETALLESREMITIDOS:
            var packingList = normalizeDatos(action.detallesRemitidos).entities.packingList;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                packingList: packingList ? packingList : [],
                lastUpdated: action.receivedAt
            });
        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                packingList: [],
            });
        case CREATE_PACKINGLIST:
            var packing = {};
            packing[action.id] = action.packingList;
            return Object.assign({}, state, {
                isFetching: false,
                packingList: merge({}, state.packingList, packing)
            });
        case UPDATE_PACKINGLIST:
            var packing = {};
            packing[action.id] = action.packingList;
            return Object.assign({}, state, {
                isFetching: false,
                packingList: merge({}, state.packingList, packing)
            });
        case RESET_PACKINGLIST:
            if (action.idDetalleRemitido) {
                return Object.assign({}, state, {
                    isFetching: false,
                    error: null,
                    packingList: pickBy(state.packingList, function (packaginList, key) {
                        return packaginList.idDetalleRemitido !== action.idDetalleRemitido;
                    })

                })
            } else {
                return Object.assign({}, state, {
                    isSaving: false,
                    error: null,
                    packingList: {},
                });
            }
        case DELETE_PACKINGLIST:
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                packingList: pickBy(state.packingList, function (packaginList, key) {
                    return key !== action.idPackingList;
                })
            });

        default:
            return state
    }
}


function allPackingList(state = [], action) {
    switch (action.type) {

        case CREATE_PACKINGLIST:
            return union(state, [action.id]);
        case DELETE_PACKINGLIST:
            return difference(state, [action.idPackingList]);
        case RESET_DETALLESREMITIDOS:
            return [];
        case RESET_PACKINGLIST:
            return difference(state, action.idsPacking);
        case RECEIVE_DETALLESREMITIDOS:
            var packingList = normalizeDatos(action.detallesRemitidos).entities.packingList;
            return packingList ? Object.keys(packingList) : [];
        default:
            return state
    }

}


function create(state = {
    isCreating: false,
    packingList: []
}, action) {
    switch (action.type) {

        case CREATE_PACKINGLIST:
            return Object.assign({}, state, {
                isCreating: false,
                packingList: union(state.packingList, [action.id])
            });
        case REQUEST_CREATE_PACKINGLIST:
            return Object.assign({}, state, {
                isCreating: true
            });
        case SUCCESS_CREATE_PACKINGLIST:
            return Object.assign({}, state, {
                isCreating: false,
                packingList: []
            });
        case ERROR_CREATE_PACKINGLIST:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_PACKINGLIST:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                packingList: difference(state.packingList, action.idsPacking)
            })
        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isCreating: false,
                packingList: [],
                error: ""
            });
        case DELETE_PACKINGLIST:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                packingList: remove(state.packingList, function (packaginList) {
                    return packaginList !== action.idPackingList;
                })
            });
        default:
            return state
    }

}

function update(state = {
    isUpdating: false,
    packingList: []
}, action) {
    switch (action.type) {

        case UPDATE_PACKINGLIST:
            return Object.assign({}, state, {
                isUpdating: false,
                packingList: union({}, state.packingList, [action.id])
            });
        case REQUEST_UPDATE_PACKINGLIST:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case SUCCESS_UPDATE_PACKINGLIST:
            return Object.assign({}, state, {
                isUpdating: false,
                packingList: []
            });
        case ERROR_UPDATE_PACKINGLIST:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_PACKINGLIST:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                packingList: difference(state.packingList, action.idsPacking)
            })
        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isUpdating: false,
                packingList: [],
                error: ""
            });
        case DELETE_PACKINGLIST:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                packingList: remove(state.packingList, function (packaginList) {
                    return packaginList !== action.idPackingList;
                })
            });
        default:
            return state
    }

}

function deleter(state = {
    isDeleting: false,
    packingList: []
}, action) {
    switch (action.type) {

        case REQUEST_DELETE_PACKINGLIST:
            return Object.assign({}, state, {
                isDeleting: true
            });
        case SUCCESS_DELETE_PACKINGLIST:
            return Object.assign({}, state, {
                isDeleting: false,
                packingList: []
            });
        case ERROR_DELETE_PACKINGLIST:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_PACKINGLIST:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                packingList: union(state.packingList, action.idsPacking)
            })

        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isDeleting: false,
                packingList: [],
                error: ""
            });
        case DELETE_PACKINGLIST:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                packingList: union({}, state.packingList, [action.idPackingList])
            });
        default:
            return state
    }

}

function impresion(state = {
    isPrinting: false,
    preguntar: false,
    error: null
}, action) {
    switch (action.type) {

        case REQUEST_IMPRIMIR_PACKINGLIST:
            return Object.assign({}, state, {
                isPrinting: true
            });
        case SUCCESS_IMPRIMIR_PACKINGLIST:
            return Object.assign({}, state, {
                isPrinting: false,
                preguntar: false
            });
        case ERROR_IMPRIMIR_PACKINGLIST:
            return Object.assign({}, state, {
                isPrinting: false,
                error: action.error
            });
        case RESET_IMPRIMIR_PACKINGLIST:
            return Object.assign({}, state, {
                isPrinting: false,
                preguntar: false,
                error: null
            });
        case REQUEST_PREGUNTAR_IMPRIMIR_PACKINGLIST:
            return Object.assign({}, state, {
                isPrinting: false,
                preguntar: true,
                error: null
            });
        case SUCCESS_PREGUNTAR_IMPRIMIR_PACKINGLIST:
            return Object.assign({}, state, {
                isPrinting: false,
                preguntar: false,
                error: null
            });
        default:
            return state
    }

}


const packingList = combineReducers({
    byId: packingListById,
    allIds: allPackingList,
    create: create,
    update: update,
    delete: deleter,
    imprimir: impresion
});

export default packingList;