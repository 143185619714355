import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom"

import '../../assets/css/LoginSignUp.css';
//Actions
import {olvideMiPassword, resetLogin} from '../../actions/AuthActions';
//components
import Loader from "./layout/Loader"


class OlvidePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        };
        this.handleChange = this.handleChange.bind(this);
        //this.restablecerContraseña = this.restablecerContraseña.bind(this);
    }

    componentDidMount() {
        document.body.classList.add("no-scroll");
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.body.classList.remove("no-scroll");
        this.props.resetLogin();
    }

    handleChange(e) {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        this.setState(cambio);
    }

    submitOlvidePassword(e) {
        e.preventDefault();
        this.props.olvideMiPassword(this.state.email);
    }

    render() {
        const tienda = this.props.tiendas.update.activo;
        return (
            <section className="login-sign-up">
                <div className="container-modal">
                    <div className="container-doble">
                        <div className="col-3 container-marca">
                            {
                                tienda && tienda.logoBlanco &&
                                <img src={`${process.env.PUBLIC_URL}/images/logos/${tienda && tienda.logoBlanco ? tienda.logoBlanco : ""}`}
                                    className="logo"/>
                            }
                            <div className="container-yaregistrado">
                                <p>
                                    Si ya estás registrado sólo inicia sesión
                                </p>
                                <Link to="/login">
                                    <button>
                                        INICIAR SESIÓN
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="container-datos">
                                <div className="cruz-cerrar" onClick={() => {
                                    this.props.history.push("/")
                                }}>&times;</div>
                                <h2>OLVIDE MI <i>CONTRASEÑA</i></h2>
                                {/*<div className="container-redes">*/}
                                {/*<FontAwesomeIcon icon={faFacebookF} color="black"/>*/}
                                {/*<FontAwesomeIcon icon={faGoogle} color="black"/>*/}
                                {/*</div>*/}

                                <form onSubmit={(e) => this.submitOlvidePassword(e)}
                                      style={{display: this.props.auth.mensaje ? "none" : "flex"}}>
                                    <p className="olvidePass">Te enviaremos un email a tu casilla con las instrucciones
                                        para recuperar la contraseña.</p>
                                    <input placeholder="E-mail" type="text" id="email"
                                           value={this.state.email} onChange={this.handleChange}/>
                                    <p style={{
                                        padding: "2px 15px",
                                        margin: "0",
                                        color: "#ea4e69",
                                        fontWeight: "300"
                                    }}>{this.props.auth.errorMessage}</p>
                                    {
                                        !this.props.auth.isFetching &&
                                        <button type="submit" disabled={this.props.auth.isFetching}>ENVIAR</button>
                                    }
                                    {
                                        this.props.auth.isFetching &&
                                        <Loader/>
                                    }
                                </form>
                                <div className="errores"><p>{this.props.auth.mensaje}</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        usuarios: state.usuarios,
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        olvideMiPassword: (email) => {
            dispatch(olvideMiPassword(email))
        },
        resetLogin: () => {
            dispatch(resetLogin())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OlvidePassword);

