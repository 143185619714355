import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {
    const stock = new schema.Entity('stocks', {}, {idAttribute: "id"});
    const stockVariedad = new schema.Entity('stocksVariedad', {stock: stock}, {idAttribute: "id"});
    const variedadCaracteristica = new schema.Entity('variedadesCaracteristica', {stock_variedad: [stockVariedad]}, {idAttribute: "id"});
    const caracteristicaProducto = new schema.Entity('caracteristicasProducto', {variedad_caracteristica: [variedadCaracteristica]}, {idAttribute: "id"});

    const marca = new schema.Entity('marcas', {}, {idAttribute: "id"});
    let productoOrigenPromocion = new schema.Entity("productosOrigenPromocion", {}, {
        idAttribute: "id"
    });

    const producto = new schema.Entity('productos', {
        marca: marca,
        producto_origen_promocion: [productoOrigenPromocion],
        caracteristica_producto: [caracteristicaProducto]
    }, {idAttribute: "id"});

    productoOrigenPromocion.define({
        producto: producto
    });
    const productoDestinoPromocion = new schema.Entity('productosDestinoPromocion', {producto: producto}, {idAttribute: "id"});
    const promocion = new schema.Entity('promociones', {
        producto_destino_promocion: [productoDestinoPromocion],
        producto_origen_promocion: [productoOrigenPromocion]
    }, {idAttribute: "id"});
    const mySchema = {promociones: [promocion]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export function normalizeDato(myData) {
    const stock = new schema.Entity('stocks', {}, {idAttribute: "id"});
    const stockVariedad = new schema.Entity('stocksVariedad', {stock: stock}, {idAttribute: "id"});
    const variedadCaracteristica = new schema.Entity('variedadesCaracteristica', {stock_variedad: [stockVariedad]}, {idAttribute: "id"});
    const caracteristicaProducto = new schema.Entity('caracteristicasProducto', {variedad_caracteristica: [variedadCaracteristica]}, {idAttribute: "id"});

    const compraComunitaria = new schema.Entity('comprasComunitarias', {}, {idAttribute: "id"});
    const marca = new schema.Entity('marcas', {}, {idAttribute: "id"});
    const producto = new schema.Entity('productos', {
        marca: marca,
        caracteristica_producto: [caracteristicaProducto]
    }, {idAttribute: "id"});
    const productoOrigenPromocion = new schema.Entity('productosOrigenPromocion', {producto: producto}, {idAttribute: "id"});
    const productoDestinoPromocion = new schema.Entity('productosDestinoPromocion', {producto: producto}, {idAttribute: "id"});
    const promocion = new schema.Entity('promociones', {
        producto_origen_promocion: [productoOrigenPromocion],
        producto_destino_promocion: [productoDestinoPromocion]
    }, {idAttribute: "id"});
    const mySchema = promocion;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}