import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
//css
import '../../../assets/css/CarrouselProductoPresentacion.css';

//Actions
//img


export default function CarrouselProductoPresentacion(props) {
    const [swiper, updateSwiper] = useState(null);

    if (swiper) {
        swiper.update();
        swiper.slideTo(props.idImagenSeleccionada !== 0 ? props.idImagenSeleccionada : 1);
        swiper.on('imagesReady', function () {
            swiper.update();
            swiper.slideTo(1);
        });
    }

    const params = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
        initialSlide: 1,
        normalizeSlideIndex: true,
        slidesPerColumnFill: 'row',
        autoWidth: true,
        loop: true,
        getSwiper: updateSwiper, // Get swiper instance callback
    };

    return (
        <React.Fragment>
            <Swiper {...params} shouldSwiperUpdate updateOnImagesReady>
                {props.children}
            </Swiper>
        </React.Fragment>
    );
}

