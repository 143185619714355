import {normalize, schema, denormalize} from 'normalizr';

export function normalizeDatos(myData) {
    const direccionEntrega = new schema.Entity('direccionesEntrega', {}, {idAttribute: "id"});

    const mySchema = {direccionesEntrega: [direccionEntrega]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const direccionEntrega = new schema.Entity('direccionesEntrega', {}, {idAttribute: "id"});

    const mySchema = direccionEntrega;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const direccionEntrega = new schema.Entity('direccionesEntrega', {}, {idAttribute: "id"});

    const mySchema = direccionEntrega;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}


