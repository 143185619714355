import {
    CREATE_PEDIDO,
    ERROR_CREATE_PEDIDO,
    ERROR_PEDIDO,
    ERROR_PEDIDOS,
    ERROR_UPDATE_PEDIDO,
    INVALIDATE_PEDIDOS,
    RECEIVE_PEDIDO,
    RECEIVE_PEDIDOS,
    REQUEST_CREATE_PEDIDO,
    REQUEST_PEDIDO,
    REQUEST_PEDIDOS,
    REQUEST_UPDATE_PEDIDO,
    RESET_CREATE_PEDIDO,
    RESET_PEDIDOS,
    RESET_UPDATE_PEDIDO,
    SUCCESS_CREATE_PEDIDO,
    SUCCESS_UPDATE_PEDIDO,
    UPDATE_PEDIDO,
} from '../actions/PedidoActions';
import {CREATE_DETALLEPEDIDO, DELETE_DETALLEPEDIDO} from "../actions/DetallePedidoActions"
import {LOGOUT_SUCCESS} from "../actions/AuthActions";
import {CREATE_DIRECCIONENTREGA} from "../actions/DireccionEntregaActions"
import {combineReducers} from 'redux';
import {loadFromLS} from "../localeStorage/ls"
import merge from "lodash/merge";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";

function getInitialStateById() {
    let pedidoGuardado = loadFromLS("pedidos");
    if (pedidoGuardado)
        //return pedidoGuardado.byId;
        return {
            isFetching: false,
            didInvalidate: true,
            pedidos: []
        }
    else
        return {
            isFetching: false,
            didInvalidate: true,
            pedidos: []
        }
}

function pedidosById(state = getInitialStateById(), action) {
    switch (action.type) {
        //PEDIDOS
        case INVALIDATE_PEDIDOS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
            break;
        case REQUEST_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
            break;
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                pedidos: merge({}, state.pedidos, action.pedidos.entities.pedidos),
                lastUpdated: action.receivedAt
            });
            break;
        case ERROR_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
            break;
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                pedidos: {},
                error: null
            });
            break;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                pedidos: [],
            });
        default:
            return state
    }
}

function getInitialStateAllIds() {
    let pedidoGuardado = loadFromLS("pedidos");
    if (pedidoGuardado)
        return [];
    //return pedidoGuardado.allIds;
    else
        return [];

}

function allPedidos(state = getInitialStateAllIds(), action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return union(state, action.pedidos.result.pedidos);
        case RESET_PEDIDOS:
            return [];
        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function getInitialStateCreate() {
    let pedidoGuardado = loadFromLS("pedidos");
    if (pedidoGuardado)
        return pedidoGuardado.create;
    else
        return {
            isCreating: false,
            nuevo: {},
            error: null
        }
}

function create(state = getInitialStateCreate(), action) {
    switch (action.type) {
        //CREATE
        case CREATE_PEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                nuevo: merge({}, state.nuevo, action.pedido),
                error: null
            });
            break;
        case REQUEST_CREATE_PEDIDO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
            break;
        case SUCCESS_CREATE_PEDIDO:
            let datoPedidoCreate = action.pedido.entities ? action.pedido.entities.pedidos : {};
            let pedidoCreate = datoPedidoCreate && Object.keys(datoPedidoCreate).length > 0 ? datoPedidoCreate[Object.keys(datoPedidoCreate)[0]] : {};
            return Object.assign({}, state, {
                isCreating: false,
                nuevo: pedidoCreate ? pedidoCreate : {},
                lastUpdated: action.receivedAt,
                error: null
            });
        case ERROR_CREATE_PEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
            break;
        case RESET_CREATE_PEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                nuevo: {},
                error: null
            });
            break;
        //DETALLES PEDIDO
        case CREATE_DETALLEPEDIDO:
            var nuevo = {};
            nuevo.detalle_pedido = Object.keys(action.detallePedido);
            nuevo.detalle_pedido = union(state.nuevo.detalle_pedido ? state.nuevo.detalle_pedido : [], nuevo.detalle_pedido);
            return merge({}, state, {
                isCreating: false,
                nuevo: merge({}, state.nuevo, nuevo),
                error: null,
            });
            break;
        case DELETE_DETALLEPEDIDO:
            var nuevo = clone(state.nuevo);
            if (Object.keys(action.detallePedido).length > 0)
                nuevo.detalle_pedido = difference(nuevo.detalle_pedido, Object.keys(action.detallePedido));
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                nuevo: nuevo,
            });
            break;
        default:
            return state
    }

}

function update(state = {
    isUpdating: false,
    isFetching: false,
    activo: {},
    error: null
}, action) {
    switch (action.type) {
        //UPDATE
        case UPDATE_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: merge({}, state.activo, action.pedido)
            });
            break;
        case REQUEST_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
            break;
        case SUCCESS_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null
            });
            break;
        case ERROR_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            break;
        case RESET_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                isFetching: false,
                activo: {},
                error: null
            });
            break;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                didInvalidate: true,
                pedidos: [],
            });

        //PEDIDO
        case REQUEST_PEDIDO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
            break;
        case RECEIVE_PEDIDO:
            let idPedido = Object.keys(action.pedido.entities.pedidos)[0];
            let pedido = action.pedido.entities.pedidos[idPedido];

            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: merge({}, state.activo, pedido),
                lastUpdated: action.receivedAt
            });
            break;
        case ERROR_PEDIDO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
            break;
        case CREATE_DIRECCIONENTREGA:
            let pedidoActivo = clone(state.activo);
            delete pedidoActivo.idDireccionEntrega;
            return Object.assign({}, state, {
                activo: pedidoActivo,
            });
            break;
        default:
            return state
    }

}


const pedidos = combineReducers({
    byId: pedidosById,
    allIds: allPedidos,
    update: update,
    create: create
});

export default pedidos;