//api
import productos from "../api/busqueda"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos} from "../normalizers/normalizeBusqueda";
import {saveCreateAnalitica} from "./AnaliticaActions";
import * as TipoAnalitica from "../constants/TipoAnalitica";

//BUSQUEDA
export const REQUEST_BUSQUEDA = 'REQUEST_BUSQUEDA';
export const RECEIVE_BUSQUEDA = 'RECEIVE_BUSQUEDA';
export const INVALIDATE_BUSQUEDA = 'INVALIDATE_BUSQUEDA';
export const ERROR_BUSQUEDA = "ERROR_BUSQUEDA";
export const RESET_BUSQUEDA = "RESET_BUSQUEDA";


export function invalidateBusquedaProductos() {
    return {
        type: INVALIDATE_BUSQUEDA
    }
}

function requestBusquedaProductos() {
    return {
        type: REQUEST_BUSQUEDA,
    }
}

function receiveBusquedaProductos(json) {
    return {
        type: RECEIVE_BUSQUEDA,
        busqueda: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorBusquedaProductos(error) {
    return {
        type: ERROR_BUSQUEDA,
        error: error,
    }
}

export function resetBusquedaProductos() {
    return {
        type: RESET_BUSQUEDA
    }
}

export function fetchBusquedaProductos(filtros) {
    return dispatch => {
        dispatch(requestBusquedaProductos());
        return productos.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveBusquedaProductos(data));
                if (filtros.searchPhase)
                    dispatch(saveCreateAnalitica(filtros.searchPhase, TipoAnalitica.BUSQUEDA_ID));
                if (filtros.categoria)
                    dispatch(saveCreateAnalitica(filtros.categoria, TipoAnalitica.BUSQUEDA_ID));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorBusquedaProductos(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorBusquedaProductos(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchBusqueda(state) {
    const productos = state.productos.byId;
    if (!productos) {
        return true
    } else if (productos.isFetching) {
        return false
    } else {
        return productos.didInvalidate;
    }
}

export function fetchBusquedaIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchBusqueda(getState())) {
            return dispatch(fetchBusquedaProductos(filtros))
        }
    }
}

