import React from "react";
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
//css
import '../../../assets/css/MensajeError.css';
import {saveCreatePedido, saveUpdatePedido} from "../../../actions/PedidoActions";
import Loader from "./Loader";

class MensajeError extends React.Component {

    constructor(props) {
        super(props);
        this.state = {mostrar: false, error: null};
        this.idPedido = props.match.params.idPedido;
    }

    componentDidUpdate(prevProps) {
        let error = null;
        Object.entries(this.props.state).forEach(
            ([key, value]) => {
                var valuePrev = prevProps.state[key];
                if (value.create && valuePrev && valuePrev.create && !value.create.isCreating && valuePrev.create.isCreating && value.create.error) {
                    error = value.create.error;
                }
                if (value.update && valuePrev && valuePrev.update && !value.update.isUpdating && valuePrev.update.isUpdating && value.update.error) {
                    error = value.update.error;
                }
                if (value.delete && valuePrev && valuePrev.delete && !value.delete.isDeleting && valuePrev.delete.isDeleting && value.delete.error) {
                    error = value.delete.error;
                }
                if (value.validate && valuePrev && valuePrev.validate && !value.validate.isValidating && valuePrev.validate.isValidating && value.validate.error) {
                    error = value.validate.error;
                }
            });
        if (this.state.mostrar != (error != null) && error != null && error.length > 0) {
            this.setState({mostrar: true, error: error});
            document.body.classList.add("no-scroll")
        }
    }


    render() {
        const {mostrar, error} = this.state;
        let Errores = "";
        let botones = [];
        let err = {};
        try {
            err = JSON.parse(error);
            if (err && typeof (err) == "object") {
                Errores = Object.values(err).map((er, index) => {
                    return er.map((mensaje) => {
                        return <p key={mensaje + index}>{mensaje}</p>;
                    })
                });
            }
        } catch (e) {
            console.log(error, e);
            Errores = <p>{error}</p>
        }

        //Botones
        if (err && (!err.Pedido || err.Pedido.length === 0)
            && err.Promociones && err.Productos
            && this.props.state.pedidos.create.nuevo.detalle_pedido
            && this.props.state.pedidos.create.nuevo.detalle_pedido.length > 0)
            botones.push(<div key="confirmar">
                <button onClick={() => {
                    this.setState({mostrar: false});
                    if (!this.idPedido)
                        this.props.saveCreatePedido();
                    else
                        this.props.saveUpdatePedido();
                    document.body.classList.remove("no-scroll");
                }}>
                    Confirmar pedido
                </button>
                <button onClick={() => {
                    this.setState({mostrar: false});
                    document.body.classList.remove("no-scroll")
                }}>
                    Revisar
                </button>
            </div>);
        else if (err && ((err.Pedido && err.Pedido.length > 0) || ((!err.Pedido || err.Pedido.length === 0)
            && err.Promociones && err.Productos
            && this.props.state.pedidos.create.nuevo.detalle_pedido
            && this.props.state.pedidos.create.nuevo.detalle_pedido.length === 0))
        )
            botones.push(
                <button key="continuar" onClick={() => {
                    this.setState({mostrar: false});
                    document.body.classList.remove("no-scroll")
                }}>
                    Continuar comprando
                </button>);
        else
            botones.push(
                <button key="cerrar" onClick={() => {
                    this.setState({mostrar: false});
                    document.body.classList.remove("no-scroll")
                }}>
                    Cerrar
                </button>
            );

        return (
            <div className="msjError" style={{display: mostrar ? "inline" : "none"}}>
                <div className="ventanaModal">
                    {Errores}
                    <small>Intentelo nuevamente.</small>
                    {
                        this.props.state.pedidos.create.isCreating || this.props.state.pedidos.update.isUpdating ?
                            <Loader/>
                            :
                            <div className="container-botones">
                                {botones}
                            </div>
                    }

                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state: state,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveCreatePedido: () => {
            dispatch(saveCreatePedido())
        },
        saveUpdatePedido: () => {
            dispatch(saveUpdatePedido())
        },
    }
};

// Wrap the component to inject dispatch and state into it
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MensajeError));
