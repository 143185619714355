import {RECEIVE_PEDIDOS, RESET_PEDIDOS} from '../actions/PedidoActions';
import {
    CREATE_ESTADOPEDIDO,
    ERROR_CREATE_ESTADOPEDIDO,
    REQUEST_CREATE_ESTADOPEDIDO,
    RESET_CREATE_ESTADOPEDIDO,
    SUCCESS_CREATE_ESTADOPEDIDO
} from "../actions/EstadoPedidoActions";
import {combineReducers} from 'redux';
import merge from "lodash/merge";

function estadosPedidoById(state = {
    isFetching: false,
    didInvalidate: true,
    estadosPedido: []
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                estadosPedido: merge({}, state.estadosPedido, action.pedidos.entities.estadosPedido),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                estadosPedido: [],
                error: null
            });
        default:
            return state
    }
}


function allEstadosPedido(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}

function createEstadoPedido(state = {isCreating: false, nuevoEstadoPedido: {}, error: ""}, action) {
    switch (action.type) {
        case CREATE_ESTADOPEDIDO:
            return Object.assign({}, state, {
                nuevoEstadoPedido: action.estadoPedido
            });
        case REQUEST_CREATE_ESTADOPEDIDO:
            return Object.assign({}, state, {
                isCreating: true, error: null
            });
        case SUCCESS_CREATE_ESTADOPEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                nuevoEstadoPedido: {}
            });
        case ERROR_CREATE_ESTADOPEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ESTADOPEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevoEstadoPedido: {},
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevoEstadoPedido: {},
            });
        default:
            return state
    }
}


const estadosPedido = combineReducers({
    byId: estadosPedidoById,
    allIds: allEstadosPedido,
    create: createEstadoPedido
});

export default estadosPedido;