import * as errorMessages from '../constants/MessageConstants';
//Actions
import {logout} from "../actions/AuthActions";
import {requestPreguntarImpresionPackingList, savePackingList} from "../actions/PackingListActions";
import {fetchPedidosIfNeeded, invalidatePedidos} from "./PedidoActions";
//api
import bultos from "../api/bultos";

//BULTO
export const RESET_BULTOS = "RESET_BULTOS";
//CREATE
export const CREATE_BULTO = 'CREATE_BULTO';
export const REQUEST_CREATE_BULTO = "REQUEST_CREATE_BULTO";
export const SUCCESS_CREATE_BULTO = "SUCCESS_CREATE_BULTO";
export const ERROR_CREATE_BULTO = "ERROR_CREATE_BULTO";
//UPDATE
export const UPDATE_BULTO = 'UPDATE_BULTO';
export const REQUEST_UPDATE_BULTO = "REQUEST_UPDATE_BULTO";
export const SUCCESS_UPDATE_BULTO = "SUCCESS_UPDATE_BULTO";
export const ERROR_UPDATE_BULTO = "ERROR_UPDATE_BULTO";
//DELETE
export const DELETE_BULTO = 'DELETE_BULTO';
export const REQUEST_DELETE_BULTO = "REQUEST_DELETE_BULTO";
export const SUCCESS_DELETE_BULTO = "SUCCESS_DELETE_BULTO";
export const ERROR_DELETE_BULTO = "ERROR_DELETE_BULTO";

//CREATE

function requestCreateBulto() {
    return {
        type: REQUEST_CREATE_BULTO,
    }
}

function receiveCreateBulto() {
    return {
        type: SUCCESS_CREATE_BULTO,
        receivedAt: Date.now()
    }
}

function errorCreateBulto(error) {
    return {
        type: ERROR_CREATE_BULTO,
        error: error,
    }
}

export function createBulto(id, bulto) {
    return {
        type: CREATE_BULTO,
        id,
        bulto
    }
}

export function saveCreateBulto() {
    return (dispatch, getState) => {
        dispatch(requestCreateBulto());
        //Tomo los nuevos packing
        var bultosN = getState().bultos.create.bultos.map((id) => {
            return (getState().bultos.byId.bultos[id]);
        });
        return bultos.saveCreate(bultosN)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveCreateBulto());
                    //dispatch(invalidateDetalleRemitido());
                    //dispatch(fetchDetallesRemitidoIfNeeded(idRemito));
                    //Refresco token
                    // auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateBulto(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCreateBulto(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE

function requestUpdateBulto() {
    return {
        type: REQUEST_UPDATE_BULTO,
    }
}

function receiveUpdateBulto() {
    return {
        type: SUCCESS_UPDATE_BULTO,
        receivedAt: Date.now()
    }
}

function errorUpdateBulto(error) {
    return {
        type: ERROR_UPDATE_BULTO,
        error: error,
    }
}

export function updateBulto(id, bulto) {
    return {
        type: UPDATE_BULTO,
        id,
        bulto
    }
}

export function saveUpdateBulto() {
    return (dispatch, getState) => {
        dispatch(requestUpdateBulto());
        //Tomo los packing a modificar
        var bultosN = getState().bultos.update.bultos.map((id) => {
            return (getState().bultos.byId.bultos[id]);
        });
        return bultos.saveUpdate(bultosN)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdateBulto());
                    //Refresco token
                    // auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateBulto(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorUpdateBulto(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//DELETE

function requestDeleteBulto() {
    return {
        type: REQUEST_DELETE_BULTO,
    }
}

function receiveDeleteBulto() {
    return {
        type: SUCCESS_DELETE_BULTO,
        receivedAt: Date.now()
    }
}

function errorDeleteBulto(error) {
    return {
        type: ERROR_DELETE_BULTO,
        error: error,
    }
}

export function deleteBulto(id) {
    return {
        type: DELETE_BULTO,
        id
    }
}

export function saveDeleteBulto() {
    return (dispatch, getState) => {
        dispatch(requestDeleteBulto());
        //Tomo los packing a modificar
        var bultosN = getState().bultos.delete.bultos.map((id) => {
            return ({id: id});
        });
        return bultos.saveDelete(bultosN)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveDeleteBulto());
                    //Refresco token
                    // auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorDeleteBulto(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDeleteBulto(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

export function changeBulto(id, bulto) {
    return (dispatch, getState) => {
        //Decidir si actualizar o modificar
        if (getState().bultos.create.bultos.indexOf(id) != -1 || !getState().bultos.byId.bultos[id])
            dispatch(createBulto(id, bulto));
        else
            dispatch(updateBulto(id, bulto));
    }
}

export function saveBulto() {
    return (dispatch, getState) => {
        return (getState().bultos.create.bultos.length > 0 ? dispatch(saveCreateBulto()) : Promise.resolve())
            .then(() => {
                (getState().bultos.update.bultos.length > 0 ? dispatch(saveUpdateBulto()) : Promise.resolve())
            })
            .then(() => {
                (getState().bultos.update.bultos.length > 0 ? dispatch(saveDeleteBulto()) : Promise.resolve())
            });
    }
}

export function saveCreateBultosAndPackingList(idRemito, idPedido) {
    return (dispatch) => {
        dispatch(saveBulto())
            .then(() =>
                dispatch(savePackingList(idRemito))
            ).then(() =>
            dispatch(requestPreguntarImpresionPackingList()))
            .then(() => {
                dispatch(invalidatePedidos());
                dispatch(fetchPedidosIfNeeded({id: idPedido}));
            });
    }

}

export function resetBultos() {
    return {
        type: RESET_BULTOS
    }
}
