import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
//Actions
import {resetLogin, restablecer, validarToken} from "../../actions/AuthActions";
//components
import Loader from "./layout/Loader"


class CambiarPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password_confirmation: ""
        };
        this.msjPassword = createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.match.params.token) {
            this.props.validarToken(this.props.match.params.token);
            this.setState({tokenReset: this.props.match.params.token});
        } else {
            this.props.history.push("/signup");
        }
    }

    componentWillUnmount() {
        this.props.resetLogin();
    }

    onChangeUsuario(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        this.setState(cambio);
        this.msjPassword.current.style.display = "none";
    }

    submitForm(e) {
        e.preventDefault();
        this.msjPassword.current.style.display = "none";
        if (this.state.password_confirmation === this.state.password)
            this.props.restablecer(this.state);
        else
            this.msjPassword.current.style.display = "inherit";
    }

    render() {
        let usuario = this.state;

        //error de alta de usuario
        let Errores = [];
        let error = this.props.auth.errorMessage;
        try {
            let err = JSON.parse(error);
            if (err && typeof (err) == "object") {
                Errores = Object.values(err).map((er, index) => {
                    return <p key={er[0] + index}>{er[0]}</p>;
                });
            }
        } catch (e) {
            Errores = <p>{error}</p>
        }
        const tienda = this.props.tiendas.update.activo;
        return (
            <section className="login-sign-up">
                <div className="container-modal">
                    <div className="container-doble">
                        <div className="col-3 container-marca">
                            {
                                tienda && tienda.logoBlanco &&
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/logos/${tienda && tienda.logoBlanco ? tienda.logoBlanco : ""}`}
                                    className="logo"/>
                            }
                            <div className="container-yaregistrado">
                                <p>
                                    Si ya estás registrado sólo inicia sesión
                                </p>
                                <Link to="/login">
                                    <button>
                                        INICIAR SESIÓN
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="container-datos">
                                <div className="cruz-cerrar" onClick={() => {
                                    this.props.history.push("/")
                                }}>&times;</div>
                                {!this.props.match.params.token ? <h2>CAMBIAR <i>CONTRASEÑA</i></h2> :
                                    <h2>RESTABLECER <i>CONTRASEÑA</i></h2>}
                                <form onSubmit={(e) => {
                                    this.submitForm(e)
                                }}
                                      style={{display: this.props.auth.mensaje || this.props.auth.errorMessage ? "none" : "flex"}}>
                                    <p>Cambia tu contraseña</p>
                                    <input placeholder="Contraseña" type="password" id="password" minLength="8"
                                           required={true}
                                           value={usuario ? usuario.password : ""}
                                           onChange={(e) => this.onChangeUsuario(e)}/>
                                    <input placeholder="Repetir contraseña" type="password" id="password_confirmation"
                                           minLength="8"
                                           value={usuario ? usuario.password_confirmation : ""}
                                           ref="password_confirmation" required={true}
                                           onChange={(e) => this.onChangeUsuario(e)}/>
                                    <div className="errores">{Errores}</div>
                                    <div ref={this.msjPassword} className="errores" style={{display: "none"}}><p>Las contraseñas no coinciden</p></div>
                                    {
                                        !this.props.auth.isFetching &&
                                        <button type="submit">Enviar</button>
                                    }
                                    {
                                        this.props.auth.isFetching &&
                                        <Loader/>
                                    }
                                </form>
                                <div className="errores"><p>{this.props.auth.errorMessage}</p></div>
                                <div className="errores"><p>{this.props.auth.mensaje}</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        usuarios: state.usuarios,
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        restablecer: (usuario) => {
            dispatch(restablecer(usuario))
        },
        validarToken: (token) => {
            dispatch(validarToken(token))
        },
        resetLogin: () => {
            dispatch(resetLogin())
        }
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CambiarPassword));