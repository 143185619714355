import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
//css
import '../../../assets/css/ModalTools.css';
//Actions
//Components
//img

export default function ModalTools(props) {
    return (
        <div className="modal-tools" style={{display: props.show ? "block" : "none"}}>
            <div className="header-modal">
                <FontAwesomeIcon icon={faArrowLeft} className="modal-volver" onClick={props.onHide}/>
                <h2>{props.titulo}</h2>
            </div>
            <div className="modal-body-tool">
                {props.children}
            </div>
        </div>
    )
}

