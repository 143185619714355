//api
import banners from "../api/banners"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "./AuthActions";
//normalizers
import {normalizeDato, normalizeDatos} from "../normalizers/normalizeBanners";
import clone from "lodash/clone";


//BANNERS
export const REQUEST_BANNERS = 'REQUEST_BANNERS';
export const RECEIVE_BANNERS = 'RECEIVE_BANNERS';
export const INVALIDATE_BANNERS = 'INVALIDATE_BANNERS';
export const ERROR_BANNERS = "ERROR_BANNERS";
export const RESET_BANNERS = "RESET_BANNERS";


export function invalidateBanners() {
    return {
        type: INVALIDATE_BANNERS
    }
}

function requestBanners() {
    return {
        type: REQUEST_BANNERS
    }
}

function receiveBanners(json) {
    return {
        type: RECEIVE_BANNERS,
        banners: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorBanners(error) {
    return {
        type: ERROR_BANNERS,
        error: error,
    }
}

export function resetBanners() {
    return {
        type: RESET_BANNERS
    }
}

export function fetchBanners(filtros) {
    return dispatch => {
        dispatch(requestBanners());
        return banners.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveBanners(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorBanners(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorBanners(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchBanners(state) {
    const banners = state.banners.byId;

    if (!banners) {
        return true
    } else if (banners.isFetching) {
        return false
    } else {
        return banners.didInvalidate;
    }
}

export function fetchBannersIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchBanners(getState())) {
            return dispatch(fetchBanners(filtros))
        }
    }
}


//MODEL
export const REQUEST_BANNER = 'REQUEST_BANNER';
export const RECEIVE_BANNER = 'RECEIVE_BANNER';
export const INVALIDATE_BANNER = 'INVALIDATE_BANNER';
export const ERROR_BANNER = "ERROR_BANNER";
export const RESET_BANNER = "RESET_BANNER";


export function invalidateBanner() {
    return {
        type: INVALIDATE_BANNER
    }
}

function requestBanner() {
    return {
        type: REQUEST_BANNER,
    }
}

function receiveBanner(json) {
    return {
        type: RECEIVE_BANNER,
        banner: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorBanner(error) {
    return {
        type: ERROR_BANNER,
        error: error,
    }
}

export function resetBanner() {
    return {
        type: RESET_BANNER
    }
}

export function fetchBanner(idBanner) {
    return dispatch => {
        dispatch(requestBanner());
        return banners.getOne(idBanner)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveBanner(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorBanner(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorBanner(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//ALTA USUARIO
export const CREATE_BANNER = 'CREATE_BANNER';
export const REQUEST_CREATE_BANNER = "REQUEST_CREATE_BANNER";
export const SUCCESS_CREATE_BANNER = "SUCCESS_CREATE_BANNER";
export const ERROR_CREATE_BANNER = "ERROR_CREATE_BANNER";
export const RESET_CREATE_BANNER = "RESET_CREATE_BANNER";


//ALTA BANNER 
function requestCreateBanner() {
    return {
        type: REQUEST_CREATE_BANNER,
    }
}

function receiveCreateBanner(json) {
    return {
        type: SUCCESS_CREATE_BANNER,
        receivedAt: Date.now(),
        banner: normalizeDato(json)
    }
}

export function errorCreateBanner(error) {
    return {
        type: ERROR_CREATE_BANNER,
        error: error,
    }
}

export function resetCreateBanner() {
    return {
        type: RESET_CREATE_BANNER
    }
}

export function createBanner(banner) {
    return {
        type: CREATE_BANNER,
        banner
    }
}

export function saveCreateBanner(mobile,desk) {
    return (dispatch, getState) => {
        dispatch(requestCreateBanner());
        let banner = clone(getState().banners.create.nuevo);
        return banners.saveCreate(banner, mobile,desk)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveCreateBanner(response));
                }
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateBanner(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorCreateBanner(JSON.parse(error.responseJSON.message)));
                        else
                            dispatch(errorUpdateBanner(errorMessages.GENERAL_ERROR));


                        return;
                }
            });

    }
}

//UPDATE MODEL
export const UPDATE_BANNER = 'UPDATE_BANNER';
export const REQUEST_UPDATE_BANNER = "REQUEST_UPDATE_BANNER";
export const SUCCESS_UPDATE_BANNER = "SUCCESS_UPDATE_BANNER";
export const ERROR_UPDATE_BANNER = "ERROR_UPDATE_BANNER";
export const RESET_UPDATE_BANNER = "RESET_UPDATE_BANNER";

function requestUpdateBanner() {
    return {
        type: REQUEST_UPDATE_BANNER,
    }
}

function receiveUpdateBanner(json) {
    return {
        type: SUCCESS_UPDATE_BANNER,
        receivedAt: Date.now(),
        banner: normalizeDato(json)
    }
}

function errorUpdateBanner(error) {
    return {
        type: ERROR_UPDATE_BANNER,
        error: error,
    }
}

export function resetUpdateBanner() {
    return {
        type: RESET_UPDATE_BANNER,
    }
}

export function updateBanner(banner) {
    return {
        type: UPDATE_BANNER,
        banner
    }
}

export function saveUpdateBanner(mobile,desk) {
    return (dispatch, getState) => {
        dispatch(requestUpdateBanner());
        return banners.saveUpdate(getState().banners.update.activo, mobile,desk)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdateBanner(response));
                }
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateBanner(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorUpdateBanner(JSON.parse(error.responseJSON.message)));
                        else
                            dispatch(errorUpdateBanner(errorMessages.GENERAL_ERROR));

                        return;
                }
            });
    }
}

//DELETE BANNER
export const DELETE_BANNER = 'DELETE_BANNER';
export const REQUEST_DELETE_BANNER = "REQUEST_DELETE_BANNER";
export const SUCCESS_DELETE_BANNER = "SUCCESS_DELETE_BANNER";
export const ERROR_DELETE_BANNER = "ERROR_DELETE_BANNER";
export const RESET_DELETE_BANNER = "RESET_DELETE_BANNER";

function requestDeleteBanner() {
    return {
        type: REQUEST_DELETE_BANNER,
    }
}

function receiveDeleteBanner(json) {
    return {
        type: SUCCESS_DELETE_BANNER,
        receivedAt: Date.now(),
        banner: normalizeDato(json)
    }
}

function errorDeleteBanner(error) {
    return {
        type: ERROR_DELETE_BANNER,
        error: error,
    }
}

export function resetDeleteBanner(error) {
    return {
        type: RESET_DELETE_BANNER,
        error: error,
    }
}

export function deleteBanner(banners) {
    return {
        type: DELETE_BANNER,
        banners
    }
}

export function saveDeleteBanner(banner) {
    return dispatch => {
        dispatch(requestDeleteBanner());
        return banners.saveDelete(banner)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(resetDeleteBanner());
                dispatch(receiveDeleteBanner(data));
            })
            .catch(function (error) {
                    switch (error.status) {
                        case 401:
                            dispatch(errorDeleteBanner(errorMessages.UNAUTHORIZED_TOKEN));
                            dispatch(logout());
                            return;
                        default:
                            dispatch(errorDeleteBanner(errorMessages.GENERAL_ERROR));
                            return;
                    }
                }
            );
    }
}