import React, {useEffect, useState} from 'react';
import Swiper from 'react-id-swiper';
//css
import '../../../assets/css/CarrouselDestacados.css';

//Actions
//img


export default function CarrouselDestacados(props) {
    const [swiper, updateSwiper] = useState(null);
    const [isBegginig, updateIsBeggining] = useState(null);
    const [isEnd, updateIsEnd] = useState(null);

    // Swiper instance
    useEffect(
        () => {
            if (swiper !== null) {
                updateIsBeggining(swiper.isBeginning);
                updateIsEnd(swiper.isEnd);
                swiper.on("slideChange", () => {
                    updateIsBeggining(swiper.isBeginning);
                    updateIsEnd(swiper.isEnd);
                });
            }
        },
        [swiper && (swiper.isEnd || swiper.isBeggining)]
    );

    const params = {
        mousewheel: {
            invert: true,
            forceToAxis: true
        },
        speed: 200,
        freeMode: true,
        autoWidth: true,
        slidesPerView: 'auto',
        slidesPerGroup: 2,
        normalizeSlideIndex: false,
        getSwiper: updateSwiper, // Get swiper instance callback
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
        },
        renderScrollbar: () => <div id={"carrousel-scrollbar-" + props.idCategoria} className="swiper-scrollbar"></div>
    };

    // Manipulate swiper from outside
    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    function toggleScrollBar(id) {
        let scrollbar = document.getElementById("carrousel-scrollbar-" + id);
        if (scrollbar)
            scrollbar.classList.contains('show') ? scrollbar.classList.remove('show') : scrollbar.classList.add('show');
    };

    return (
        <React.Fragment>
            <div className="container-limit carrousel-destacados">
                <Swiper {...params} shouldSwiperUpdate updateOnWindowResize>
                    {props.children}
                </Swiper>
            </div>
            <div className="carrousel-buttons"
                 onMouseOver={() => toggleScrollBar(props.idCategoria)}
                 onMouseOut={() => toggleScrollBar(props.idCategoria)}>
                <div className={`swiper-button-prev ${isBegginig && "swiper-button-disabled"}`} onClick={goPrev}>
                </div>
                <div className={`swiper-button-next ${isEnd && "swiper-button-disabled"}`} onClick={goNext}>
                </div>
            </div>
        </React.Fragment>
    );
}

