import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

import '../../assets/css/CodigoPromocional.css';
//components
import CampoTexto from "./componentes/CampoTexto";
//img
import {ReactComponent as Lapiz} from "../../assets/images/pencil.svg";
import {createDetallePedido} from "../../actions/DetallePedidoActions";

import promociones from "../../utils/calculoPromociones";


class CodigoPromocional extends Component {


    constructor(props) {
        super(props);
        this.state = {codigoPromocional: ""};
        this.msjCodigoPromocional = {};
    }

    componentDidMount() {
    }

    handleChangeCodigo(e) {
        this.setState({codigoPromocional: e.target.value})
    }

    onSubmit(e) {
        e.preventDefault();
        let promocionEncontrada = null;
        this.props.promociones.allIds.some((idPromocion) => {
            let promocion = this.props.promociones.byId.promociones[idPromocion];
            console.log(this.state, promocion);
            if (promocion.formatoPromocion === "Código promocional" && promocion.codigo.toUpperCase() === this.state.codigoPromocional.toUpperCase()) {
                promocionEncontrada = promocion;
                return true;
            }
            return false;
        });
        console.log(promocionEncontrada);
        if (promocionEncontrada) {

            let cambio = {};
            promociones.calcularPromocionCodigoPromocional(cambio, promocionEncontrada, this.props.detallesPedido, this.props.productosDestinoPromocion);
            cambio.desDetallePedido = promocionEncontrada.nomPromocion;
            this.props.createDetallePedido(cambio);
            this.msjCodigoPromocional.innerHTML = "Perfecto! Ya aplicamos tu código promocional! &#128513;";
        } else {
            console.log(this.msjCodigoPromocional);
            this.msjCodigoPromocional.innerHTML = "Tu código promocional no es válido. &#128552;"
        }
    }

    render() {
        let {codigoPromocional} = this.state;

        //Evaluar si hay disponible un código
        let habilitado = this.props.promociones.allIds.some((idPromocion) => {
            let promocion = this.props.promociones.byId.promociones[idPromocion];
            if (promocion.formatoPromocion === "Código promocional")
                return true;
            else
                return false;
        });
        if (habilitado === true)
            return (
                <section className="codigoPromocional">
                    <h4>¿Tenés un código promocional?</h4>
                    <form onSubmit={(e) => this.onSubmit(e)}>
                        <div className="containerCodigoPromocional">
                            <CampoTexto required={false} id="codigoPromocional" nombre="Escribe tu código"
                                        img={<Lapiz style={{width: "15px", height: "15px"}}/>} type="text"
                                        value={codigoPromocional ? codigoPromocional : ""}
                                        handleChange={this.handleChangeCodigo.bind(this)}/>
                            <button type="submit" className="finalizar">
                                Aplicar!
                            </button>
                        </div>
                    </form>
                    <p ref={(e) => this.msjCodigoPromocional = e}></p>
                </section>
            );
        else
            return <div></div>;
    }
}

function mapStateToProps(state) {
    return {
        usuarios: state.usuarios,
        pedidos: state.pedidos,
        auth: state.auth,
        promociones: state.promociones,
        detallesPedido: state.detallesPedido,
        productosDestinoPromocion: state.productosDestinoPromocion
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createDetallePedido: (detalle) => {
            dispatch(createDetallePedido(detalle))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CodigoPromocional));
