import React, {useState} from 'react';
import Swiper from 'react-id-swiper';
//css
import '../../../assets/css/CarrouselCombo.css';

//Actions
//img


export default function CarrouselCombo(props) {
    const [swiper, updateSwiper] = useState(null);

    if (swiper) {
        swiper.on('imagesReady', function () {
            swiper.update();
            swiper.slideTo(1);
        });
    }

    const params = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        normalizeSlideIndex: true,
        loop: true,
        getSwiper: updateSwiper, // Get swiper instance callback
    };

    return (
        <React.Fragment>
            <Swiper {...params} shouldSwiperUpdate updateOnWindowResize>
                {props.children}
            </Swiper>
        </React.Fragment>
    );
}