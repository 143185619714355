import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../../assets/css/AvisoPrecios.css';
import {fetchAvisos} from "../../../actions/AvisoActions";
import merge from "lodash/merge"

//Actions
class AvisoCerrado extends Component {
    constructor(props) {
        super(props);
        this.state = {mostrar: {}};
    }

    componentDidMount() {
        this.props.fetchAvisos();
    }

    cerrarAviso(aviso) {
        let mostrar = {};
        mostrar[aviso.id] = false;
        this.setState({mostrar: merge({}, this.state.mostrar, mostrar)});
    }

    render() {
        let cartelesAvisos = () => {
            let avisos = this.props.avisos;
            if (avisos && avisos.totalAvisos > 0) {
                return avisos.allIds.map((idAviso) => {
                    let aviso = avisos.byId.avisos[idAviso];
                    return (
                        <div className={`mensaje-especial`}
                             style={{
                                 margin: "5px auto",
                                 display: this.state.mostrar[idAviso] === false ? "none" : "flex"
                             }}>
                            <p>
                                {aviso && aviso.mensaje}
                            </p>
                            {
                                aviso && aviso.esCerrable ?
                                    <div className="cruz-cerrar"
                                         onClick={() => this.cerrarAviso(aviso)}>
                                        &times;
                                    </div> : ""
                            }
                        </div>)
                })
            }
        }

        return (
            <div className="container-aviso-especial">
                {cartelesAvisos()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        avisos: state.avisos,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAvisos: () => {
            dispatch(fetchAvisos());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AvisoCerrado);

