import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {
    const zonaEntrega = new schema.Entity('zonasEntrega', {}, {idAttribute: "id"});

    const mySchema = [zonaEntrega];
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const zonaEntrega = new schema.Entity('zonasEntrega', {}, {idAttribute: "id"});

    const mySchema = zonaEntrega;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

