import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

import '../../../assets/css/DatosContacto.css';
//components
import CampoTextArea from "./CampoTextArea";
//img
import {ReactComponent as Lapiz} from "../../../assets/images/pencil.svg";


class Observaciones extends Component {


    componentDidMount() {
    }

    render() {
        let {pedido} = this.props;
        return (
            <section className="datosContacto">
                <h2 className="titulo-seccion subtitulo-checkout">¿Querés aclarar algo?</h2>
                <CampoTextArea required={false} id="observaciones" nombre="Observaciones"
                               img={<Lapiz style={{width: "15px", height: "15px"}}/>} type="text"
                               value={pedido.observaciones ? pedido.observaciones : ""}
                               handleChange={this.props.handleChangePedido.bind(this)}/>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        usuarios: state.usuarios,
        pedidos: state.pedidos,
        auth: state.auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Observaciones));
