//api
import provinciasApi from "../api/provinciasApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeProvincias";

//PROVINCIAS
export const REQUEST_PROVINCIAS = 'REQUEST_PROVINCIAS';
export const RECEIVE_PROVINCIAS = 'RECEIVE_PROVINCIAS';
export const INVALIDATE_PROVINCIAS = 'INVALIDATE_PROVINCIAS';
export const ERROR_PROVINCIAS = "ERROR_PROVINCIAS";
export const RESET_PROVINCIAS = "RESET_PROVINCIAS";


export function invalidateProvincias() {
    return {
        type: INVALIDATE_PROVINCIAS
    }
}

function requestProvincias() {
    return {
        type: REQUEST_PROVINCIAS,
    }
}

function receiveProvincias(json) {
    return {
        type: RECEIVE_PROVINCIAS,
    provincias: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorProvincias(error) {
    return {
        type: ERROR_PROVINCIAS,
        error: error,
    }
}

export function resetProvincias() {
    return {
        type: RESET_PROVINCIAS
    }
}

export function fetchProvincias(filtros) {
    return dispatch => {
        dispatch(requestProvincias());
        return provinciasApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProvincias(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorProvincias(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProvincias(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProvincias(state) {
    const provincias = state.provincias.byId;
    if (!provincias) {
        return true
    } else if (provincias.isFetching) {
        return false
    } else {
        return provincias.didInvalidate;
    }
}

export function fetchProvinciasIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchProvincias(getState())) {
            return dispatch(fetchProvincias(filtros))
        }
    }
}


//MODEL
export const REQUEST_PROVINCIA = 'REQUEST_PROVINCIA';
export const RECEIVE_PROVINCIA = 'RECEIVE_PROVINCIA';
export const INVALIDATE_PROVINCIA = 'INVALIDATE_PROVINCIA';
export const ERROR_PROVINCIA = "ERROR_PROVINCIA";
export const RESET_PROVINCIA = "RESET_PROVINCIA";


export function invalidateProvincia() {
    return {
        type: INVALIDATE_PROVINCIA
    }
}

function requestProvincia() {
    return {
        type: REQUEST_PROVINCIA,
    }
}

function receiveProvincia(json) {
    return {
        type: RECEIVE_PROVINCIA,
    provincia: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorProvincia(error) {
    return {
        type: ERROR_PROVINCIA,
        error: error,
    }
}

export function fetchProvincia(idProvincia) {
    return dispatch => {
        dispatch(requestProvincia());
        return provinciasApi.getOne(idProvincia)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProvincia(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProvincia(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProvincia(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_PROVINCIA = 'UPDATE_PROVINCIA';
export const REQUEST_UPDATE_PROVINCIA = "REQUEST_UPDATE_PROVINCIA";
export const SUCCESS_UPDATE_PROVINCIA = "SUCCESS_UPDATE_PROVINCIA";
export const ERROR_UPDATE_PROVINCIA = "ERROR_UPDATE_PROVINCIA";
export const RESET_UPDATE_PROVINCIA = "RESET_UPDATE_PROVINCIA";

function requestUpdateProvincia() {
    return {
        type: REQUEST_UPDATE_PROVINCIA,
    }
}

function receiveUpdateProvincia(provincia) {
    return {
        type: SUCCESS_UPDATE_PROVINCIA,
        receivedAt: Date.now(),
        provincia: normalizeDato(provincia)
    }
}

function errorUpdateProvincia(error) {
    return {
        type: ERROR_UPDATE_PROVINCIA,
        error: error,
    }
}

export function resetUpdateProvincia() {
    return {
        type: RESET_UPDATE_PROVINCIA,
    }
}

export function updateProvincia(provincia) {
    return {
        type: UPDATE_PROVINCIA,
        provincia
    }
}

export function saveUpdateProvincia() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProvincia());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let provincia = denormalizeDato(getState().provincias.update.activo, store);

        return provinciasApi.saveUpdate(provincia)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProvincia(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateProvincia(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorUpdateProvincia(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorUpdateProvincia(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorUpdateProvincia(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}

//UPDATE PROVINCIAS
export const REQUEST_UPDATE_PROVINCIAS = "REQUEST_UPDATE_PROVINCIAS";
export const SUCCESS_UPDATE_PROVINCIAS = "SUCCESS_UPDATE_PROVINCIAS";
export const ERROR_UPDATE_PROVINCIAS = "ERROR_UPDATE_PROVINCIAS";
export const RESET_UPDATE_PROVINCIAS = "RESET_UPDATE_PROVINCIAS";

function requestUpdateProvincias() {
    return {
        type: REQUEST_UPDATE_PROVINCIAS,
}
}

function receiveUpdateProvincias(provincias) {
    return {
        type: SUCCESS_UPDATE_PROVINCIAS,
    receivedAt: Date.now(),
        provincias: normalizeDatos(provincias)
}
}

function errorUpdateProvincias(error) {
    return {
        type: ERROR_UPDATE_PROVINCIAS,
    error: error,
}
}

export function resetUpdateProvincias() {
    return {
        type: RESET_UPDATE_PROVINCIAS,
}
}

export function saveUpdateProvincias() {
    return (dispatch, getState) => {
        dispatch(requestUpdateProvincias());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let provincias = getState().provincias.update.activos.map((idProvincia) => {
            return denormalizeDato(getState().provincias.byId.provincias[idProvincia], store);
        });

        return provinciasApi.saveUpdateProvincias(provincias)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateProvincias(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateProvincias(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorUpdateProvincias(JSON.parse(error.responseJSON.message)));
                    else
                        try {
                            error.json().then((error) => {
                                dispatch(errorUpdateProvincias(JSON.parse(error.message)));
                            });
                        } catch {
                            dispatch(errorUpdateProvincias(errorMessages.GENERAL_ERROR));
                        }


                        return;
                }
            });
    }
}


//ALTA PROVINCIA
export const CREATE_PROVINCIA = 'CREATE_PROVINCIA';
export const REQUEST_CREATE_PROVINCIA = "REQUEST_CREATE_PROVINCIA";
export const SUCCESS_CREATE_PROVINCIA = "SUCCESS_CREATE_PROVINCIA";
export const ERROR_CREATE_PROVINCIA = "ERROR_CREATE_PROVINCIA";
export const RESET_CREATE_PROVINCIA = "RESET_CREATE_PROVINCIA";


//ALTA PROVINCIA
function requestCreateProvincia() {
    return {
        type: REQUEST_CREATE_PROVINCIA,
    }
}

function receiveCreateProvincia(provincia) {
    return {
        type: SUCCESS_CREATE_PROVINCIA,
        receivedAt: Date.now(),
        provincia: normalizeDato(provincia)
    }
}

export function errorCreateProvincia(error) {
    return {
        type: ERROR_CREATE_PROVINCIA,
        error: error,
    }
}

export function resetCreateProvincia() {
    return {
        type: RESET_CREATE_PROVINCIA
    }
}

export function createProvincia(provincia) {
    return {
        type: CREATE_PROVINCIA,
        provincia
    }
}

export function saveCreateProvincia() {
    return (dispatch, getState) => {
        dispatch(requestCreateProvincia());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let provincia = denormalizeDato(getState().provincias.create.nuevo, store);

        return provinciasApi.saveCreate(provincia)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateProvincia(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateProvincia(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorCreateProvincia(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorCreateProvincia(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorCreateProvincia(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}

//DELETE PROVINCIA
export const DELETE_PROVINCIA = 'DELETE_PROVINCIA';
export const REQUEST_DELETE_PROVINCIA = "REQUEST_DELETE_PROVINCIA";
export const SUCCESS_DELETE_PROVINCIA = "SUCCESS_DELETE_PROVINCIA";
export const ERROR_DELETE_PROVINCIA = "ERROR_DELETE_PROVINCIA";
export const RESET_DELETE_PROVINCIA = "RESET_DELETE_PROVINCIA";

function requestDeleteProvincia() {
    return {
        type: REQUEST_DELETE_PROVINCIA,
    }
}

function receiveDeleteProvincia(provincia) {
    return {
        type: SUCCESS_DELETE_PROVINCIA,
        receivedAt: Date.now(),
        provincia: normalizeDato(provincia)
    }
}

function errorDeleteProvincia(error) {
    return {
        type: ERROR_DELETE_PROVINCIA,
        error: error,
    }
}

export function resetDeleteProvincia(error) {
    return {
        type: RESET_DELETE_PROVINCIA,
        error: error,
    }
}

export function deleteProvincia(provincia) {
    return {
        type: DELETE_PROVINCIA,
        provincia
    }
}

export function saveDeleteProvincia(provincia) {
    return dispatch => {
        dispatch(requestDeleteProvincia());
        return provinciasApi.saveDelete(provincia)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteProvincia());
                            dispatch(receiveDeleteProvincia(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteProvincia(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorDeleteProvincia(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorDeleteProvincia(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorDeleteProvincia(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}