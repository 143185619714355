import {normalize, schema, denormalize} from 'normalizr';

export function normalizeDatos(myData) {
    const moneda = new schema.Entity('monedas', {}, {idAttribute: "id"});

    const mySchema = {monedas: [moneda]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const moneda = new schema.Entity('monedas', {}, {idAttribute: "id"});

    const mySchema = moneda;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const moneda = new schema.Entity('monedas', {}, {idAttribute: "id"});

    const mySchema = moneda;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}


