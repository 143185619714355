import {combineReducers} from 'redux';

import merge from "lodash/merge";
import union from "lodash/union";
import {RECEIVE_PRODUCTO, RECEIVE_PRODUCTOS} from "../actions/ProductoActions";
import {RECEIVE_CATEGORIAS_DESTACADAS} from "../actions/CategoriaActions";
import {RECEIVE_BUSQUEDA} from "../actions/BusquedaActions";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        variedadesCaracteristica: {},
    }
}

function variedadesCaracteristicaById(state = getInitialStateById(), action) {
    switch (action.type) {
        case RECEIVE_PRODUCTO:
            let datoProducto = action.producto.entities.variedadesCaracteristica;
            return Object.assign({}, state, {
                variedadesCaracteristica: merge({}, state.variedadesCaracteristica, datoProducto),
                isFetching: false,
            });
        case RECEIVE_PRODUCTOS:
            let datoProductos = action.productos.entities.variedadesCaracteristica;
            return Object.assign({}, state, {
                variedadesCaracteristica: merge({}, state.variedadesCaracteristica, datoProductos),
                isFetching: false,
            });
        case RECEIVE_CATEGORIAS_DESTACADAS:
            let datoCategorias = action.categorias.entities.variedadesCaracteristica;
            return Object.assign({}, state, {
                variedadesCaracteristica: merge({}, state.variedadesCaracteristica, datoCategorias),
                isFetching: false,
            });
        case RECEIVE_BUSQUEDA:
            let datoBusqueda = action.busqueda.entities.variedadesCaracteristica;
            return Object.assign({}, state, {
                variedadesCaracteristica: merge({}, state.variedadesCaracteristica, datoBusqueda),
                isFetching: false,
            });
        // case RESET_VARIEDADESCARACTERISTICA:
        //     return Object.assign({}, state, {
        //         isFetching: false,
        //         didInvalidate: true,
        //         error: null,
        //         lastUpdated: null,
        //         variedadesCaracteristica: {}
        //     });
        default:
            return state
    }
}


function allVariedadesCaracteristica(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTO:
            let result = [];
            if (action.producto.entities.variedadesCaracteristica)
                result = Object.keys(action.producto.entities.variedadesCaracteristica).map(function (x) {
                    return parseInt(x);
                });
            return action.producto.entities.variedadesCaracteristica ? result : [];
        case RECEIVE_PRODUCTOS:
            let results = [];
            if (action.productos.entities.variedadesCaracteristica)
                results = Object.keys(action.productos.entities.variedadesCaracteristica).map(function (x) {
                    return parseInt(x);
                });
            return action.productos.entities.variedadesCaracteristica ? union(results, state) : [];
        case RECEIVE_CATEGORIAS_DESTACADAS:
            let resultsCat = [];
            if (action.categorias.entities.variedadesCaracteristica)
                resultsCat = Object.keys(action.categorias.entities.variedadesCaracteristica).map(function (x) {
                    return parseInt(x);
                });
            return action.categorias.entities.variedadesCaracteristica ? union(resultsCat, state) : [];
        case RECEIVE_BUSQUEDA:
            let resultVariedades = [];
            if (action.busqueda.entities.variedadesCaracteristica)
                resultVariedades = Object.keys(action.busqueda.entities.variedadesCaracteristica).map(function (x) {
                    return parseInt(x);
                });
            return action.busqueda.entities.variedadesCaracteristica ? union(state, resultVariedades) : [];
        // case RESET_VARIEDADESCARACTERISTICA:
        //     return [];
        default:
            return state
    }
}

const variedadesCaracteristica = combineReducers({
    byId: variedadesCaracteristicaById,
    allIds: allVariedadesCaracteristica
});

export default variedadesCaracteristica;