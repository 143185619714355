import * as errorMessages from '../constants/MessageConstants';
//Actions
import {fetchPedidosIfNeeded, invalidatePedidos, resetPedidos} from "./PedidoActions";
import {logout} from "../actions/AuthActions";
//api
import estadosRemitoU from "../api/estadosRemito";

//ESTADOREMITO
export const CREATE_ESTADOREMITO = 'CREATE_ESTADOREMITO';
export const REQUEST_CREATE_ESTADOREMITO = "REQUEST_CREATE_ESTADOREMITO";
export const SUCCESS_CREATE_ESTADOREMITO = "SUCCESS_CREATE_ESTADOREMITO";
export const ERROR_CREATE_ESTADOREMITO = "ERROR_CREATE_ESTADOREMITO";
export const RESET_CREATE_ESTADOREMITO = "RESET_CREATE_ESTADOREMITO";

function requestEstadoRemito() {
    return {
        type: REQUEST_CREATE_ESTADOREMITO,
    }
}

function receiveEstadoRemito() {
    return {
        type: SUCCESS_CREATE_ESTADOREMITO,
        receivedAt: Date.now()
    }
}

function errorEstadoRemito(error) {
    return {
        type: ERROR_CREATE_ESTADOREMITO,
        error: error,
    }
}

export function resetEstadoRemito(error) {
    return {
        type: RESET_CREATE_ESTADOREMITO,
        error: error,
    }
}

export function createEstadoRemito(idRemito, estadoRemito) {
    return {
        type: CREATE_ESTADOREMITO,
        id: idRemito,
        estadoRemito
    }
}

export function saveEstadoRemito(estadoRemito, idRemito) {
    return (dispatch, getState) => {
        dispatch(requestEstadoRemito());
        return estadosRemitoU.save(estadoRemito, idRemito)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveEstadoRemito());
                    dispatch(invalidatePedidos());
                    //obtener remito
                    let remito = getState().remitos.byId.remitos[idRemito];
                    dispatch(fetchPedidosIfNeeded({id: remito.idPedidoMon}));
                    //Refresco token
                    // auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorEstadoRemito(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error.message != "")
                                    dispatch(errorEstadoRemito(error.message));
                                else
                                    dispatch(errorEstadoRemito(errorMessages.GENERAL_ERROR));
                            })
                            .catch(function (error) {
                                dispatch(errorEstadoRemito(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}

//Cerrar ESTADOREMITO
export const UPDATE_ESTADOREMITO = 'UPDATE_ESTADOREMITO';
export const REQUEST_UPDATE_ESTADOREMITO = "REQUEST_UPDATE_ESTADOREMITO";
export const SUCCESS_UPDATE_ESTADOREMITO = "SUCCESS_UPDATE_ESTADOREMITO";
export const ERROR_UPDATE_ESTADOREMITO = "ERROR_UPDATE_ESTADOREMITO";
export const RESET_UPDATE_ESTADOREMITO = "RESET_UPDATE_ESTADOREMITO";

function requestUpdateEstadoRemito() {
    return {
        type: REQUEST_UPDATE_ESTADOREMITO,
    }
}

function receiveUpdateEstadoRemito() {
    return {
        type: SUCCESS_UPDATE_ESTADOREMITO,
        receivedAt: Date.now()
    }
}

function errorUpdateEstadoRemito(error) {
    return {
        type: ERROR_UPDATE_ESTADOREMITO,
        error: error,
    }
}

export function resetUpdateEstadoRemito(error) {
    return {
        type: RESET_UPDATE_ESTADOREMITO,
        error: error,
    }
}

export function updateEstadoRemito(idRemito, estadoRemito) {
    return {
        type: UPDATE_ESTADOREMITO,
        id: idRemito,
        estadoRemito
    }
}

export function saveUpdateEstadoRemito() {
    return (dispatch, getState) => {
        dispatch(requestUpdateEstadoRemito());
        return estadosRemitoU.cerrar()
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdateEstadoRemito());
                    dispatch(invalidatePedidos());
                    dispatch(resetPedidos());
                    dispatch(fetchPedidosIfNeeded({
                        registros: 0,
                        order: "fechaPedido",
                        searchPhase: "",
                        mostrar: "importantes",
                        direction: "DESC"
                    }));
                    //Refresco token
                    // auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateEstadoRemito(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error.message != "")
                                    dispatch(errorUpdateEstadoRemito(error.message));
                                else
                                    dispatch(errorUpdateEstadoRemito(errorMessages.GENERAL_ERROR));
                            })
                            .catch(function (error) {
                                dispatch(errorUpdateEstadoRemito(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}