import {
    ERROR_DETALLESREMITIDOS,
    ERROR_UPDATE_DETALLESREMITIDOS,
    INVALIDATE_DETALLESREMITIDOS,
    RECEIVE_DETALLESREMITIDOS,
    REQUEST_DETALLESREMITIDOS,
    REQUEST_UPDATE_DETALLESREMITIDOS,
    RESET_DETALLESREMITIDOS,
    SUCCESS_UPDATE_DETALLESREMITIDOS,
    UPDATE_DETALLEREMITIDO
} from '../actions/DetalleRemitidoActions';
import {CREATE_PACKINGLIST, DELETE_PACKINGLIST, RESET_PACKINGLIST} from "../actions/PackingListActions"
import {combineReducers} from 'redux';
import normalizeDatos from "../normalizers/normalizeDetallesRemitidos";
import merge from "lodash/merge";
import union from "lodash/union";
import clone from "lodash/clone";
import remove from "lodash/remove";

function detallesRemitidosById(state = {
    isFetching: false,
    didInvalidate: true,
    detallesRemitidos: []
}, action) {
    switch (action.type) {
        case INVALIDATE_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_DETALLESREMITIDOS:
            let dato = normalizeDatos(action.detallesRemitidos).entities.detallesRemitidos;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                //detallesRemitidos: normalizeDatos(action.detallesRemitidos).entities.detallesRemitidos?normalizeDatos(action.detallesRemitidos).entities.detallesRemitidos:[],
                detallesRemitidos: dato ? dato : [],
                lastUpdated: action.receivedAt,
                error: null
            });
        case ERROR_DETALLESREMITIDOS:
            //TODO definir que hacer con los errores si los invalido puede entrar en loop
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                detallesRemitidos: []
            });
        case REQUEST_UPDATE_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isUpdating: true,
                didInvalidate: false
            });
        case UPDATE_DETALLEREMITIDO:
            var detalleRemitido = {};
            detalleRemitido[action.idDetalleRemitido] = action.detalleRemitido;
            return Object.assign({}, state, {
                isFetching: false,
                //didInvalidate: true,
                detallesRemitidos: merge({}, state.detallesRemitidos, detalleRemitido),
            });
        /*case SUCCESS_UPDATE_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isUpdating: false,
                isFetching: false,
                didInvalidate: false,
                lastUpdated: action.receivedAt
            });
        case ERROR_UPDATE_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });*/
        case CREATE_PACKINGLIST:

            var packing = {};
            packing[action.packingList.idDetalleRemitido] = {packing_list: union(state.detallesRemitidos[action.packingList.idDetalleRemitido].packing_list, [action.id])};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                detallesRemitidos: merge({}, state.detallesRemitidos, packing)
            });
        case RESET_PACKINGLIST:
            var cloneState = clone(state.detallesRemitidos);
            cloneState[action.idDetalleRemitido].packing_list = [];
            if (action.idDetalleRemitido) {
                return Object.assign({}, state, {
                    isFetching: false,
                    didInvalidate: false,
                    detallesRemitidos: cloneState,
                    error: null,
                });
            }
        // else{
        //     return Object.assign({}, state, {
        //         isSaving: false,
        //         error: null,
        //         packingList: {},
        //     });
        // }
        case DELETE_PACKINGLIST:
            var array = remove(state.detallesRemitidos[action.idDetalleRemitido].packing_list, function (idPackaginList) {
                return idPackaginList !== action.idPackingList;
            });
            var cloneState = clone(state.detallesRemitidos);
            cloneState[action.idDetalleRemitido].packing_list = array;
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                detallesRemitidos: cloneState
            });

        default:
            return state
    }
}


function allDetallesRemitidos(state = [], action) {
    switch (action.type) {
        case RECEIVE_DETALLESREMITIDOS:
            return normalizeDatos(action.detallesRemitidos).result ? normalizeDatos(action.detallesRemitidos).result : [];
        case RESET_DETALLESREMITIDOS:
            return [];
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    detallesRemitidos: []
}, action) {
    switch (action.type) {
        case ERROR_UPDATE_DETALLESREMITIDOS:
            //TODO definir que hacer con los errores si los invalido puede entrar en loop
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                detallesRemitidos: []
            });
        case REQUEST_UPDATE_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case UPDATE_DETALLEREMITIDO:
            return Object.assign({}, state, {
                detallesRemitidos: union(state.detallesRemitidos, [action.idDetalleRemitido]),
            });
        case SUCCESS_UPDATE_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isUpdating: false,
                detallesRemitidos: []
            });
        default:
            return state
    }
}


const detallesRemitidos = combineReducers({
    byId: detallesRemitidosById,
    allIds: allDetallesRemitidos,
    update: update
});

export default detallesRemitidos;