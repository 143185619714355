import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
import logoMerca from "../../../../assets/images/logoMerca.png";
import * as TipoAnalitica from "../../../../constants/TipoAnalitica";

import clone from "lodash/clone";

import '../../../../assets/css/ProductoBuscado.css';
//Actions
import {createDetallePedido, deleteDetallePedido} from "../../../../actions/DetallePedidoActions";
import {saveCreateAnalitica} from "../../../../actions/AnaliticaActions";
import history from "../../../../history";

class ProductoBuscado extends Component {

    handleAgregarCantidad(idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        let producto = this.props.productos.byId.productos[idProducto];

        //Cantidad
        let nuevo = {};
        if (!detalle) {
            detalle = {};
            detalle["idProducto"] = idProducto;
            detalle["cantidad"] = 1;
        } else {
            detalle.cantidad = detalle.cantidad + 1;
        }

        //Cálculo de precio
        detalle.precioUnitario = producto ? producto.precio : null;


        nuevo[idProducto + "-"] = detalle;

        this.props.createDetallePedido(nuevo);
    }

    handleQuitarCantidad(idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        if (detalle) {
            if (detalle.cantidad > 1) {
                var cambio = {};
                detalle.cantidad = detalle.cantidad - 1;
                cambio[idProducto + "-"] = detalle;
                this.props.createDetallePedido(cambio);
            } else {
                var cambio = {};
                cambio[idProducto + "-"] = detalle;
                this.props.deleteDetallePedido(cambio);
            }
        }
    }

    handleChangeCantidad(e, idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        let producto = this.props.productos.byId.productos[idProducto];

        if (detalle) {
            var cambio = {};
            detalle.cantidad = parseInt(e.target.value);
            //Cálculo de precio
            detalle.precioUnitario = producto ? producto.precio : null;

            cambio[idProducto + "-"] = detalle;
            this.props.createDetallePedido(cambio);
        }
    }

    deleteProducto(detalle) {
        let cambio = {};
        cambio[detalle.idProducto + "-"] = detalle;
        this.props.deleteDetallePedido(cambio);
    }

    gotoProducto(id) {
        history.push("/producto/" + id);
    }

    render() {
        const {producto} = this.props;
        const tienda = this.props.tiendas.update.activo;

        let precioActual = producto.precio;

        let detallePedido = this.props.detallesPedido.create.detallesPedido[producto.id + "-"];

        return (
            <section className="producto-buscado producto-carrito">
                <div className="producto" onClick={() => this.gotoProducto(producto.id)}>
                    <div className="container-img">
                        {
                            producto.imgProducto ?
                                <img src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto} alt=""/>
                                :
                                <img src={logoMerca} alt=""/>

                        }
                    </div>
                    <div className="container-info-producto">
                        <div>
                            <div className="container-detalle">
                                <div className="nombre-producto">
                                    <p>{producto && producto.nomProducto}</p>
                                </div>
                                <div className="detalle-producto">
                                    <p>{producto && producto.presentacion}</p>
                                </div>
                            </div>
                            <div className="container-precios">
                                <div className="container-precio">
                                    <p>{precioActual ? '$' + precioActual : ""}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cantidad-precio">
                        {
                            producto.publicar === 1 && producto.noVendible === 0 &&
                            <div className="container-cantidad">
                                <div className="btn-restar"
                                     onClick={(a) => {
                                         a.stopPropagation();
                                         this.handleQuitarCantidad(producto.id);
                                     }}>&#8722;</div>
                                <input type="number" min={0}
                                       value={detallePedido ? detallePedido.cantidad : 0}
                                       onChange={(e) => this.handleChangeCantidad(e, producto.id)}/>
                                <div className="btn-sumar"
                                     onClick={(a) => {
                                         a.stopPropagation();
                                         this.handleAgregarCantidad(producto.id)
                                     }}>&#43;</div>
                            </div>
                        }
                        {
                            (producto.publicar === 0 || (producto.publicar === 1 && producto.noVendible === 1)) &&
                            <div className="container-cantidad">
                                <a alt="Chatea con nosotros"
                                   href={`${tienda ? tienda.whatsappLink : ""}?text=Hola! Quisiera consultar por el producto ${producto.nomProducto} ${producto.codBarra}. Gracias!`}
                                   target="_blank"
                                   onClick={() => {
                                       this.props.saveCreateAnalitica(producto.id, TipoAnalitica.CLICK_WHATSAPP_ID);
                                   }}
                                >
                                    Consultar
                                </a>
                            </div>
                        }


                    </div>
                </div>
            </section>

        );
    }
}

function mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        tiendas: state.tiendas

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createDetallePedido: (detalle) => {
            dispatch(createDetallePedido(detalle))
        },
        deleteDetallePedido: (detalle) => {
            dispatch(deleteDetallePedido(detalle))
        },
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductoBuscado));


