import React from "react";
import c from '../constants/Constants';

require('es6-promise').polyfill();
require('isomorphic-fetch');

var estadosDetallePedido = {

    saveCreate(estados) {

        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(estados)
        };

        return fetch(c.BASE_URL + '/estadoDetallePedido', defaultOptions);
    },
    saveUpdate(estados) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(estados)
        };

        return fetch(c.BASE_URL + '/estadoDetallePedido/1', defaultOptions);
    }
};

export default estadosDetallePedido;