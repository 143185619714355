import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
//css
import '../../assets/css/MisPedidos.css';
//Actions
import {fetchPedidosIfNeeded, invalidatePedidos, resetPedidos} from '../../actions/PedidoActions';
import {fetchFormasEntregaIfNeeded} from "../../actions/FormaEntregaActions";
import EstadoLabel from "./componentes/EstadoLabel";
import estadoPedidoHelper from "../../utils/estadosPedido";
import {
    fetchDetallesPedidoIfNeeded,
    invalidateDetallesPedido,
    resetDetallesPedido
} from "../../actions/DetallePedidoActions";
import * as FormaEntrega from "../../constants/FormaEntrega";
import {resetDireccionesEntrega} from "../../actions/DireccionEntregaActions";
//Components

//img

var moment = require('moment');

export default function MisPedidos() {
    const [showDetalle, setShowDetalle] = useState(null);

    const pedidos = useSelector(state => state.pedidos);
    const direccionesEntrega = useSelector(state => state.direccionesEntrega);
    const estadosPedido = useSelector(state => state.estadosPedido);
    const detallesPedido = useSelector(state => state.detallesPedido);
    const productos = useSelector(state => state.productos);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(invalidatePedidos());
        dispatch(fetchPedidosIfNeeded({
            order: "fechaPedido",
            searchPhase: "",
            mostrar: "todos",
            direction: "DESC",
            registros: 0
        }));
        dispatch(fetchFormasEntregaIfNeeded());
        return function cleanup() {
            dispatch(resetPedidos());
            dispatch(resetDireccionesEntrega());
            dispatch(resetDetallesPedido());
        }
    }, []);


    function viewEstados(a) {
        a.stopPropagation();
    }

    function getDetallePedido(idPedido) {
        dispatch(invalidateDetallesPedido());
        dispatch(fetchDetallesPedidoIfNeeded(idPedido));
    }

    let array = [1, 2, 3, 4];
    let filas =
        pedidos.byId.isFetching ?
            (array.map(function (array, index) {
                return (<tr key={index} className="loader-invisible">
                    <th><p className="parrafoInvisible"/></th>
                    <th><p className="parrafoInvisible"/></th>
                    <th><p className="parrafoInvisible"/></th>
                    <th><p className="parrafoInvisible"/></th>
                    <th><p className="parrafoInvisible"/></th>
                    <th><p className="parrafoInvisible"/></th>
                    <th><p className="parrafoInvisible"/></th>
                </tr>)
            }))
            :
            pedidos.allIds.map(function (key, i) {
                let pedido = pedidos.byId.pedidos[key];
                let direccionEntrega = direccionesEntrega.byId.direccionesEntrega[pedido.idDireccionEntrega];

                let totalEnvio = 0;
                if (pedido.idFormaEntrega && pedido.idFormaEntrega == FormaEntrega.ENVIO_A_DOMICILIO_CON_PAGO_EN_DESTINO_ID) {
                    totalEnvio = parseFloat(pedido.totalEnvio);
                }
                let precioTotal = ((totalEnvio ? totalEnvio : 0) + parseFloat(pedido.total)).toFixed(2);


                return (
                    <React.Fragment key={i}>
                        <tr className="tr-tabla-ppal" key={pedido.id}>
                            <td>{pedido.id}</td>
                            <td>{moment(pedido.fechaPedido).format("DD/MM/YYYY HH:mm")}</td>
                            <td>{direccionEntrega ? direccionEntrega.direccionEntrega : FormaEntrega.PICKUP_CON_PAGO_EN_DESTINO}</td>
                            <td>{pedido.detalle_pedido ? pedido.detalle_pedido.length : ""}</td>
                            <td>{"$" + precioTotal}</td>
                            <td onClick={(a) => viewEstados(a)}>
                                <EstadoLabel
                                    funcionEstados={estadoPedidoHelper.labelEstadoPedido}
                                    listaIdEstados={pedido.estado_pedido}
                                    estadoById={estadosPedido.byId.estadosPedido}
                                    nombreDescripcion="desEstadoPedido"
                                />
                            </td>
                            <td>
                                <a href="" onClick={(e) => {
                                    e.preventDefault();
                                    if (showDetalle === "show-" + pedido.id) {
                                        setShowDetalle(null);
                                    } else {
                                        setShowDetalle("show-" + pedido.id);
                                        getDetallePedido(pedido.id);
                                    }
                                }}>
                                    {showDetalle !== "show-" + pedido.id ? "Ver detalle" : "Ocultar detalle"}
                                </a>
                            </td>
                        </tr>
                        <tr className={`detalle-pedido ${showDetalle === "show-" + pedido.id ? "showDetalle" : ""}`}>
                            <td colSpan={7} style={{textAlign: "center", width: "100vw"}}>
                                <table className={"tabla-detalle-pedido"}>
                                    <thead>
                                    <tr>
                                        <th>Descripción</th>
                                        <th>Cantidad</th>
                                        <th>Precio unitario</th>
                                        <th>Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        pedido.detalle_pedido.map((idDetallePedido) => {
                                            let detallePedido = detallesPedido.byId.detallesPedido[idDetallePedido];
                                            let producto = null;
                                            if (detallePedido)
                                                producto = productos.byId.productos[detallePedido.idProducto];
                                            return (
                                                <tr key={idDetallePedido}>
                                                    <td>
                                                        {producto && producto.nomProducto}
                                                    </td>
                                                    <td>
                                                        {detallePedido && detallePedido.cantidad}
                                                    </td>
                                                    <td>
                                                        {detallePedido && "$ " + detallePedido.precioUnitario}
                                                    </td>
                                                    <td>
                                                        {detallePedido && "$ " + (detallePedido.precioUnitario * detallePedido.cantidad).toFixed(2)}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    {
                                        <React.Fragment>
                                            <tr style={{backgroundColor: "white"}}>
                                                <td colSpan={3} style={{textAlign: "right"}}>
                                                    <b>Subtotal</b>
                                                </td>
                                                <td>
                                                    {pedido.total && "$ " + pedido.total.toFixed(2)}
                                                </td>
                                            </tr>

                                            {
                                                !!totalEnvio &&
                                                <tr style={{backgroundColor: "white"}}>
                                                    <td colSpan={3} style={{textAlign: "right"}}>
                                                        <b>Envío</b>
                                                    </td>
                                                    <td>
                                                        {"$ " + totalEnvio.toFixed(2)}
                                                    </td>
                                                </tr>
                                            }
                                            <tr style={{backgroundColor: "white"}}>
                                                <td colSpan={3} style={{textAlign: "right"}}>
                                                    <b>Total</b>
                                                </td>
                                                <td>
                                                    {"$ " + precioTotal}
                                                </td>
                                            </tr>
                                        </React.Fragment>

                                    }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </React.Fragment>
                )
            });

    return (
        <div className="mis-pedidos">
            <div className="titulo-configuracion">
                <h2>Mis Pedidos</h2>
            </div>
            <table className="tabla-ppal">
                <thead className="thead-tabla-ppal">
                <tr>
                    <th>Nº Pedido</th>
                    <th>Fecha de pedido</th>
                    <th>Dirección de entrega</th>
                    <th>Cant. Items</th>
                    <th>Importe</th>
                    <th>Estado</th>
                    <th>Detalle</th>
                </tr>
                </thead>
                <tbody className="body-tabla-ppal">
                {filas.length ? filas :
                    <td colSpan={7} style={{textAlign: "center"}}>No hay registros de pedidos</td>}
                </tbody>
            </table>
        </div>
    );
}

