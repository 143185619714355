import {
    ERROR_PROMOCION,
    ERROR_PROMOCIONES,
    INVALIDATE_PROMOCIONES,
    RECEIVE_PROMOCION,
    RECEIVE_PROMOCIONES,
    REQUEST_PROMOCION,
    REQUEST_PROMOCIONES,
    RESET_PROMOCIONES,
} from '../actions/PromocionActions';
import {combineReducers} from 'redux';

import merge from "lodash/merge";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        promociones: {},
    }
}

function promocionesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_PROMOCIONES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_PROMOCIONES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_PROMOCIONES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PROMOCIONES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                promociones: {}
            });
        case RECEIVE_PROMOCIONES:
            let dato = action.promociones.entities.promociones;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                promociones: merge({}, state.promociones, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_PROMOCION:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_PROMOCION:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_PROMOCION:
            let datoPromocion = action.promociones.entities.promociones;
            return Object.assign({}, state, {
                promociones: merge({}, state.promociones, datoPromocion),
                isFetching: false,
            });
        default:
            return state
    }
}


function allPromociones(state = [], action) {
    switch (action.type) {
        case RECEIVE_PROMOCIONES:
            return action.promociones.result.promociones;
        case RESET_PROMOCIONES:
            return [];
        default:
            return state
    }
}

const promociones = combineReducers({
    byId: promocionesById,
    allIds: allPromociones
});

export default promociones;