//api
import promociones from "../api/promociones"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDato, normalizeDatos} from "../normalizers/normalizePromociones";

//PROMOCIONES
export const REQUEST_PROMOCIONES = 'REQUEST_PROMOCIONES';
export const RECEIVE_PROMOCIONES = 'RECEIVE_PROMOCIONES';
export const INVALIDATE_PROMOCIONES = 'INVALIDATE_PROMOCIONES';
export const ERROR_PROMOCIONES = "ERROR_PROMOCIONES";
export const RESET_PROMOCIONES = "RESET_PROMOCIONES";


export function invalidatePromociones() {
    return {
        type: INVALIDATE_PROMOCIONES
    }
}

function requestPromociones() {
    return {
        type: REQUEST_PROMOCIONES,
    }
}

function receivePromociones(json) {
    return {
        type: RECEIVE_PROMOCIONES,
        promociones: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorPromociones(error) {
    return {
        type: ERROR_PROMOCIONES,
        error: error,
    }
}

export function resetPromociones() {
    return {
        type: RESET_PROMOCIONES
    }
}

export function fetchPromociones(filtros) {
    return dispatch => {
        dispatch(requestPromociones());
        return promociones.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePromociones(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorPromociones(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPromociones(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchPromociones(state) {
    const promociones = state.promociones.byId;
    if (!promociones) {
        return true
    } else if (promociones.isFetching) {
        return false
    } else {
        return promociones.didInvalidate;
    }
}

export function fetchPromocionesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchPromociones(getState())) {
            return dispatch(fetchPromociones(filtros))
        }
    }
}


//MODEL
export const REQUEST_PROMOCION = 'REQUEST_PROMOCION';
export const RECEIVE_PROMOCION = 'RECEIVE_PROMOCION';
export const INVALIDATE_PROMOCION = 'INVALIDATE_PROMOCION';
export const ERROR_PROMOCION = "ERROR_PROMOCION";
export const RESET_PROMOCION = "RESET_PROMOCION";


export function invalidatePromocion() {
    return {
        type: INVALIDATE_PROMOCION
    }
}

function requestPromocion() {
    return {
        type: REQUEST_PROMOCION,
    }
}

function receivePromocion(json) {
    return {
        type: RECEIVE_PROMOCION,
        promociones: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorPromocion(error) {
    return {
        type: ERROR_PROMOCION,
        error: error,
    }
}

export function resetPromocion() {
    return {
        type: RESET_PROMOCION
    }
}

export function fetchPromocion(idPromocion) {
    return dispatch => {
        dispatch(requestPromocion());
        return promociones.getOne(idPromocion)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receivePromocion(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorPromocion(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorPromocion(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}