//api
import detallesRemitidos from "../api/detallesRemitidos"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";


//DETALLESREMITIDOS
export const REQUEST_DETALLESREMITIDOS = 'REQUEST_DETALLESREMITIDOS';
export const RECEIVE_DETALLESREMITIDOS = 'RECEIVE_DETALLESREMITIDOS';
export const INVALIDATE_DETALLESREMITIDOS = 'INVALIDATE_DETALLESREMITIDOS';
export const ERROR_DETALLESREMITIDOS = "ERROR_DETALLESREMITIDOS";
export const RESET_DETALLESREMITIDOS = "RESET_DETALLESREMITIDOS";
export const REQUEST_UPDATE_DETALLESREMITIDOS = "REQUEST_UPDATE_DETALLESREMITIDOS";
export const UPDATE_DETALLEREMITIDO = "UPDATE_DETALLEREMITIDO";
export const SUCCESS_UPDATE_DETALLESREMITIDOS = "SUCCESS_UPDATE_DETALLESREMITIDOS";
export const ERROR_UPDATE_DETALLESREMITIDOS = "ERROR_UPDATE_DETALLESREMITIDOS";


export function invalidateDetalleRemitido(detallePedidoMon) {
    return {
        type: INVALIDATE_DETALLESREMITIDOS,
        detallePedidoMon
    }
}

function requestDetalleRemitido() {
    return {
        type: REQUEST_DETALLESREMITIDOS,
    }
}

function receiveDetalleRemitido(json) {
    return {
        type: RECEIVE_DETALLESREMITIDOS,
        detallesRemitidos: json,
        receivedAt: Date.now()
    }
}

function errorDetalleRemitido(error) {
    return {
        type: ERROR_DETALLESREMITIDOS,
        error: error,
    }
}

export function resetDetalleRemitido(error) {
    return {
        type: RESET_DETALLESREMITIDOS,
        error: error,
    }
}

function fetchDetalleRemitido(idRemito) {
    return dispatch => {
        dispatch(requestDetalleRemitido());
        return detallesRemitidos.getByIdRemito(idRemito)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    // auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveDetalleRemitido(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorDetalleRemitido(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDetalleRemitido(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchDetalleRemitido(state, idRemito) {
    var remito = state.remitos.byId.remitos[idRemito];

    const detallesRemitidos = state.detallesRemitidos.byId;
    if (!detallesRemitidos) {
        return true
    } else if (detallesRemitidos.isFetching) {
        return false
    } else if (remito != undefined) {
        for (var idDetalleRemitido of remito.detalle_remitido) {
            if (!state.detallesRemitidos.byId.detallesRemitidos[idDetalleRemitido]) {
                return true;
            }
        }
        return detallesRemitidos.didInvalidate;
    } else {
        return detallesRemitidos.didInvalidate;
    }
}

export function fetchDetallesRemitidoIfNeeded(idRemito) {
    return (dispatch, getState) => {
        if (shouldFetchDetalleRemitido(getState(), idRemito)) {
            return dispatch(fetchDetalleRemitido(idRemito))
        }
    }
}

function requestUpdateDetalleRemitido() {
    return {
        type: REQUEST_UPDATE_DETALLESREMITIDOS,
    }
}

function receiveUpdateDetalleRemitido() {
    return {
        type: SUCCESS_UPDATE_DETALLESREMITIDOS,
        receivedAt: Date.now()
    }
}

function updateDetalleRemitido(detalleRemitido, idDetalleRemitido) {
    return {
        type: UPDATE_DETALLEREMITIDO,
        detalleRemitido,
        idDetalleRemitido
    }
}

export function changeDetalleRemitido(remito, idDetalleRemitido) {
    return dispatch => {
        //dispatch(invalidateDetalleRemitido());
        dispatch(updateDetalleRemitido(remito, idDetalleRemitido));
    }
}

function errorUpdateDetalleRemitido(error) {
    return {
        type: ERROR_UPDATE_DETALLESREMITIDOS,
        error: error,
    }
}

export function saveUpdateDetalleRemitido(idRemito) {
    return (dispatch, getState) => {
        dispatch(requestUpdateDetalleRemitido());
        //Tomo los nuevos packing
        var detallesRemitidosM = getState().detallesRemitidos.update.detallesRemitidos.map((id) => {
            return (getState().detallesRemitidos.byId.detallesRemitidos[id]);
        });
        return detallesRemitidos.update(detallesRemitidosM)
            .then(function (response) {
                //Refresco token
                // auth.addToken(response.headers);
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdateDetalleRemitido());
                    //dispatch(invalidateDetalleRemitido());
                    //dispatch(fetchDetallesRemitidoIfNeeded(idRemito));
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateDetalleRemitido(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorUpdateDetalleRemitido(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}