import React, {useEffect, useRef} from 'react';
import {useParams} from "react-router";
import '../../assets/css/CompraExitosa.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretSquareLeft} from "@fortawesome/free-solid-svg-icons";
import logoMercadoPago from "../../assets/images/logo-mercadopago.png";
import history from '../../history';
import {useDispatch, useSelector} from "react-redux";

import {fetchPedido} from "../../actions/PedidoActions";

export default function PagoMP() {
    const pedidos = useSelector(state => state.pedidos);
    const {idPedido} = useParams();
    const dispatch = useDispatch();
    const mounted = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);

        if (pedidos.update.activo.id && pedidos.update.activo.id == idPedido) {
            createScriptMP();
        } else {
            dispatch(fetchPedido(idPedido));
        }
    }, []);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            if (pedidos.update.isFetching === false && pedidos.update.activo.id) {
                createScriptMP();
            }
        }
    }, [pedidos.update.isFetching]);


    function createScriptMP() {
        const script = document.createElement("script");

        script.src = "https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js";
        script.async = true;
        script.onload = () => document.getElementsByClassName("mercadopago-button")[0].click();
        script.setAttribute('data-button-label', "PAGAR");
        script.setAttribute('data-preference-id', pedidos.update.activo.idPagoMP);
        script.setAttribute('id', "modal-mp");
        document.getElementById("mp-container").appendChild(script);

        window.addEventListener("message", (event) => {
            if (event.origin !== 'https://www.mercadopago.com.ar' || !event.data.type) {
                return;
            }

            let dataType = event.data.type; // "close", "submit", etc...
            if (dataType === 'submit') {
                // Tu funcionalidad a ejecutar...
                const paymentData = event.data.value; // Es un arreglo con la respuesta del pago procesado.

                let payment_status = null;
                paymentData.some((data) => {
                    if (data.id === "payment_status") {
                        payment_status = data.value;
                    }
                })
                let wrapper = document.getElementsByClassName("mp-mercadopago-checkout-wrapper")[0];
                let modalMp = document.getElementById("modal-mp");
                switch (payment_status) {
                    case "approved":
                        if (wrapper)
                            wrapper.remove();
                        if (modalMp)
                            modalMp.remove();
                        history.push("/estadoCompra/success");
                        break;
                    case "pending":
                        history.push("/estadoCompra/success");
                        if (wrapper)
                            wrapper.remove();
                        if (modalMp)
                            modalMp.remove();
                        history.push("/estadoCompra/pending");
                        break;
                    // case "rejected":
                    //     history.push("/estadoCompra/failure");
                    //     break;

                }

                document.body.style.overflowY = "auto";


                return;
            }
        });
    }

    return (
        <section className="compra-exitosa">
            <div className="container-limit">
                <div className="imagen-circular">
                    <img src={logoMercadoPago} alt=""/>
                </div>
                <h2>Proceso de pago</h2>
                <form action="/" id={"l"} onSubmit={(e) => {
                    e.preventDefault();
                }}>
                    <div className="container-botones" id={"mp-container"}>
                        <button className="encargar" style={{outline: "none"}} onClick={() => {
                            history.push("/");
                        }}>
                            VOLVER <span><FontAwesomeIcon icon={faCaretSquareLeft} color="white"/></span>
                        </button>
                    </div>
                </form>
            </div>
        </section>

    );
}