import React, {useEffect, useRef} from 'react';
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import "../../../../assets/css/DireccionEntregaRegistro.css";
//Hooks
//Actions
import {
    createDireccionEntrega,
    fetchDireccionEntrega,
    invalidateDireccionEntrega,
    updateDireccionEntrega,
} from "../../../../actions/DireccionEntregaActions";
import CampoTexto from "../../componentes/CampoTexto";
import {ReactComponent as Lapiz} from "../../../../assets/images/pencil.svg";
import CampoTextArea from "../../componentes/CampoTextArea";
import {GoogleApiWrapper} from 'google-maps-react';
import {fetchZonasEntregaIfNeeded} from "../../../../actions/ZonasEntregaActions";
import AutosuggestProvincia from "../../componentes/Checkout/DireccionEntrega/AutosuggestProvincia";
import AutosuggestLocalidad from "../../componentes/Checkout/DireccionEntrega/AutosuggestLocalidad";
import zonaEntregaUtil from "../../../../utils/calculoZonaEntrega";

//lodash

//Constants


const DireccionEntregaRegistro = (props) => {
    const dispatch = useDispatch();
    let {idDireccionEntrega} = useParams();
    if (idDireccionEntrega === 'nuevo') idDireccionEntrega = null;
    const mounted = useRef();
    //Store
    const direccionesEntrega = useSelector(state => state.direccionesEntrega);
    const direccionEntrega = idDireccionEntrega ? direccionesEntrega.update.activo : direccionesEntrega.create.nuevo;
    const zonasEntrega = useSelector(state => state.zonasEntrega);
    const guardandoLoading = idDireccionEntrega ? direccionesEntrega.update.isUpdating : direccionesEntrega.create.isCreating;
    const error = idDireccionEntrega ? direccionesEntrega.update.error : direccionesEntrega.create.error;


    //Effects
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(invalidateDireccionEntrega());
        if (idDireccionEntrega)
            dispatch(fetchDireccionEntrega(idDireccionEntrega));
        dispatch(fetchZonasEntregaIfNeeded());

        return function cleanup() {
            zonaEntregaUtil.identificarLugar(direccionEntrega, idDireccionEntrega, zonasEntrega, dispatch, updateDireccionEntrega, createDireccionEntrega);
        }
    }, []);

    useEffect(() => {
        if (props.paso === 5)
            zonaEntregaUtil.identificarLugar(direccionEntrega, idDireccionEntrega, zonasEntrega, dispatch, updateDireccionEntrega, createDireccionEntrega);
    }, [props.paso]);


    const handleChangeDireccionEntrega = (e) => {
        let cambio = {};
        cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
        cambio.principal = 1;

        if (idDireccionEntrega) {
            dispatch(updateDireccionEntrega(cambio));
        } else {
            dispatch(createDireccionEntrega(cambio));
        }
    };


// endregion

    return (
        <section className="direcionEntregaRegistro">
            {/*<div className="container-limit">*/}
            <div className="container-direccionEntrega">

                <div className="container-direccionEntregaRegistro-form">
                    <div className="fila">
                        <CampoTexto id="calle" nombre="Calle"
                                    value={direccionEntrega.calle ? direccionEntrega.calle : ""}
                                    handleChange={handleChangeDireccionEntrega.bind(this)}
                                    autoComplete="off" required={true}/>
                        <CampoTexto id="numero" nombre="Número"
                                    value={direccionEntrega.numero ? direccionEntrega.numero : ""}
                                    handleChange={handleChangeDireccionEntrega.bind(this)}
                                    autoComplete="off" required={true}/>
                    </div>
                    <div className="fila">
                        <CampoTexto id="detalles" nombre="Piso, departamento (opcional)"
                                    value={direccionEntrega.detalles ? direccionEntrega.detalles : ""}
                                    handleChange={handleChangeDireccionEntrega.bind(this)}
                                    autoComplete="new-password"/>
                    </div>
                    <div className="fila">
                        <CampoTexto id="codPostal" nombre="Código postal"
                                    value={direccionEntrega.codPostal ? direccionEntrega.codPostal : ""}
                                    handleChange={handleChangeDireccionEntrega.bind(this)}
                                    autoComplete="off" required={true}/>
                    </div>
                    <div className="fila">
                        <AutosuggestProvincia id="provincia"
                                              value={direccionEntrega.provincia ? direccionEntrega.provincia : ""}
                                              handleChange={handleChangeDireccionEntrega.bind(this)}
                                              nombre="Provincia"/>
                        <AutosuggestLocalidad id="localidad"
                                              value={direccionEntrega.localidad ? direccionEntrega.localidad : ""}
                                              handleChange={handleChangeDireccionEntrega.bind(this)}
                                              nombre="Localidad"/>
                    </div>
                    <div className="fila">
                        <CampoTextArea required={false} id="observaciones"
                                       nombre="Observaciones (opcional)"
                                       img={<Lapiz style={{width: "15px", height: "15px"}}/>} type="text" rows={2}
                                       value={direccionEntrega.observaciones ? direccionEntrega.observaciones : ""}
                                       handleChange={handleChangeDireccionEntrega.bind(this)}/>
                    </div>
                </div>
            </div>

            {/*</div>*/}
        </section>
    );
};

export default GoogleApiWrapper({
    apiKey: ("AIzaSyANSH-395EpmTpki-222stF4V76Rp8KcZY"),
    libraries: ['places', 'geometry'],
})(DireccionEntregaRegistro);
