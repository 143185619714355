import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom'
import MetaTags from 'react-meta-tags';
import * as TipoAnalitica from "../../constants/TipoAnalitica";
//css
import '../../assets/css/ProductoPresentacion.css';
import '../../assets/css/Breadcrum.css';
//img
import logoMerca from "../../assets/images/logoMerca.png";
import {ReactComponent as Happy} from "../../assets/images/happy.svg";
//Actions
import {fetchProducto, resetProducto} from "../../actions/ProductoActions";
import {fetchCategoriasIfNeeded} from "../../actions/CategoriaActions"
import {createDetallePedido, deleteDetallePedido} from "../../actions/DetallePedidoActions";
import {fetchPromocionesIfNeeded} from "../../actions/PromocionActions";
//Components
import {ReactComponent as Cart} from "../../assets/images/cart.svg";
import clone from "lodash/clone";
import Gallery from 'react-photo-gallery';
import CarrouselProductoPresentacion from "./Carruseles/CarrouselProductoPresentacion";
import ProductoUnitarioCombo from "./componentes/Productos/ProductoUnitarioCombo";
import {saveCreateAnalitica} from "../../actions/AnaliticaActions";


class ProductoPresentacion extends Component {

    constructor(props) {
        super(props);
        this.state = {currentImage: 0, modalIsOpen: false};
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);

        this.photos = [];

        this.lightbox = [];
    }

    openLightbox(event, obj) {
        this.setState({currentImage: obj.index + 1});
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    componentDidMount() {
        let idProducto = this.props.match.params.idProducto;
        this.props.fetchPromocionesIfNeeded({estado: true});
        this.props.fetchProducto(idProducto);
        this.props.fetchCategoriasIfNeeded({orden: 1, publicar: true});
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.idProducto !== this.props.match.params.idProducto) {
            let idProducto = this.props.match.params.idProducto;
            this.props.fetchProducto(idProducto);
        }
    }

    componentWillUnmount() {
        this.props.resetProducto();
    }

    handleAgregarCantidad(idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        let producto = this.props.productos.byId.productos[idProducto];

        //Cantidad
        let nuevo = {};
        if (!detalle) {
            detalle = {};
            detalle["idProducto"] = idProducto;
            detalle["cantidad"] = 1;
        } else {
            detalle.cantidad = detalle.cantidad + 1;
        }
        detalle.precioUnitario = producto.precio;

        nuevo[idProducto + "-"] = detalle;

        this.props.createDetallePedido(nuevo);

        if (!this.mobilecheck() && window.innerWidth > 850)
            this.props.toogleBarraCarrito(true);
    }

    handleQuitarCantidad(idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        if (detalle) {
            if (detalle.cantidad > 1) {
                var cambio = {};
                detalle.cantidad = detalle.cantidad - 1;
                //Cálculo de precio
                let producto = this.props.productos.byId.productos[idProducto];
                detalle.precioUnitario = producto.precio;

                cambio[idProducto + "-"] = detalle;
                this.props.createDetallePedido(cambio);
            } else {
                var cambio = {};
                cambio[idProducto + "-"] = detalle;
                this.props.deleteDetallePedido(cambio);
            }
            if (!this.mobilecheck() && window.innerWidth > 850)
                this.props.toogleBarraCarrito(true);
        }
    }

    handleChangeCantidad(e, idProducto) {
        var detalle = clone(this.props.detallesPedido.create.detallesPedido[idProducto + "-"]);
        if (detalle) {
            var cambio = {};
            detalle.cantidad = parseInt(e.target.value);
            //Cálculo de precio
            let producto = this.props.productos.byId.productos[idProducto];
            detalle.precioUnitario = producto.precio;
            cambio[idProducto + "-"] = detalle;
            this.props.createDetallePedido(cambio);
            if (!this.mobilecheck() && window.innerWidth > 850)
                this.props.toogleBarraCarrito(true);
        }
    }

    mobilecheck() {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    render() {
        let idProducto = this.props.match.params.idProducto;
        let producto = {};
        if (this.props.productos.byId.productos[idProducto])
            producto = this.props.productos.byId.productos[idProducto];

        this.photos = [];

        let Imagenes = [];
        if (producto && producto.imagenes) {
            Imagenes = producto.imagenes.map((imagen) => {
                this.photos.push({src: imagen.nombre ? process.env.PUBLIC_URL + "/productos/" + imagen.nombre : logoMerca,});
                return (
                    <div className="item">
                        <img
                            src={process.env.PUBLIC_URL + "/productos/" + imagen.nombre}
                            alt=""/>
                    </div>
                );
            });
        }

        let detallePedido = this.props.detallesPedido.create.detallesPedido[producto.id + "-"];

        let precioProducto = producto.precio ? producto.precio.toString().split('.') : null;

        //Selección de categoria
        let linkCategorias = [];
        let categoriaSeleccionada = {};

        let categoria = this.props.categorias.byId.categorias[producto.idCategoria];
        //Navegador de categoria seleccioanda
        var idCategoriaPadre = categoria ? categoria.idCategoriaPadre : null;

        while (idCategoriaPadre !== null && idCategoriaPadre !== undefined) {
            let categoria = this.props.categorias.byId.categorias[idCategoriaPadre];

            if (categoria && categoria.id) {
                linkCategorias.push(<li><Link
                    to={{pathname: "/search", search: "?cat=" + categoria.id}}>{categoria.desCategoria}</Link>
                </li>);
                idCategoriaPadre = categoria.idCategoriaPadre;
            } else
                idCategoriaPadre = null;
        }
        linkCategorias.reverse();


        let aclaracion = "";
        let color = {color: "inherit"};
        if (detallePedido && producto && !detallePedido.idPromocion && producto.cantidadMinimaCompra && producto.cantidadMinimaCompra > 0 && detallePedido.cantidad < producto.cantidadMinimaCompra) {
            aclaracion = "Cantidad mínima de compra: " + producto.cantidadMinimaCompra
            color = {color: "red"};
        } else if (detallePedido && detallePedido.avisoStock === true) {
            aclaracion = "Últimos " + producto.stock + " disponibles 🔥";
            color = {color: "red"};
        }

        //Promocion
        let Promociones = [];
        if (producto && producto.producto_origen_promocion)
            Promociones = producto.producto_origen_promocion.map((idProductoOrigenPromocion) => {
                let productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                let promocionProducto = {};
                if (productoOrigenPromocion)
                    promocionProducto = this.props.promociones.byId.promociones[productoOrigenPromocion.idPromocion];
                let productosDestinoPromocion = [];
                let productosOrigenPromocion = [];
                let productos = [];
                let Origenes = [];
                if (promocionProducto && promocionProducto.producto_origen_promocion)
                    Origenes = promocionProducto.producto_origen_promocion.map((idProductoOrigenPromocion) => {
                        let productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                        let producto = this.props.productos.byId.productos[productoOrigenPromocion.idProducto];
                        productos.push(producto);
                        productosOrigenPromocion.push(productoOrigenPromocion);
                        return (
                            <li>
                                <img
                                    src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}
                                    alt=""/>
                                {
                                    promocionProducto.formatoPromocion === "Combo" &&
                                    <p>{productoOrigenPromocion.cantidad + " " + (producto.medida ? producto.medida : "unidad") + " de " + producto.nomProducto + " - " + producto.presentacion}</p>
                                }
                                {
                                    promocionProducto.formatoPromocion === "Producto individual" &&
                                    <p>{producto.nomProducto + " - " + producto.presentacion}</p>
                                }

                            </li>
                        )
                    })
                let Destino = [];
                if (promocionProducto && promocionProducto.producto_destino_promocion)
                    Destino = promocionProducto.producto_destino_promocion.map((idProductoDestinoPromocion) => {
                        let productoDestinoPromocion = this.props.productosDestinoPromocion.byId.productosDestinoPromocion[idProductoDestinoPromocion];
                        let producto = this.props.productos.byId.productos[productoDestinoPromocion.idProducto];
                        productosDestinoPromocion.push(productoDestinoPromocion);
                        return (
                            <li>
                                <img
                                    src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}
                                    alt=""/>
                                <p>{promocionProducto.tipoPromocion === "Descuento" ? productoDestinoPromocion.descuento + "% OFF en " + producto.nomProducto + " - " + producto.presentacion : productoDestinoPromocion.cantidad + " " + (producto.medida ? producto.medida : "unidad") + " de " + producto.nomProducto + " - " + producto.presentacion} </p>
                            </li>
                        )
                    })
                return (
                    <div className="promocion">
                        <h4>{`${promocionProducto && promocionProducto.nomPromocion}`}</h4>
                        {
                            promocionProducto && promocionProducto.formatoPromocion === "Combo" &&
                            <p>Llevando los siguientes productos</p>
                        }
                        {
                            promocionProducto && promocionProducto.formatoPromocion === "Producto individual" &&
                            <p>{`Llevando ${promocionProducto.cantidad} unidades de cualquiera de los siguientes productos`}</p>
                        }
                        <ul className="lista-promos">
                            {Origenes}
                        </ul>
                        {
                            promocionProducto && promocionProducto.tipoPromocion === "Descuento" &&
                            <p>Tenés descuento en</p>
                        }
                        {
                            promocionProducto && promocionProducto.tipoPromocion === "Sin cargo" &&
                            <p>Te regalamos</p>
                        }
                        <ul className="lista-promos">
                            {Destino}
                        </ul>
                        {
                            promocionProducto && promocionProducto.formatoPromocion === "Combo" &&
                            <div className="productos-relacionados">
                                <ProductoUnitarioCombo key={promocionProducto.id}
                                                       productos={productos}
                                                       promocion={promocionProducto}
                                                       productosDestinoPromocion={productosDestinoPromocion}
                                                       productosOrigenPromocion={productosOrigenPromocion}
                                                       detallesPedido={this.props.detallesPedido.create.detallesPedido}
                                                       toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}
                                                       createDetallePedido={this.props.createDetallePedido.bind(this)}
                                                       deleteDetallePedido={this.props.deleteDetallePedido.bind(this)}
                                />
                            </div>
                        }
                    </div>
                )


            });

        const tienda = this.props.tiendas.update.activo;

        return (
            <React.Fragment>
                <MetaTags>
                    <title>{producto.nomProducto}</title>
                    <meta name="twitter:card" content={producto.presentacion}/>
                    <meta name="twitter:title"
                          content={(tienda ? tienda.nombreEmpresa : "") + ' | ' + producto.nomProducto}/>
                    <meta name="twitter:description" content={producto.desProducto}/>
                    <meta name="twitter:image" content={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}/>
                    <meta property="og:type" content="website"/>
                    <meta property="fb:app_id" content="659565587823933"/>
                    <meta property="og:title"
                          content={(tienda ? tienda.nombreEmpresa : "") + ' | ' + producto.nomProducto}/>
                    <meta property="og:description"
                          content={producto.desProducto ? producto.desProducto : producto.nomProducto}/>
                    <meta property="og:image:width" content="240"/>
                    <meta property="og:image:height" content="240"/>
                    <meta property="og:image"
                          content={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}/>
                    <meta property="og:image" content={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}/>
                    <meta property="og:url"
                          content={process.env.PUBLIC_URL + "/producto/" + producto.id}/>
                    <meta property="id" content={producto.id}/>
                    <meta property="og:availability"
                          content={detallePedido && detallePedido.avisoStock === true ? "pending" : "in stock"}/>
                    <meta property="product:price:amount" content={producto.precio}/>
                    <meta property="product:price:currency" content="ARS"/>
                    <meta property="product:retailer_item_id" content={producto.id}/>

                </MetaTags>

                <section className="producto-presentacion" id="producto-presentacion">

                    <div className="container-limit">
                        <div className="container-breadcrum">
                            <ul className="breadcrum">
                                {linkCategorias}
                            </ul>

                        </div>
                        <section className="container-producto-presentacion">

                            <div className="container-productos">
                                <div className="wrapper-principal">
                                    <div className="producto" key={producto.id} style={{boxShadow: "none"}}>

                                        <div className="galeria-fotos">
                                            <Gallery columns={4} margin={0} photos={this.photos}
                                                     onClick={this.openLightbox}/>
                                        </div>
                                        <div className="container-img">
                                            {
                                                producto.imgProducto !== null ?
                                                    Imagenes.length > 0 ?
                                                        <CarrouselProductoPresentacion
                                                            idImagenSeleccionada={this.state.currentImage}>
                                                            {Imagenes}
                                                        </CarrouselProductoPresentacion>
                                                        : ""
                                                    :
                                                    <img src={logoMerca} alt=""/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="wrapper-secundario">
                                    <div className="container-titulo">
                                        <div className="nombre-producto">
                                            <p>{producto.nomProducto}</p>
                                        </div>
                                        <div className="detalle-producto">
                                            <p>{producto.presentacion}</p>
                                        </div>
                                        <br/>
                                        <div className="detalle-producto">
                                            <p style={color}>{aclaracion}</p>
                                        </div>
                                    </div>
                                    <div className="container-precios">
                                        <div className="container-botones">
                                            <div className="precios-vigentes">
                                                <div className="costo-inicial">
                                                    <b style={{
                                                        textDecoration: "line-through",
                                                    }}>{producto.precioAnterior && producto.precioAnterior != 0 ? "$" + producto.precioAnterior : ""}</b>
                                                </div>
                                                <div className={"costo-final"}>
                                                    {
                                                        !!precioProducto && !!precioProducto[0] && !!precioProducto[1] &&
                                                        <b>{`$ ${precioProducto[0]}`}<span>{precioProducto[1]}</span></b>
                                                    }
                                                    {
                                                        !!precioProducto && precioProducto.length <= 1 &&
                                                        <b>{`$ ${precioProducto[0]}`}</b>
                                                    }
                                                    {
                                                        !precioProducto &&
                                                        <Link className="registrate" to={"/login"}>Ver precio</Link>
                                                    }
                                                    <p>{(producto.precioAnterior && producto.precioAnterior != 0 ? Math.round((1 - producto.precio / producto.precioAnterior) * 100) + "% OFF" : "")}</p>
                                                </div>
                                                {/*<div className="container-stock">*/}
                                                {/*    {*/}
                                                {/*        <p>Stock disponible</p>*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                                <div className="mas-info">
                                                    {
                                                        producto.tagPrincipal && producto.tagPrincipal != "" &&
                                                        <p className="etiqueta-promo">{producto.tagPrincipal}</p>
                                                    }
                                                    {
                                                        (!!producto.publicar && producto.noVendible !== 1) &&
                                                        <div className="cantidad-precio">
                                                            <p>Cantidad:</p>

                                                            <div className="container-cantidad">
                                                                <div className="btn-restar"
                                                                     onClick={(a) => {
                                                                         a.stopPropagation();
                                                                         this.handleQuitarCantidad(producto.id);
                                                                     }}>&#8722;</div>
                                                                <input type="number" min={0}
                                                                       value={detallePedido ? detallePedido.cantidad : 0}
                                                                       onChange={(e) => this.handleChangeCantidad(e, producto.id)}/>
                                                                <div className="btn-sumar"
                                                                     onClick={(a) => {
                                                                         a.stopPropagation();
                                                                         this.handleAgregarCantidad(producto.id)
                                                                     }}>&#43;</div>
                                                            </div>


                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                (!!producto.publicar && producto.noVendible !== 1) &&
                                                <button className="encargar"
                                                        onClick={() => this.handleAgregarCantidad(producto.id)}>
                                                    COMPRAR <span><Cart/></span>
                                                </button>
                                            }
                                            {
                                                (producto.publicar === 0 || (producto.publicar === 1 && producto.noVendible === 1)) &&
                                                <a alt="Chatea con nosotros"
                                                   href={`${tienda ? tienda.whatsappLink : ""}?text=Hola! Quisiera consultar por el producto ${producto.nomProducto} ${producto.codBarra}. Gracias!`}
                                                   target="_blank"
                                                   className="button-consultar"
                                                   onClick={
                                                       () => {
                                                           this.props.saveCreateAnalitica(producto.id, TipoAnalitica.CLICK_WHATSAPP_ID);
                                                       }
                                                   }>
                                                    <button className="encargar" disabled={false}
                                                            style={{height: "100%"}}>
                                                        Consultar <span><Happy height="15px"/></span>
                                                    </button>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-productos">
                                <div className="wrapper-principal">
                                    <div className="container-precio-detalle">
                                        {/*<div className="container-detalle">*/}
                                        {/*    <div className="detalles-features">*/}
                                        {/*        <h3 className="titulo-secc-producto">*/}
                                        {/*            Características*/}
                                        {/*        </h3>*/}
                                        {/*        <ul>*/}
                                        {/*            <li>*/}
                                        {/*                <b>Presentación</b> <span>Caja</span>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <b>Contenido</b> <span>Caja</span>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <b>Tipo</b> <span>Caja</span>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <b>Presentación</b> <span>Caja</span>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <b>Presentación</b> <span>Caja</span>*/}
                                        {/*            </li>*/}
                                        {/*        </ul>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {
                                            producto.desProducto &&

                                            <div className="descripcion-producto" style={{paddingTop: "32px"}}>
                                                <h3 className="titulo-secc-producto">
                                                    Descripción
                                                </h3>
                                                <p dangerouslySetInnerHTML={{__html: producto.desProducto}}></p>
                                            </div>
                                        }
                                        {
                                            Promociones.length > 0 &&

                                            <div className="descripcion-producto" style={{paddingTop: "32px"}}>
                                                <h3 className="titulo-secc-producto">
                                                    Promociones vigentes
                                                </h3>
                                                {Promociones}
                                            </div>
                                        }
                                        {/*<div className="opiones-producto" style={{paddingTop: "32px"}}>*/}
                                        {/*    <h3 className="titulo-secc-producto">*/}
                                        {/*        Opiniones sobre el producto*/}
                                        {/*    </h3>*/}
                                        {/*    <ListaReviews/>*/}
                                        {/*</div>*/}

                                        {/*<div className="comentarios-section" style={{paddingTop: "32px"}}>*/}
                                        {/*    <h3 className="titulo-secc-producto">*/}
                                        {/*        Preguntas y respuestas*/}
                                        {/*    </h3>*/}
                                        {/*    <ListaPreguntasRespuestas/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="wrapper-secundario">

                                </div>
                            </div>
                        </section>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        pedidos: state.pedidos,
        categorias: state.categorias,
        promociones: state.promociones,
        productosOrigenPromocion: state.productosOrigenPromocion,
        productosDestinoPromocion: state.productosDestinoPromocion,
        tiendas: state.tiendas

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProducto: (idProducto) => {
            dispatch(fetchProducto(idProducto));
        },
        createDetallePedido: (detalle) => {
            dispatch(createDetallePedido(detalle))
        },
        resetProducto: () => {
            dispatch(resetProducto())
        },
        deleteDetallePedido: (detalle) => {
            dispatch(deleteDetallePedido(detalle))
        },
        fetchCategoriasIfNeeded: (filtros) => {
            dispatch(fetchCategoriasIfNeeded(filtros))
        },
        fetchPromocionesIfNeeded: (filtros) => {
            dispatch(fetchPromocionesIfNeeded(filtros))
        },
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductoPresentacion));

