import React from "react";
import * as EstadoPedido from "../constants/EstadoPedido"
import c from '../constants/Constants';
require('es6-promise').polyfill();
require('isomorphic-fetch');

var estadosPedido = {

    labelEstadoPedido(etiquetaEstado) {
        switch (etiquetaEstado) {
            case EstadoPedido.APROBADO:
                return <label className="label-estado success">{EstadoPedido.APROBADO}</label>;
            case EstadoPedido.STOCK_VERIFICADO_CON_DIFICULTAD:
                return <label className="label-estado danger">{EstadoPedido.STOCK_VERIFICADO_CON_DIFICULTAD}</label>;
            case EstadoPedido.EN_CURSO:
                return <label className="label-estado warning">{EstadoPedido.EN_CURSO}</label>;
            case EstadoPedido.CERRADO:
                return <label className="label-estado primary">{EstadoPedido.CERRADO}</label>;
            case EstadoPedido.CERRADO_CON_INCONVENIENTES:
                return <label className="label-estado danger">{EstadoPedido.CERRADO_CON_INCONVENIENTES}</label>;
            case EstadoPedido.CERRADO_RESUELTO:
                return <label className="label-estado default">{EstadoPedido.CERRADO_RESUELTO}</label>;
            case EstadoPedido.CANCELADO:
                return <label className="label-estado danger">{EstadoPedido.CANCELADO}</label>;
            default:
                return <label className="label-estado default">{etiquetaEstado}</label>;
        }
    },
    estiloEstadoPedido(etiquetaEstado) {
        switch (etiquetaEstado) {
            case EstadoPedido.APROBADO:
                return "success";
            case EstadoPedido.STOCK_VERIFICADO_CON_DIFICULTAD:
                return "danger";
            case EstadoPedido.EN_CURSO:
                return "warning";
            case EstadoPedido.CERRADO:
                return "primary";
            case EstadoPedido.CERRADO_CON_INCONVENIENTES:
                return "danger";
            case EstadoPedido.CERRADO_RESUELTO:
                return "default";
            case EstadoPedido.CANCELADO:
                return "danger";
            default:
                return "default";
        }
    },
    buscarUltimoEstadoPedido(listaIdEstados,estadoById){
        let ultimoEstadoPedido = null;
        let maxFecha = null;
        listaIdEstados.some(function (idEstado) {
            let estado = estadoById[idEstado];
            if (!maxFecha || estado.fechaEstado > maxFecha) {
                maxFecha = estado.fechaEstado;
                ultimoEstadoPedido = estado;
            }
        });
        return ultimoEstadoPedido;
    }
};

export default estadosPedido;