import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
//css
import '../../../assets/css/HoraEntrega.css';
//actions
import {fetchFormasEntregaIfNeeded} from "../../../actions/FormaEntregaActions";

class HoraEntrega extends Component {

    componentDidMount() {
        this.props.fetchFormasEntregaIfNeeded();
    }

    render() {
        const tienda = this.props.tiendas.update.activo;
        let texto = {__html: (tienda && tienda.horarioEntregaEmpresa ? tienda.horarioEntregaEmpresa : "")};

        return (
            <section className="horaEntrega">
                {
                    tienda && tienda.horarioEntregaEmpresa && tienda.horarioEntregaEmpresa !== "" &&
                    <React.Fragment>
                        <h2 className="titulo-seccion subtitulo-checkout">Hora de entrega</h2>
                        <div>
                            {
                                <div dangerouslySetInnerHTML={texto}/>
                            }
                        </div>
                        <br/>
                    </React.Fragment>
                }
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        pedidos: state.pedidos,
        formasEntrega: state.formasEntrega,
        tiendas: state.tiendas

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFormasEntregaIfNeeded: () => {
            dispatch(fetchFormasEntregaIfNeeded())
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HoraEntrega));
