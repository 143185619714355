import React from 'react';
import {Route, Redirect} from 'react-router-dom'
import auth from "./auth"

export const PrivateRoute = ({component, loggedIn, roles, soloYo, rol, ...rest}) => ( // eslint-disable-line
    <Route
        {...rest} render={props => {
        return (
            !auth.isLoggedIn() ?
                (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {from: props.location}, // eslint-disable-line
                        }}
                    />
                ) :
                React.createElement(component, props)
        )
    }}
    />
);
