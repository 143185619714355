import React, {Component} from 'react';

import '../../assets/css/BannerLanzamiento.css';
import recibi from '../../assets/images/lanzamiento/recibi.gif';
import mapa from '../../assets/images/lanzamiento/mapa.png';
import {ConfiguracionContext} from "../../utils/Context";


class BannerLanzamiento extends Component {
    render() {
        let Configuracion = this.context;
        let texto = {__html: Configuracion.FRASE_LANZAMIENTO};
        return (
            <section className="banner-lanzamiento">
                <img className="img-fondo" src={mapa}/>
                <div className="container-limit" style={{display: "flex"}}>
                    <div className="titulo-promo">
                        <h3 dangerouslySetInnerHTML={texto}/>
                        {/*<i style={{fontSize:"12px"}}>¡Te enviamos todo sin cargo!</i>*/}
                    </div>
                    <div className="container-camioncito">
                        <img src={recibi} alt=""/>
                    </div>
                </div>

            </section>

        );
    }
}

BannerLanzamiento.contextType = ConfiguracionContext;
export default BannerLanzamiento;
