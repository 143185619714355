import {
    INVALIDATE_AVISOS,
    ERROR_AVISOS,
    RECEIVE_AVISOS,
    REQUEST_AVISOS,
    RESET_AVISOS,
    ERROR_AVISO,
    RECEIVE_AVISO,
    REQUEST_AVISO,
    UPDATE_AVISO,
    REQUEST_UPDATE_AVISO,
    SUCCESS_UPDATE_AVISO,
    ERROR_UPDATE_AVISO,
    RESET_UPDATE_AVISO,
    REQUEST_UPDATE_AVISOS,
    SUCCESS_UPDATE_AVISOS,
    ERROR_UPDATE_AVISOS,
    RESET_UPDATE_AVISOS,
    CREATE_AVISO,
    ERROR_CREATE_AVISO,
    REQUEST_CREATE_AVISO,
    RESET_CREATE_AVISO,
    SUCCESS_CREATE_AVISO,
    DELETE_AVISO,
    REQUEST_DELETE_AVISO,
    SUCCESS_DELETE_AVISO,
    ERROR_DELETE_AVISO,
    RESET_DELETE_AVISO,

} from '../actions/AvisoActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        avisos: {},
    }
}

function avisosById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_AVISOS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_AVISOS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_AVISOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_AVISOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                avisos: {}
            });
        case RECEIVE_AVISOS:
            let dato = action.avisos.entities.avisos;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                avisos: merge({}, state.avisos, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_AVISO:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_AVISO:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_AVISO:
            let datoAviso = action.aviso.entities.avisos;
            return Object.assign({}, state, {
                avisos: merge({}, state.avisos, datoAviso),
                isFetching: false,
            });
        case CREATE_AVISO:
            let datoAvisoCreate = action.aviso;
            let idNuevoCreate = null;
            if (Object.values(datoAvisoCreate).length > 0)
                idNuevoCreate = Object.values(datoAvisoCreate)[0] && Object.values(datoAvisoCreate)[0].id ? Object.values(datoAvisoCreate)[0].id : null;
            if (idNuevoCreate)
                return Object.assign({}, state, {
                    avisos: merge({}, state.avisos, datoAvisoCreate)
                });
            else
                return state;
        case UPDATE_AVISO:
            let datoAvisoUpdate = action.aviso;
            let idNuevoUpdate = null;
            if (Object.values(datoAvisoUpdate).length > 0)
                idNuevoUpdate = Object.values(datoAvisoUpdate)[0] && Object.values(datoAvisoUpdate)[0].id ? Object.values(datoAvisoUpdate)[0].id : null;
            if (idNuevoUpdate)
                return Object.assign({}, state, {
                    avisos: merge({}, state.avisos, datoAvisoUpdate)
                });
            else
                return state;
        case DELETE_AVISO:
            let datoAvisoDelete = action.aviso;
            let idNuevoDelete = null;
            if (Object.values(datoAvisoDelete).length > 0)
                idNuevoDelete = Object.values(datoAvisoDelete)[0] && Object.values(datoAvisoDelete)[0].id ? Object.values(datoAvisoDelete)[0].id : null;
            if (idNuevoDelete)
                return Object.assign({}, state, {
                    avisos: omit(clone(state.avisos), Object.keys(datoAvisoDelete))
                });
            else
               return state;
        case SUCCESS_DELETE_AVISO:
            let datoAvisoEliminado = action.aviso.entities.avisos;
            return Object.assign({}, state, {
                avisos: mergeWith(clone(datoAvisoEliminado), state.avisos, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_AVISO:
            let datoAvisoCreado = action.aviso.entities.avisos;
            return Object.assign({}, state, {
                avisos: mergeWith(clone(datoAvisoCreado), state.avisos, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_UPDATE_AVISO:
            let datoAvisoActualizado = action.aviso.entities.avisos;
            return Object.assign({}, state, {
                avisos: mergeWith(clone(datoAvisoActualizado), state.avisos, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_AVISOS:
            let datosAvisoActualizado = action.avisos.entities.avisos;
                return Object.assign({}, state, {
                    avisos: mergeWith(clone(datosAvisoActualizado), state.avisos, (objValue, srcValue) => {return objValue;})
                });

            

            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                avisos: {}
            });
        default:
            return state
    }
}


function allAvisos(state = [], action) {
    switch (action.type) {
        case RECEIVE_AVISOS:
            return action.avisos.result && action.avisos.result.avisos ? action.avisos.result.avisos : (action.avisos.result ? action.avisos.result : state) ;

        case CREATE_AVISO:
            let datoAvisoCreate = action.aviso;
            let idNuevoCreate = null;
            if (Object.values(datoAvisoCreate).length > 0)
                idNuevoCreate = Object.values(datoAvisoCreate)[0] && Object.values(datoAvisoCreate)[0].id ? Object.values(datoAvisoCreate)[0].id : null;
            if (idNuevoCreate)
                return union(state, [idNuevoCreate]);
            else
                return state;
        case UPDATE_AVISO:
            let datoAvisoUpdate = action.aviso;
            let idNuevoUpdate = null;
            if (Object.values(datoAvisoUpdate).length > 0)
                idNuevoUpdate = Object.values(datoAvisoUpdate)[0] && Object.values(datoAvisoUpdate)[0].id ?  Object.values(datoAvisoUpdate)[0].id : null;
            if (idNuevoUpdate)
                return union(state, [idNuevoUpdate]);
            else
                return state;
        case DELETE_AVISO:
           let datoAvisoDelete = action.aviso;
           let idNuevoDelete = null;
           if (Object.values(datoAvisoDelete).length > 0)
               idNuevoDelete = Object.values(datoAvisoDelete)[0] && Object.values(datoAvisoDelete)[0].id ? Object.values(datoAvisoDelete)[0].id : null;
           if (idNuevoDelete)
               return difference(state, [idNuevoDelete]);
           else
               return state;
        case RESET_AVISOS:
            return [];

            

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalAvisos(state = null, action) {
    switch (action.type) {
        case RECEIVE_AVISOS:
            return action.avisos && action.avisos.result.total ? action.avisos.result.total : 0;
        case RESET_AVISOS:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_AVISO:
            let dato = action.aviso.entities.avisos;
            let aviso = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: aviso ? aviso : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_AVISO:
            let idNuevo = null;
            if (Object.values(action.aviso).length > 0)
                idNuevo = Object.values(action.aviso)[0] && Object.values(action.aviso)[0].id ? Object.values(action.aviso)[0].id : null;
            return merge({}, state, {
                activo: action.aviso,
                activos: idNuevo ? union(state.activos, [idNuevo]) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_AVISO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_AVISO:
            let datoAvisoActualizado = {};
            if (Object.values(action.aviso.entities.avisos).length > 0)
                datoAvisoActualizado = Object.values(action.aviso.entities.avisos)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoAvisoActualizado
            });
        case ERROR_UPDATE_AVISO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_AVISOS:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_AVISOS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_AVISOS:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_AVISO:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });

           

           

        case DELETE_AVISO:
            let datoAvisoDelete = action.aviso;
            let idNuevoDelete = null;
            if (Object.values(datoAvisoDelete).length > 0)
                idNuevoDelete = Object.values(datoAvisoDelete)[0] && Object.values(datoAvisoDelete)[0].id ? Object.values(datoAvisoDelete)[0].id : null;
            if (idNuevoDelete)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoAvisoDelete)),
                    activos: difference(clone(state.activos), [idNuevoDelete])
                });
            else
               return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_AVISO:
             let idNuevo = null;
             if (Object.values(action.aviso).length > 0)
                idNuevo = Object.values(action.aviso)[0] && Object.values(action.aviso)[0].id ? Object.values(action.aviso)[0].id : null;
            return merge({}, state, {
                isCreating: false,
                nuevo: action.aviso,
                nuevos: idNuevo ? union(state.nuevos, [idNuevo]) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_AVISO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_AVISO:
            let datoAvisoNuevo = {};
            if (Object.values(action.aviso.entities.avisos).length > 0)
                datoAvisoNuevo = Object.values(action.aviso.entities.avisos)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoAvisoNuevo,
                nuevos: []
            });
        case ERROR_CREATE_AVISO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_AVISO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });

             

             
        case DELETE_AVISO:
            let datoAvisoDelete = action.aviso;
            let idNuevoDelete = null;
            if (Object.values(datoAvisoDelete).length > 0)
                idNuevoDelete = Object.values(datoAvisoDelete)[0] && Object.values(datoAvisoDelete)[0].id ? Object.values(datoAvisoDelete)[0].id : null;
            if (idNuevoDelete)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoAvisoDelete)),
                    nuevos: difference(clone(state.nuevos), [idNuevoDelete])
                });
            else
               return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_AVISO:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.aviso,
                error: null,
            });
        case REQUEST_DELETE_AVISO:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_AVISO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_AVISO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_AVISO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

const avisos = combineReducers({
    byId: avisosById,
    allIds: allAvisos,
    update: update,
    create: create,
    totalAvisos: totalAvisos,
    delete: deleter
});

export default avisos;