import {
    INVALIDATE_LOCALIDADES,
    ERROR_LOCALIDADES,
    RECEIVE_LOCALIDADES,
    REQUEST_LOCALIDADES,
    RESET_LOCALIDADES,
    ERROR_LOCALIDAD,
    RECEIVE_LOCALIDAD,
    REQUEST_LOCALIDAD,
    UPDATE_LOCALIDAD,
    REQUEST_UPDATE_LOCALIDAD,
    SUCCESS_UPDATE_LOCALIDAD,
    ERROR_UPDATE_LOCALIDAD,
    RESET_UPDATE_LOCALIDAD,
    REQUEST_UPDATE_LOCALIDADES,
    SUCCESS_UPDATE_LOCALIDADES,
    ERROR_UPDATE_LOCALIDADES,
    RESET_UPDATE_LOCALIDADES,
    CREATE_LOCALIDAD,
    ERROR_CREATE_LOCALIDAD,
    REQUEST_CREATE_LOCALIDAD,
    RESET_CREATE_LOCALIDAD,
    SUCCESS_CREATE_LOCALIDAD,
    DELETE_LOCALIDAD,
    REQUEST_DELETE_LOCALIDAD,
    SUCCESS_DELETE_LOCALIDAD,
    ERROR_DELETE_LOCALIDAD,
    RESET_DELETE_LOCALIDAD,

} from '../actions/LocalidadActions';

import {
    RECEIVE_PROVINCIA,
    RECEIVE_PROVINCIAS,
    SUCCESS_DELETE_PROVINCIA,
    SUCCESS_CREATE_PROVINCIA,
    SUCCESS_UPDATE_PROVINCIA,
    SUCCESS_UPDATE_PROVINCIAS

} from '../actions/ProvinciaActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        localidades: {},
    }
}

function localidadesById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_LOCALIDADES:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_LOCALIDADES:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_LOCALIDADES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_LOCALIDADES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                localidades: {}
            });
        case RECEIVE_LOCALIDADES:
            let dato = action.localidades.entities.localidades;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                localidades: merge({}, state.localidades, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_LOCALIDAD:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_LOCALIDAD:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_LOCALIDAD:
            let datoLocalidad = action.localidad.entities.localidades;
            return Object.assign({}, state, {
                localidades: merge({}, state.localidades, datoLocalidad),
                isFetching: false,
            });
        case CREATE_LOCALIDAD:
            let datoLocalidadCreate = action.localidad;
            let idNuevoCreate = null;
            if (Object.values(datoLocalidadCreate).length > 0)
                idNuevoCreate = Object.values(datoLocalidadCreate)[0] && Object.values(datoLocalidadCreate)[0].id ? Object.values(datoLocalidadCreate)[0].id : null;
            if (idNuevoCreate)
                return Object.assign({}, state, {
                    localidades: merge({}, state.localidades, datoLocalidadCreate)
                });
            else
                return state;
        case UPDATE_LOCALIDAD:
            let datoLocalidadUpdate = action.localidad;
            let idNuevoUpdate = null;
            if (Object.values(datoLocalidadUpdate).length > 0)
                idNuevoUpdate = Object.values(datoLocalidadUpdate)[0] && Object.values(datoLocalidadUpdate)[0].id ? Object.values(datoLocalidadUpdate)[0].id : null;
            if (idNuevoUpdate)
                return Object.assign({}, state, {
                    localidades: merge({}, state.localidades, datoLocalidadUpdate)
                });
            else
                return state;
        case DELETE_LOCALIDAD:
            let datoLocalidadDelete = action.localidad;
            let idNuevoDelete = null;
            if (Object.values(datoLocalidadDelete).length > 0)
                idNuevoDelete = Object.values(datoLocalidadDelete)[0] && Object.values(datoLocalidadDelete)[0].id ? Object.values(datoLocalidadDelete)[0].id : null;
            if (idNuevoDelete)
                return Object.assign({}, state, {
                    localidades: omit(clone(state.localidades), Object.keys(datoLocalidadDelete))
                });
            else
               return state;
        case SUCCESS_DELETE_LOCALIDAD:
            let datoLocalidadEliminado = action.localidad.entities.localidades;
            return Object.assign({}, state, {
                localidades: mergeWith(clone(datoLocalidadEliminado), state.localidades, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_LOCALIDAD:
            let datoLocalidadCreado = action.localidad.entities.localidades;
            return Object.assign({}, state, {
                localidades: mergeWith(clone(datoLocalidadCreado), state.localidades, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_UPDATE_LOCALIDAD:
            let datoLocalidadActualizado = action.localidad.entities.localidades;
            return Object.assign({}, state, {
                localidades: mergeWith(clone(datoLocalidadActualizado), state.localidades, (objValue, srcValue) => {return objValue;})
            });
         case SUCCESS_UPDATE_LOCALIDADES:
            let datosLocalidadActualizado = action.localidades.entities.localidades;
                return Object.assign({}, state, {
                    localidades: mergeWith(clone(datosLocalidadActualizado), state.localidades, (objValue, srcValue) => {return objValue;})
                });

            //PROVINCIA
case RECEIVE_PROVINCIA:
    let provincia = action.provincia.entities.localidades ? action.provincia.entities.localidades : {};
    return Object.assign({}, state, {
        localidades: merge({}, state.localidades, provincia),
    });
case RECEIVE_PROVINCIAS:
    let provincias = action.provincias.entities.localidades;
    return Object.assign({}, state, {
        localidades: merge({}, state.localidades, provincias),
    });
case SUCCESS_DELETE_PROVINCIA:
    let datoprovinciaEliminado = action.provincia.entities.localidades ? action.provincia.entities.localidades : {};
    return Object.assign({}, state, {
        localidades: merge({}, datoprovinciaEliminado, state.localidades)
    });
case SUCCESS_CREATE_PROVINCIA:
    let datoprovinciaCreado = action.provincia.entities.localidades ? action.provincia.entities.localidades : {};
    return Object.assign({}, state, {
        localidades: merge({}, datoprovinciaCreado, state.localidades)
    });
case SUCCESS_UPDATE_PROVINCIA:
    let datoprovinciaActualizado = action.provincia.entities.localidades ? action.provincia.entities.localidades : {};
    return Object.assign({}, state, {
        localidades: merge({}, datoprovinciaActualizado, state.localidades)
    });
 case SUCCESS_UPDATE_PROVINCIAS:
    let datosprovinciaActualizado = action.provincias.entities.localidades ? action.provincias.entities.localidades : {} ;
        return Object.assign({}, state, {
            localidades: mergeWith(clone(datosprovinciaActualizado), state.localidades, (objValue, srcValue) => {return objValue;})
        });


            

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                localidades: {}
            });
        default:
            return state
    }
}


function allLocalidades(state = [], action) {
    switch (action.type) {
        case RECEIVE_LOCALIDADES:
            return action.localidades.result && action.localidades.result.localidades ? action.localidades.result.localidades : (action.localidades.result ? action.localidades.result : state) ;

        case CREATE_LOCALIDAD:
            let datoLocalidadCreate = action.localidad;
            let idNuevoCreate = null;
            if (Object.values(datoLocalidadCreate).length > 0)
                idNuevoCreate = Object.values(datoLocalidadCreate)[0] && Object.values(datoLocalidadCreate)[0].id ? Object.values(datoLocalidadCreate)[0].id : null;
            if (idNuevoCreate)
                return union(state, [idNuevoCreate]);
            else
                return state;
        case UPDATE_LOCALIDAD:
            let datoLocalidadUpdate = action.localidad;
            let idNuevoUpdate = null;
            if (Object.values(datoLocalidadUpdate).length > 0)
                idNuevoUpdate = Object.values(datoLocalidadUpdate)[0] && Object.values(datoLocalidadUpdate)[0].id ?  Object.values(datoLocalidadUpdate)[0].id : null;
            if (idNuevoUpdate)
                return union(state, [idNuevoUpdate]);
            else
                return state;
        case DELETE_LOCALIDAD:
           let datoLocalidadDelete = action.localidad;
           let idNuevoDelete = null;
           if (Object.values(datoLocalidadDelete).length > 0)
               idNuevoDelete = Object.values(datoLocalidadDelete)[0] && Object.values(datoLocalidadDelete)[0].id ? Object.values(datoLocalidadDelete)[0].id : null;
           if (idNuevoDelete)
               return difference(state, [idNuevoDelete]);
           else
               return state;
        case SUCCESS_CREATE_LOCALIDAD:
                   let datoLocalidadSCreate = action.localidad.entities.localidades;
                   let idNuevoSCreate = null;
                   if (Object.values(datoLocalidadSCreate).length > 0)
                       idNuevoSCreate = Object.values(datoLocalidadSCreate)[0] && Object.values(datoLocalidadSCreate)[0].id ? Object.values(datoLocalidadSCreate)[0].id : null;
                   if (idNuevoSCreate)
                       return union(state, [idNuevoSCreate]);
                   else
                       return state;
        case RESET_LOCALIDADES:
            return [];

            case RECEIVE_PROVINCIA:
    let provincia = action.provincia.entities.localidades;
    return provincia ?
        union(state, Object.values(provincia).map((provincia) => {
            return provincia.id
        })) : state;
case RECEIVE_PROVINCIAS:
    let provincias = action.provincias.entities.localidades;
    return provincias ?
        union(state, Object.values(provincias).map((provincias) => {
            return provincias.id
        })) : state;

case SUCCESS_DELETE_PROVINCIA:
    let provinciaDelete = action.provincia.entities.localidades;
        return provinciaDelete ?
            Object.values(provinciaDelete).map((provincia) => {
                return provincia.id
            }) : state;
case SUCCESS_CREATE_PROVINCIA:
   let provinciaCreate = action.provincia.entities.localidades;
       return provinciaCreate ?
          Object.values(provinciaCreate).map((provincia) => {
               return provincia.id
           }) : state;
case SUCCESS_UPDATE_PROVINCIA:
    let provinciaUpdate = action.provincia.entities.localidades;
        return provinciaUpdate ?
            Object.values(provinciaUpdate).map((provincia) => {
                return provincia.id
            }) : state;
 case SUCCESS_UPDATE_PROVINCIAS:
     let provinciasUpdate = action.provincias.entities.localidades;
        return provinciasUpdate ?
            union(state, Object.values(provinciasUpdate).map((provincias) => {
                return provincias.id
            })) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalLocalidades(state = null, action) {
    switch (action.type) {
        case RECEIVE_LOCALIDADES:
            return action.localidades && action.localidades.result.total ? action.localidades.result.total : 0;
        case RESET_LOCALIDADES:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_LOCALIDAD:
            let dato = action.localidad.entities.localidades;
            let localidad = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: localidad ? localidad : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_LOCALIDAD:
            let idNuevo = null;
            if (Object.values(action.localidad).length > 0)
                idNuevo = Object.values(action.localidad)[0] && Object.values(action.localidad)[0].id ? Object.values(action.localidad)[0].id : null;
            return merge({}, state, {
                activo: action.localidad,
                activos: idNuevo ? union(state.activos, [idNuevo]) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_LOCALIDAD:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_LOCALIDAD:
            let datoLocalidadActualizado = {};
            if (Object.values(action.localidad.entities.localidades).length > 0)
                datoLocalidadActualizado = Object.values(action.localidad.entities.localidades)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoLocalidadActualizado
            });
        case ERROR_UPDATE_LOCALIDAD:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
            case REQUEST_UPDATE_LOCALIDADES:
                return Object.assign({}, state, {
                    isUpdating: true,
                    error: null
                });
            case SUCCESS_UPDATE_LOCALIDADES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    lastUpdated: action.receivedAt,
                    error: null,
                    activo: {},
                    activos: []
                });
            case ERROR_UPDATE_LOCALIDADES:
                return Object.assign({}, state, {
                    isUpdating: false,
                    error: action.error
                });
        case RESET_UPDATE_LOCALIDAD:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });

           

           //PROVINCIA
 case SUCCESS_CREATE_PROVINCIA:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_UPDATE_PROVINCIA:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_DELETE_PROVINCIA:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });
 case SUCCESS_UPDATE_PROVINCIAS:
        return Object.assign({}, state, {
            activo: {},
            activos: []
        });

        case DELETE_LOCALIDAD:
            let datoLocalidadDelete = action.localidad;
            let idNuevoDelete = null;
            if (Object.values(datoLocalidadDelete).length > 0)
                idNuevoDelete = Object.values(datoLocalidadDelete)[0] && Object.values(datoLocalidadDelete)[0].id ? Object.values(datoLocalidadDelete)[0].id : null;
            if (idNuevoDelete)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoLocalidadDelete)),
                    activos: difference(clone(state.activos), [idNuevoDelete])
                });
            else
               return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_LOCALIDAD:
             let idNuevo = null;
             if (Object.values(action.localidad).length > 0)
                idNuevo = Object.values(action.localidad)[0] && Object.values(action.localidad)[0].id ? Object.values(action.localidad)[0].id : null;
            return merge({}, state, {
                isCreating: false,
                nuevo: action.localidad,
                nuevos: idNuevo ? union(state.nuevos, [idNuevo]) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_LOCALIDAD:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_LOCALIDAD:
            let datoLocalidadNuevo = {};
            if (Object.values(action.localidad.entities.localidades).length > 0)
                datoLocalidadNuevo = Object.values(action.localidad.entities.localidades)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoLocalidadNuevo,
                nuevos: []
            });
        case ERROR_CREATE_LOCALIDAD:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_LOCALIDAD:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });

             

             //PROVINCIA
 case SUCCESS_CREATE_PROVINCIA:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PROVINCIA:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_DELETE_PROVINCIA:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });
 case SUCCESS_UPDATE_PROVINCIAS:
        return Object.assign({}, state, {
            nuevo: {},
            nuevos: []
        });

        case DELETE_LOCALIDAD:
            let datoLocalidadDelete = action.localidad;
            let idNuevoDelete = null;
            if (Object.values(datoLocalidadDelete).length > 0)
                idNuevoDelete = Object.values(datoLocalidadDelete)[0] && Object.values(datoLocalidadDelete)[0].id ? Object.values(datoLocalidadDelete)[0].id : null;
            if (idNuevoDelete)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoLocalidadDelete)),
                    nuevos: difference(clone(state.nuevos), [idNuevoDelete])
                });
            else
               return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_LOCALIDAD:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.localidad,
                error: null,
            });
        case REQUEST_DELETE_LOCALIDAD:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_LOCALIDAD:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_LOCALIDAD:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_LOCALIDAD:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
             //PROVINCIA
 case SUCCESS_CREATE_PROVINCIA:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PROVINCIA:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_DELETE_PROVINCIA:
        return Object.assign({}, state, {
            eliminado: {},
        });
 case SUCCESS_UPDATE_PROVINCIAS:
        return Object.assign({}, state, {
            eliminado: {},
        });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

const localidades = combineReducers({
    byId: localidadesById,
    allIds: allLocalidades,
    update: update,
    create: create,
    totalLocalidades: totalLocalidades,
    delete: deleter
});

export default localidades;