//api
import localidadesApi from "../api/localidadesApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeLocalidades";

//LOCALIDADES
export const REQUEST_LOCALIDADES = 'REQUEST_LOCALIDADES';
export const RECEIVE_LOCALIDADES = 'RECEIVE_LOCALIDADES';
export const INVALIDATE_LOCALIDADES = 'INVALIDATE_LOCALIDADES';
export const ERROR_LOCALIDADES = "ERROR_LOCALIDADES";
export const RESET_LOCALIDADES = "RESET_LOCALIDADES";


export function invalidateLocalidades() {
    return {
        type: INVALIDATE_LOCALIDADES
    }
}

function requestLocalidades() {
    return {
        type: REQUEST_LOCALIDADES,
    }
}

function receiveLocalidades(json) {
    return {
        type: RECEIVE_LOCALIDADES,
    localidades: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorLocalidades(error) {
    return {
        type: ERROR_LOCALIDADES,
        error: error,
    }
}

export function resetLocalidades() {
    return {
        type: RESET_LOCALIDADES
    }
}

export function fetchLocalidades(filtros) {
    return dispatch => {
        dispatch(requestLocalidades());
        return localidadesApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveLocalidades(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorLocalidades(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLocalidades(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchLocalidades(state) {
    const localidades = state.localidades.byId;
    if (!localidades) {
        return true
    } else if (localidades.isFetching) {
        return false
    } else {
        return localidades.didInvalidate;
    }
}

export function fetchLocalidadesIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchLocalidades(getState())) {
            return dispatch(fetchLocalidades(filtros))
        }
    }
}


//MODEL
export const REQUEST_LOCALIDAD = 'REQUEST_LOCALIDAD';
export const RECEIVE_LOCALIDAD = 'RECEIVE_LOCALIDAD';
export const INVALIDATE_LOCALIDAD = 'INVALIDATE_LOCALIDAD';
export const ERROR_LOCALIDAD = "ERROR_LOCALIDAD";
export const RESET_LOCALIDAD = "RESET_LOCALIDAD";


export function invalidateLocalidad() {
    return {
        type: INVALIDATE_LOCALIDAD
    }
}

function requestLocalidad() {
    return {
        type: REQUEST_LOCALIDAD,
    }
}

function receiveLocalidad(json) {
    return {
        type: RECEIVE_LOCALIDAD,
    localidad: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorLocalidad(error) {
    return {
        type: ERROR_LOCALIDAD,
        error: error,
    }
}

export function fetchLocalidad(idLocalidad) {
    return dispatch => {
        dispatch(requestLocalidad());
        return localidadesApi.getOne(idLocalidad)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveLocalidad(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorLocalidad(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorLocalidad(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_LOCALIDAD = 'UPDATE_LOCALIDAD';
export const REQUEST_UPDATE_LOCALIDAD = "REQUEST_UPDATE_LOCALIDAD";
export const SUCCESS_UPDATE_LOCALIDAD = "SUCCESS_UPDATE_LOCALIDAD";
export const ERROR_UPDATE_LOCALIDAD = "ERROR_UPDATE_LOCALIDAD";
export const RESET_UPDATE_LOCALIDAD = "RESET_UPDATE_LOCALIDAD";

function requestUpdateLocalidad() {
    return {
        type: REQUEST_UPDATE_LOCALIDAD,
    }
}

function receiveUpdateLocalidad(localidad) {
    return {
        type: SUCCESS_UPDATE_LOCALIDAD,
        receivedAt: Date.now(),
        localidad: normalizeDato(localidad)
    }
}

function errorUpdateLocalidad(error) {
    return {
        type: ERROR_UPDATE_LOCALIDAD,
        error: error,
    }
}

export function resetUpdateLocalidad() {
    return {
        type: RESET_UPDATE_LOCALIDAD,
    }
}

export function updateLocalidad(localidad) {
    return {
        type: UPDATE_LOCALIDAD,
        localidad
    }
}

export function saveUpdateLocalidad() {
    return (dispatch, getState) => {
        dispatch(requestUpdateLocalidad());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let localidad = denormalizeDato(getState().localidades.update.activo, store);

        return localidadesApi.saveUpdate(localidad)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateLocalidad(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateLocalidad(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorUpdateLocalidad(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorUpdateLocalidad(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorUpdateLocalidad(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}

//UPDATE LOCALIDADES
export const REQUEST_UPDATE_LOCALIDADES = "REQUEST_UPDATE_LOCALIDADES";
export const SUCCESS_UPDATE_LOCALIDADES = "SUCCESS_UPDATE_LOCALIDADES";
export const ERROR_UPDATE_LOCALIDADES = "ERROR_UPDATE_LOCALIDADES";
export const RESET_UPDATE_LOCALIDADES = "RESET_UPDATE_LOCALIDADES";

function requestUpdateLocalidades() {
    return {
        type: REQUEST_UPDATE_LOCALIDADES,
}
}

function receiveUpdateLocalidades(localidades) {
    return {
        type: SUCCESS_UPDATE_LOCALIDADES,
    receivedAt: Date.now(),
        localidades: normalizeDatos(localidades)
}
}

function errorUpdateLocalidades(error) {
    return {
        type: ERROR_UPDATE_LOCALIDADES,
    error: error,
}
}

export function resetUpdateLocalidades() {
    return {
        type: RESET_UPDATE_LOCALIDADES,
}
}

export function saveUpdateLocalidades() {
    return (dispatch, getState) => {
        dispatch(requestUpdateLocalidades());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let localidades = getState().localidades.update.activos.map((idLocalidad) => {
            return denormalizeDato(getState().localidades.byId.localidades[idLocalidad], store);
        });

        return localidadesApi.saveUpdateLocalidades(localidades)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateLocalidades(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateLocalidades(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorUpdateLocalidades(JSON.parse(error.responseJSON.message)));
                    else
                        try {
                            error.json().then((error) => {
                                dispatch(errorUpdateLocalidades(JSON.parse(error.message)));
                            });
                        } catch {
                            dispatch(errorUpdateLocalidades(errorMessages.GENERAL_ERROR));
                        }


                        return;
                }
            });
    }
}


//ALTA LOCALIDAD
export const CREATE_LOCALIDAD = 'CREATE_LOCALIDAD';
export const REQUEST_CREATE_LOCALIDAD = "REQUEST_CREATE_LOCALIDAD";
export const SUCCESS_CREATE_LOCALIDAD = "SUCCESS_CREATE_LOCALIDAD";
export const ERROR_CREATE_LOCALIDAD = "ERROR_CREATE_LOCALIDAD";
export const RESET_CREATE_LOCALIDAD = "RESET_CREATE_LOCALIDAD";


//ALTA LOCALIDAD
function requestCreateLocalidad() {
    return {
        type: REQUEST_CREATE_LOCALIDAD,
    }
}

function receiveCreateLocalidad(localidad) {
    return {
        type: SUCCESS_CREATE_LOCALIDAD,
        receivedAt: Date.now(),
        localidad: normalizeDato(localidad)
    }
}

export function errorCreateLocalidad(error) {
    return {
        type: ERROR_CREATE_LOCALIDAD,
        error: error,
    }
}

export function resetCreateLocalidad() {
    return {
        type: RESET_CREATE_LOCALIDAD
    }
}

export function createLocalidad(localidad) {
    return {
        type: CREATE_LOCALIDAD,
        localidad
    }
}

export function saveCreateLocalidad() {
    return (dispatch, getState) => {
        dispatch(requestCreateLocalidad());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let localidad = denormalizeDato(getState().localidades.create.nuevo, store);

        return localidadesApi.saveCreate(localidad)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateLocalidad(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateLocalidad(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorCreateLocalidad(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorCreateLocalidad(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorCreateLocalidad(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}

//DELETE LOCALIDAD
export const DELETE_LOCALIDAD = 'DELETE_LOCALIDAD';
export const REQUEST_DELETE_LOCALIDAD = "REQUEST_DELETE_LOCALIDAD";
export const SUCCESS_DELETE_LOCALIDAD = "SUCCESS_DELETE_LOCALIDAD";
export const ERROR_DELETE_LOCALIDAD = "ERROR_DELETE_LOCALIDAD";
export const RESET_DELETE_LOCALIDAD = "RESET_DELETE_LOCALIDAD";

function requestDeleteLocalidad() {
    return {
        type: REQUEST_DELETE_LOCALIDAD,
    }
}

function receiveDeleteLocalidad(localidad) {
    return {
        type: SUCCESS_DELETE_LOCALIDAD,
        receivedAt: Date.now(),
        localidad: normalizeDato(localidad)
    }
}

function errorDeleteLocalidad(error) {
    return {
        type: ERROR_DELETE_LOCALIDAD,
        error: error,
    }
}

export function resetDeleteLocalidad(error) {
    return {
        type: RESET_DELETE_LOCALIDAD,
        error: error,
    }
}

export function deleteLocalidad(localidad) {
    return {
        type: DELETE_LOCALIDAD,
        localidad
    }
}

export function saveDeleteLocalidad(localidad) {
    return dispatch => {
        dispatch(requestDeleteLocalidad());
        return localidadesApi.saveDelete(localidad)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteLocalidad());
                            dispatch(receiveDeleteLocalidad(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteLocalidad(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorDeleteLocalidad(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorDeleteLocalidad(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorDeleteLocalidad(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}