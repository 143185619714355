import {RECEIVE_PEDIDOS, RESET_PEDIDOS} from '../actions/PedidoActions';
import {
    ERROR_CREATE_ORDENPEDIDOENVIO,
    ERROR_PRODUCTONROSERIE,
    ERROR_ULTIMO_REMITO,
    ERROR_UPDATE_REMITO,
    INVALIDATE_ULTIMO_REMITO,
    RECEIVE_PRODUCTONROSERIE,
    RECEIVE_ULTIMO_REMITO,
    REQUEST_CREATE_ORDENPEDIDOENVIO,
    REQUEST_PRODUCTONROSERIE,
    REQUEST_ULTIMO_REMITO,
    REQUEST_UPDATE_REMITO,
    RESET_PRODUCTONROSERIE,
    SUCCESS_CREATE_ORDENPEDIDOENVIO,
    SUCCESS_UPDATE_REMITO,
    UPDATE_REMITO
} from '../actions/RemitoActions';
import {RESET_DETALLESREMITIDOS} from "../actions/DetalleRemitidoActions";
import {combineReducers} from 'redux';
import merge from "lodash/merge";
import union from "lodash/union";

function remitosById(state = {
    isFetching: false,
    didInvalidate: true,
    remitos: {},
    ultimoRemito: {},
    busquedaRemitos: [],
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                remitos: merge({}, state.remitos, action.pedidos.entities.remitos),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                remitos: {},
                error: null
            });
        case UPDATE_REMITO:
            var remito = {};
            remito[action.idRemito] = action.remito;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                remitos: merge({}, state.remitos, remito),
            });
        case INVALIDATE_ULTIMO_REMITO:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ULTIMO_REMITO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_ULTIMO_REMITO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                ultimoRemito: action.ultimoRemito,
                lastUpdated: action.receivedAt
            });
        case ERROR_ULTIMO_REMITO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                ultimoRemito: [],
            });
        case REQUEST_PRODUCTONROSERIE:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_PRODUCTONROSERIE:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                busquedaRemitos: action.busquedaRemitos,
                lastUpdated: action.receivedAt
            });
        case ERROR_PRODUCTONROSERIE:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_PRODUCTONROSERIE:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                busquedaRemitos: [],
            });
        default:
            return state
    }
}


function allRemitos(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    remitos: [],
    error: null
}, action) {
    switch (action.type) {
        case UPDATE_REMITO:
            return Object.assign({}, state, {
                isUpdating: false,
                remitos: union({}, state.remitos, [action.idRemito]),
                error: null
            });
        case REQUEST_UPDATE_REMITO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_REMITO:
            return Object.assign({}, state, {
                isUpdating: false,
                remitos: [],
                error: null
            });
        case ERROR_UPDATE_REMITO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isUpdating: false,
                remitos: [],
                error: null
            });
        default:
            return state
    }

}

function create(state = {
    isCreating: false,
    error: null
}, action) {
    switch (action.type) {
        case REQUEST_CREATE_ORDENPEDIDOENVIO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null
            });
        case SUCCESS_CREATE_ORDENPEDIDOENVIO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null
            });
        case ERROR_CREATE_ORDENPEDIDOENVIO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        default:
            return state
    }

}

const remitos = combineReducers({
    byId: remitosById,
    allIds: allRemitos,
    update: update,
    create: create
});

export default remitos;