import React, {Component} from 'react';
import {ConfiguracionContext} from "../../utils/Context"

import '../../assets/css/QuienesSomos.css';
import {saveCreateAnalitica} from "../../actions/AnaliticaActions";
import {connect} from "react-redux";


class QuienesSomos extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const tienda = this.props.tiendas.update.activo;
        let texto = {__html: (tienda ? tienda.historiaEmpresa : "")};
        return (
            <section className="quienes-somos">
                <div dangerouslySetInnerHTML={texto}/>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuienesSomos);
