import React, {Component} from 'react';
import "../../assets/css/Productos.css";
import ComoComprar from "./ComoComprar"
import ZonaEntregas from "./ZonaEntregas";
import BannerLanzamiento from "./BannerLanzamiento";
import Faqs from "./Faqs";
import Contacto from "./Contacto";
import {Route, Switch} from "react-router-dom";
import SignUp from "./pages/Registro/SignUp";
import SuperMercado from "./SuperMercado";
import OlvidePassword from "./OlvidePassword";
import CambiarPassword from "./CambiarPassword";
import ActivarCuenta from "./ActivarCuenta";
import SliderPrincipal from "./SliderPrincipal";
import LogIn from "./LogIn";
import Promociones from "./secciones/PromocionesConOrden";
import BarraCategorias from "./BarraCategorias";
import SignUpDistribuidor from "./pages/Registro/SignUpDistribuidor";
import MarcasDestacadas from "./MarcasDestacadas";
import AvisoPrecios from "./layout/AvisoPrecios";
import BarraInformacion from "./BarraInformacion";
import * as TipoAnalitica from "../../constants/TipoAnalitica";
import AvisoCerrado from "./layout/AvisoCerrado";
import {connect} from "react-redux";


class Home extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        let token = this.props.match.params.token;
        if (token)
            this.props.saveCreateAnalitica(token, TipoAnalitica.CLICK_BOTON_MAIL_ID);

    }

    render() {
        const tienda = this.props.tiendas.update.activo;

        return (
            <div>
                <Switch>
                    <Route path="/login" render={props => <LogIn {...props} />}/>
                    <Route path="/activarCuenta/:token" render={props => <ActivarCuenta {...props} />}/>
                    {
                        tienda && tienda.tipoRegistro && tienda.tipoRegistro === "distribuidor" &&
                        <Route path="/signup" render={props => <SignUpDistribuidor {...props} />}/>
                    }
                    {
                        tienda && tienda.tipoRegistro && tienda.tipoRegistro === "minorista" &&
                        <Route path="/signup" render={props => <SignUp {...props} />}/>
                    }
                    <Route path="/olvidepassword" render={props => <OlvidePassword {...props} />}/>/>
                    <Route path="/resetPassword/:token" exact component={CambiarPassword}/>
                    <Route path="/contacto" exact component={Contacto}/>
                </Switch>
                {
                    tienda && tienda.tipoRegistro && tienda.tipoRegistro === "distribuidor" &&
                    <AvisoPrecios/>
                }
                <AvisoCerrado/>
                <SliderPrincipal/>
                {/*<Productos toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}/>*/}
                <BarraCategorias/>
                <BarraInformacion/>
                <Promociones toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}/>
                <MarcasDestacadas toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}/>
                <SuperMercado toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}/>
                <ComoComprar/>
                <ZonaEntregas saveCreateAnalitica={this.props.saveCreateAnalitica.bind(this)}
                              zonasEntrega={this.props.zonasEntrega}/>
                <BannerLanzamiento/>
                <Faqs/>
                <Contacto/>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {}
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
