import React, {useState} from 'react';
import {Link, Switch} from 'react-router-dom';
import {useSelector} from "react-redux";
import {PrivateRoute} from "../../utils/PrivateRoute";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faList, faUserCog} from '@fortawesome/free-solid-svg-icons';
//css
import '../../assets/css/MiCuenta.css';
//Actions
//Components
import MisPedidos from "./MisPedidos";
import MisDatos from "./MisDatos";
//img

export default function MiCuenta() {
    const [showMenu, setShowMenu] = useState(true);
    const auth = useSelector(state => state.auth);

    return (
        <section className={"micuenta"}>
            <aside className={`barra-micuenta ${showMenu && "open"}`}>
                <FontAwesomeIcon className={`btn-opciones ${showMenu && "rotado"}`} icon={faChevronCircleDown}
                                 onClick={() => {
                                     setShowMenu(prev => !prev);
                                 }}
                />
                <div>
                    <p className="titulo-micuenta">
                        Mi cuenta
                    </p>
                    <span>¡Hola {auth.usuarioLogueado.nombre}!</span>
                </div>
                <ul className={"opciones-micuenta"}>
                    <li>
                        <FontAwesomeIcon icon={faList} style={{width: "23px", height: "23px", color: "#666"}}/>
                        <Link to={"/miCuenta/pedidos"}>Mis pedidos</Link>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faUserCog} style={{width: "23px", height: "23px", color: "#666"}}/>
                        <Link to={"/miCuenta/datosPersonales"}>Datos personales</Link>
                    </li>
                </ul>
            </aside>
            <div className="container-micuenta">
                <Switch>
                    <PrivateRoute path="/miCuenta/pedidos" component={MisPedidos}/>
                    <PrivateRoute path="/miCuenta/datosPersonales" component={MisDatos}/>
                </Switch>
            </div>
        </section>
    )
}

