import c from '../constants/Constants';
import $ from "jquery";

require('es6-promise').polyfill();
require('isomorphic-fetch');


var banners = {

    getAll(filtros) {
        var esc = encodeURIComponent;
        var query = "";
        if (filtros)
            query = Object.keys(filtros)
                .map(k => esc(k) + '=' + esc(filtros[k]))
                .join('&');

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8"
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/banners/?' + query, defaultOptions);
    },

    getOne(idBanner) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8"
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL + '/banners/' + idBanner, defaultOptions);
    },
    saveUpdate(activo, mobile,desk) {
        var formData = new FormData();
        if (mobile) {
                formData.append("mobile", mobile);
        }
        if (desk) {
                formData.append("desk", desk);
        }

        formData.append("banner", JSON.stringify(activo));
        return $.ajax({
            url: c.BASE_URL + '/banners/' + activo.id,
            //dataType: 'json',
            data: formData,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('authorization', localStorage.token);
            },
            contentType: false,
            type: 'POST',
            processData: false,
            enctype: 'multipart/form-data',
        });
    },
    saveCreate(banner, mobile,desk) {

        var formData = new FormData();
        if (mobile) {
            formData.append("mobile", mobile);
        }
        if (desk) {
            formData.append("desk", desk);
        }

        formData.append("banner", JSON.stringify(banner));
        return $.ajax({
            url: c.BASE_URL + '/banners/',
            //dataType: 'json',
            data: formData,
            beforeSend: function (xhr) {
                xhr.setRequestHeader('authorization', localStorage.token);
            },
            contentType: false,
            type: 'POST',
            processData: false,
            enctype: 'multipart/form-data',
        });
    },
    saveDelete(idBanner) {

        let defaultOptions = {
            url: '',
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };

        return fetch(c.BASE_URL + '/banners/' + idBanner, defaultOptions);
    }
};

export default banners;