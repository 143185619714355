import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom"

import '../../assets/css/LoginSignUp.css';
//Actions
import {login, olvideMiPassword, resetLogin} from '../../actions/AuthActions';
//components
import Loader from "./layout/Loader"


class LogIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
        };
        this.handleChange = this.handleChange.bind(this);
        //this.restablecerContraseña = this.restablecerContraseña.bind(this);
    }

    componentDidMount() {
        // window.addEventListener('click', (e) => this.cierraModal(e));
        document.body.classList.add("no-scroll");
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        // window.removeEventListener('click', (e) => this.cierraModal(e));
        document.body.classList.remove("no-scroll");
        document.body.style.overflowY = "scroll";
        this.props.resetLogin();
    }

    handleChange(e) {
        let cambio = {};
        cambio[e.target.id] = e.target.value;
        this.setState(cambio);
    }

    submitLogin(e) {
        e.preventDefault();
        let from = null;
        if (this.props.location.state)
            from = this.props.location.state.from;
        this.props.login(this.state.email, this.state.password, from);
    }

    olvideMiPasswordInicio(e) {
        this.props.history.push("olvidepassword")
    }

    cierraModal(e) {
        let modal = document.getElementById('modal-login') ? document.getElementById('modal-login').contains(e.target) : null
        if (!modal) {
            this.props.history.push("/")
        }
    }

    render() {
        const tienda = this.props.tiendas.update.activo;
        return (
            <section className="login-sign-up login">
                <div className="container-modal" id={"modal-login"}>
                    <div className="container-doble">
                        <div className="col-3 container-marca">
                            {
                                window.innerWidth < 714 &&
                                <div className="cruz-cerrar" onClick={() => {
                                    this.props.history.push("/")
                                }}>&times;</div>
                            }
                            {
                                tienda && tienda.logoBlanco &&
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/logos/${tienda && tienda.logoBlanco ? tienda.logoBlanco : ""}`}
                                    className="logo"/>
                            }
                            <div className="container-yaregistrado">
                                <p>
                                    Si aun no estas registrado
                                </p>
                                <Link to="/signup">
                                    <button>
                                        REGISTRATE
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="container-datos">
                                {
                                    window.innerWidth > 714 &&
                                    <div className="cruz-cerrar" onClick={() => {
                                        this.props.history.push("/")
                                    }}>&times;</div>
                                }
                                <h2>INICIAR <i>SESION</i></h2>
                                <div className="errores"><p>{this.props.auth.mensaje}</p></div>
                                <form onSubmit={(e) => this.submitLogin(e)}>
                                    <input placeholder="E-mail" type="email" id="email"
                                           value={this.state.email} onChange={this.handleChange} required/>
                                    <input placeholder="Contraseña" type="password" id="password"
                                           value={this.state.password} onChange={this.handleChange} required/>
                                    <p style={{
                                        padding: "2px 15px",
                                        margin: "0",
                                        color: "#ea4e69",
                                        fontWeight: "300"
                                    }}>{this.props.auth.errorMessage}</p>
                                    <a style={{display: this.props.auth.isFetching ? "none" : "block"}}
                                       onClick={() => this.olvideMiPasswordInicio()}>Olvidé mi contraseña</a>
                                    {
                                        !this.props.auth.isFetching &&
                                        <button type="submit" disabled={this.props.auth.isFetching}>ENTRAR</button>
                                    }
                                    {
                                        this.props.auth.isFetching &&
                                        <Loader/>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        usuarios: state.usuarios,
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password, to) => {
            dispatch(login(username, password, to))
        },
        olvideMiPassword: (email) => {
            dispatch(olvideMiPassword(email))
        },
        resetLogin: () => {
            dispatch(resetLogin())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);

