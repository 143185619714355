import {combineReducers} from 'redux'

import pedidos from './pedidos';
import formasEntrega from './formaEntrega';
import formasPago from './formaPago';
import formasPagoListasPrecio from './formaPagoListaPrecio';
import usuarios from "./usuarios";
import estadosPedido from "./estadosPedido";
import estadosEntrega from "./estadosEntrega";
import direccionesEntrega from "./direccionesEntrega";
import estadosDetallePedido from "./estadosDetallePedido";
import detallesPedido from "./detallesPedido";
import productos from "./producto";
import remitos from "./remitos";
import estadosRemito from "./estadosRemito";
import detallesRemitidos from "./detallesRemitidos";
import bultos from "./bultos";
import packingList from "./packingList";
import comprasComunitarias from "./comprasComunitarias";
import categorias from "./categorias";
import marcas from "./marcas";
import promociones from "./promocion";
import productosDestinoPromocion from "./productosDestinoPromocion";
import productosOrigenPromocion from "./productosOrigenPromocion";
import zonasEntrega from "./zonasEntrega";
import caracteristicasProducto from "./caracteristicaProducto";
import variedadesCaracteristica from "./variedadCaracteristica";
import stocksVariedad from "./stockVariedad";
import stocks from "./stock";
import localidades from "./localidades";
import provincias from "./provincias";
import auth from "./authReducer";
import serviceWorker from "./serviceWorkerReducer";
import banners from "./banners";
import avisos from "./avisos";
import monedas from "./monedas";
import tiendas from "./tiendas";


const appReducers = combineReducers({
    auth,
    avisos,
    banners,
    bultos,
    comprasComunitarias,
    categorias,
    caracteristicasProducto,
    direccionesEntrega,
    detallesPedido,
    detallesRemitidos,
    formasEntrega,
    formasPago,
    formasPagoListasPrecio,
    estadosPedido,
    estadosEntrega,
    estadosDetallePedido,
    estadosRemito,
    marcas,
    monedas,
    localidades,
    pedidos,
    productos,
    packingList,
    promociones,
    productosDestinoPromocion,
    productosOrigenPromocion,
    provincias,
    remitos,
    tiendas,
    usuarios,
    serviceWorker,
    stocks,
    stocksVariedad,
    variedadesCaracteristica,
    zonasEntrega,
});

export default appReducers;