import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'
//css
import '../../../assets/css/SuperMercado.css';
//Actions
import {fetchPromocionesIfNeeded} from "../../../actions/PromocionActions"
import {createDetallePedido, deleteDetallePedido} from "../../../actions/DetallePedidoActions";
import {saveCreateAnalitica} from "../../../actions/AnaliticaActions";
//Components
import ProductoUnitario from "../componentes/Productos/ProductoUnitario";
import Carrousel from "../Carruseles/CarrouselDestacados";
import Skelleton from "./../componentes/Skelleton/Skelleton";
import ProductoUnitarioCombo from "../componentes/Productos/ProductoUnitarioCombo";

const CarrouselPromociones = (props) => {
    let innerWidth = window.innerWidth;
    return (<div>
        {
            innerWidth > 714 &&
            <React.Fragment>
                <div className="productos">
                    {
                        props.isFetching ?
                            <Skelleton tipo="destacados"/>
                            :
                            <div className="row-categoria">
                                <div className="container-limit">
                                    <h4>{props.titulo}</h4>
                                </div>
                                <Carrousel idCategoria="promociones">
                                    {props.children}
                                </Carrousel>

                            </div>
                    }
                </div>
            </React.Fragment>
        }
        {
            innerWidth < 714 &&
            <React.Fragment>
                <div className="container-limit" style={{paddingTop: "25px"}}>
                    {
                        props.isFetching ?
                            <Skelleton tipo="destacados"/>
                            :

                            <div className="row-categoria">
                                <div className="container-limit">
                                    <h4>{props.titulo}</h4>
                                </div>
                                <div className="productos-hm">
                                    {props.children}
                                </div>
                            </div>

                    }

                </div>
            </React.Fragment>
        }
    </div>);
}

class Promociones extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchPromocionesIfNeeded({estado: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.fetchPromocionesIfNeeded({estado: true});
    }

    render() {
        // let SinCargo = [];
        // let Descuentos = [];
        // let MultiplesOrigenes = [];
        let Combos = [];
        let Promociones = [];

        this.props.promociones.allIds.forEach((idPromocion, i) => {
            let promocion = this.props.promociones.byId.promociones[idPromocion];

            if (promocion.formatoPromocion === "Combo") {
                let productosCombo = [];
                let productosOrigenPromocion = [];
                promocion.producto_origen_promocion.map((idProductoProductoOrigenPromocion) => {
                    let productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoProductoOrigenPromocion];
                    let producto = this.props.productos.byId.productos[productoOrigenPromocion.idProducto];
                    productosOrigenPromocion.push(productoOrigenPromocion);
                    productosCombo.push(producto);
                });
                let productosDestinoPromocion = [];
                promocion.producto_destino_promocion.map((idProductoProductoDestinoPromocion) => {
                    let productoDestinoPromocion = this.props.productosDestinoPromocion.byId.productosDestinoPromocion[idProductoProductoDestinoPromocion];
                    productosDestinoPromocion.push(productoDestinoPromocion);
                });
                Combos.push(
                    <div key={idPromocion}>
                        <ProductoUnitarioCombo
                            productos={productosCombo}
                            promocion={promocion}
                            productosDestinoPromocion={productosDestinoPromocion}
                            productosOrigenPromocion={productosOrigenPromocion}
                            detallesPedido={this.props.detallesPedido.create.detallesPedido}
                            toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}
                            createDetallePedido={this.props.createDetallePedido.bind(this)}
                            deleteDetallePedido={this.props.deleteDetallePedido.bind(this)}
                            createAnalitica={this.props.saveCreateAnalitica.bind(this)}
                        />
                    </div>
                );
            } else {
                let array = [];
                promocion.producto_origen_promocion.forEach((idProductoProductoOrigenPromocion) => {
                    let productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoProductoOrigenPromocion];
                    let producto = this.props.productos.byId.productos[productoOrigenPromocion.idProducto];
                    array.push(
                        <div key={productoOrigenPromocion.idProducto}>
                            <ProductoUnitario
                                producto={producto ? producto : []}
                                promocion={promocion}
                                productoOrigenPromocion={productoOrigenPromocion}
                                detallePedido={this.props.detallesPedido.create.detallesPedido[productoOrigenPromocion.idProducto + "-"]}
                                toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}
                                createDetallePedido={this.props.createDetallePedido.bind(this)}
                                deleteDetallePedido={this.props.deleteDetallePedido.bind(this)}
                                createAnalitica={this.props.saveCreateAnalitica.bind(this)}
                            />
                        </div>
                    );
                });
                Promociones.push(array);
            }
        });
        return (

            <section className="supermercado" id="productos">

                {
                    [Promociones, Combos].map((arrayProductos, i) => {
                        let titulo = "";
                        switch (i) {
                            case 0:
                                titulo = "Ofertas especiales";
                                break;
                            case 1:
                                titulo = "Combos";
                                break;
                        }
                        if (i === 0 && arrayProductos && arrayProductos.length > 1) {
                            let arrayFila = [];
                            arrayFila[0] = [];
                            let categoriasFila = [];
                            categoriasFila[0] = [];
                            let titulosFila = [];
                            titulosFila[0] = [];
                            return arrayProductos.map((productosPromocion, i2) => {
                                if (productosPromocion[0]) {
                                    let promocion = productosPromocion[0].props.children.props.promocion;
                                    let producto = productosPromocion[0].props.children.props.producto;
                                    if (productosPromocion.length > 1) {
                                        titulo = `Llevando ${promocion.cantidad} ${promocion.cantidad > 1 && producto && producto.medida ? producto.medida + "es" : "unidades"} de cualquiera de estos productos`;
                                        if (productosPromocion.length > 0)
                                            return (
                                                <CarrouselPromociones key={i + "-" + i2} titulo={titulo}
                                                                      isFetching={this.props.promociones.byId.isFetching}>
                                                    {productosPromocion}
                                                </CarrouselPromociones>
                                            )
                                    } else {
                                        arrayFila[arrayFila.length - 1].push(productosPromocion);
                                        if (categoriasFila[categoriasFila.length - 1].indexOf(producto.idCategoria) === -1)
                                            categoriasFila[categoriasFila.length - 1].push(producto.idCategoria);
                                        titulosFila[titulosFila.length - 1].splice(0, titulosFila[titulosFila.length - 1].length);

                                        if (categoriasFila[categoriasFila.length - 1].length > 2)
                                            titulosFila[titulosFila.length - 1].push("Ofertas especiales");
                                        else {
                                            titulosFila[titulosFila.length - 1].push("Ofertas en ");
                                            categoriasFila[categoriasFila.length - 1].forEach((idCategoria, i) => {
                                                let categoria = this.props.categorias.byId.categorias[idCategoria];
                                                if (categoria)
                                                    titulosFila[titulosFila.length - 1].push(categoria.desCategoria + (i === 0 && categoriasFila[categoriasFila.length - 1].length > 1 ? " y " : ""))
                                            })
                                        }

                                        if (arrayFila[arrayFila.length - 1].length === 1) {
                                            return (
                                                <CarrouselPromociones key={i + "-" + i2}
                                                                      titulo={titulosFila[titulosFila.length - 1]}
                                                                      isFetching={this.props.promociones.byId.isFetching}>
                                                    {arrayFila[arrayFila.length - 1]}
                                                </CarrouselPromociones>
                                            )
                                        }
                                        if (arrayFila[arrayFila.length - 1].length === 4) {
                                            arrayFila[arrayFila.length] = [];
                                            categoriasFila[categoriasFila.length] = [];
                                            titulosFila[titulosFila.length] = [];
                                        }
                                    }
                                }
                            })
                        } else {
                            if (arrayProductos.length >= 1)
                                return (
                                    <CarrouselPromociones key={i + "- combo"}
                                                          titulo={"Combos"}
                                                          isFetching={this.props.promociones.byId.isFetching}>
                                        {arrayProductos}
                                    </CarrouselPromociones>
                                )
                        }
                        // if (arrayProductos.length > 0)
                        //     return (
                        //         <CarrouselPromociones key={i} titulo={titulo}
                        //                               isFetching={this.props.promociones.byId.isFetching}>
                        //             {arrayProductos}
                        //         </CarrouselPromociones>
                        //     )

                    })
                }
            </section>

        );
    }
}

function mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        comprasComunitarias: state.comprasComunitarias,
        promociones: state.promociones,
        productosOrigenPromocion: state.productosOrigenPromocion,
        productosDestinoPromocion: state.productosDestinoPromocion,
        categorias: state.categorias

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPromocionesIfNeeded: (filtros) => {
            dispatch(fetchPromocionesIfNeeded(filtros))
        },
        createDetallePedido: (detalle) => {
            dispatch(createDetallePedido(detalle))
        },
        deleteDetallePedido: (detalle) => {
            dispatch(deleteDetallePedido(detalle))
        },
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Promociones));

