import React, {Component} from 'react';

import '../../assets/css/CompraExitosa.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretSquareLeft} from "@fortawesome/free-solid-svg-icons";
import success from "../../assets/images/estadoCompra/interface.png";
import pending from "../../assets/images/estadoCompra/clock.png";
import failure from "../../assets/images/estadoCompra/failure.png";
import notFound from "../../assets/images/estadoCompra/signs.png";

import history from '../../history';

class CompraConfirmada extends Component {

    componentDidMount() {
        //Subo página
        window.scrollTo(0, 0);
    }

    render() {
        let estadoCompraQuery = this.props.match.params.estado;
        let idPagoTP = this.props.match.params.idPagoTP;

        const estadoCompra = () => {
            switch (estadoCompraQuery) {
                case "success":
                    return (
                        <div>
                            <div className="imagen-circular">
                                <img src={success}/>
                            </div>
                            <h2>¡Tu compra está confirmada!</h2>
                            <p>Te mantendremos informado sobre tu pedido al celular registrado.</p>
                        </div>)
                    break;
                case "failure":
                    return (
                        <div>
                            <div className="imagen-circular">
                                <img src={failure}/>
                            </div>
                            <h2>Tu pago fue rechazado</h2>
                            <p>Envianos un WhatsApp haciendo click en el ícono que se encuentra en la parte inferior
                                derecha de la pantalla, así te ayudamos con tu pago</p>
                        </div>)
                    break;
                case "pending":
                    return (
                        <div>
                            <div className="imagen-circular">
                                <img src={pending}/>
                            </div>
                            <h2>Tu pago está pendiente de aprobación</h2>
                            <p>Te mantendremos informado sobre tu pedido al celular registrado.</p>
                        </div>)
                    break;
                default:
                    return (
                        <div>
                            <div className="imagen-circular">
                                <img src={notFound}/>
                            </div>
                            <h2>Dirección incorrecta</h2>
                        </div>
                    )

            }
        }

        return (
            <section className="compra-exitosa">
                <div className="container-limit">
                    {estadoCompra()}
                    <div className="container-botones">
                        <button className="encargar" style={{outline: "none"}} onClick={() => {
                            history.push("/");
                        }}>
                            VOLVER <span><FontAwesomeIcon icon={faCaretSquareLeft} color="white"/></span>
                        </button>
                    </div>
                </div>
            </section>

        );
    }
}

export default CompraConfirmada;
