require('es6-promise').polyfill();
require('isomorphic-fetch');


var moment = require('moment');


var fechasEntrega = {

    calcularFechaEntrega(zona) {

        let diasPosibles = [];
        if (zona) {
            if (zona.lunes)
                diasPosibles.push(1);
            if (zona.martes)
                diasPosibles.push(2);
            if (zona.miercoles)
                diasPosibles.push(3);
            if (zona.jueves)
                diasPosibles.push(4);
            if (zona.viernes)
                diasPosibles.push(5);
            if (zona.sabado)
                diasPosibles.push(6);
            if (zona.domingo)
                diasPosibles.push(7);
        }
        if (diasPosibles.length > 0) {


            const diaSemanaHoy = moment().isoWeekday();
            const hoy = moment();
            const horaMaxima = moment(zona.horaMaxima, "HH:mm:ss");
            const hoyHoraMaxima = moment().hour(horaMaxima.hour()).minute(horaMaxima.minute());

            let diaElegido = null;
            //Misma semana
            diasPosibles.some((diaPosible) => {
                if (diaPosible < diaSemanaHoy) {
                    return false;
                } else {
                    let proximoDiaHoraMaxima = moment().isoWeekday(diaPosible).hour(horaMaxima.hour()).minute(horaMaxima.minute());
                    if (proximoDiaHoraMaxima.isSameOrAfter(hoyHoraMaxima, "minute")) {
                        var duration = moment.duration(proximoDiaHoraMaxima.diff(hoy));
                        var horas = duration.asHours();
                        if (horas >= zona.demora) {
                            diaElegido = proximoDiaHoraMaxima;
                            return true;
                        } else
                            return false;
                    }
                }
            });
            //Si no encontre posibilidad para esta semana busco para las siguientes
            let i = 1;
            while (!diaElegido) {
                diasPosibles.some((diaPosible) => {
                    let proximoDiaHoraMaxima = moment().add(i, 'weeks').isoWeekday(diaPosible).hour(horaMaxima.hour()).minute(horaMaxima.minute());
                    if (proximoDiaHoraMaxima.isAfter(hoyHoraMaxima, "minute")) {
                        var duration = moment.duration(proximoDiaHoraMaxima.diff(hoy));
                        var horas = duration.asHours();
                        if (horas >= zona.demora && (!zona.semanas || (zona.semanas.indexOf(fechasEntrega.getSemanaDeDia(proximoDiaHoraMaxima)) !== -1))) {
                            diaElegido = proximoDiaHoraMaxima;
                            return true;
                        } else
                            return false;
                    }
                    return false;
                });
                i++;
            }
            return diaElegido.format("YYYY-MM-DD");
        } else
            return null;
    },
    getSemanaDeDia(dateObj) {
        var monday = moment(dateObj);
        monday.startOf('month')
            .day(dateObj.day());
        if (monday.date() > 7) monday.add(7, 'd');
        var month = monday.month();
        let i = 1;
        let semana = null;
        while (month === monday.month()) {
            if (dateObj.isSame(monday, "day"))
                semana = i;
            monday.add(7, 'd');
            i++;
        }
        return semana;
    },
    esZonaActiva(zona) {
        if (zona.lunes != 1 && zona.martes != 1 && zona.miercoles != 1 && zona.jueves != 1 && zona.viernes != 1 && zona.sabado != 1 && zona.domingo != 1) {
            return false;
        } else {
            return true;
        }
    }
};

export default fechasEntrega;