import {
    INVALIDATE_FORMASENTREGA,
    ERROR_FORMASENTREGA,
    RECEIVE_FORMASENTREGA,
    REQUEST_FORMASENTREGA,
    RESET_FORMASENTREGA,
    ERROR_FORMAENTREGA,
    RECEIVE_FORMAENTREGA,
    REQUEST_FORMAENTREGA,
    UPDATE_FORMAENTREGA,
    REQUEST_UPDATE_FORMAENTREGA,
    SUCCESS_UPDATE_FORMAENTREGA,
    ERROR_UPDATE_FORMAENTREGA,
    RESET_UPDATE_FORMAENTREGA,
    REQUEST_UPDATE_FORMASENTREGA,
    SUCCESS_UPDATE_FORMASENTREGA,
    ERROR_UPDATE_FORMASENTREGA,
    RESET_UPDATE_FORMASENTREGA,
    CREATE_FORMAENTREGA,
    ERROR_CREATE_FORMAENTREGA,
    REQUEST_CREATE_FORMAENTREGA,
    RESET_CREATE_FORMAENTREGA,
    SUCCESS_CREATE_FORMAENTREGA,
    DELETE_FORMAENTREGA,
    REQUEST_DELETE_FORMAENTREGA,
    SUCCESS_DELETE_FORMAENTREGA,
    ERROR_DELETE_FORMAENTREGA,
    RESET_DELETE_FORMAENTREGA,

} from '../actions/FormaEntregaActions';


import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import filter from "lodash/filter";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        formasEntrega: {},
    }
}

function formasEntregaById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_FORMASENTREGA:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_FORMASENTREGA:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_FORMASENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_FORMASENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                formasEntrega: {}
            });
        case RECEIVE_FORMASENTREGA:
            let dato = action.formasEntrega.entities.formasEntrega;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                formasEntrega: merge({}, state.formasEntrega, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_FORMAENTREGA:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_FORMAENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_FORMAENTREGA:
            let datoFormaEntrega = action.formaEntrega.entities.formasEntrega;
            return Object.assign({}, state, {
                formasEntrega: merge({}, state.formasEntrega, datoFormaEntrega),
                isFetching: false,
            });
        case CREATE_FORMAENTREGA:
            let datoFormaEntregaCreate = action.formaEntrega;
            let idNuevoCreate = null;
            if (Object.values(datoFormaEntregaCreate).length > 0)
                idNuevoCreate = Object.values(datoFormaEntregaCreate)[0] && Object.values(datoFormaEntregaCreate)[0].id ? Object.values(datoFormaEntregaCreate)[0].id : null;
            if (idNuevoCreate)
                return Object.assign({}, state, {
                    formasEntrega: merge({}, state.formasEntrega, datoFormaEntregaCreate)
                });
            else
                return state;
        case UPDATE_FORMAENTREGA:
            let datoFormaEntregaUpdate = action.formaEntrega;
            let idNuevoUpdate = null;
            if (Object.values(datoFormaEntregaUpdate).length > 0)
                idNuevoUpdate = Object.values(datoFormaEntregaUpdate)[0] && Object.values(datoFormaEntregaUpdate)[0].id ? Object.values(datoFormaEntregaUpdate)[0].id : null;
            if (idNuevoUpdate)
                return Object.assign({}, state, {
                    formasEntrega: merge({}, state.formasEntrega, datoFormaEntregaUpdate)
                });
            else
                return state;
        case DELETE_FORMAENTREGA:
            let datoFormaEntregaDelete = action.formaEntrega;
            let idNuevoDelete = null;
            if (Object.values(datoFormaEntregaDelete).length > 0)
                idNuevoDelete = Object.values(datoFormaEntregaDelete)[0] && Object.values(datoFormaEntregaDelete)[0].id ? Object.values(datoFormaEntregaDelete)[0].id : null;
            if (idNuevoDelete)
                return Object.assign({}, state, {
                    formasEntrega: omit(clone(state.formasEntrega), Object.keys(datoFormaEntregaDelete))
                });
            else
                return state;
        case SUCCESS_DELETE_FORMAENTREGA:
            let datoFormaEntregaEliminado = action.formaEntrega.entities.formasEntrega;
            return Object.assign({}, state, {
                formasEntrega: mergeWith(clone(datoFormaEntregaEliminado), state.formasEntrega, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_FORMAENTREGA:
            let datoFormaEntregaCreado = action.formaEntrega.entities.formasEntrega;
            return Object.assign({}, state, {
                formasEntrega: mergeWith(clone(datoFormaEntregaCreado), state.formasEntrega, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_UPDATE_FORMAENTREGA:
            let datoFormaEntregaActualizado = action.formaEntrega.entities.formasEntrega;
            return Object.assign({}, state, {
                formasEntrega: mergeWith(clone(datoFormaEntregaActualizado), state.formasEntrega, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_UPDATE_FORMASENTREGA:
            let datosFormaEntregaActualizado = action.formasEntrega.entities.formasEntrega;
            return Object.assign({}, state, {
                formasEntrega: mergeWith(clone(datosFormaEntregaActualizado), state.formasEntrega, (objValue, srcValue) => {return objValue;})
            });





        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                formasEntrega: {}
            });
        default:
            return state
    }
}


function allFormasEntrega(state = [], action) {
    switch (action.type) {
        case RECEIVE_FORMASENTREGA:
            return action.formasEntrega.result && action.formasEntrega.result.formasEntrega ? action.formasEntrega.result.formasEntrega : (action.formasEntrega.result ? action.formasEntrega.result : state) ;

        case CREATE_FORMAENTREGA:
            let idsCreate = [];
            Object.values(action.formaEntrega).map((formaEntregaCreate) => {
                if (formaEntregaCreate && formaEntregaCreate.id)
                    idsCreate.push(formaEntregaCreate.id);
            });
            return union(state, idsCreate);
        case UPDATE_FORMAENTREGA:
            let idsUpdate = [];
            Object.values(action.formaEntrega).map((formaEntregaCreate) => {
                if (formaEntregaCreate && formaEntregaCreate.id)
                    idsUpdate.push(formaEntregaCreate.id);
            });
            return union(state, idsUpdate);
        case DELETE_FORMAENTREGA:
            let idsDelete = [];
            Object.values(action.formaEntrega).map((formaEntregaCreate) => {
                if (formaEntregaCreate && formaEntregaCreate.id)
                    idsDelete.push(formaEntregaCreate.id);
            });
            return difference(state, idsDelete);
        case SUCCESS_CREATE_FORMAENTREGA:
            let datoFormaEntregaSCreate = action.formaEntrega.entities.formasEntrega;
            let idNuevoSCreate = null;
            if (Object.values(datoFormaEntregaSCreate).length > 0)
                idNuevoSCreate = Object.values(datoFormaEntregaSCreate)[0] && Object.values(datoFormaEntregaSCreate)[0].id ? Object.values(datoFormaEntregaSCreate)[0].id : null;
            if (idNuevoSCreate)
                return union(state, [idNuevoSCreate]);
            else
                return state;
        case RESET_FORMASENTREGA:
            return [];



        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalFormasEntrega(state = null, action) {
    switch (action.type) {
        case RECEIVE_FORMASENTREGA:
            return action.formasEntrega && action.formasEntrega.result.total ? action.formasEntrega.result.total : 0;
        case RESET_FORMASENTREGA:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_FORMAENTREGA:
            let dato = action.formaEntrega.entities.formasEntrega;
            let formaEntrega = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: formaEntrega ? formaEntrega : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_FORMAENTREGA:
            let idsUpdate = [];
            Object.values(action.formaEntrega).map((formaEntregaUpdate) => {
                if (formaEntregaUpdate && formaEntregaUpdate.id)
                    idsUpdate.push(formaEntregaUpdate.id);
            });
            return merge({}, state, {
                activo: action.formaEntrega,
                activos: idsUpdate.length > 0 ? union(state.activos, idsUpdate) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_FORMAENTREGA:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_FORMAENTREGA:
            let datoFormaEntregaActualizado = {};
            if (Object.values(action.formaEntrega.entities.formasEntrega).length > 0)
                datoFormaEntregaActualizado = Object.values(action.formaEntrega.entities.formasEntrega)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoFormaEntregaActualizado
            });
        case ERROR_UPDATE_FORMAENTREGA:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case REQUEST_UPDATE_FORMASENTREGA:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_FORMASENTREGA:
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: {},
                activos: []
            });
        case ERROR_UPDATE_FORMASENTREGA:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_UPDATE_FORMAENTREGA:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                activos: [],
                error: ""
            });





        case DELETE_FORMAENTREGA:
            let datoFormaEntregaDelete = action.formaEntrega;
            let idsDelete = [];
            Object.values(action.formaEntrega).map((formaEntregaDelete) => {
                if (formaEntregaDelete && formaEntregaDelete.id)
                    idsDelete.push(formaEntregaDelete.id);
            });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoFormaEntregaDelete)),
                    activos: difference(clone(state.activos), idsDelete)
                });
            else
                return state;
        case SUCCESS_DELETE_FORMAENTREGA:
            let datoFormaEntregaDeleted = {};
            if (Object.values(action.formaEntrega.entities.formasEntrega).length > 0)
                datoFormaEntregaDeleted = Object.values(action.formaEntrega.entities.formasEntrega)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoFormaEntregaDeleted
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_FORMAENTREGA:
            let idsCreate = [];
            Object.values(action.formaEntrega).map((formaEntregaCreate) => {
                if (formaEntregaCreate && formaEntregaCreate.id)
                    idsCreate.push(formaEntregaCreate.id);
            });
            return merge({}, state, {
                isCreating: false,
                nuevo: action.formaEntrega,
                nuevos: idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_FORMAENTREGA:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_FORMAENTREGA:
            let datoFormaEntregaNuevo = {};
            if (Object.values(action.formaEntrega.entities.formasEntrega).length > 0)
                datoFormaEntregaNuevo = Object.values(action.formaEntrega.entities.formasEntrega)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoFormaEntregaNuevo,
                nuevos: []
            });
        case ERROR_CREATE_FORMAENTREGA:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_FORMAENTREGA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {},
                nuevos: []
            });




        case DELETE_FORMAENTREGA:
            let datoFormaEntregaDelete = action.formaEntrega;
            let idsDelete = [];
            Object.values(action.formaEntrega).map((formaEntregaDelete) => {
                if (formaEntregaDelete && formaEntregaDelete.id)
                    idsDelete.push(formaEntregaDelete.id);
            });
            if (idsDelete.length > 0)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.activo), Object.keys(datoFormaEntregaDelete)),
                    nuevos: difference(clone(state.activos), idsDelete)
                });
            else
                return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_FORMAENTREGA:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.formaEntrega,
                error: null,
            });
        case REQUEST_DELETE_FORMAENTREGA:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_FORMAENTREGA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_FORMAENTREGA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_FORMAENTREGA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });

        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

const formasEntrega = combineReducers({
    byId: formasEntregaById,
    allIds: allFormasEntrega,
    update: update,
    create: create,
    totalFormasEntrega: totalFormasEntrega,
    delete: deleter
});

export default formasEntrega;