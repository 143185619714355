import * as errorMessages from '../constants/MessageConstants';
//Actions
import {fetchDetallesRemitidoIfNeeded, invalidateDetalleRemitido} from "./DetalleRemitidoActions";
import {logout} from "../actions/AuthActions";
//api
import packingList from "../api/packingList";

//PACKINGLIST
export const RESET_PACKINGLIST = "RESET_PACKINGLIST";

//CREATE
export const CREATE_PACKINGLIST = 'CREATE_PACKINGLIST';
export const REQUEST_CREATE_PACKINGLIST = "REQUEST_CREATE_PACKINGLIST";
export const SUCCESS_CREATE_PACKINGLIST = "SUCCESS_CREATE_PACKINGLIST";
export const ERROR_CREATE_PACKINGLIST = "ERROR_CREATE_PACKINGLIST";

//UPDATE
export const UPDATE_PACKINGLIST = 'UPDATE_PACKINGLIST';
export const REQUEST_UPDATE_PACKINGLIST = "REQUEST_UPDATE_PACKINGLIST";
export const SUCCESS_UPDATE_PACKINGLIST = "SUCCESS_UPDATE_PACKINGLIST";
export const ERROR_UPDATE_PACKINGLIST = "ERROR_UPDATE_PACKINGLIST";
export const RESET_UPDATE_PACKINGLIST = "RESET_UPDATE_PACKINGLIST";

//DELETE
export const DELETE_PACKINGLIST = 'DELETE_PACKINGLIST';
export const REQUEST_DELETE_PACKINGLIST = "REQUEST_DELETE_PACKINGLIST";
export const SUCCESS_DELETE_PACKINGLIST = "SUCCESS_DELETE_PACKINGLIST";
export const ERROR_DELETE_PACKINGLIST = "ERROR_DELETE_PACKINGLIST";
export const RESET_DELETE_PACKINGLIST = "RESET_DELETE_PACKINGLIST";

//IMPRIMIR
export const REQUEST_IMPRIMIR_PACKINGLIST = "REQUEST_IMPRIMIR_PACKINGLIST";
export const SUCCESS_IMPRIMIR_PACKINGLIST = "SUCCESS_IMPRIMIR_PACKINGLIST";
export const ERROR_IMPRIMIR_PACKINGLIST = "ERROR_IMPRIMIR_PACKINGLIST";
export const REQUEST_PREGUNTAR_IMPRIMIR_PACKINGLIST = "REQUEST_PREGUNTAR_IMPRIMIR_PACKINGLIST";
export const SUCCESS_PREGUNTAR_IMPRIMIR_PACKINGLIST = "SUCCESS_PREGUNTAR_IMPRIMIR_PACKINGLIST";
export const RESET_IMPRIMIR_PACKINGLIST = "REQUEST_IMPRIMIR_PACKINGLIST";


//CREATE
function requestCreatePackingList() {
    return {
        type: REQUEST_CREATE_PACKINGLIST,
    }
}

function receiveCreatePackingList() {
    return {
        type: SUCCESS_CREATE_PACKINGLIST,
        receivedAt: Date.now()
    }
}

function errorCreatePackingList(error) {
    return {
        type: ERROR_CREATE_PACKINGLIST,
        error: error,
    }
}

export function createPackingList(id, packingList) {
    return {
        type: CREATE_PACKINGLIST,
        id,
        packingList
    }
}


export function saveCreatePackingList() {
    return (dispatch, getState) => {
        dispatch(requestCreatePackingList());

        //Tomo los nuevos packing
        var packing = getState().packingList.create.packingList.map((id) => {
            return (getState().packingList.byId.packingList[id]);
        });

        return packingList.saveCreate(packing)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveCreatePackingList());
                    //dispatch(invalidateDetalleRemitido());
                    //dispatch(fetchDetallesRemitidoIfNeeded(idRemito));
                    //Refresco token
                    // auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCreatePackingList(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCreatePackingList(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE
function requestUpdatePackingList() {
    return {
        type: REQUEST_UPDATE_PACKINGLIST,
    }
}

function receiveUpdatePackingList() {
    return {
        type: SUCCESS_UPDATE_PACKINGLIST,
        receivedAt: Date.now()
    }
}

function errorUpdatePackingList(error) {
    return {
        type: ERROR_UPDATE_PACKINGLIST,
        error: error,
    }
}

export function resetUpdatePackingList(idDetalleRemitido, idsPacking) {
    return {
        type: RESET_UPDATE_PACKINGLIST,
        idDetalleRemitido,
        idsPacking
    }
}

export function updatePackingList(id, packingList) {
    return {
        type: UPDATE_PACKINGLIST,
        id,
        packingList
    }
}

export function saveUpdatePackingList() {
    return (dispatch, getState) => {
        dispatch(requestUpdatePackingList());

        //Tomo los nuevos packing
        var packing = getState().packingList.update.packingList.map((id) => {
            return (getState().packingList.byId.packingList[id]);
        });

        return packingList.saveUpdate(packing)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdatePackingList());
                    //dispatch(invalidateDetalleRemitido());
                    //dispatch(fetchDetallesRemitidoIfNeeded(idRemito));
                    //Refresco token
                    // auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdatePackingList(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorUpdatePackingList(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//DELETE
export function deletePackingList(idPackingList, idDetalleRemitido) {
    return {
        type: DELETE_PACKINGLIST,
        idPackingList,
        idDetalleRemitido
    }
}

function requestDeletePackingList() {
    return {
        type: REQUEST_DELETE_PACKINGLIST,
    }
}

function receiveDeletePackingList() {
    return {
        type: SUCCESS_DELETE_PACKINGLIST,
        receivedAt: Date.now()
    }
}

function errorDeletePackingList(error) {
    return {
        type: ERROR_DELETE_PACKINGLIST,
        error: error,
    }
}

export function resetDeletePackingList(idDetalleRemitido, idsPacking) {
    return {
        type: RESET_DELETE_PACKINGLIST,
        idDetalleRemitido,
        idsPacking
    }
}

export function saveDeletePackingList() {
    return (dispatch, getState) => {
        dispatch(requestDeletePackingList());

        //Tomo los packing a eliminar
        var packing = getState().packingList.delete.packingList.map((id) => {
            return ({id: id});
        });

        return packingList.saveDelete(packing)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveDeletePackingList());
                    //dispatch(invalidateDetalleRemitido());
                    //dispatch(fetchDetallesRemitidoIfNeeded(idRemito));
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorDeletePackingList(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDeletePackingList(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//IMPRESION

function requestImpresionPackingList() {
    return {
        type: REQUEST_IMPRIMIR_PACKINGLIST,
    }
}

function receiveImpresionPackingList() {
    return {
        type: SUCCESS_IMPRIMIR_PACKINGLIST,
        receivedAt: Date.now()
    }
}

function errorImpresionPackingList(error) {
    return {
        type: ERROR_IMPRIMIR_PACKINGLIST,
        error: error,
    }
}

export function imprimirPackingList(idRemito) {
    return (dispatch, getState) => {
        dispatch(requestImpresionPackingList());

        return packingList.imprimir(idRemito)
            .then(function (response) {
                //Refresco token
                // auth.addToken(response.headers);
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receivePreguntarImpresionPackingList());
                    return response.text();
                }
            })
            .then(function (data) {
                // window.open("data:application/pdf;base64, " + data);
                // base64 string
                var base64str = data;

                // decode base64 string, remove space for IE compatibility
                var binary = atob(base64str.replace(/\s/g, ''));
                var len = binary.length;
                var buffer = new ArrayBuffer(len);
                var view = new Uint8Array(buffer);
                for (var i = 0; i < len; i++) {
                    view[i] = binary.charCodeAt(i);
                }
                var file = new Blob([view], {type: 'application/pdf;base64'});
                var fileURL = URL.createObjectURL(file);
                var win = window.open();
                win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorImpresionPackingList(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorImpresionPackingList(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

export function requestPreguntarImpresionPackingList() {
    return {
        type: REQUEST_PREGUNTAR_IMPRIMIR_PACKINGLIST
    }
}

export function receivePreguntarImpresionPackingList() {
    return {
        type: SUCCESS_PREGUNTAR_IMPRIMIR_PACKINGLIST,
        receivedAt: Date.now()
    }
}

export function resetImprimirPackingList() {
    return {
        type: RESET_IMPRIMIR_PACKINGLIST
    }
}


//GENERALES

export function changePackingList(id, packingList) {
    return (dispatch, getState) => {
        //Decidir si actualizar o modificar
        if (getState().packingList.create.packingList.indexOf(id) != -1 || !getState().packingList.byId.packingList[id])
            dispatch(createPackingList(id, packingList));
        else
            dispatch(updatePackingList(id, packingList));
    }
}

export function savePackingList(idRemito) {
    return (dispatch, getState) => {
        return (getState().packingList.create.packingList.length > 0 ? dispatch(saveCreatePackingList()) : Promise.resolve())
            .then(() => {
                (getState().packingList.update.packingList.length > 0 ? dispatch(saveUpdatePackingList()) : Promise.resolve())
            })
            .then(() => {
                (getState().packingList.delete.packingList.length > 0 ? dispatch(saveDeletePackingList()) : Promise.resolve())
            })
            .then(() =>
                dispatch(invalidateDetalleRemitido()))
            .then(() =>
                dispatch(fetchDetallesRemitidoIfNeeded(idRemito))
            );
    }
}

export function resetCreatePackingList(idDetalleRemitido, idsPacking) {
    return {
        type: RESET_PACKINGLIST,
        idDetalleRemitido,
        idsPacking
    }
}