//api
import detallesPedido from "../api/detallesPedido"
//constants
import * as errorMessages from '../constants/MessageConstants';
import * as TipoAnalitica from '../constants/TipoAnalitica';
//actions
import {logout} from "../actions/AuthActions";
import {saveCreateAnalitica} from "../actions/AnaliticaActions";
//normalizers
import normalizeDatos from "../normalizers/normalizeDetallesPedido";
import promociones from "../utils/calculoPromociones";


//DETALLESPEDIDO
export const REQUEST_DETALLESPEDIDO = 'REQUEST_DETALLESPEDIDO';
export const RECEIVE_DETALLESPEDIDO = 'RECEIVE_DETALLESPEDIDO';
export const INVALIDATE_DETALLESPEDIDO = 'INVALIDATE_DETALLESPEDIDO';
export const ERROR_DETALLESPEDIDO = "ERROR_DETALLESPEDIDO";
export const RESET_DETALLESPEDIDO = "RESET_DETALLESPEDIDO";


export function invalidateDetallesPedido(detallePedido) {
    return {
        type: INVALIDATE_DETALLESPEDIDO,
        detallePedido
    }
}

function requestDetallesPedido() {
    return {
        type: REQUEST_DETALLESPEDIDO,
    }
}

function receiveDetallesPedido(json) {
    return {
        type: RECEIVE_DETALLESPEDIDO,
        detallesPedido: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorDetallesPedido(error) {
    return {
        type: ERROR_DETALLESPEDIDO,
        error: error,
    }
}

export function resetDetallesPedido() {
    return {
        type: RESET_DETALLESPEDIDO,
    }
}

function fetchDetallesPedido(idPedido) {
    return dispatch => {
        dispatch(requestDetallesPedido());
        return detallesPedido.getByIdPedido(idPedido)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveDetallesPedido(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorDetallesPedido(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDetallesPedido(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchDetallesPedido(state, idPedido) {
    var pedido = state.pedidos.byId.pedidos[idPedido];
    const detallesPedido = state.detallesPedido.byId;
    if (!detallesPedido) {
        return true
    } else if (detallesPedido.isFetching) {
        return false
    } else if (pedido) {
        // Verificar si estan los detalles pedido de ese pedido
        for (var idDetallePedido of pedido.detalle_pedido) {
            if (state.detallesPedido.byId.detallesPedido[idDetallePedido]) {
                return false;
            }
        }
        return detallesPedido.didInvalidate;
    } else {
        return detallesPedido.didInvalidate;
    }
}

export function fetchDetallesPedidoIfNeeded(idPedido) {
    return (dispatch, getState) => {
        if (shouldFetchDetallesPedido(getState(), idPedido)) {
            return dispatch(fetchDetallesPedido(idPedido))
        }
    }
}

// CREATE
export const CREATE_DETALLEPEDIDO = 'CREATE_DETALLEPEDIDO';
export const RESET_CREATE_DETALLESPEDIDO = "RESET_CREATE_DETALLESPEDIDO";


export function createDetallePedido(detallePedido) {
    return (dispatch, getState) => {
        if (Object.values(detallePedido).length > 0 && !getState().detallesPedido.create.detallesPedido[Object.keys(detallePedido)[0]]) {
            dispatch(saveCreateAnalitica(Object.values(detallePedido)[0].idProducto, TipoAnalitica.AGREGACARRITO_ID));
        }
        let detallePedidoAgregar = detallePedido;
        let detallePedidoEliminar = {};
        promociones.calcularPromociones(detallePedidoAgregar, detallePedidoEliminar, getState());
        dispatch({
            type: CREATE_DETALLEPEDIDO,
            detallePedido: detallePedidoAgregar
        });
        dispatch({
            type: DELETE_DETALLEPEDIDO,
            detallePedido: detallePedidoEliminar
        });
    }

}

export function resetCreateDetallesPedido() {
    return {
        type: RESET_CREATE_DETALLESPEDIDO,
    }
}

// DELETE
export const DELETE_DETALLEPEDIDO = "DELETE_DETALLEPEDIDO";

export function deleteDetallePedido(detallePedido) {
    return (dispatch, getState) => {
        let detallePedidoEliminar = detallePedido;
        let detallePedidoAgregar = {};
        promociones.calcularPromociones(detallePedidoAgregar, detallePedidoEliminar, getState());
        dispatch({
            type: CREATE_DETALLEPEDIDO,
            detallePedido: detallePedidoAgregar
        })
        dispatch({
            type: DELETE_DETALLEPEDIDO,
            detallePedido: detallePedidoEliminar
        })
    }
}


//UPDATE
export const RESET_UPDATE_DETALLESPEDIDO = "RESET_UPDATE_DETALLESPEDIDO";

export function resetUpdateDetallesPedido() {
    return {
        type: RESET_UPDATE_DETALLESPEDIDO,
    }
}

