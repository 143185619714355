import {
    CREATE_DETALLEPEDIDO,
    DELETE_DETALLEPEDIDO,
    ERROR_DETALLESPEDIDO,
    INVALIDATE_DETALLESPEDIDO,
    RECEIVE_DETALLESPEDIDO,
    REQUEST_DETALLESPEDIDO,
    RESET_CREATE_DETALLESPEDIDO,
    RESET_DETALLESPEDIDO,
    RESET_UPDATE_DETALLESPEDIDO
} from '../actions/DetallePedidoActions';
import {CREATE_ESTADODETALLEPEDIDO} from "../actions/EstadoDetallePedidoActions";
import {RECEIVE_PEDIDO, SUCCESS_CREATE_PEDIDO} from "../actions/PedidoActions"
import {combineReducers} from 'redux';
import {loadFromLS} from "../localeStorage/ls"
import merge from "lodash/merge";
import union from "lodash/union";
import pickBy from "lodash/pickBy";

function detallesPedidoById(state = {
    isFetching: false,
    didInvalidate: true,
    detallesPedido: {}
}, action) {
    switch (action.type) {
        case INVALIDATE_DETALLESPEDIDO:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_DETALLESPEDIDO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_DETALLESPEDIDO:
            let dato = action.detallesPedido.entities.detallesPedido;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                detallesPedido: dato ? dato : {},
                lastUpdated: action.receivedAt
            });
        case ERROR_DETALLESPEDIDO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_DETALLESPEDIDO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                detallesPedido: {}
            });
        case CREATE_ESTADODETALLEPEDIDO:
            var estado = {};
            estado[action.estadoDetallePedido.idDetallePedido] = {estado_detalle_pedido: union(state.detallesPedido[action.estadoDetallePedido.idDetallePedido].estado_detalle_pedido, [action.id])};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                detallesPedido: merge({}, state.detallesPedido, estado)
            });
        //PEDIDO
        // case RECEIVE_PEDIDO:
        //     return Object.assign({}, state, {
        //         detallesPedido: merge({}, state.detallesPedido, action.pedido.entities.detallesPedido),
        //         lastUpdated: action.receivedAt
        //     });
        // // CREATE
        // case CREATE_DETALLEPEDIDO:
        //     return Object.assign({}, state, {
        //         error: null,
        //         detallesPedido: merge({}, state.detallesPedido, action.detallePedido),
        //     });
        // // DELETE
        // case DELETE_DETALLEPEDIDO:
        //     return Object.assign({}, state, {
        //         isFetching: false,
        //         error: null,
        //         detallesPedido: pickBy(state.detallesPedido, function (bulto, key) {
        //             return key !== Object.keys(action.detallePedido)[0];
        //         })
        //     });
        // case LOGOUT_SUCCESS:
        //     return Object.assign({}, state, {
        //         error: null,
        //         detallesPedido: {}
        //     });
        default:
            return state
    }
}


function allDetallesPedido(state = [], action) {
    switch (action.type) {
        case RECEIVE_DETALLESPEDIDO:
            return action.detallesPedido.result ? action.detallesPedido.result : [];
        case RESET_DETALLESPEDIDO:
            return [];
        default:
            return state
    }
}

function getInitialStateCreate() {
    let detallesPedidoGuardado = loadFromLS("detallesPedido");
    if (detallesPedidoGuardado)
        return detallesPedidoGuardado.create;
    else
        return {
            isCreating: false,
            didInvalidate: true,
            detallesPedido: {}
        }
}

function create(state = getInitialStateCreate(), action) {
    switch (action.type) {
        case RESET_CREATE_DETALLESPEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                detallesPedido: {}
            });
        // CREATE
        case CREATE_DETALLEPEDIDO:
            return Object.assign({}, state, {
                error: null,
                detallesPedido: merge({}, state.detallesPedido, action.detallePedido),
            });
        // DELETE
        case DELETE_DETALLEPEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                detallesPedido: pickBy(state.detallesPedido, function (bulto, key) {
                    return Object.keys(action.detallePedido).indexOf(key) === -1;
                })
            });
        case SUCCESS_CREATE_PEDIDO:
            return Object.assign({}, state, {
                detallesPedido: action.pedido.entities && action.pedido.entities.detallesPedido ? action.pedido.entities.detallesPedido : {},
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
}

function update(state = {
    isFetching: false,
    didInvalidate: true,
    detallesPedido: {}
}, action) {
    switch (action.type) {
        case RESET_UPDATE_DETALLESPEDIDO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                detallesPedido: {}
            });
        //PEDIDO
        case RECEIVE_PEDIDO:
            return Object.assign({}, state, {
                detallesPedido: merge({}, state.detallesPedido, action.pedido.entities.detallesPedido),
                lastUpdated: action.receivedAt
            });
        // case LOGOUT_SUCCESS:
        //     return Object.assign({}, state, {
        //         error: null,
        //         detallesPedido: {}
        //     });
        default:
            return state
    }
}

const detallesPedido = combineReducers({
    byId: detallesPedidoById,
    allIds: allDetallesPedido,
    create: create,
    update: update
});

export default detallesPedido;