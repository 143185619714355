import {normalize, schema, denormalize} from 'normalizr';

export function normalizeDatos(myData) {
    const aviso = new schema.Entity('avisos', {}, {idAttribute: "id"});

    const mySchema = {avisos: [aviso]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const aviso = new schema.Entity('avisos', {}, {idAttribute: "id"});

    const mySchema = aviso;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const aviso = new schema.Entity('avisos', {}, {idAttribute: "id"});

    const mySchema = aviso;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}


