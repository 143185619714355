import {
    ERROR_FORMASPAGO,
    INVALIDATE_FORMASPAGO,
    RECEIVE_FORMASPAGO,
    REQUEST_FORMASPAGO
} from '../actions/FormaPagoActions';
import {combineReducers} from 'redux';
import {normalize, schema} from 'normalizr';

function normalizeDatos(myData) {
    const formasPago = new schema.Entity('formasPago');
    const mySchema = [formasPago];
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

function formasPagoById(state = {
    isFetching: false,
    didInvalidate: true,
    formasPago: []
}, action) {
    switch (action.type) {
        case INVALIDATE_FORMASPAGO:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_FORMASPAGO:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_FORMASPAGO:
            let dato = action.formasPago.entities.formasPago;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                formasPago: dato ? dato : [],
                lastUpdated: action.receivedAt
            });
        case ERROR_FORMASPAGO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        default:
            return state
    }
}


function allFormasPago(state = [], action) {
    switch (action.type) {
        case RECEIVE_FORMASPAGO:
            return action.formasPago.result.formasPago;
        default:
            return state
    }
}


const formasPago = combineReducers({
    byId: formasPagoById,
    allIds: allFormasPago
});

export default formasPago;