import {RECEIVE_PROMOCIONES, RESET_PROMOCIONES} from '../actions/PromocionActions';
import {combineReducers} from 'redux';

import merge from "lodash/merge";
import {RECEIVE_PRODUCTO, RECEIVE_PRODUCTOS} from "../actions/ProductoActions";
import {RECEIVE_CATEGORIAS} from "../actions/CategoriaActions";
import {RECEIVE_BUSQUEDA} from "../actions/BusquedaActions";

function productosOrigenPromocionById(state = {
    isFetching: false,
    didInvalidate: true,
    productosOrigenPromocion: {}
}, action) {
    switch (action.type) {
        case RECEIVE_PROMOCIONES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productosOrigenPromocion: merge({}, state.productosOrigenPromocion, action.promociones.entities.productosOrigenPromocion),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_PRODUCTOS:
            return Object.assign({}, state, {
                productosOrigenPromocion: merge({}, state.productosOrigenPromocion, action.productos.entities.productosOrigenPromocion),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_PRODUCTO:
            return Object.assign({}, state, {
                productosOrigenPromocion: merge({}, state.productosOrigenPromocion, action.producto.entities.productosOrigenPromocion),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_BUSQUEDA:
            return Object.assign({}, state, {
                productosOrigenPromocion: merge({}, state.productosOrigenPromocion, action.busqueda.entities.productosOrigenPromocion),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_CATEGORIAS:
            return Object.assign({}, state, {
                productosOrigenPromocion: merge({}, state.productosOrigenPromocion, action.categorias.entities.productosOrigenPromocion),
                lastUpdated: action.receivedAt
            });
        case RESET_PROMOCIONES:
            return Object.assign({}, state, {
                isFetching: false,
                productosOrigenPromocion: {},
                error: null
            });
        default:
            return state
    }
}


function allProductosOrigenPromocion(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}


const productosOrigenPromocion = combineReducers({
    byId: productosOrigenPromocionById,
    allIds: allProductosOrigenPromocion
});

export default productosOrigenPromocion;