export const AGREGACARRITO = 'Agregar al carrito';
export const VALIDARDIRECCIONENTREGA = 'Dirección entrega valida';
export const VISITAPAGINA = 'Visita a la página';
export const COMPRA = "Compra";
export const BUSQUEDA = 'Búsqueda';
export const NOVALIDARDIRECCIONENTREGA = 'Dirección entrega no valida';
export const REGISTRO = 'Registro';
export const REGISTRO_POSIBLE = 'Registro posible';
export const INICIO_SESION_POSIBLE = 'Posible Inicio sesión';
export const INICIO_SESION = 'Inicio sesión';
export const CLICK_WHATSAPP = "Click en WhatsApp";
export const CLICK_BOTON_MAIL = "Click botón de mail";

export const AGREGACARRITO_ID = 1;
export const VALIDARDIRECCIONENTREGA_ID = 2;
export const VISITAPAGINA_ID = 3;
export const COMPRA_ID = 4;
export const BUSQUEDA_ID = 5;
export const NOVALIDARDIRECCIONENTREGA_ID = 6;
export const REGISTRO_ID = 7;
export const REGISTRO_POSIBLE_ID = 8;
export const INICIO_SESION_POSIBLE_ID = 9;
export const INICIO_SESION_ID = 10;
export const CLICK_WHATSAPP_ID = 11;
export const CLICK_BOTON_MAIL_ID = 16;