//api
import direccionesEntregaApi from "../api/direccionesEntregaApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeDireccionesEntrega";

//DIRECCIONESENTREGA
export const REQUEST_DIRECCIONESENTREGA = 'REQUEST_DIRECCIONESENTREGA';
export const RECEIVE_DIRECCIONESENTREGA = 'RECEIVE_DIRECCIONESENTREGA';
export const INVALIDATE_DIRECCIONESENTREGA = 'INVALIDATE_DIRECCIONESENTREGA';
export const ERROR_DIRECCIONESENTREGA = "ERROR_DIRECCIONESENTREGA";
export const RESET_DIRECCIONESENTREGA = "RESET_DIRECCIONESENTREGA";


export function invalidateDireccionesEntrega() {
    return {
        type: INVALIDATE_DIRECCIONESENTREGA
    }
}

function requestDireccionesEntrega() {
    return {
        type: REQUEST_DIRECCIONESENTREGA,
    }
}

function receiveDireccionesEntrega(json) {
    return {
        type: RECEIVE_DIRECCIONESENTREGA,
    direccionesEntrega: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorDireccionesEntrega(error) {
    return {
        type: ERROR_DIRECCIONESENTREGA,
        error: error,
    }
}

export function resetDireccionesEntrega() {
    return {
        type: RESET_DIRECCIONESENTREGA
    }
}

export function fetchDireccionesEntrega(filtros) {
    return dispatch => {
        dispatch(requestDireccionesEntrega());
        return direccionesEntregaApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveDireccionesEntrega(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorDireccionesEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDireccionesEntrega(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchDireccionesEntrega(state) {
    const direccionesEntrega = state.direccionesEntrega.byId;
    if (!direccionesEntrega) {
        return true
    } else if (direccionesEntrega.isFetching) {
        return false
    } else {
        return direccionesEntrega.didInvalidate;
    }
}

export function fetchDireccionesEntregaIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchDireccionesEntrega(getState())) {
            return dispatch(fetchDireccionesEntrega(filtros))
        }
    }
}


//MODEL
export const REQUEST_DIRECCIONENTREGA = 'REQUEST_DIRECCIONENTREGA';
export const RECEIVE_DIRECCIONENTREGA = 'RECEIVE_DIRECCIONENTREGA';
export const INVALIDATE_DIRECCIONENTREGA = 'INVALIDATE_DIRECCIONENTREGA';
export const ERROR_DIRECCIONENTREGA = "ERROR_DIRECCIONENTREGA";
export const RESET_DIRECCIONENTREGA = "RESET_DIRECCIONENTREGA";


export function invalidateDireccionEntrega() {
    return {
        type: INVALIDATE_DIRECCIONENTREGA
    }
}

function requestDireccionEntrega() {
    return {
        type: REQUEST_DIRECCIONENTREGA,
    }
}

function receiveDireccionEntrega(json) {
    return {
        type: RECEIVE_DIRECCIONENTREGA,
    direccionEntrega: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorDireccionEntrega(error) {
    return {
        type: ERROR_DIRECCIONENTREGA,
        error: error,
    }
}

export function fetchDireccionEntrega(idDireccionEntrega) {
    return dispatch => {
        dispatch(requestDireccionEntrega());
        return direccionesEntregaApi.getOne(idDireccionEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveDireccionEntrega(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorDireccionEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorDireccionEntrega(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_DIRECCIONENTREGA = 'UPDATE_DIRECCIONENTREGA';
export const REQUEST_UPDATE_DIRECCIONENTREGA = "REQUEST_UPDATE_DIRECCIONENTREGA";
export const SUCCESS_UPDATE_DIRECCIONENTREGA = "SUCCESS_UPDATE_DIRECCIONENTREGA";
export const ERROR_UPDATE_DIRECCIONENTREGA = "ERROR_UPDATE_DIRECCIONENTREGA";
export const RESET_UPDATE_DIRECCIONENTREGA = "RESET_UPDATE_DIRECCIONENTREGA";

function requestUpdateDireccionEntrega() {
    return {
        type: REQUEST_UPDATE_DIRECCIONENTREGA,
    }
}

function receiveUpdateDireccionEntrega(direccionEntrega) {
    return {
        type: SUCCESS_UPDATE_DIRECCIONENTREGA,
        receivedAt: Date.now(),
        direccionEntrega: normalizeDato(direccionEntrega)
    }
}

function errorUpdateDireccionEntrega(error) {
    return {
        type: ERROR_UPDATE_DIRECCIONENTREGA,
        error: error,
    }
}

export function resetUpdateDireccionEntrega() {
    return {
        type: RESET_UPDATE_DIRECCIONENTREGA,
    }
}

export function updateDireccionEntrega(direccionEntrega) {
    return {
        type: UPDATE_DIRECCIONENTREGA,
        direccionEntrega
    }
}

export function saveUpdateDireccionEntrega() {
    return (dispatch, getState) => {
        dispatch(requestUpdateDireccionEntrega());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let direccionEntrega = denormalizeDato(getState().direccionesEntrega.update.activo, store);

        return direccionesEntregaApi.saveUpdate(direccionEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateDireccionEntrega(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateDireccionEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorUpdateDireccionEntrega(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorUpdateDireccionEntrega(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorUpdateDireccionEntrega(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}

//UPDATE DIRECCIONESENTREGA
export const REQUEST_UPDATE_DIRECCIONESENTREGA = "REQUEST_UPDATE_DIRECCIONESENTREGA";
export const SUCCESS_UPDATE_DIRECCIONESENTREGA = "SUCCESS_UPDATE_DIRECCIONESENTREGA";
export const ERROR_UPDATE_DIRECCIONESENTREGA = "ERROR_UPDATE_DIRECCIONESENTREGA";
export const RESET_UPDATE_DIRECCIONESENTREGA = "RESET_UPDATE_DIRECCIONESENTREGA";

function requestUpdateDireccionesEntrega() {
    return {
        type: REQUEST_UPDATE_DIRECCIONESENTREGA,
}
}

function receiveUpdateDireccionesEntrega(direccionesEntrega) {
    return {
        type: SUCCESS_UPDATE_DIRECCIONESENTREGA,
    receivedAt: Date.now(),
        direccionesEntrega: normalizeDatos(direccionesEntrega)
}
}

function errorUpdateDireccionesEntrega(error) {
    return {
        type: ERROR_UPDATE_DIRECCIONESENTREGA,
    error: error,
}
}

export function resetUpdateDireccionesEntrega() {
    return {
        type: RESET_UPDATE_DIRECCIONESENTREGA,
}
}

export function saveUpdateDireccionesEntrega() {
    return (dispatch, getState) => {
        dispatch(requestUpdateDireccionesEntrega());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let direccionesEntrega = getState().direccionesEntrega.update.activos.map((idDireccionEntrega) => {
            return denormalizeDato(getState().direccionesEntrega.byId.direccionesEntrega[idDireccionEntrega], store);
        });

        return direccionesEntregaApi.saveUpdateDireccionesEntrega(direccionesEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateDireccionesEntrega(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateDireccionesEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorUpdateDireccionesEntrega(JSON.parse(error.responseJSON.message)));
                    else
                        try {
                            error.json().then((error) => {
                                dispatch(errorUpdateDireccionesEntrega(JSON.parse(error.message)));
                            });
                        } catch {
                            dispatch(errorUpdateDireccionesEntrega(errorMessages.GENERAL_ERROR));
                        }


                        return;
                }
            });
    }
}


//ALTA DIRECCIONENTREGA
export const CREATE_DIRECCIONENTREGA = 'CREATE_DIRECCIONENTREGA';
export const REQUEST_CREATE_DIRECCIONENTREGA = "REQUEST_CREATE_DIRECCIONENTREGA";
export const SUCCESS_CREATE_DIRECCIONENTREGA = "SUCCESS_CREATE_DIRECCIONENTREGA";
export const ERROR_CREATE_DIRECCIONENTREGA = "ERROR_CREATE_DIRECCIONENTREGA";
export const RESET_CREATE_DIRECCIONENTREGA = "RESET_CREATE_DIRECCIONENTREGA";


//ALTA DIRECCIONENTREGA
function requestCreateDireccionEntrega() {
    return {
        type: REQUEST_CREATE_DIRECCIONENTREGA,
    }
}

function receiveCreateDireccionEntrega(direccionEntrega) {
    return {
        type: SUCCESS_CREATE_DIRECCIONENTREGA,
        receivedAt: Date.now(),
        direccionEntrega: normalizeDato(direccionEntrega)
    }
}

export function errorCreateDireccionEntrega(error) {
    return {
        type: ERROR_CREATE_DIRECCIONENTREGA,
        error: error,
    }
}

export function resetCreateDireccionEntrega() {
    return {
        type: RESET_CREATE_DIRECCIONENTREGA
    }
}

export function createDireccionEntrega(direccionEntrega) {
    return {
        type: CREATE_DIRECCIONENTREGA,
        direccionEntrega
    }
}

export function saveCreateDireccionEntrega() {
    return (dispatch, getState) => {
        dispatch(requestCreateDireccionEntrega());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let direccionEntrega = denormalizeDato(getState().direccionesEntrega.create.nuevo, store);

        return direccionesEntregaApi.saveCreate(direccionEntrega)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateDireccionEntrega(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateDireccionEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorCreateDireccionEntrega(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorCreateDireccionEntrega(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorCreateDireccionEntrega(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}

//DELETE DIRECCIONENTREGA
export const DELETE_DIRECCIONENTREGA = 'DELETE_DIRECCIONENTREGA';
export const REQUEST_DELETE_DIRECCIONENTREGA = "REQUEST_DELETE_DIRECCIONENTREGA";
export const SUCCESS_DELETE_DIRECCIONENTREGA = "SUCCESS_DELETE_DIRECCIONENTREGA";
export const ERROR_DELETE_DIRECCIONENTREGA = "ERROR_DELETE_DIRECCIONENTREGA";
export const RESET_DELETE_DIRECCIONENTREGA = "RESET_DELETE_DIRECCIONENTREGA";

function requestDeleteDireccionEntrega() {
    return {
        type: REQUEST_DELETE_DIRECCIONENTREGA,
    }
}

function receiveDeleteDireccionEntrega(direccionEntrega) {
    return {
        type: SUCCESS_DELETE_DIRECCIONENTREGA,
        receivedAt: Date.now(),
        direccionEntrega: normalizeDato(direccionEntrega)
    }
}

function errorDeleteDireccionEntrega(error) {
    return {
        type: ERROR_DELETE_DIRECCIONENTREGA,
        error: error,
    }
}

export function resetDeleteDireccionEntrega(error) {
    return {
        type: RESET_DELETE_DIRECCIONENTREGA,
        error: error,
    }
}

export function deleteDireccionEntrega(direccionEntrega) {
    return {
        type: DELETE_DIRECCIONENTREGA,
        direccionEntrega
    }
}

export function saveDeleteDireccionEntrega(direccionEntrega) {
    return dispatch => {
        dispatch(requestDeleteDireccionEntrega());
        return direccionesEntregaApi.saveDelete(direccionEntrega)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteDireccionEntrega());
                            dispatch(receiveDeleteDireccionEntrega(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteDireccionEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorDeleteDireccionEntrega(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorDeleteDireccionEntrega(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorDeleteDireccionEntrega(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}