import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom'
//css
import '../../assets/css/SuperMercado.css';
//Actions
import {fetchCategoriasDestacadasIfNeeded, fetchCategoriasIfNeeded} from "../../actions/CategoriaActions"
import {createDetallePedido, deleteDetallePedido} from "../../actions/DetallePedidoActions";
import {saveCreateAnalitica} from "../../actions/AnaliticaActions";
//Components
import ProductoUnitario from "./componentes/Productos/ProductoUnitario";
import CarrouselDestacados from "./Carruseles/CarrouselDestacados";
import Skelleton from "./componentes/Skelleton/Skelleton";


class SuperMercado extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchCategoriasDestacadasIfNeeded({destacados: true});
        this.props.fetchCategoriasIfNeeded({orden: 1, publicar: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.fetchCategoriasDestacadasIfNeeded({destacados: true});
        this.props.fetchCategoriasIfNeeded({orden: 1, publicar: true});
    }

    render() {
        let innerWidth = window.innerWidth;


        return (
            <section className="supermercado" id="productos">
                {
                    innerWidth >= 769 &&
                    <React.Fragment>
                        <div className="productos">
                            {
                                this.props.categorias.byId.isFetchingDestacadas ?
                                    <Skelleton tipo="destacados"/>
                                    :
                                    this.props.categorias.allIds.allIdsDestacados.map((idCategoria, i) => {
                                        let categoria = this.props.categorias.byId.categorias[idCategoria];
                                        let producto = [];
                                        let detallePedido = [];
                                        if (categoria.destacado === 1 && categoria.producto && categoria.producto.length > 0)
                                            return (
                                                <div className="row-categoria" key={idCategoria}>
                                                    <div className="container-limit">
                                                        <Link to={`/search?cat=${idCategoria}`}
                                                              style={{color: "inherit", textDecoration: "none"}}>
                                                            <h4>{`${categoria.desCategoria}`}</h4>
                                                            <b>Ver todos</b>
                                                        </Link>
                                                    </div>
                                                    <CarrouselDestacados idCategoria={idCategoria}>
                                                        {
                                                            categoria.producto.map((idProducto) => {
                                                                producto = this.props.productos.byId.productos[idProducto];
                                                                detallePedido = this.props.detallesPedido.create.detallesPedido[producto.id + "-"];
                                                                let promocion = null;
                                                                let productoOrigenPromocion = null;
                                                                if (producto.producto_origen_promocion)
                                                                    producto.producto_origen_promocion.some((idProductoOrigenPromocion) => {
                                                                        productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                                                                        let promocionProducto = null;
                                                                        if (productoOrigenPromocion)
                                                                            promocionProducto = this.props.promociones.byId.promociones[productoOrigenPromocion.idPromocion];
                                                                        if (promocionProducto && promocionProducto.estado === 1 && promocionProducto.formatoPromocion !== "Combo") {
                                                                            promocion = promocionProducto;
                                                                            return true;
                                                                        } else
                                                                            return false;
                                                                    });
                                                                return (
                                                                    <div key={idProducto}>
                                                                        <ProductoUnitario
                                                                            key={idProducto}
                                                                            producto={producto}
                                                                            promocion={promocion}
                                                                            productoOrigenPromocion = {productoOrigenPromocion}
                                                                            detallePedido={detallePedido}
                                                                            toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}
                                                                            createDetallePedido={this.props.createDetallePedido.bind(this)}
                                                                            deleteDetallePedido={this.props.deleteDetallePedido.bind(this)}
                                                                            createAnalitica={this.props.saveCreateAnalitica.bind(this)}
                                                                        />
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </CarrouselDestacados>

                                                </div>
                                            );
                                    })
                            }
                        </div>
                    </React.Fragment>
                }
                {
                    innerWidth < 769 &&
                    <React.Fragment>
                        <div className="container-limit">
                            {
                                this.props.categorias.byId.isFetchingDestacadas ?
                                    <Skelleton tipo="destacados"/>
                                    :
                                    this.props.categorias.allIds.allIdsDestacados.map((idCategoria, i) => {
                                        let categoria = this.props.categorias.byId.categorias[idCategoria];
                                        let producto = [];
                                        let detallePedido = [];
                                        if (categoria.destacado === 1 && categoria.producto && categoria.producto.length > 0)
                                            return (
                                                <div className="row-categoria">
                                                    <Link to={`/search?cat=${idCategoria}`}
                                                          style={{color: "inherit", textDecoration: "none"}}>
                                                        <h4>{`${categoria.desCategoria}`}</h4>
                                                        <b>Ver todos</b>
                                                    </Link>
                                                    <div className="productos-hm">
                                                        {
                                                            categoria.producto.map((idProducto) => {
                                                                producto = this.props.productos.byId.productos[idProducto];
                                                                detallePedido = this.props.detallesPedido.create.detallesPedido[producto.id + "-"];
                                                                let promocion = null;
                                                                let productoOrigenPromocion = null;
                                                                if (producto.producto_origen_promocion)
                                                                    producto.producto_origen_promocion.some((idProductoOrigenPromocion) => {
                                                                        productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                                                                        let promocionProducto = null;
                                                                        if (productoOrigenPromocion)
                                                                            promocionProducto = this.props.promociones.byId.promociones[productoOrigenPromocion.idPromocion];
                                                                        if (promocionProducto && promocionProducto.estado === 1 && promocionProducto.formatoPromocion !== "Combo") {
                                                                            promocion = promocionProducto;
                                                                            return true;
                                                                        } else
                                                                            return false;
                                                                    });
                                                                return (
                                                                    <div>
                                                                        <ProductoUnitario
                                                                            key={idProducto}
                                                                            producto={producto}
                                                                            promocion={promocion}
                                                                            productoOrigenPromocion={productoOrigenPromocion}
                                                                            detallePedido={detallePedido}
                                                                            toogleBarraCarrito={this.props.toogleBarraCarrito.bind(this)}
                                                                            createDetallePedido={this.props.createDetallePedido.bind(this)}
                                                                            deleteDetallePedido={this.props.deleteDetallePedido.bind(this)}
                                                                            createAnalitica={this.props.saveCreateAnalitica.bind(this)}
                                                                        />
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            );
                                    })
                            }

                        </div>
                    </React.Fragment>
                }
            </section>

        );
    }
}

function

mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        comprasComunitarias: state.comprasComunitarias,
        categorias: state.categorias,
        promociones: state.promociones,
        productosOrigenPromocion: state.productosOrigenPromocion,

    };
}

const
    mapDispatchToProps = (dispatch) => {
        return {
            fetchCategoriasIfNeeded: (filtros) => {
                dispatch(fetchCategoriasIfNeeded(filtros))
            },
            fetchCategoriasDestacadasIfNeeded: (filtros) => {
                dispatch(fetchCategoriasDestacadasIfNeeded(filtros))
            },
            createDetallePedido: (detalle) => {
                dispatch(createDetallePedido(detalle))
            },
            deleteDetallePedido: (detalle) => {
                dispatch(deleteDetallePedido(detalle))
            },
            saveCreateAnalitica: (analitica, tipo) => {
                dispatch(saveCreateAnalitica(analitica, tipo))
            },
        }
    };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuperMercado));

