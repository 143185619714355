import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {
    const usuario = new schema.Entity('usuarios', {}, {idAttribute: "id"});
    const direccionEntrega = new schema.Entity('direccionesEntrega', {}, {idAttribute: "id"});
    const estadoPedido = new schema.Entity('estadosPedido', {}, {idAttribute: "id"});
    const estadoEntrega = new schema.Entity('estadosEntrega', {}, {idAttribute: "id"});
    const compraComunitaria = new schema.Entity('comprasComunitarias', {}, {idAttribute: "id"});
    const detallePedido = new schema.Entity('detallesPedido', {compraComunitaria: compraComunitaria}, {idAttribute: "id"});
    const estadoRemito = new schema.Entity('estadosRemito', {}, {idAttribute: "id"});
    const detalleRemitido = new schema.Entity('detalleRemitido', {}, {idAttribute: "id"});
    const bulto = new schema.Entity('bultos', {}, {idAttribute: "id"});
    const remito = new schema.Entity('remitos', {
        estado_remito: [estadoRemito],
        detalle_remitido: [detalleRemitido],
        bulto: [bulto]
    }, {idAttribute: "id"});
    const pedido = new schema.Entity('pedidos',
        {
            usuario: usuario,
            direccion_entrega: direccionEntrega,
            estado_pedido: [estadoPedido],
            estado_entrega: [estadoEntrega],
            detalle_pedido: [detallePedido],
            remito: [remito]
        }, {idAttribute: "id"});
    const mySchema = {pedidos: [pedido]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export function normalizeDato(myData) {
    const usuario = new schema.Entity('usuarios', {}, {idAttribute: "id"});
    const direccionEntrega = new schema.Entity('direccionesEntrega', {}, {idAttribute: "id"});
    const estadoPedido = new schema.Entity('estadosPedido', {}, {idAttribute: "id"});
    const estadoEntrega = new schema.Entity('estadosEntrega', {}, {idAttribute: "id"});
    const producto = new schema.Entity('productos', {}, {idAttribute: "id"});
    const compraComunitaria = new schema.Entity('comprasComunitarias', {}, {idAttribute: "id"});
    const detallePedido = new schema.Entity('detallesPedido', {
        compra_comunitaria: compraComunitaria,
        producto: producto
    }, {idAttribute: "id"});
    const estadoRemito = new schema.Entity('estadosRemito', {}, {idAttribute: "id"});
    const detalleRemitido = new schema.Entity('detalleRemitido', {}, {idAttribute: "id"});
    const bulto = new schema.Entity('bultos', {}, {idAttribute: "id"});
    const remito = new schema.Entity('remitos', {
        estado_remito: [estadoRemito],
        detalle_remitido: [detalleRemitido],
        bulto: [bulto]
    }, {idAttribute: "id"});
    const pedido = new schema.Entity('pedidos',
        {
            usuario: usuario,
            direccion_entrega: direccionEntrega,
            estado_pedido: [estadoPedido],
            estado_entrega: [estadoEntrega],
            detalle_pedido: [detallePedido],
            remito: [remito]
        }, {idAttribute: "id"});
    const mySchema = pedido;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

