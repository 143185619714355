export const SERVICEWORKER_INIT = 'SERVICEWORKER_INIT';
export const SERVICEWORKER_UPDATE = 'SERVICEWORKER_UPDATE';

export function serviceWorkerUpdate(registration) {
    console.log("Action Updateads");
    return {
        type: SERVICEWORKER_UPDATE,
        payload: registration,
        receivedAt: Date.now()
    }
}

export function serviceWorkerInit() {
    console.log("Action Init");
    return {
        type: SERVICEWORKER_INIT,
    }
}

