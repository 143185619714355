import {RECEIVE_CATEGORIAS,} from "../actions/CategoriaActions";
import {RECEIVE_PRODUCTOS} from "../actions/ProductoActions";
import {ERROR_MARCAS, INVALIDATE_MARCAS, RECEIVE_MARCAS, REQUEST_MARCAS, RESET_MARCAS} from "../actions/MarcaActions";
import {combineReducers} from 'redux';

import merge from "lodash/merge";
import {RECEIVE_BUSQUEDA, RESET_BUSQUEDA} from "../actions/BusquedaActions";


function marcasById(state = {
    isFetching: false,
    didInvalidate: true,
    marcas: {}
}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            let dato = action.categorias.entities.marcas;
            return Object.assign({}, state, {
                marcas: merge({}, state.marcas, dato),
            });
        case RECEIVE_PRODUCTOS:
            let datoProductos = action.productos.entities.marcas;
            return Object.assign({}, state, {
                marcas: merge({}, state.marcas, datoProductos),
            });
        case RECEIVE_BUSQUEDA:
            let datosBusqueda = action.busqueda.entities.marcas;
            return Object.assign({}, state, {
                marcas: merge({}, state.marcas, datosBusqueda),
            });
        case INVALIDATE_MARCAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_MARCAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case RECEIVE_MARCAS:
            let datoMarcas = action.marcas.entities.marcas;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                marcas: datoMarcas ? merge({}, state.marcas, datoMarcas) : {},
                lastUpdated: action.receivedAt
            });
        case ERROR_MARCAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_MARCAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                marcas: {}
            });
        default:
            return state
    }
}


function allMarcas(state = {allIds: [], allIdsBuscados: []}, action) {
    switch (action.type) {
        case RECEIVE_MARCAS:
            return Object.assign({}, state, {
                allIds: action.marcas.result,
            });
        case RECEIVE_BUSQUEDA:
            return Object.assign({}, state, {
                allIdsBuscados: action.busqueda && action.busqueda.result.marcas ? action.busqueda.result.marcas : [],
            });
        case RESET_BUSQUEDA:
            return Object.assign({}, state, {
                allIdsBuscados: [],
            });
        case RESET_MARCAS:
            return Object.assign({}, state, {
                allIds: [],
                allIdsBuscados: [],
            });
        default:
            return state
    }
}


const marcas = combineReducers({
    byId: marcasById,
    allIds: allMarcas
});

export default marcas;