import c from '../constants/Constants';

require('es6-promise').polyfill();
require('isomorphic-fetch');


var remitos = {

    update(remito) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(remito)
        };

        return fetch(c.BASE_URL + '/remitos/' + remito.id, defaultOptions);
    },

    generarOrdenPedidoEnvio(datos) {
        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(datos)
        };

        return fetch(c.BASE_URL + '/pedidoEnvio/', defaultOptions);
    },

    getByIdRemito(idRemito) {

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/remitos/' + idRemito, defaultOptions);
    }
};

export default remitos;