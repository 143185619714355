import React, {Component} from 'react';

import '../../assets/css/ComoComprar.css';
//img
import encarga from '../../assets/images/encarga.png';
import espera from '../../assets/images/espera.png';
import recibi from '../../assets/images/recibi.png';
import {ConfiguracionContext} from "../../utils/Context";

//constants


class ComoComprar extends Component {

    componentDidMount() {
    }

    render() {
        let Configuracion = this.context;
        return (
            <section className="como-comprar" id="proceso">
                <div className="container-limit">
                    <h2 className="titulo-seccion titulo-home">
                        ¿CÓMO <i>COMPRAR</i>?
                    </h2>
                    <div className="container-features">
                        <div className="feature encarga">
                            <img src={encarga} alt=""/>
                            <h6>ELEGÍ</h6>
                            <p>{Configuracion.COMO_COMPRAR_PASO1 ? Configuracion.COMO_COMPRAR_PASO1.texto1 : ""}</p>
                            <p className="feature-info">
                                {Configuracion.COMO_COMPRAR_PASO1 ? Configuracion.COMO_COMPRAR_PASO1.texto2 : ""}
                            </p>
                        </div>
                        <div className="feature espera">
                            <img src={espera} alt=""/>
                            <div>
                                <h6>ESPERÁ</h6>
                                <p>{Configuracion.COMO_COMPRAR_PASO2 ? Configuracion.COMO_COMPRAR_PASO2.texto1 : ""}</p>
                                <p className="feature-info">
                                    {Configuracion.COMO_COMPRAR_PASO2 ? Configuracion.COMO_COMPRAR_PASO2.texto2 : ""}
                                </p>
                            </div>
                        </div>
                        <div className="feature recibi">
                            <img src={recibi} alt=""/>
                            <div>
                                <h6>RECIBÍ</h6>
                                <p>{Configuracion.COMO_COMPRAR_PASO3 ? Configuracion.COMO_COMPRAR_PASO3.texto1 : ""}</p>
                                <p className="feature-info">
                                    {Configuracion.COMO_COMPRAR_PASO3 ? Configuracion.COMO_COMPRAR_PASO3.texto2 : ""}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

ComoComprar.contextType = ConfiguracionContext;
export default ComoComprar;
