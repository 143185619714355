require('es6-promise').polyfill();
require('isomorphic-fetch');


var promociones = {

    calcularPromociones(detallePedidoAgregar, detallePedidoEliminar, state) {
        let detallePedidoACambiar = detallePedidoAgregar[Object.keys(detallePedidoAgregar)[0]];
        let detallePedidoACambiarEliminar = detallePedidoEliminar[Object.keys(detallePedidoEliminar)[0]];
        let producto = {};
        if (detallePedidoACambiar && detallePedidoACambiar.idProducto)
            producto = state.productos.byId.productos[detallePedidoACambiar.idProducto];
        else if (detallePedidoACambiarEliminar && detallePedidoACambiarEliminar.idProducto)
            producto = state.productos.byId.productos[detallePedidoACambiarEliminar.idProducto];
        //Evaluar promociones del producto
        if (producto && producto.producto_origen_promocion)
            producto.producto_origen_promocion.forEach((idProductoOrigenPromocion) => {
                let productoOrigenPromocion = state.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                if (productoOrigenPromocion) {
                    let promocion = state.promociones.byId.promociones[productoOrigenPromocion.idPromocion];
                    let cantidadAcumulada = 0;
                    let totalAcumulado = new Map();
                    let combo = true;
                    //Evaluar promocion
                    let cantidadComboTotal = 0;
                    if (promocion)
                        promocion.producto_origen_promocion.forEach((idProductoOrigenPromocion) => {
                            let encontrado = false;
                            let productoOrigenPromocion = state.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                            if (detallePedidoACambiar && detallePedidoACambiar.idProducto == productoOrigenPromocion.idProducto) {
                                cantidadAcumulada += detallePedidoACambiar.cantidad;
                                totalAcumulado.set(productoOrigenPromocion.idProducto, detallePedidoACambiar.cantidad * detallePedidoACambiar.precioUnitario);
                                if (detallePedidoACambiar.cantidad >= productoOrigenPromocion.cantidad) {
                                    let cantidadCombo = Math.floor(detallePedidoACambiar.cantidad / productoOrigenPromocion.cantidad);
                                    if (cantidadComboTotal === 0 || cantidadComboTotal > cantidadCombo)
                                        cantidadComboTotal = cantidadCombo;
                                    encontrado = true;
                                }
                            }

                            Object.keys(state.detallesPedido.create.detallesPedido).forEach((idDetallePedido) => {
                                let detallePedidoGuardado = state.detallesPedido.create.detallesPedido[idDetallePedido];
                                if (detallePedidoGuardado.idProducto == productoOrigenPromocion.idProducto
                                    & !detallePedidoGuardado.idPromocion && (!detallePedidoACambiar || detallePedidoACambiar.idProducto !== detallePedidoGuardado.idProducto)
                                    && (Object.keys(detallePedidoEliminar).length == 0 || Object.keys(detallePedidoEliminar)[0] != idDetallePedido)) {
                                    cantidadAcumulada += detallePedidoGuardado.cantidad;
                                    totalAcumulado.set(productoOrigenPromocion.idProducto, detallePedidoGuardado.cantidad * detallePedidoGuardado.precioUnitario);
                                    if (detallePedidoGuardado.cantidad >= productoOrigenPromocion.cantidad) {
                                        let cantidadCombo = Math.floor(detallePedidoGuardado.cantidad / productoOrigenPromocion.cantidad);
                                        if (cantidadComboTotal === 0 || cantidadComboTotal > cantidadCombo)
                                            cantidadComboTotal = cantidadCombo;
                                        encontrado = true;
                                    }
                                }
                            })
                            if (!encontrado)
                                combo = false;
                        });
                    let promocionAutorizada = false;
                    if (promocion)
                        if (promocion.formatoPromocion === "Combo")
                            promocionAutorizada = combo;
                        else
                            promocionAutorizada = cantidadAcumulada >= promocion.cantidad;

                    //Aplicar promocion
                    if (promocion)
                        for (let i = 0; i < promocion.producto_destino_promocion.length; i++) {
                            let idProductoDestinoPromocion = promocion.producto_destino_promocion[i];
                            let productoDestinoPromocion = state.productosDestinoPromocion.byId.productosDestinoPromocion[idProductoDestinoPromocion];
                            let detalle = {};
                            detalle["idProducto"] = productoDestinoPromocion.idProducto;
                            detalle["idPromocion"] = promocion.id;
                            if (promocion.tipoPromocion === "Descuento") {
                                detalle["cantidad"] = 1;
                                detalle.precioUnitario = -((totalAcumulado.get(productoDestinoPromocion.idProducto) * productoDestinoPromocion.descuento) / 100).toFixed(2);
                            } else {
                                if (promocion.formatoPromocion === "Combo")
                                    detalle["cantidad"] = productoDestinoPromocion.cantidad * cantidadComboTotal;
                                else
                                    detalle["cantidad"] = Math.floor(cantidadAcumulada / promocion.cantidad) * productoDestinoPromocion.cantidad;
                                detalle.precioUnitario = 0;
                            }
                            if (promocion.tipoPromocion === "Descuento" && !totalAcumulado.get(productoDestinoPromocion.idProducto)) {
                                detallePedidoEliminar[productoDestinoPromocion.idProducto + "-" + promocion.id] = detalle;
                                continue;
                            }
                            if (promocionAutorizada) {
                                detallePedidoAgregar[productoDestinoPromocion.idProducto + "-" + promocion.id] = detalle;
                            } else {
                                detallePedidoEliminar[productoDestinoPromocion.idProducto + "-" + promocion.id] = detalle;
                            }
                        }
                }
            });
    },
    calcularPromocionCodigoPromocional(detallePedido, promocion, detallesPedido, productosDestinoPromocion) {
        let detalle = {};
        detalle.cantidad = 1;
        detalle.idPromocion = promocion.id;
        if (promocion.producto_destino_promocion.length === 0 && promocion.descuento && promocion.descuento > 0) {
            let total = 0;
            Object.keys(detallesPedido.create.detallesPedido).forEach((idDetallePedido) => {
                let detallePedidoGuardado = detallesPedido.create.detallesPedido[idDetallePedido];
                if (detallePedidoGuardado.idPromocion != promocion.id && !detallePedidoGuardado.idFormaPago)
                    total = total + detallePedidoGuardado.precioUnitario * detallePedidoGuardado.cantidad;
            });
            console.log("TOTAL", total, parseFloat(promocion.descuento));
            detalle.precioUnitario = -((total * parseFloat(promocion.descuento)) / 100);
            detalle["desDetallePedido"] = promocion.nomPromocion;
            detallePedido[promocion.id + "-cupon"] = detalle;
        } else {
            for (let i = 0; i < promocion.producto_destino_promocion.length; i++) {
                let idProductoDestinoPromocion = promocion.producto_destino_promocion[i];
                let productoDestinoPromocion = productosDestinoPromocion.byId.productosDestinoPromocion[idProductoDestinoPromocion];
                let detalle = {};
                detalle["idProducto"] = productoDestinoPromocion.idProducto;
                detalle["idPromocion"] = promocion.id;
                if (promocion.tipoPromocion === "Descuento") {
                    detalle["cantidad"] = 1;
                    //todo
                    //detalle.precioUnitario = -((totalAcumulado.get(productoDestinoPromocion.idProducto) * productoDestinoPromocion.descuento) / 100).toFixed(2);
                } else {
                    detalle["cantidad"] = productoDestinoPromocion.cantidad;
                    detalle.precioUnitario = 0;
                }
                detallePedido[productoDestinoPromocion.idProducto + "-" + promocion.id + "-cupon"] = detalle;
            }
        }
    }
};

export default promociones;