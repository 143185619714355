import React from 'react';
//css
import '../../assets/css/MisDatos.css';
import {useSelector} from "react-redux";
//Actions
//Components
//img

export default function MisDatos() {
    const auth = useSelector(state => state.auth);

    return (
        <div className="mis-datos">
            <div className="titulo-configuracion">
                <h2>Mis Datos</h2>
            </div>

            <div className="cuerpo-configuracion">
                <div className="formulario-container">
                    <label htmlFor="nombre">Nombre:</label>
                    <input type="text" id="nombre" name="nombre" value={auth.usuarioLogueado.nombre} disabled/>
                    <label htmlFor="nombre">Apellido:</label>
                    <input type="text" id="apellido" name="apellido" value={auth.usuarioLogueado.apellido} disabled/>
                    <label htmlFor="nombre">Email:</label>
                    <input type="text" id="email" name="email" value={auth.usuarioLogueado.email} disabled/>
                </div>
            </div>

        </div>
    )
}

