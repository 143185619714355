import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
//Router
import {Switch} from 'react-router';
import {Route, Router} from 'react-router-dom'
import history from './history';
//Redux
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import appReducers from './reducers/index';
import {createLogger} from 'redux-logger';
import {Provider} from 'react-redux';
//Components
import Layout from './components/plataforma/layout/Layout';

import * as serviceWorker from './serviceWorker';
//LocaleStorage
import {saveToLS} from "./localeStorage/ls";
import {serviceWorkerInit, serviceWorkerUpdate} from "./actions/ServiceWorkerActions";
//CSS
import 'swiper/css/swiper.css';
import Configuracion from "./utils/Configuracion";
//Sentry error tracking
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";


// Creates the Redux reducer with the redux-thunk middleware, which allows us
// to do asynchronous things in the actions
var createStoreWithMiddleware = null;
if (process.env.NODE_ENV !== 'production') {
    const loggerMiddleware = createLogger();
    createStoreWithMiddleware = applyMiddleware(thunk, loggerMiddleware)(createStore);
    console.log("Modo Debug");
} else {
    console.log("Modo Producción activado");
    Sentry.init({
        dsn: "https://420ce8d592494236a6aeda925eda1073@o584209.ingest.sentry.io/5737142",
        integrations: [new Integrations.BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.8,
    });

    createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

//chau console.los metodo 4 de esta pagina https://stapp.space/how-to-remove-javascript-console-log-pros-2/
    (function () {
        var method;
        var noop = function noop() {
        };
        var methods = [
            'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
            'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
            'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
            'timeStamp', 'trace', 'warn'
        ];
        var length = methods.length;
        var console = (window.console = window.console || {});

        while (length--) {
            method = methods[length];
            console[method] = noop;
        }
    }());
}

const store = createStoreWithMiddleware(appReducers);

store.subscribe(function () {
    saveToLS("detallesPedido", store.getState().detallesPedido);
    saveToLS("pedidos", store.getState().pedidos);
    saveToLS("productos", store.getState().productos);
    saveToLS("comprasComunitarias", store.getState().comprasComunitarias);
});

const getConfiguracion = async () => {
    await Configuracion.getConfiguracion();
}

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Switch>
                <Route path="/" component={Layout}/>
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
        onSuccess: () => store.dispatch(serviceWorkerInit()),
        onUpdate: registration => {
            store.dispatch(serviceWorkerUpdate(registration));
        }
    }
);
