import * as errorMessages from '../constants/MessageConstants';
//Actions
import {fetchPedidosIfNeeded, invalidatePedidos} from "./PedidoActions";
import {logout} from "../actions/AuthActions";
//api
import estadosPedidoU from "../api/estadosPedido";
//import auth from "../api/auth";

//ESTADOPEDIDO
export const CREATE_ESTADOPEDIDO = 'CREATE_ESTADOPEDIDO';
export const REQUEST_CREATE_ESTADOPEDIDO = "REQUEST_CREATE_ESTADOPEDIDO";
export const SUCCESS_CREATE_ESTADOPEDIDO = "SUCCESS_CREATE_ESTADOPEDIDO";
export const ERROR_CREATE_ESTADOPEDIDO = "ERROR_CREATE_ESTADOPEDIDO";
export const RESET_CREATE_ESTADOPEDIDO = "RESET_CREATE_ESTADOPEDIDO";

function requestEstadoPedido() {
    return {
        type: REQUEST_CREATE_ESTADOPEDIDO,
    }
}

function receiveEstadoPedido() {
    return {
        type: SUCCESS_CREATE_ESTADOPEDIDO,
        receivedAt: Date.now()
    }
}

function errorEstadoPedido(error) {
    return {
        type: ERROR_CREATE_ESTADOPEDIDO,
        error: error,
    }
}

export function resetEstadoPedido(error) {
    return {
        type: RESET_CREATE_ESTADOPEDIDO,
        error: error,
    }
}

export function createEstadoPedido(idPedido, estadoPedido) {
    return {
        type: CREATE_ESTADOPEDIDO,
        id: idPedido,
        estadoPedido
    }
}

export function saveEstadoPedido(estadoPedido) {
    return dispatch => {
        dispatch(requestEstadoPedido());
        return estadosPedidoU.save(estadoPedido)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveEstadoPedido());
                    dispatch(invalidatePedidos());
                    dispatch(fetchPedidosIfNeeded({id: estadoPedido.idPedido}));
                    //Refresco token
                    //auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorEstadoPedido(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        error.json()
                            .then((error) => {
                                if (error.message != "")
                                    dispatch(errorEstadoPedido(error.message));
                                else
                                    dispatch(errorEstadoPedido(errorMessages.GENERAL_ERROR));
                            })
                            .catch((err) => {
                                dispatch(errorEstadoPedido(errorMessages.GENERAL_ERROR));
                            });
                        return;
                }
            });
    }
}