import React, {Component} from 'react';
import {ReactComponent as Cart} from "../../assets/images/cart.svg";
import '../../assets/css/Nav.css';
import {Link, withRouter} from 'react-router-dom';
import {NavHashLink as NavLink} from 'react-router-hash-link'
import {ReactComponent as Arrow} from "../../assets/images/right-arrow.svg";
import {ReactComponent as Lupa} from "../../assets/images/lupa.svg";
import authUtil from "../../utils/auth";
import perfil from '../../assets/images/account.png';
import {logout} from "../../actions/AuthActions";
import BuscadorDesplegable from "./componentes/BuscadorDesplegable";
import {connect} from 'react-redux';
import {fetchBusquedaProductos, resetBusquedaProductos} from "../../actions/BusquedaActions";
//Utils
import Hammer from 'react-hammerjs';
import {saveCreateAnalitica} from "../../actions/AnaliticaActions";
import * as TipoAnalitica from "../../constants/TipoAnalitica";
import {ConfiguracionContext} from "../../utils/Context";
import Select, {components} from "react-select";
import {fetchMonedasIfNeeded} from "../../actions/MonedaActions";

var Fuse = require('fuse.js');


class Nav extends Component {

    constructor(props) {
        super(props);
        this.itemsMenu = [
            {link: "/quienesSomos", nombre: "QUIENES SOMOS"},
            {link: "/#productos", nombre: "PRODUCTOS", isBig: true},
            {link: "/#proceso", nombre: "PROCESO DE COMPRA"},
            {link: "/#faqs", nombre: "PREGUNTAS FRECUENTES"},
            {link: "/#zonaEntrega", nombre: "ZONA ENTREGA"},
            {link: "/#contacto", nombre: "CONTACTO"}
        ];
        this.state = {
            visibleDropDown: false,
            searchPhase: "",
            productos: [],
            marcas: [],
            categorias: [],
            visibleBuscador: false,
            showMenu: false,
        };

        this.inputSearch = null;
    }

    componentDidMount() {
        this.props.fetchMonedasIfNeeded();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let cantAnterior = 0;
        Object.values(prevProps.detallesPedido.create.detallesPedido).map(
            key => cantAnterior += key.cantidad
        );
        let cantActual = 0;
        Object.values(this.props.detallesPedido.create.detallesPedido).map(
            key => cantActual += key.cantidad
        );
        let cantRef = this.refs.cantRef;

        if (cantAnterior != cantActual) {
            setTimeout(() => {
                cantRef.classList.remove("animate");
            }, 200);
            cantRef.classList.add("animate");

        }
        if (this.props.match.params !== prevProps.match.params) {
            this.toogleVisibleBuscador(false);
        }
    }

    toggleMenu() {
        this.setState(prevState => ({
            showMenu: !prevState.showMenu,
        }));
    }

    toogleVisibleBuscador(estado, e) {
        if (e)
            e.preventDefault();
        this.setState({visibleBuscador: estado});
    }

    changeSearch(e) {
        const WAIT_INTERVAL = 500;

        clearTimeout(this.timer);

        this.setState({searchPhase: e.target.value});

        this.timer = setTimeout(() => {
            let filtros = {
                searchPhase: this.state.searchPhase,
                limit: 4,
                sugerencias: true,
                publicar: true

            };
            if (this.state.searchPhase !== "" && this.state.searchPhase.length > 3) {
                this.props.fetchBusquedaProductos(filtros);
                this.toogleVisibleBuscador(true);
            }


        }, WAIT_INTERVAL);
    }

    submitForm(e) {
        e.preventDefault();
        this.props.history.push("/search?sp=" + this.state.searchPhase);
        this.toogleVisibleBuscador(false);
        this.clearSearch();
    }

    clearSearch() {
        this.setState({searchPhase: ""});
        this.props.resetBusquedaProductos();
        this.inputSearch.focus();

    }

    formatMiniTotal(total) {
        let totalSplit = total.split('.');
        return (<div>$<span className="moneda"></span><span className="intPart">{totalSplit[0]}</span><span
            className="decPart">{totalSplit[1]}</span></div>)
    }


    showBuscadorResponsive() {
        // window.scrollTo(0, this.state.prevScrollPos);
        this.inputSearch.focus();
        setTimeout(() => this.inputSearch.focus(), 600);
    }

    render() {
        const tienda = this.props.tiendas.update.activo;
        let total = 0;
        if (this.props.pedidos.create.nuevo.detalle_pedido) {
            let idDetallesOrdenados = this.props.pedidos.create.nuevo.detalle_pedido;
            idDetallesOrdenados.forEach((idDetallePedido) => {
                let detallePedido = this.props.detallesPedido.create.detallesPedido[idDetallePedido];
                if (detallePedido.idCompraComunitaria) {
                    let compraComunitaria = this.props.comprasComunitarias.byId.comprasComunitarias[detallePedido.idCompraComunitaria];
                    if (!compraComunitaria)
                        return;

                    //Cálculo de totales
                    total = total + detallePedido.precioUnitario * detallePedido.cantidad;
                } else {
                    //Cálculo de totales
                    total = total + detallePedido.precioUnitario * detallePedido.cantidad;
                }

            });
        }
        let menu = this.itemsMenu.map((item, index) => {
            return (
                <li className="item-menu" key={index}>
                    <NavLink onClick={() => {
                        this.toggleMenu()
                    }} smooth scroll={e => e.scrollIntoView({
                        behavior: "smooth",
                        block: item.isBig == true ? "start" : "center"
                    })} to={item.link} className={item.clase}>{item.nombre}</NavLink>
                </li>
            )
        });

        let cantProductos = 0;
        Object.values(this.props.detallesPedido.create.detallesPedido).map(
            key => {
                cantProductos += key.cantidad;
            }
        );

        let usuarioLogueado = this.props.auth.usuarioLogueado;

        let idMonedaDefault = 0;
        let opcionesMoneda = this.props.monedas.allIds.map((idMoneda, index) => {
            let moneda = this.props.monedas.byId.monedas[idMoneda];
            if (moneda.default === 1)
                idMonedaDefault = index;
            return {value: moneda.nomMoneda, label: moneda.nomMoneda + " " + moneda.simbolo}
        });

        const SingleValue = ({children, ...props}) => (
            <components.SingleValue {...props}>{children}</components.SingleValue>
        );

        return (
            <div className="menu-nav">
                <nav className="nav-bar"
                    // style={{
                    //     backgroundImage: `url(${process.env.PUBLIC_URL}/images/nav/nav-bg-puntomarket.png)`,
                    // }}
                >
                    <div className="container-limit">
                        <div className="container-nav">
                            <div className="container-logo">
                                <NavLink to="/">
                                    {
                                        tienda && tienda.logo &&
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/logos/${tienda.logo}`}
                                            className="logo"/>
                                    }
                                </NavLink>
                            </div>
                            <div className="buscador-container">
                                <form className="buscador-input hidden-714"
                                      onSubmit={(e) => this.submitForm(e)}
                                      autoComplete="off">
                                    <Lupa className="buscador-lupa" style={{width: "15px", height: "15px"}}/>

                                    <input id="buscar" type="text" placeholder="Buscar"
                                           value={this.state.searchPhase}
                                           onChange={(e) => this.changeSearch(e)}
                                           onFocus={() => {
                                               this.toogleVisibleBuscador(true);
                                           }}
                                           autoComplete="off"/>
                                    <button className="search-clear"
                                            style={{
                                                display: this.state.searchPhase.length > 0 ? "block" : "none",
                                                marginRight: "72px"
                                            }}
                                            type="button"
                                            onClick={() => this.clearSearch()}
                                    >
                                        &#10006;
                                    </button>
                                    <button type="submit">Buscar</button>
                                </form>
                                <BuscadorDesplegable productosSeleccionados={this.state.productos}
                                                     marcasSeleccionadas={this.state.marcas}
                                                     categoriasSeleccionadas={this.state.categorias}
                                                     visible={this.state.visibleBuscador}
                                                     toogleVisibleBuscador={this.toogleVisibleBuscador.bind(this)}
                                                     searchPhase={this.state.searchPhase}/>
                            </div>
                            <div className="container-menu">
                                <ul>
                                    {!authUtil.isLoggedIn() &&
                                    <li className="hidden-900">
                                        <NavLink to={"/login"}
                                                 onClick={() => this.props.saveCreateAnalitica(null, TipoAnalitica.INICIO_SESION_POSIBLE_ID)}>
                                            Iniciar sesión
                                        </NavLink>
                                    </li>
                                    }
                                    {!authUtil.isLoggedIn() &&
                                    <li className="hidden-900 button-signup">
                                        <NavLink to={"/signup"} style={{}}
                                                 onClick={() => this.props.saveCreateAnalitica(null, TipoAnalitica.REGISTRO_POSIBLE_ID)}>
                                            Registrate
                                        </NavLink>
                                    </li>
                                    }
                                    <li className="hidden-900">
                                        <div style={{
                                            height: "42px",
                                            width: "9px",
                                            marginLeft: "4px"
                                        }} className="separadores"></div>
                                    </li>
                                    <li className="item-burguer-menu" onClick={() => this.toggleMenu()}>
                                        <div className="burguer-menu">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </li>
                                    <li className="show-900">
                                        <div style={{
                                            height: "42px",
                                            width: "9px",
                                            marginLeft: "19px",
                                        }} className="separadores"></div>
                                    </li>
                                    <li>
                                        <div style={{display: "flex", cursor: "pointer"}}>
                                            <a onClick={() => this.props.toogleBarraCarrito()}
                                               style={{position: "relative"}}>
                                                <Cart className="cart"
                                                      style={{width: "20px", height: "20px", cursor: "pointer"}}/>
                                                <div ref="cantRef" className="cant-items"
                                                     style={{display: cantProductos > 0 ? "block" : "none"}}>
                                                    <p>{cantProductos}</p>
                                                </div>
                                            </a>
                                            <span onClick={() => this.props.toogleBarraCarrito()}
                                                  style={{
                                                      display: total > 0 ? "block" : "none"
                                                  }}
                                                  className="span-precio-nav"
                                            >{this.formatMiniTotal(total.toFixed(2))}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="buscador-container responsive"
                     style={{display: "block"}}>
                    <form className="buscador-input" onSubmit={(e) => this.submitForm(e)}
                          autoComplete="off">
                        <Lupa className="buscador-lupa" style={{width: "15px", height: "15px"}}/>

                        <input id="buscar" type="text" placeholder="Buscar" ref={(input) => {
                            this.inputSearch = input;
                        }}
                               value={this.state.searchPhase}
                               onChange={(e) => this.changeSearch(e)}
                               onFocus={(e) => {
                                   this.toogleVisibleBuscador(true, e);
                               }}
                               autoComplete="off"/>
                        <button className="search-clear"
                                style={{display: this.state.searchPhase.length > 0 ? "block" : "none"}}
                                type="button"
                                onClick={() => this.clearSearch()}
                        >
                            &#10006;
                        </button>
                    </form>
                </div>
                <Hammer
                    onSwipe={() => {
                        this.toggleMenu()
                    }}
                    direction="DIRECTION_RIGHT"
                >
                    <div ref="menuRes"
                         className={`menu-res ${this.state.showMenu ? "show" : ""}`}
                    >
                        <div className="container-menu-res">
                            <div className="titulo-menu-res">
                                <h6>MENU</h6>
                                <Arrow className="arrow-menu" onClick={() => this.toggleMenu()}/>
                            </div>
                            {!authUtil.isLoggedIn() &&
                            <div className="titulo-menu-login">
                                <Link onClick={() => {
                                    this.toggleMenu();
                                    this.props.saveCreateAnalitica(null, TipoAnalitica.INICIO_SESION_POSIBLE_ID);
                                }} to={"/login"}>
                                    Iniciar Sesión
                                </Link>
                                <Link onClick={() => {
                                    this.toggleMenu();
                                    this.props.saveCreateAnalitica(null, TipoAnalitica.REGISTRO_POSIBLE_ID);
                                }} className="registrate" to={"/signup"}>Registrate</Link>
                            </div>
                            }
                            {authUtil.isLoggedIn() &&
                            <div className="menu-usuario">
                                <img src={perfil} alt="foto de usuario"/>
                                <div className="perfil-usuario">
                                    <p>{usuarioLogueado ? usuarioLogueado.nombre + " " + usuarioLogueado.apellido : ""}</p>
                                    <button onClick={() => {
                                        this.props.history.push("/miCuenta/pedidos");
                                        this.toggleMenu();
                                    }}>
                                        MI CUENTA
                                    </button>
                                    {
                                        opcionesMoneda.length > 1 &&
                                        <div className="selector-moneda">
                                            <Select
                                                defaultValue={opcionesMoneda[idMonedaDefault]}
                                                styles={{
                                                    control: () => ({
                                                        border: "none",
                                                        display: "flex",
                                                        cursor: "pointer",
                                                        justifyContent: "flex-start",
                                                        alignItems: "center"
                                                    }),
                                                    input: () => ({
                                                        opacity: 0
                                                    }),
                                                    valueContainer: () => ({
                                                        paddingLeft: 0,
                                                        height: "10px",
                                                    }),
                                                    indicatorsContainer: () => ({
                                                        paddingRight: 0,
                                                    }),
                                                    indicatorSeparator: () => ({
                                                        display: "none",
                                                    }),
                                                    singleValue: base => ({
                                                        ...base,
                                                        position: "relative",
                                                        maxWidth: "inherit",
                                                        padding: "3px 9px",
                                                        fontSize: "11px",
                                                        borderRadius: 4,
                                                        color: 'white',
                                                        display: 'flex',
                                                        backgroundColor: "gray",
                                                        margin: 0,
                                                    }),
                                                }}
                                                isSearchable={false}
                                                components={{SingleValue}}
                                                name={"selectorMoneda"}
                                                options={opcionesMoneda}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            }
                            <ul>
                                {menu}
                                {authUtil.isLoggedIn() &&
                                <li className="item-menu">
                                    <NavLink onClick={() => {
                                        this.props.logOut();
                                        this.toggleMenu();
                                    }} to={"/"}>CERRAR SESIÓN</NavLink>
                                </li>
                                }
                            </ul>
                        </div>
                    </div>
                </Hammer>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        pedidos: state.pedidos,
        auth: state.auth,
        productos: state.productos,
        marcas: state.marcas,
        categorias: state.categorias,
        monedas: state.monedas,
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logOut: () => {
            dispatch(logout());
        },
        fetchBusquedaProductos: (filtros) => {
            return dispatch(fetchBusquedaProductos(filtros))
        },
        resetBusquedaProductos: () => {
            dispatch(resetBusquedaProductos())
        },
        // invalidateProductos: () => {
        //     dispatch(invalidateProducto())
        // },
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
        fetchMonedasIfNeeded: (filtros) => {
            dispatch(fetchMonedasIfNeeded(filtros))
        }
    }
};
Nav.contextType = ConfiguracionContext;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Nav));
