import React, {useState, useEffect, useRef} from 'react';
import {Route, Switch, Link} from 'react-router-dom';
import {useSelector, useDispatch} from "react-redux";
//css
import './Skelleton.css';

export default function Skelleton(props) {

    if (props.tipo === "destacados")
        return (
            <div className="row-categoria">
                <div className="container-limit">
                    <h4 className="skelleton-loader h2-loader"/>
                </div>
                <div className="productos-hm skelleton">
                    <div className="skelleton-loader p-loader"/>
                    <div className="skelleton-loader p-loader"/>
                    <div className="skelleton-loader p-loader"/>
                    <div className="skelleton-loader p-loader"/>
                </div>
                <div className="container-limit">
                    <h4 className="skelleton-loader h2-loader"/>
                </div>
                <div className="productos-hm skelleton">
                    <div className="skelleton-loader p-loader"/>
                    <div className="skelleton-loader p-loader"/>
                    <div className="skelleton-loader p-loader"/>
                    <div className="skelleton-loader p-loader"/>
                </div>
            </div>
        );

    if (props.tipo === "categorias") {
        let categorias = [];
        for (var i = 0; i < 11; i++) {
            categorias.push(
                <a href="" key={i}>
                    <li className="skelleton-loader categorias"></li>
                </a>
            );
        }
        return categorias;
    }

    if (props.tipo === "productos")
        return (
            <React.Fragment>
                <div className="skelleton-loader buscad p-loader"/>
                <div className="skelleton-loader buscad p-loader"/>
                <div className="skelleton-loader buscad p-loader"/>
                <div className="skelleton-loader buscad p-loader"/>
                <div className="skelleton-loader buscad p-loader"/>
                <div className="skelleton-loader buscad p-loader"/>
                <div className="skelleton-loader buscad p-loader"/>
                <div className="skelleton-loader buscad p-loader"/>
                <div className="skelleton-loader buscad p-loader"/>
            </React.Fragment>
        );

    if (props.tipo === "direccionEntrega")
        return (
            <React.Fragment>
                <div className="skelleton-loader todo-loader"/>
            </React.Fragment>
        );

}

