//api
import analiticas from "../api/analiticas"
//constants


//ALTA ANALITICA
export const CREATE_ANALITICA = 'CREATE_ANALITICA';
export const REQUEST_CREATE_ANALITICA = "REQUEST_CREATE_ANALITICA";
export const SUCCESS_CREATE_ANALITICA = "SUCCESS_CREATE_ANALITICA";
export const ERROR_CREATE_ANALITICA = "ERROR_CREATE_ANALITICA";
export const RESET_CREATE_ANALITICA = "RESET_CREATE_ANALITICA";


//ALTA ANALITICA
function requestCreateAnalitica() {
    return {
        type: REQUEST_CREATE_ANALITICA,
    }
}

function receiveCreateAnalitica(json) {
    return {
        type: SUCCESS_CREATE_ANALITICA,
        receivedAt: Date.now()
    }
}

export function errorCreateAnalitica(error) {
    return {
        type: ERROR_CREATE_ANALITICA,
        error: error,
    }
}

export function resetCreateAnalitica() {
    return {
        type: RESET_CREATE_ANALITICA
    }
}

export function createAnalitica(producto) {
    return {
        type: CREATE_ANALITICA,
        producto
    }
}

export function saveCreateAnalitica(analitica, tipoAnalitica) {
    return (dispatch, getState) => {
        dispatch(requestCreateAnalitica());
        return analiticas.saveCreate({analitica: analitica, idTipoAnalitica: tipoAnalitica})
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveCreateAnalitica(response));
                }
            })
            .catch(function (error) {
                console.log(error, error.status);
            });
    }
}
