import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom"
import '../../../../assets/css/LoginSignUpDistribuidor.css';
//Actions
import {
    createUsuario,
    errorCreateUsuario,
    resetCreateUsuario,
    saveCreateUsuario
} from '../../../../actions/UsuarioActions';
//components
import Loader from "./../../layout/Loader"
//Actions
import {fetchZonasEntregaIfNeeded} from "../../../../actions/ZonasEntregaActions";
import {saveCreateAnalitica} from "../../../../actions/AnaliticaActions";
//Constants
import * as CondicionIVA from "../../../../constants/CondicionIVA";
import * as TipoAnalitica from "../../../../constants/TipoAnalitica";
import * as Rol from "../../../../constants/Rol";
//Util
import authUtil from "../../../../utils/auth";
//Components
import DireccionEntregaRegistro from "./DireccionEntregaRegistro";
import CampoTexto from "../../componentes/CampoTexto";
//Actions
import {resetCreateDireccionEntrega} from "../../../../actions/DireccionEntregaActions";

const pasosMinorista = [1, 2, 3, 4, 5];
const pasosConsumidorFinal = [1, 2, 5];

class SignUpDistribuidor extends Component {
    constructor(props) {
        super(props);
        this.state = {paso: 1};
        this.paso1 = React.createRef();
        this.paso2 = React.createRef();
    }

    componentDidMount() {
        document.body.classList.add("no-scroll");
        window.scrollTo(0, 0)
        this.props.fetchZonasEntregaIfNeeded({});
    }

    componentWillUnmount() {
        document.body.classList.remove("no-scroll");
        document.body.style.overflowY = "scroll";
        this.props.resetCreateUsuario();
        this.props.resetCreateDireccionEntrega();
    }

    onChangeUsuario(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        let usuario = this.props.usuarios.create.nuevo;
        if (e.target.id === "idRol" && e.target.value == Rol.CONSUMIDOR_FINAL_ID && usuario && usuario.nombre && usuario.apellido) {
            cambio.razonSocial = usuario.nombre + " " + usuario.apellido;
            cambio.idCondicionIva = CondicionIVA.CONSUMIDOR_FINAL_ID;
        } else if ((e.target.id === "idRol" && e.target.value != Rol.CONSUMIDOR_FINAL_ID)) {
            cambio.razonSocial = "";
            cambio.idCondicionIva = null;
        } else if (e.target.id === "idCondicionIva")
            cambio.razonSocial = "";
        this.props.createUsuario(cambio);
    }

    submitRegistro(e) {
        e.preventDefault();
        this.props.saveCreateUsuario(this.props.usuarios.create.nuevo);
        this.props.saveCreateAnalitica(null, TipoAnalitica.REGISTRO_ID);
    }

    cierraModal(e) {
        let modal = document.getElementById('modal-sign-up') ? document.getElementById('modal-sign-up').contains(e.target) : null
        if (!modal) {
            this.props.history.push("/")
        }
    }


    siguiente(e) {
        e.preventDefault();
        if (e.target.getElementsByTagName("input").length == 0 || ((e.target.getElementsByTagName("input").length == 1 &&
            e.target.getElementsByTagName("input")[0].id !== "direccion") || e.target.getElementsByTagName("input").length > 1)) {

            let pasoSiguiente = 1;
            const usuario = this.props.usuarios.create.nuevo;
            if (usuario.idRol == Rol.MINORISTA_ID) {
                let index = pasosMinorista.indexOf(this.state.paso);
                pasoSiguiente = pasosMinorista[index + 1];
            } else {
                let index = pasosConsumidorFinal.indexOf(this.state.paso);
                pasoSiguiente = pasosConsumidorFinal[index + 1];
            }
            this.setState({paso: pasoSiguiente});
        }
        this.props.errorCreateUsuario(null);
    }

    atras() {
        let pasoAnterior = 1;
        const usuario = this.props.usuarios.create.nuevo;
        if (usuario.idRol == Rol.MINORISTA_ID) {
            let index = pasosMinorista.indexOf(this.state.paso);
            pasoAnterior = pasosMinorista[index - 1];
        } else {
            let index = pasosConsumidorFinal.indexOf(this.state.paso);
            pasoAnterior = pasosConsumidorFinal[index - 1];
        }
        this.setState({paso: pasoAnterior});
        this.props.errorCreateUsuario(null);
    }


    render() {
        const {paso} = this.state;
        const usuario = this.props.usuarios.create.nuevo;
        const direccionEntrega = this.props.direccionesEntrega.create.nuevo;
        let zonaEntrega = {};
        if (direccionEntrega.idZonaEntrega)
            zonaEntrega = this.props.zonasEntrega.byId.zonasEntrega[direccionEntrega.idZonaEntrega];
        let tienda = this.props.tiendas.update.activo;
        //error de alta de usuario
        let Errores = [];
        let error = this.props.usuarios.create.error;
        try {
            let err = JSON.parse(error);
            if (err && typeof (err) == "object") {
                Errores = Object.values(err).map((er, index) => {
                    return <p key={er[0] + index}>{er[0]}</p>;
                });
            }
        } catch (e) {
            Errores = <p>{error}</p>
        }

        let opcionesCondicionesIVA = [];
        opcionesCondicionesIVA.push(<option value={""}>Seleccione condición</option>);
        opcionesCondicionesIVA.push(<option
            value={CondicionIVA.CONSUMIDOR_FINAL_ID}>{CondicionIVA.CONSUMIDOR_FINAL}</option>);
        opcionesCondicionesIVA.push(<option
            value={CondicionIVA.RESPONSABLE_INSCRIPTO_ID}>{CondicionIVA.RESPONSABLE_INSCRIPTO}</option>);
        opcionesCondicionesIVA.push(<option
            value={CondicionIVA.RESPONSABLE_MONOTRIBUTO_ID}>{CondicionIVA.RESPONSABLE_MONOTRIBUTO}</option>);
        opcionesCondicionesIVA.push(<option value={CondicionIVA.EXENTO_ID}>{CondicionIVA.EXENTO}</option>);
        // opcionesCondicionesIVA.push(<option
        //     value={CondicionIVA.RESPONSABLE_NO_INSCIPTO_ID}>{CondicionIVA.RESPONSABLE_NO_INSCIPTO}</option>);
        // opcionesCondicionesIVA.push(<option
        //     value={CondicionIVA.NO_RESPONSABLE_ID}>{CondicionIVA.NO_RESPONSABLE}</option>);
        // opcionesCondicionesIVA.push(<option
        //     value={CondicionIVA.EXPORTACION_ID}>{CondicionIVA.EXPORTACION}</option>);
        // opcionesCondicionesIVA.push(<option
        //     value={CondicionIVA.IMPORTACION_ID}>{CondicionIVA.IMPORTACION}</option>);
        // opcionesCondicionesIVA.push(<option value={0}>No se ha podido verificar la condición de IVA</option>);

        return (
            <section className="login-sign-up-distribuidor">
                <div className="container-modal" id={"modal-sign-up"}>
                    <div className="container-doble">
                        <div className="col-3 container-marca">
                            {
                                window.innerWidth < 714 &&
                                <div className="cruz-cerrar" onClick={() => {
                                    this.props.history.push("/")
                                }}>&times;</div>
                            }
                            {
                                tienda && tienda.logoBlanco &&
                                <img src={`${process.env.PUBLIC_URL}/images/logos/${tienda && tienda.logoBlanco ? tienda.logoBlanco : ""}`}
                                    className="logo"/>
                            }
                            {
                                !authUtil.isLoggedIn() &&
                                <div className="container-yaregistrado">
                                    <p>
                                        Si ya estás registrado sólo inicia sesión
                                    </p>
                                    <Link to="/login">
                                        <button>
                                            INICIAR SESIÓN
                                        </button>
                                    </Link>
                                </div>
                            }
                        </div>
                        <div className="col-9">
                            <div className="container-datos">
                                {
                                    window.innerWidth > 714 &&
                                    <div className="cruz-cerrar" onClick={() => {
                                        this.props.history.push("/")
                                    }}>&times;</div>
                                }
                                <h2>CREAR <i>CUENTA</i></h2>

                                <div className="general"
                                     style={{display: this.props.usuarios.create.mensaje ? "none" : "flex"}}>
                                    <div id="paso1" ref={this.paso1} style={{display: paso === 1 ? "inline" : "none"}}>
                                        <form onSubmit={(e) => this.siguiente(e)}>
                                            <p>¿Tenés negocio?</p>
                                            <select required={true} id="idRol"
                                                    value={usuario.idRol ? usuario.idRol : ""}
                                                    onChange={(e) => this.onChangeUsuario(e)}>
                                                <option value={""}>¿Tenés negocio?</option>
                                                <option value={Rol.MINORISTA_ID}>¡Sí! Tengo negocio</option>
                                                <option value={Rol.CONSUMIDOR_FINAL_ID}>No tengo negocio</option>
                                            </select>
                                            {
                                                !this.props.usuarios.create.isCreating && (paso === 1 || paso === 2 || paso === 3 || paso === 4) &&
                                                <button type="submit">SIGUIENTE</button>
                                            }
                                        </form>
                                    </div>
                                    <div id="paso2" ref={this.paso2} style={{display: paso === 2 ? "inline" : "none"}}>
                                        <form onSubmit={(e) => this.siguiente(e)}>
                                            <p>Usá tu e-mail para registrarte</p>
                                            <CampoTexto nombre="Nombre *" type="text" id="nombre" name="nombre"
                                                        required={true}
                                                        value={usuario.nombre}
                                                        handleChange={(e) => this.onChangeUsuario(e)}/>
                                            <CampoTexto nombre="Apellido *" type="text" id="apellido" name="apellido"
                                                        required={true}
                                                        value={usuario.apellido}
                                                        handleChange={(e) => this.onChangeUsuario(e)}/>
                                            <CampoTexto nombre="E-mail *" type="text" id="email" name="email"
                                                        required={true}
                                                        value={usuario.email}
                                                        handleChange={(e) => this.onChangeUsuario(e)}/>
                                            <CampoTexto nombre="Contraseña *" type="password" id="password"
                                                        minLength="8"
                                                        required={true}
                                                        value={usuario.password}
                                                        handleChange={(e) => this.onChangeUsuario(e)}/>
                                            <CampoTexto nombre="Repetir contraseña *" type="password"
                                                        id="password_confirmation" minLength="8" required={true}
                                                        value={usuario.password_confirmation}
                                                        handleChange={(e) => this.onChangeUsuario(e)}/>
                                            {
                                                usuario.idRol == Rol.MINORISTA_ID &&
                                                <CampoTexto nombre="Celular *" type="number" id="celular" name="celular"
                                                            required={true}
                                                            value={usuario.celular}
                                                            handleChange={(e) => this.onChangeUsuario(e)}/>
                                            }
                                            <div className="botonera">
                                                {
                                                    !this.props.usuarios.create.isCreating && (paso === 3 || paso === 2 || paso === 4) &&
                                                    <button type="button" onClick={() => this.atras()}>ATRÁS</button>
                                                }
                                                {
                                                    !this.props.usuarios.create.isCreating && (paso === 1 || paso === 2 || paso === 3) &&
                                                    <button type="submit">SIGUIENTE</button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                    <div id="paso3" ref={this.paso3} style={{display: paso === 3 ? "inline" : "none"}}>
                                        <form onSubmit={(e) => this.siguiente(e)}>
                                            <p>Datos de tu negocio</p>
                                            <select required={true} id="idCondicionIva"
                                                    value={usuario.idCondicionIva ? usuario.idCondicionIva : ""}
                                                    onChange={(e) => this.onChangeUsuario(e)}>
                                                {opcionesCondicionesIVA}
                                            </select>
                                            <CampoTexto
                                                nombre={usuario && usuario.idCondicionIva != CondicionIVA.CONSUMIDOR_FINAL_ID ? "Razón social *" : "Nombre y apellido"}
                                                type="text" id="razonSocial"
                                                name="razonSocial" required={true}
                                                value={usuario.razonSocial}
                                                handleChange={(e) => this.onChangeUsuario(e)}/>
                                            <CampoTexto
                                                nombre={usuario && usuario.idCondicionIva != CondicionIVA.CONSUMIDOR_FINAL_ID ? "Cuit (solo números sin guiones) *" : "DNI (solo números) *"}
                                                type="text" id="cuit" name="cuit" pattern="^[0-9]+$"
                                                required={true}
                                                value={usuario.cuit}
                                                handleChange={(e) => this.onChangeUsuario(e)}/>

                                            {/*<input placeholder="Teléfono" type="number" id="telefono" name="telefono"*/}
                                            {/*       required={false}*/}
                                            {/*       value={usuario.telefono}*/}
                                            {/*       onChange={(e) => this.onChangeUsuario(e)}/>*/}
                                            <div className="botonera">
                                                {
                                                    !this.props.usuarios.create.isCreating && (paso === 3 || paso === 2 || paso === 4) &&
                                                    <button type="button" onClick={() => this.atras()}>ATRÁS</button>
                                                }
                                                {
                                                    !this.props.usuarios.create.isCreating && (paso === 1 || paso === 2 || paso === 3) &&
                                                    <button type="submit">SIGUIENTE</button>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                    <div id="paso4" style={{display: paso === 4 ? "inline" : "none"}}>
                                        <form onSubmit={(e) => this.siguiente(e)}>
                                            <p>Dirección de entrega</p>
                                            <DireccionEntregaRegistro paso={paso}/>
                                            {
                                                direccionEntrega.direccionEntrega &&
                                                <div>
                                                    <p>Zona de entrega: <b>{zonaEntrega.nomZonaEntrega}</b></p>
                                                    <p className="diasEntrega">Día de entrega:
                                                        <b className={zonaEntrega.lunes === 1 ? "active" : ""}>L</b>
                                                        <b className={zonaEntrega.martes === 1 ? "active" : ""}>M</b>
                                                        <b className={zonaEntrega.miercoles === 1 ? "active" : ""}>M</b>
                                                        <b className={zonaEntrega.jueves === 1 ? "active" : ""}>J</b>
                                                        <b className={zonaEntrega.viernes === 1 ? "active" : ""}>V</b>
                                                        <b className={zonaEntrega.sabado === 1 ? "active" : ""}>S</b>
                                                        <b className={zonaEntrega.domingo === 1 ? "active" : ""}>D</b>
                                                    </p>
                                                </div>
                                            }
                                            <div className="botonera">
                                                {
                                                    !this.props.usuarios.create.isCreating && (paso === 3 || paso === 2 || paso === 4) &&
                                                    <button type="button" onClick={() => this.atras()}>ATRÁS</button>
                                                }
                                                {
                                                    !this.props.usuarios.create.isCreating && (paso === 1 || paso === 2 || paso === 3 || paso === 4) &&
                                                    <button type="submit">SIGUIENTE</button>
                                                }
                                                {
                                                    this.props.usuarios.create.isCreating &&
                                                    <Loader/>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                    <div id="paso5"
                                         style={{display: paso === 5 ? "inline" : "none", textAlign: "left"}}>
                                        <form onSubmit={(e) => this.submitRegistro(e)}>
                                            <p>Confirme sus datos</p>
                                            <p>Email: <b>{usuario.email}</b></p>
                                            <p>Nombre: <b>{usuario.nombre}</b></p>
                                            <p>Apellido: <b>{usuario.apellido}</b></p>
                                            {
                                                usuario.celular && usuario.idRol == Rol.MINORISTA_ID &&
                                                <p>Celular: <b>{usuario.celular}</b></p>
                                            }
                                            {
                                                usuario.razonSocial && usuario.idRol == Rol.MINORISTA_ID &&
                                                <p>Razón social: <b>{usuario.razonSocial}</b></p>
                                            }
                                            {
                                                usuario.cuit && usuario.idRol == Rol.MINORISTA_ID &&
                                                <p>CUIT: <b>{usuario.cuit}</b></p>
                                            }
                                            <br/>
                                            {
                                                direccionEntrega.calle &&
                                                <div>
                                                    <p>Calle: <b>{direccionEntrega.calle}</b></p>
                                                    <p>Número: <b>{direccionEntrega.numero ? direccionEntrega.numero : "<sin número>"}</b>
                                                    </p>
                                                    <p>Detalles: <b>{direccionEntrega.detalles ? direccionEntrega.detalles : "<sin detalles>"}</b>
                                                    </p>
                                                    <p>Localidad: <b>{direccionEntrega.localidad}</b></p>
                                                    <p>Provincia: <b>{direccionEntrega.provincia}</b></p>
                                                    <p>Cod postal: <b>{direccionEntrega.codPostal}</b></p>
                                                </div>
                                            }
                                            <div className="botonera">
                                                {
                                                    !this.props.usuarios.create.isCreating && (paso === 3 || paso === 2 || paso === 4 || paso === 5) &&
                                                    <button type="button" onClick={() => this.atras()}>ATRÁS</button>
                                                }
                                                {
                                                    !this.props.usuarios.create.isCreating && paso === 5 &&
                                                    <button type="submit" name={"registroCompleto"}>COMPLETAR
                                                        REGISTRO</button>
                                                }
                                                {
                                                    this.props.usuarios.create.isCreating &&
                                                    <Loader/>
                                                }
                                            </div>
                                        </form>
                                    </div>

                                    <div className="errores">{Errores}</div>


                                </div>
                                {
                                    this.props.usuarios.create.mensaje &&
                                    <div className="mensajeExito">
                                        <p>{this.props.usuarios.create.mensaje}</p>
                                        <Link to="/">
                                            <button>
                                                CONTINUAR
                                            </button>
                                        </Link>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        catedras: state.catedras,
        usuarios: state.usuarios,
        direccionesEntrega: state.direccionesEntrega,
        zonasEntrega: state.zonasEntrega,
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createUsuario: (usuario) => {
            dispatch(createUsuario(usuario))
        },
        saveCreateUsuario: (usuario) => {
            dispatch(saveCreateUsuario(usuario))
        },
        errorCreateUsuario: (error) => {
            dispatch(errorCreateUsuario(error))
        },
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
        fetchZonasEntregaIfNeeded: (filtros) => {
            dispatch(fetchZonasEntregaIfNeeded(filtros))
        },
        resetCreateUsuario: (filtros) => {
            dispatch(resetCreateUsuario())
        },
        resetCreateDireccionEntrega: (filtros) => {
            dispatch(resetCreateDireccionEntrega())
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpDistribuidor);

