export const loadFromLS = (itemDelReducer) => {
    try {
        const serializedData = localStorage.getItem(itemDelReducer)
        return JSON.parse(serializedData); // Si encontramos con exito nuestro storage lo devolvemos.
    } catch (error) {
        return null
    }
}
export const saveToLS = (itemDelReducer, data) => {
    try {
        let serializedData = JSON.stringify(data);
        localStorage.setItem(itemDelReducer, serializedData)
    } catch (error) {
        console.log(error);
        // Acá podemos capturar o crear cualquier log que deseemos en caso de que falle el salvado en el storage.
    }
}