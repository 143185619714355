import {normalize, schema, denormalize} from 'normalizr';

export function normalizeDatos(myData) {
    const tienda = new schema.Entity('tiendas', {}, {idAttribute: "id"});

    const mySchema = {tiendas: [tienda]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const tienda = new schema.Entity('tiendas', {}, {idAttribute: "id"});

    const mySchema = tienda;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const tienda = new schema.Entity('tiendas', {}, {idAttribute: "id"});

    const mySchema = tienda;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}


