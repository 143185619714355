import {combineReducers} from 'redux';

import merge from "lodash/merge";
import union from "lodash/union";
import {RECEIVE_PRODUCTO, RECEIVE_PRODUCTOS} from "../actions/ProductoActions";
import {RECEIVE_CATEGORIAS_DESTACADAS} from "../actions/CategoriaActions";
import {RECEIVE_BUSQUEDA} from "../actions/BusquedaActions";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        stocksVariedad: {},
    }
}

function stocksVariedadById(state = getInitialStateById(), action) {
    switch (action.type) {
        case RECEIVE_PRODUCTO:
            let datoProducto = action.producto.entities.stocksVariedad;
            return Object.assign({}, state, {
                stocksVariedad: merge({}, state.stocksVariedad, datoProducto),
                isFetching: false,
            });
        case RECEIVE_PRODUCTOS:
            let datoProductos = action.productos.entities.stocksVariedad;
            return Object.assign({}, state, {
                stocksVariedad: merge({}, state.stocksVariedad, datoProductos),
                isFetching: false,
            });
        case RECEIVE_CATEGORIAS_DESTACADAS:
            let datoCategorias = action.categorias.entities.stocksVariedad;
            return Object.assign({}, state, {
                stocksVariedad: merge({}, state.stocksVariedad, datoCategorias),
                isFetching: false,
            });
        case RECEIVE_BUSQUEDA:
            let datoBusqueda = action.busqueda.entities.stocksVariedad;
            return Object.assign({}, state, {
                stocksVariedad: merge({}, state.stocksVariedad, datoBusqueda),
                isFetching: false,
            });
        // case RESET_STOCKSVARIEDAD:
        //     return Object.assign({}, state, {
        //         isFetching: false,
        //         didInvalidate: true,
        //         error: null,
        //         lastUpdated: null,
        //         stocksVariedad: {}
        //     });
        //
        default:
            return state
    }
}


function allProductosOrigenProducto(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTO:
            let result = [];
            if (action.producto.entities.stocksVariedad)
                result = Object.keys(action.producto.entities.stocksVariedad).map(function (x) {
                    return parseInt(x);
                });
            return action.producto.entities.stocksVariedad ? result : [];
        case RECEIVE_PRODUCTOS:
            let results = [];
            if (action.productos.entities.stocksVariedad)
                results = Object.keys(action.productos.entities.stocksVariedad).map(function (x) {
                    return parseInt(x);
                });
            return action.productos.entities.stocksVariedad ? union(state, results) : [];
        case RECEIVE_CATEGORIAS_DESTACADAS:
            let resultsCat = [];
            if (action.categorias.entities.stocksVariedad)
                resultsCat = Object.keys(action.categorias.entities.stocksVariedad).map(function (x) {
                    return parseInt(x);
                });
            return action.categorias.entities.stocksVariedad ? union(state, resultsCat) : [];
        case RECEIVE_BUSQUEDA:
            let resultStockVariedad = [];
            if (action.busqueda.entities.stocksVariedad)
                resultStockVariedad = Object.keys(action.busqueda.entities.stocksVariedad).map(function (x) {
                    return parseInt(x);
                });
            return action.busqueda.entities.stocksVariedad ? union(state, resultStockVariedad) : [];
        // case RESET_STOCKSVARIEDAD:
        //     return [];
        default:
            return state
    }
}

const stocksVariedad = combineReducers({
    byId: stocksVariedadById,
    allIds: allProductosOrigenProducto,
});

export default stocksVariedad;