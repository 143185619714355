import c from '../constants/Constants';

require('es6-promise').polyfill();
require('isomorphic-fetch');


var bultos = {

    saveCreate(bultos) {

        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(bultos)
        };

        return fetch(c.BASE_URL + '/bultos', defaultOptions);
    },
    saveUpdate(bultos) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(bultos)
        };

        return fetch(c.BASE_URL + '/bultos/1', defaultOptions);
    },
    saveDelete(bultos) {

        let defaultOptions = {
            url: '',
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(bultos)
        };

        return fetch(c.BASE_URL + '/bultos/1', defaultOptions);
    },
};

export default bultos;