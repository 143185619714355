import {RECEIVE_FORMASPAGO} from '../actions/FormaPagoActions';
import {combineReducers} from 'redux';
import {normalize, schema} from 'normalizr';

function normalizeDatos(myData) {
    const formasPagoListasPrecio = new schema.Entity('formasPagoListasPrecio');
    const mySchema = [formasPagoListasPrecio];
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

function formasPagoListasPrecioById(state = {
    isFetching: false,
    didInvalidate: true,
    formasPagoListasPrecio: []
}, action) {
    switch (action.type) {
        case RECEIVE_FORMASPAGO:
            let dato = action.formasPago.entities.formasPagoListasPrecio;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                formasPagoListasPrecio: dato ? dato : [],
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
}


function allFormasPagoListasPrecio(state = [], action) {
    switch (action.type) {
        case RECEIVE_FORMASPAGO:
            return action.formasPago.entities.formasPagoListasPrecio ? Object.keys(action.formasPago.entities.formasPagoListasPrecio) : [];
        default:
            return state
    }
}


const formasPagoListasPrecio = combineReducers({
    byId: formasPagoListasPrecioById,
    allIds: allFormasPagoListasPrecio
});

export default formasPagoListasPrecio;