import React, {Component} from 'react';
import $ from 'jquery';
import c from '../../constants/Constants';

import '../../assets/css/Contacto.css';


class Contacto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                origen: "Contacto",
                nombre: "",
                email: "",
                celular: "",
                mensaje: "",
            },
            error: null,
            enviando: null,

        };

        this.submitForm = this.submitForm.bind(this);
        this.changeForm = this.changeForm.bind(this);
    }

    submitForm(e) {
        e.preventDefault();
        $.ajax({
            url: c.BASE_URL + "/contacto",
            type: 'POST',
            data: JSON.stringify(this.state.form),
            contentType: 'application/json',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            beforeSend: function () {
                this.setState({enviando: true});
            }.bind(this),
            cache: false,
            success: function (data) {
                this.setState({error: false});
                this.setState({enviando: false});
            }.bind(this),
            // Fail..
            error: function (xhr, status, err) {
                alert("Ocurrio un error, intente nuevamente mas tarde ");
                this.setState({error: true});
                this.setState({enviando: false});
            }.bind(this)
        });
    }


    changeForm(e) {
        var cambio = this.state.form;
        cambio[e.target.id] = e.target.value;
        this.setState({form: cambio});
    }

    render() {
        return (
            <section className="contacto" id="contacto">
                <div className="container-limit">
                    <h2 className="titulo-seccion titulo-home">
                        CONTACTO
                    </h2>
                    <br/><br/>
                    <p>¡Escribinos por cualquier duda, inquietud o sugerencia!</p>
                    <form onSubmit={(e) => this.submitForm(e)}>
                        <div className="container-doble">
                            <div className="col-6 container-inputs">
                                <input id="nombre" type="text" placeholder="Nombre y Apellido"
                                       onChange={(e) => this.changeForm(e)} required/>
                                <input id="email" type="email" placeholder="E-mail"
                                       onChange={(e) => this.changeForm(e)} required/>
                                <input id="celular" type="tel" placeholder="Celular"
                                       onChange={(e) => this.changeForm(e)}/>
                            </div>
                            <div className="col-6 container-inputs">
                                <textarea name="mensaje" id="mensaje" placeholder="Mensaje"
                                          onChange={(e) => this.changeForm(e)} required></textarea>
                            </div>
                        </div>
                        {this.state.enviando ?
                            <p className="btn-enviar saving">ENVIANDO<span>.</span><span>.</span><span>.</span></p>
                            :
                            <button type="submit" className="btn-enviar">ENVIAR</button>
                        }
                        {this.state.enviando == false && this.state.error == false ?
                            <p style={{fontSize: "14px"}}>&#10004; Consulta enviada correctamente!</p> : ""
                        }
                    </form>
                </div>
            </section>

        );
    }
}

export default Contacto;
