import {normalize, schema} from 'normalizr';

function normalizeDatos(myData) {
    const producto = new schema.Entity('producto', {}, {idAttribute: "id"});
    const estadoDetallePedido = new schema.Entity('estadosDetallePedido', {}, {idAttribute: "id"});
    const detallesPedido = new schema.Entity('detallesPedido', {
        producto: producto,
        estado_detalle_pedido: [estadoDetallePedido]
    });
    const mySchema = [detallesPedido];
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;