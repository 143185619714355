import React, {useContext} from 'react';
//css
import '../../assets/css/BarraInformacion.css';
import {ConfiguracionContext} from "../../utils/Context";
//Actions
//Components
//img
export default function BarraInformacion() {
    const Configuracion = useContext(ConfiguracionContext);

    let contenido = (Configuracion && Configuracion.BARRA_INFORMACION) ?
        Configuracion.BARRA_INFORMACION.map((key, i) => {
            return (
                <div className="info-feature" key={i}>
                    <div className="img-feature">
                        <img src={process.env.PUBLIC_URL + "/images/otros/" + key.icono} alt=""/>
                    </div>
                    <div className="txt-cont-feature">
                        <p>
                            {key.text1}
                        </p>
                        <p>
                            {key.text2}
                        </p>
                    </div>
                </div>
            )
        }) : "";

    return (
        <div className="container-limit">
            {
                Configuracion && Configuracion.BARRA_INFORMACION &&
                <div className="barra-informacion">
                    {contenido}
                </div>
            }
        </div>
    )
}

