import React, {Component} from 'react';
//css
import '../../../assets/css/CampoTextArea.css';


class CampoTextArea extends Component {
    render() {
        let {id, nombre, type, img, color, value, handleChange, autoComplete, required, name, onBlur, rows} = this.props;
        return (
            <div className="campoTexto">
                <label>
                {/*{img}*/}
                <textarea type={type} id={id} name={name} className="campoTexto-input"
                          style={color ? {color: color} : {color: "inherit"}} value={value}
                          onChange={(e) => {
                              if (handleChange) handleChange(e)
                          }} required={required}
                          onBlur={(e) => {
                              if (onBlur) onBlur(e)
                          }}
                          rows={rows ? rows : 3}
                />

                {/*<div className="campoTexto-linea" style={(color ? {borderColor: color} : {borderColor: ""})}/>*/}
                <span className={`campoTexto-label ${!!value && value !== "" ? "show" : ""}`}
                       // style={color ? {color: color} : {color: "inherit"}}
                >{nombre}</span>
                </label>
            </div>
        );
    }
}

export default CampoTextArea;
