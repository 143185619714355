//api
import avisosApi from "../api/avisosApi"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "./AuthActions";
//normalizers
import {normalizeDatos, normalizeDato, denormalizeDato} from "../normalizers/normalizeAvisos";

//AVISOS
export const REQUEST_AVISOS = 'REQUEST_AVISOS';
export const RECEIVE_AVISOS = 'RECEIVE_AVISOS';
export const INVALIDATE_AVISOS = 'INVALIDATE_AVISOS';
export const ERROR_AVISOS = "ERROR_AVISOS";
export const RESET_AVISOS = "RESET_AVISOS";


export function invalidateAvisos() {
    return {
        type: INVALIDATE_AVISOS
    }
}

function requestAvisos() {
    return {
        type: REQUEST_AVISOS,
    }
}

function receiveAvisos(json) {
    return {
        type: RECEIVE_AVISOS,
    avisos: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorAvisos(error) {
    return {
        type: ERROR_AVISOS,
        error: error,
    }
}

export function resetAvisos() {
    return {
        type: RESET_AVISOS
    }
}

export function fetchAvisos(filtros) {
    return dispatch => {
        dispatch(requestAvisos());
        return avisosApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAvisos(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorAvisos(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAvisos(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchAvisos(state) {
    const avisos = state.avisos.byId;
    if (!avisos) {
        return true
    } else if (avisos.isFetching) {
        return false
    } else {
        return avisos.didInvalidate;
    }
}

export function fetchAvisosIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchAvisos(getState())) {
            return dispatch(fetchAvisos(filtros))
        }
    }
}


//MODEL
export const REQUEST_AVISO = 'REQUEST_AVISO';
export const RECEIVE_AVISO = 'RECEIVE_AVISO';
export const INVALIDATE_AVISO = 'INVALIDATE_AVISO';
export const ERROR_AVISO = "ERROR_AVISO";
export const RESET_AVISO = "RESET_AVISO";


export function invalidateAviso() {
    return {
        type: INVALIDATE_AVISO
    }
}

function requestAviso() {
    return {
        type: REQUEST_AVISO,
    }
}

function receiveAviso(json) {
    return {
        type: RECEIVE_AVISO,
    aviso: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorAviso(error) {
    return {
        type: ERROR_AVISO,
        error: error,
    }
}

export function fetchAviso(idAviso) {
    return dispatch => {
        dispatch(requestAviso());
        return avisosApi.getOne(idAviso)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveAviso(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorAviso(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorAviso(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_AVISO = 'UPDATE_AVISO';
export const REQUEST_UPDATE_AVISO = "REQUEST_UPDATE_AVISO";
export const SUCCESS_UPDATE_AVISO = "SUCCESS_UPDATE_AVISO";
export const ERROR_UPDATE_AVISO = "ERROR_UPDATE_AVISO";
export const RESET_UPDATE_AVISO = "RESET_UPDATE_AVISO";

function requestUpdateAviso() {
    return {
        type: REQUEST_UPDATE_AVISO,
    }
}

function receiveUpdateAviso(aviso) {
    return {
        type: SUCCESS_UPDATE_AVISO,
        receivedAt: Date.now(),
        aviso: normalizeDato(aviso)
    }
}

function errorUpdateAviso(error) {
    return {
        type: ERROR_UPDATE_AVISO,
        error: error,
    }
}

export function resetUpdateAviso() {
    return {
        type: RESET_UPDATE_AVISO,
    }
}

export function updateAviso(aviso) {
    return {
        type: UPDATE_AVISO,
        aviso
    }
}

export function saveUpdateAviso() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAviso());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let aviso = denormalizeDato(getState().avisos.update.activo, store);

        return avisosApi.saveUpdate(aviso)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAviso(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorUpdateAviso(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorUpdateAviso(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorUpdateAviso(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorUpdateAviso(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}

//UPDATE AVISOS
export const REQUEST_UPDATE_AVISOS = "REQUEST_UPDATE_AVISOS";
export const SUCCESS_UPDATE_AVISOS = "SUCCESS_UPDATE_AVISOS";
export const ERROR_UPDATE_AVISOS = "ERROR_UPDATE_AVISOS";
export const RESET_UPDATE_AVISOS = "RESET_UPDATE_AVISOS";

function requestUpdateAvisos() {
    return {
        type: REQUEST_UPDATE_AVISOS,
}
}

function receiveUpdateAvisos(avisos) {
    return {
        type: SUCCESS_UPDATE_AVISOS,
    receivedAt: Date.now(),
        avisos: normalizeDatos(avisos)
}
}

function errorUpdateAvisos(error) {
    return {
        type: ERROR_UPDATE_AVISOS,
    error: error,
}
}

export function resetUpdateAvisos() {
    return {
        type: RESET_UPDATE_AVISOS,
}
}

export function saveUpdateAvisos() {
    return (dispatch, getState) => {
        dispatch(requestUpdateAvisos());

        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let avisos = getState().avisos.update.activos.map((idAviso) => {
            return denormalizeDato(getState().avisos.byId.avisos[idAviso], store);
        });

        return avisosApi.saveUpdateAvisos(avisos)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateAvisos(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateAvisos(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorUpdateAvisos(JSON.parse(error.responseJSON.message)));
                    else
                        try {
                            error.json().then((error) => {
                                dispatch(errorUpdateAvisos(JSON.parse(error.message)));
                            });
                        } catch {
                            dispatch(errorUpdateAvisos(errorMessages.GENERAL_ERROR));
                        }


                        return;
                }
            });
    }
}


//ALTA AVISO
export const CREATE_AVISO = 'CREATE_AVISO';
export const REQUEST_CREATE_AVISO = "REQUEST_CREATE_AVISO";
export const SUCCESS_CREATE_AVISO = "SUCCESS_CREATE_AVISO";
export const ERROR_CREATE_AVISO = "ERROR_CREATE_AVISO";
export const RESET_CREATE_AVISO = "RESET_CREATE_AVISO";


//ALTA AVISO
function requestCreateAviso() {
    return {
        type: REQUEST_CREATE_AVISO,
    }
}

function receiveCreateAviso(aviso) {
    return {
        type: SUCCESS_CREATE_AVISO,
        receivedAt: Date.now(),
        aviso: normalizeDato(aviso)
    }
}

export function errorCreateAviso(error) {
    return {
        type: ERROR_CREATE_AVISO,
        error: error,
    }
}

export function resetCreateAviso() {
    return {
        type: RESET_CREATE_AVISO
    }
}

export function createAviso(aviso) {
    return {
        type: CREATE_AVISO,
        aviso
    }
}

export function saveCreateAviso() {
    return (dispatch, getState) => {
        dispatch(requestCreateAviso());
        let store = {};
        Object.keys(getState()).forEach(
            (key) => {
                if (getState()[key].byId)
                    store[key] = getState()[key].byId[key];
            });

        let aviso = denormalizeDato(getState().avisos.create.nuevo, store);

        return avisosApi.saveCreate(aviso)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateAviso(json));
            })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorCreateAviso(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorCreateAviso(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorCreateAviso(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorCreateAviso(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}

//DELETE AVISO
export const DELETE_AVISO = 'DELETE_AVISO';
export const REQUEST_DELETE_AVISO = "REQUEST_DELETE_AVISO";
export const SUCCESS_DELETE_AVISO = "SUCCESS_DELETE_AVISO";
export const ERROR_DELETE_AVISO = "ERROR_DELETE_AVISO";
export const RESET_DELETE_AVISO = "RESET_DELETE_AVISO";

function requestDeleteAviso() {
    return {
        type: REQUEST_DELETE_AVISO,
    }
}

function receiveDeleteAviso(aviso) {
    return {
        type: SUCCESS_DELETE_AVISO,
        receivedAt: Date.now(),
        aviso: normalizeDato(aviso)
    }
}

function errorDeleteAviso(error) {
    return {
        type: ERROR_DELETE_AVISO,
        error: error,
    }
}

export function resetDeleteAviso(error) {
    return {
        type: RESET_DELETE_AVISO,
        error: error,
    }
}

export function deleteAviso(aviso) {
    return {
        type: DELETE_AVISO,
        aviso
    }
}

export function saveDeleteAviso(aviso) {
    return dispatch => {
        dispatch(requestDeleteAviso());
        return avisosApi.saveDelete(aviso)
            .then(function (response) {
                            if (response.status >= 400) {
                                return Promise.reject(response);
                            } else {
                                var data = response.json();
                                //Refresco token
                                //auth.addToken(response.headers);
                                return data;
                            }
                        })
            .then(function (data) {
                            dispatch(resetDeleteAviso());
                            dispatch(receiveDeleteAviso(data));
                        })
            .catch(function (error) {
                            console.log(error, error.status);
                            switch (error.status) {
                                case 401:
                                    dispatch(errorDeleteAviso(errorMessages.UNAUTHORIZED_TOKEN));
                                    return;
                                default:
                                    if (error.responseJSON && error.responseJSON.message !== "")
                                        dispatch(errorDeleteAviso(JSON.parse(error.responseJSON.message)));
                                    else
                                        try {
                                            error.json().then((error) => {
                                                dispatch(errorDeleteAviso(JSON.parse(error.message)));
                                            });
                                        } catch {
                                            dispatch(errorDeleteAviso(errorMessages.GENERAL_ERROR));
                                        }


                                    return;
                            }
                        });
    }
}