import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../../assets/css/AvisoPrecios.css';
//Actions
import {fetchUsuario} from '../../../actions/UsuarioActions';

import * as Rol from "../../../constants/Rol";


class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {mostrar: false};
    }

    componentDidMount() {
        if (this.props.auth.usuarioLogueado && this.props.auth.usuarioLogueado.idUsuario)
            this.props.fetchUsuario(this.props.auth.usuarioLogueado.idUsuario);
    }

    componentDidUpdate(prevProps) {
        if (this.props.auth.loggedIn != prevProps.auth.loggedIn && this.props.auth.loggedIn == true && this.props.auth.usuarioLogueado && this.props.auth.usuarioLogueado.idUsuario)
            this.props.fetchUsuario(this.props.auth.usuarioLogueado.idUsuario);
        if (this.props.usuarios.update.activo.id != prevProps.usuarios.update.activo.id && this.props.usuarios.update.activo.id && this.props.usuarios.update.activo.lista_precio
            && this.props.usuarios.update.activo.lista_precio.default == true && this.props.usuarios.update.activo.idRol == Rol.MINORISTA_ID) {
            this.setState({mostrar: true})
        }
    }


    render() {

        if (this.state.mostrar)
            return (
                <div className="container-aviso-especial">
                    <div className="mensaje-especial">
                        <p>
                            Podes acceder a <b>mejores precios</b>! Comunicate a nuestro WhatsApp.
                        </p>
                        <div className="cruz-cerrar" onClick={() => {
                            this.setState({mostrar: false})
                        }}>&times;</div>
                    </div>
                </div>
            );
        else return <div/>
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        usuarios: state.usuarios
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsuario: (idUsuario) => {
            dispatch(fetchUsuario(idUsuario))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

