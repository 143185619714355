import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {
    const banner = new schema.Entity('banners', {}, {idAttribute: "id"});

    const mySchema = {banners: [banner]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const banner = new schema.Entity('banners', {}, {idAttribute: "id"});

    const mySchema = banner;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

