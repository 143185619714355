import c from '../constants/Constants';

require('es6-promise').polyfill();
require('isomorphic-fetch');


var detallesPedidoMon = {

    getByIdPedido(idPedido) {

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/detallePedido/' + idPedido, defaultOptions);
    },
};

export default detallesPedidoMon;