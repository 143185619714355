import {
    ERROR_RESTABLECER,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    RESET_LOGIN,
    RESTABLECER_REQUEST,
    RESTABLECER_SUCCESS
} from '../actions/AuthActions';

import authUtil from '../utils/auth';

const assign = Object.assign || require('object.assign');

function authentication(state = {
    isFetching: false,
    loggedIn: authUtil.isLoggedIn(),
    usuarioLogueado: authUtil.getUser(),
    errorMessage: null
}, action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                loggedIn: false,
                errorMessage: null,
                mensaje: null
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                loggedIn: true,
                errorMessage: '',
                usuarioLogueado: action.user,
                mensaje: action.mensaje ? action.mensaje : null
            });
        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                loggedIn: false,
                errorMessage: action.message,
                mensaje: null
            });
        case RESET_LOGIN:
            return Object.assign({}, state, {
                isFetching: false,
                loggedIn: false,
                errorMessage: action.message,
                mensaje: null
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                loggedIn: false,
                usuarioLogueado: null,
                mensaje: null
            });
        case RESTABLECER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage: null,
                mensaje: null
            });
        case RESTABLECER_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: '',
                mensaje: action.mensaje ? action.mensaje : null
            });
        case ERROR_RESTABLECER:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.error,
                mensaje: null
            });
        default:
            return state;
    }
}

export default authentication;
