//api
import categorias from "../api/categorias"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDatos} from "../normalizers/normalizeCategorias";

//CATEGORIAS
export const REQUEST_CATEGORIAS = 'REQUEST_CATEGORIAS';
export const INVALIDATE_CATEGORIAS_DESTACADAS = "INVALIDATE_CATEGORIAS_DESTACADAS";
export const REQUEST_CATEGORIAS_DESTACADAS = 'REQUEST_CATEGORIAS_DESTACADAS';
export const RECEIVE_CATEGORIAS_DESTACADAS = 'RECEIVE_CATEGORIAS_DESTACADAS';
export const RECEIVE_CATEGORIAS = 'RECEIVE_CATEGORIAS';
export const INVALIDATE_CATEGORIAS = 'INVALIDATE_CATEGORIAS';
export const ERROR_CATEGORIAS = "ERROR_CATEGORIAS";
export const RESET_CATEGORIAS = "RESET_CATEGORIAS";


export function invalidateCategorias() {
    return {
        type: INVALIDATE_CATEGORIAS
    }
}

function requestCategorias() {
    return {
        type: REQUEST_CATEGORIAS,
    }
}

function receiveCategorias(json) {
    return {
        type: RECEIVE_CATEGORIAS,
        categorias: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorCategorias(error) {
    return {
        type: ERROR_CATEGORIAS,
        error: error,
    }
}

export function resetCategorias() {
    return {
        type: RESET_CATEGORIAS
    }
}

export function fetchCategorias(filtros) {
    return dispatch => {
        dispatch(requestCategorias());
        return categorias.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCategorias(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCategorias(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCategorias(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchCategorias(state) {
    const categorias = state.categorias.byId;
    if (!categorias) {
        return true
    } else if (categorias.isFetching) {
        return false
    } else {
        return categorias.didInvalidate;
    }
}

export function fetchCategoriasIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchCategorias(getState())) {
            return dispatch(fetchCategorias(filtros))
        }
    }
}

export function invalidateCategoriasDestacadas() {
    return {
        type: INVALIDATE_CATEGORIAS_DESTACADAS
    }
}

function requestCategoriasDestacadas() {
    return {
        type: REQUEST_CATEGORIAS_DESTACADAS,
    }
}

function receiveCategoriasDestacadas(json) {
    return {
        type: RECEIVE_CATEGORIAS_DESTACADAS,
        categorias: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

export function fetchCategoriasDestacadas(filtros) {
    return dispatch => {
        dispatch(requestCategoriasDestacadas());
        return categorias.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveCategoriasDestacadas(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorCategorias(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorCategorias(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchCategoriasDestacadas(state) {
    const categorias = state.categorias.byId;
    if (!categorias) {
        return true
    } else if (categorias.isFetchingDestacadas) {
        return false
    } else {
        return categorias.didInvalidateDestacadas;
    }
}

export function fetchCategoriasDestacadasIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchCategoriasDestacadas(getState())) {
            return dispatch(fetchCategoriasDestacadas(filtros))
        }
    }
}