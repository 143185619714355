import {
    ERROR_CATEGORIAS,
    INVALIDATE_CATEGORIAS,
    INVALIDATE_CATEGORIAS_DESTACADAS,
    RECEIVE_CATEGORIAS,
    RECEIVE_CATEGORIAS_DESTACADAS,
    REQUEST_CATEGORIAS,
    REQUEST_CATEGORIAS_DESTACADAS,
    RESET_CATEGORIAS,
} from '../actions/CategoriaActions';
import {combineReducers} from 'redux';

import union from "lodash/union"
import merge from "lodash/merge";
import {RECEIVE_BUSQUEDA, RESET_BUSQUEDA} from "../actions/BusquedaActions";

function categoriasById(state = {
    isFetching: false,
    isFetchingDestacadas: false,
    didInvalidate: true,
    didInvalidateDestacadas: true,
    categorias: {}
}, action) {
    switch (action.type) {
        case INVALIDATE_CATEGORIAS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case REQUEST_CATEGORIAS_DESTACADAS:
            return Object.assign({}, state, {
                isFetchingDestacadas: true,
                didInvalidateDestacadas: false
            });
        case RECEIVE_CATEGORIAS:
            let dato = action.categorias.entities.categorias;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                categorias: dato ? merge({}, state.categorias, dato) : state.categorias,
                lastUpdated: action.receivedAt
            });
        case INVALIDATE_CATEGORIAS_DESTACADAS:
            return Object.assign({}, state, {
                didInvalidateDestacadas: true
            });
        case RECEIVE_CATEGORIAS_DESTACADAS:
            let datoDes = action.categorias.entities.categorias;
            return Object.assign({}, state, {
                isFetchingDestacadas: false,
                didInvalidateDestacadas: false,
                categorias: datoDes ? merge({}, state.categorias, datoDes) : state.categorias,
                lastUpdated: action.receivedAt
            });
        case ERROR_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_CATEGORIAS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                categorias: {}
            });
        case RECEIVE_BUSQUEDA:
            let datosBusqueda = action.busqueda.entities.categorias;
            return Object.assign({}, state, {
                categorias: merge({}, state.categorias, datosBusqueda),
            });
        default:
            return state
    }
}


function allCategorias(state = {allIds: [], allIdsDestacados: [], allIdsBuscados: []}, action) {
    switch (action.type) {
        case RECEIVE_CATEGORIAS:
            return Object.assign({}, state, {
                allIds: action.categorias ? union(state.allIds, action.categorias.result) : [],
            });
        case RECEIVE_CATEGORIAS_DESTACADAS:
            // return union(state, action.categorias.result);
            return Object.assign({}, state, {
                allIdsDestacados: action.categorias ? union(state.allIdsDestacados, action.categorias.result) : [],
            });
        case RECEIVE_BUSQUEDA:
            return Object.assign({}, state, {
                allIdsBuscados: action.busqueda && action.busqueda.result.categorias ? action.busqueda.result.categorias : [],
            });
        case RESET_BUSQUEDA:
            return Object.assign({}, state, {
                allIdsBuscados: [],
            });
        default:
            return state
    }
}

const categorias = combineReducers({
    byId: categoriasById,
    allIds: allCategorias,
});

export default categorias;