import React from 'react';
import {Link} from 'react-router-dom'
import * as TipoAnalitica from "../../../../constants/TipoAnalitica";
//lodash
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
//css
import '../../../../assets/css/ProductoUnitarioCombo.css';
//img
import {ReactComponent as Cart} from "../../../../assets/images/cart.svg";
import {ReactComponent as Happy} from "../../../../assets/images/happy.svg";
//components
import CarrouselCombo from "../../Carruseles/CarrouselCombo";
import isNil from "lodash/isNil";
import {useSelector} from "react-redux";


export default React.memo(function ProductoUnitarioCombo(props) {

    const {productos, promocion, productosDestinoPromocion, productosOrigenPromocion, detallesPedido} = props;
    const tiendas = useSelector(state => state.tiendas);
    const tienda = tiendas.update.activo && tiendas.update.activo.id ? tiendas.update.activo : null;

    const mobilecheck = () => {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    let total = productos.reduce((total, producto) => {
        let cantidad = 0;
        productosOrigenPromocion.some((productoOrigenPromocion) => {
            if (producto.id === productoOrigenPromocion.idProducto) {
                cantidad = productoOrigenPromocion.cantidad;
                return true;
            } else
                return false;
        });
        return total + parseFloat(producto.precio * cantidad);
    }, 0);
    total = total.toFixed(2);

    let precios = [];
    let precioActual = 0;
    if (isNil(promocion) || isNil(promocion.precioPromocional))
        precios = [];
    else
        precios = [{precio: total, leyenda: "Antes"}];
    const precioTachado = precios.find((value) => {
        return (!isNil(value.precio) && value.precio !== 0)
    });
    if (isNil(promocion) || isNil(promocion.precioPromocional))
        precioActual = total;
    else
        precioActual = promocion.precioPromocional;

    //Detalle pedido
    let cantidadComboTotal = 0;
    let combo = true;
    if (promocion && promocion.producto_origen_promocion) {
        productosOrigenPromocion.forEach((productoOrigenPromocion) => {
            let encontrado = false;
            Object.keys(detallesPedido).forEach((idDetallePedido) => {
                let detallePedidoGuardado = detallesPedido[idDetallePedido];
                if (detallePedidoGuardado.idProducto === productoOrigenPromocion.idProducto && !detallePedidoGuardado.idPromocion) {
                    if (detallePedidoGuardado.cantidad >= productoOrigenPromocion.cantidad) {
                        let cantidadCombo = Math.floor(detallePedidoGuardado.cantidad / productoOrigenPromocion.cantidad);
                        if (cantidadComboTotal === 0 || cantidadComboTotal > cantidadCombo)
                            cantidadComboTotal = cantidadCombo;
                        encontrado = true;
                    }
                }
            });
            if (!encontrado)
                combo = false;
        });
    }
    if (!combo)
        cantidadComboTotal = 0;

    let Imagenes = [];
    if (promocion && promocion.imgPromocion)
        Imagenes.push(<div className="item">
            <Link to={`/producto/combo/${promocion.id}`}>
                <img
                    src={process.env.PUBLIC_URL + "/productos/" + promocion.imgPromocion}
                    alt=""/>
            </Link>
        </div>);
    let vendible = true;
    productos.forEach((producto) => {
        if (producto.publicar === 0 || producto.noVendible === 1)
            vendible = false;
        if (producto.imgProducto)
            Imagenes.push(
                <div className="item" key={producto.id}>
                    <Link to={`/producto/combo/${promocion.id}`}>
                        <img
                            src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}
                            alt=""/>
                    </Link>
                </div>
            )
    });

    const handleAgregarCantidad = (idProducto) => {

        //Cantidad
        productosOrigenPromocion.forEach((productoOrigenPromocion) => {
            let nuevo = {};
            var detalle = clone(detallesPedido[productoOrigenPromocion.idProducto + "-"]);
            if (!detalle) {
                detalle = {};
                detalle["idProducto"] = productoOrigenPromocion.idProducto;
                detalle["cantidad"] = productoOrigenPromocion.cantidad;
            } else {
                detalle.cantidad = detalle.cantidad + productoOrigenPromocion.cantidad;
            }
            productos.some((producto) => {
                if (producto.id === productoOrigenPromocion.idProducto) {
                    detalle.precioUnitario = producto.precio;
                    return true;
                } else
                    return false;
            });
            nuevo[productoOrigenPromocion.idProducto + "-"] = detalle;
            props.createDetallePedido(nuevo);
        });


        if (!mobilecheck() && window.innerWidth > 850)
            props.toogleBarraCarrito(true);
    };

    const handleQuitarCantidad = (idProducto) => {
        //Cantidad
        productosOrigenPromocion.forEach((productoOrigenPromocion) => {
            let nuevo = {};
            var detalle = clone(detallesPedido[productoOrigenPromocion.idProducto + "-"]);
            if (detalle.cantidad > productoOrigenPromocion.cantidad) {
                detalle.cantidad = detalle.cantidad - productoOrigenPromocion.cantidad;
                productos.some((producto) => {
                    if (producto.id === productoOrigenPromocion.idProducto) {
                        detalle.precioUnitario = producto.precio;
                        return true;
                    } else
                        return false;
                });
                nuevo[productoOrigenPromocion.idProducto + "-"] = detalle;
                props.createDetallePedido(nuevo);
            } else {
                var cambio = {};
                cambio[productoOrigenPromocion.idProducto + "-"] = detalle;
                props.deleteDetallePedido(cambio);
            }

        });
        if (!mobilecheck() && window.innerWidth > 850)
            props.toogleBarraCarrito(true);
    };

    const handleAnalitica = (analitica, idTipo) => {
        props.createAnalitica(analitica, idTipo)
    };

    return (
        <div className="producto combo">
            {
                promocion && promocion.resultadoPromocion ?
                    <p className="etiqueta-promo">{`LLevando ${promocion.nomPromocion} ${promocion.resultadoPromocion} `}</p>
                    : null
            }

            <div className="ribbon-wrapper">
                <div className="ribbon">{"COMBO"}</div>
            </div>

            {
                !!productos &&
                <div className="container-img carrousel-combo">
                    <CarrouselCombo>
                        {Imagenes}
                    </CarrouselCombo>
                </div>
            }
            <div className="container-detalle">
                <div className="nombre-producto">
                    <p style={{fontSize: "13px"}}><Link
                        to={`/producto/combo/${promocion.id}`}>{promocion.nomPromocion}</Link></p>
                </div>
                <div className="detalle-producto">
                    <p style={{fontSize: "11px", marginTop: "5px", marginBottom: "25px", textAlign: "center"}}><Link
                        to={`/producto/combo/${promocion.id}`}>Ver detalle</Link></p>
                </div>
            </div>
            <div className="sub-container-botones">
                <div className="container-botones">
                    <div className="precios-vigentes">
                        {
                            precioTachado &&
                            <div className="costo-inicial">
                                <b style={{
                                    fontSize: "unset",
                                    whiteSpace: "nowrap",
                                }}>
                                        <span style={{fontSize: "x-small", marginRight: "5px"}}>
                                            {precioTachado && precioTachado.precio !== 0 && !isNil(precioTachado.precio) ? precioTachado.leyenda : ""}
                                    </span>
                                    <span style={{textDecoration: "line-through"}}>
                                            {(precioTachado.precio !== 0 && !isNil(precioTachado.precio)) ? " $" + precioTachado.precio : ""}
                                        </span>
                                </b>
                            </div>
                        }
                        <div className={"costo-final"}>
                            <p>{precioTachado && precioTachado.precio !== 0 && !isNil(precioTachado.precio) ? Math.round((1 - precioActual / precioTachado.precio) * 100) + "% OFF" : ""}</p>
                            <b>{precioActual && precioActual !== 0 ? "$" + precioActual : ""}</b>
                        </div>
                        {
                            (!total || total === 0) &&
                            <div className={"ver-precio"}>
                                <Link className="registrate" to={"/login"}>Ver precio</Link>
                            </div>
                        }
                    </div>
                    {
                        vendible === true && (!cantidadComboTotal ?
                                <button className="encargar"
                                        onClick={() => handleAgregarCantidad(promocion.id)}>
                                    COMPRAR <span><Cart/></span>
                                </button> :
                                <div className="container-cantidad">
                                    <div className="btn-restar"
                                         onClick={() => handleQuitarCantidad(promocion.id)}>&#8722;</div>
                                    <input type="number" value={cantidadComboTotal ? cantidadComboTotal : 0}
                                           disabled={true}/>
                                    <div className="btn-sumar"
                                         onClick={() => handleAgregarCantidad(promocion.id)}>&#43;</div>
                                </div>
                        )
                    }
                    {
                        vendible === false &&
                        <a alt="Chatea con nosotros"
                           href={`${tienda ? tienda.whatsappLink : ""}?text=Hola! Quisiera consultar por el combo ${promocion.nomPromocion}. Gracias!`}
                           target="_blank"
                           onClick={() => {
                               handleAnalitica("combo/" + promocion.id, TipoAnalitica.CLICK_WHATSAPP_ID);
                           }}
                           className={"btn-combo"}
                        >
                            <button className="encargar" disabled={false} style={{height: "100%"}}>
                                Consultar <span><Happy height="15px"/></span>
                            </button>
                        </a>
                    }


                </div>

            </div>
        </div>
    );
}, function (prevProps, nextProps) {
    // console.log(prevProps.mapaDesactivar.size, nextProps.mapaDesactivar.size)
    // console.log(prevProps.value === nextProps.value && prevProps.label === nextProps.label && prevProps.mapaDesactivar.size === nextProps.mapaDesactivar.size)
    return prevProps.promocion.id === nextProps.promocion.id && prevProps.promocion.precio === nextProps.promocion.precio && prevProps.cantidad === nextProps.cantidad && isEqual(prevProps.detallesPedido, nextProps.detallesPedido) && isEqual(prevProps.productos, nextProps.productos);
})




