import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import Swiper from 'react-id-swiper';

import '../../assets/css/SliderPrincipal.css';
import {fetchBannersIfNeeded} from "../../actions/BannersActions";
import c from "../../constants/Constants";


export default function SliderPrincipal() {
    const dispatch = useDispatch();
    //Stores
    const banners = useSelector(state => state.banners);
    //Effects
    useEffect(() => {
        let filtros = {
            publicar: true
        };
        dispatch(fetchBannersIfNeeded(filtros));
    }, []);

    const params = {
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        autoplay: {
            delay: 5000,
        },
        loop: true,
    };

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    let Sliders = [];

    if (banners)
        banners.allIds.map((key) => {
            let banner = banners.byId.banners[key];
            let idAntiCache = makeid(5);

            Sliders.push(
                <Link key={"banner" + banner.id} to={banner.url ? banner.url : ""}>
                    <div className="item">
                        <picture>
                            <source
                                srcSet={c.BASE_URL + '/banners/' + banner.id + "/mobile/" + idAntiCache}
                                media={'(max-width: 800px)'}/>
                            <img
                                src={c.BASE_URL + '/banners/' + banner.id + "/desktop/" + idAntiCache}/>
                        </picture>
                    </div>
                </Link>
            )
        })

    return (
        <section className="slider-principal">
            {Sliders.length > 0 ?
                <Swiper {...params} shouldSwiperUpdate updateOnWindowResize>
                    {Sliders}
                </Swiper> : ""
            }
        </section>

    );
}
