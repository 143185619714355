import {RECEIVE_PEDIDOS, RESET_PEDIDOS} from '../actions/PedidoActions';
import {combineReducers} from 'redux';
import merge from "lodash/merge";

import {
    CREATE_ESTADOREMITO,
    ERROR_CREATE_ESTADOREMITO,
    ERROR_UPDATE_ESTADOREMITO,
    REQUEST_CREATE_ESTADOREMITO,
    REQUEST_UPDATE_ESTADOREMITO,
    RESET_CREATE_ESTADOREMITO,
    RESET_UPDATE_ESTADOREMITO,
    SUCCESS_CREATE_ESTADOREMITO,
    SUCCESS_UPDATE_ESTADOREMITO
} from "../actions/EstadoRemitoActions";

function estadosRemitoById(state = {
    isFetching: false,
    didInvalidate: true,
    estadosRemito: []
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                estadosRemito: merge({}, state.estadosRemito, action.pedidos.entities.estadosRemito),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                estadosRemito: [],
                error: null
            });
        default:
            return state
    }
}


function allEstadosRemito(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}

function create(state = {isCreating: false, nuevoEstadoRemito: {}, error: ""}, action) {
    switch (action.type) {
        case CREATE_ESTADOREMITO:
            return Object.assign({}, state, {
                nuevoEstadoRemito: action.estadoPedido,
                error: ""
            });
        case REQUEST_CREATE_ESTADOREMITO:
            return Object.assign({}, state, {
                isCreating: true,
                error: ""
            });
        case SUCCESS_CREATE_ESTADOREMITO:
            return Object.assign({}, state, {
                isCreating: false,
                nuevoEstadoRemito: {},
                error: ""
            });
        case ERROR_CREATE_ESTADOREMITO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_ESTADOREMITO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevoEstadoRemito: {},
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                iisCreating: false,
                error: null,
                nuevoEstadoRemito: {}
            });
        default:
            return state
    }
}

function update(state = {isUpdating: false, error: ""}, action) {
    switch (action.type) {
        case REQUEST_UPDATE_ESTADOREMITO:
            return Object.assign({}, state, {
                isUpdating: true,
                error: ""
            });
        case SUCCESS_UPDATE_ESTADOREMITO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: ""
            });
        case ERROR_UPDATE_ESTADOREMITO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_UPDATE_ESTADOREMITO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                iisUpdating: false,
                error: null,
            });
        default:
            return state
    }
}


const estadosRemito = combineReducers({
    byId: estadosRemitoById,
    allIds: allEstadosRemito,
    create: create,
    update: update
});

export default estadosRemito;