import c from '../constants/Constants';

require('es6-promise').polyfill();
require('isomorphic-fetch');


var detallesRemitidos = {

    getByIdRemito(idRemito) {

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
        };
        return fetch(c.BASE_URL + '/detallesRemitidos/' + idRemito, defaultOptions);
    },
    update(detallesRemitido) {

        let defaultOptions = {
            url: '',
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify(detallesRemitido)
        };

        return fetch(c.BASE_URL + '/detallesRemitidos/1', defaultOptions);
    },
};

export default detallesRemitidos;