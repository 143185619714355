import React, {Component} from 'react';
//css
import '../../../assets/css/CampoTexto.css';


class CampoTexto extends Component {
    render() {
        let {id, nombre, type, img, color, value, handleChange, autoComplete, required, name, onBlur, disabled} = this.props;
        return (
            <div className="campoTexto" id={"input-" + id}>
                <label>
                    {/*{img}*/}
                    <input type={type} id={id} name={name} className="campoTexto-input"
                           disabled={disabled ? disabled : false}
                           autoComplete="peppapig"
                           // style={color ? {color: color} : {color: "inherit"}}
                           value={value}
                           onChange={(e) => {
                               if (handleChange) handleChange(e)
                           }} required={required}
                           // onBlur={(e) => {
                           //     if (onBlur) onBlur(e)
                           // }}
                        placeholder=""
                    />

                    {/*<div className="campoTexto-linea" style={(color ? {borderColor: color} : {borderColor: ""})}/>*/}
                    <span className={`campoTexto-label ${!!value && value !== "" ? "show" : ""}`}
                           // style={color ? {color: color} : {color: "inherit"}}
                    >{nombre}</span>
                </label>
            </div>
        );
    }
}

export default CampoTexto;
