export const CONSUMIDOR_FINAL_ID= 1;
export const RESPONSABLE_INSCRIPTO_ID = 2;
export const RESPONSABLE_MONOTRIBUTO_ID = 3;
export const EXENTO_ID = 4;
export const RESPONSABLE_NO_INSCIPTO_ID = 5;
export const NO_RESPONSABLE_ID = 6;
export const EXPORTACION_ID = 7;
export const IMPORTACION_ID = 8;

export const CONSUMIDOR_FINAL= 'Consumidor Final';
export const RESPONSABLE_INSCRIPTO = 'Responsable Inscripto';
export const RESPONSABLE_MONOTRIBUTO = 'Responsable Monotributo';
export const EXENTO = 'Exento';
export const RESPONSABLE_NO_INSCIPTO = 'Responsable No Inscripto';
export const NO_RESPONSABLE = 'No Responsable';
export const EXPORTACION = 'Exportación';
export const IMPORTACION = 'Importación';