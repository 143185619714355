import React, {Component} from 'react';
import {NavHashLink as NavLink} from 'react-router-hash-link'
import {ConfiguracionContext} from "../../utils/Context"

import '../../assets/css/Footer.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebook, faInstagram, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faCopyright} from '@fortawesome/free-regular-svg-icons'
import dataweb from '../../assets/images/DATAWEB.jpg';
import {connect} from "react-redux";
import {saveCreateAnalitica} from "../../actions/AnaliticaActions";
import * as TipoAnalitica from "../../constants/TipoAnalitica";

class Footer extends Component {
    render() {
        const tienda = this.props.tiendas.update.activo;
        return (
            <footer className="footer">
                <div className="container-limit">
                    <div className="container-footer">
                        <div className="container-items-footer">
                            <ul>
                                <li>
                                    <NavLink to="/quienesSomos">QUIÉNES SOMOS</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/#productos">PRODUCTOS</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/#proceso">PROCESO DE COMPRA</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/#faqs">FAQS</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/#contacto">CONTACTO</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="redes-sociales">
                            {
                                tienda && tienda.facebookLink && tienda.facebookLink !== "" &&
                                <a target="_blank" href={tienda ? tienda.facebookLink : ""}>
                                    <FontAwesomeIcon icon={faFacebook} color="white"/>
                                </a>
                            }
                            {
                                tienda && tienda.instagramLink && tienda.instagramLink !== "" &&
                                <a target="_blank" href={tienda ? tienda.instagramLink : ""}>
                                    <FontAwesomeIcon icon={faInstagram} color="white"/>
                                </a>
                            }
                            {
                                tienda && tienda.whatsappLink && tienda.whatsappLink !== "" &&
                                <a target="_blank" href={tienda ? tienda.whatsappLink : ""}
                                   onClick={() => {
                                       this.props.saveCreateAnalitica(null, TipoAnalitica.CLICK_WHATSAPP_ID);
                                   }}>
                                    <FontAwesomeIcon icon={faWhatsapp} color="white"/>
                                </a>
                            }
                        </div>
                        <div className="container-copyright">
                            <p>
                                Copyright <FontAwesomeIcon icon={faCopyright}/> {tienda ? tienda.firmaFooter : ""}
                            </p>
                        </div>
                        <div className="firma">
                            <a className="desa" target="_blank" href="http://www.exadec.com">
                                <span>
                                    <div className="exadec"/>
                                </span>
                                <p style={{color: "grey", fontSize: "7px", margin: "0", textDecoration: "none"}}>
                                    Diseño y desarrollo
                                </p>
                            </a>
                            {
                                tienda && tienda.afipLink && tienda.afipLink !== "" &&
                                <a href={tienda ? tienda.afipLink : ""} target="_F960AFIPInfo">
                                    <img className="data-fiscal" src={dataweb} border="0"/>
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </footer>

        );
    }
}

function mapStateToProps(state) {
    return {
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
