//api
import productos from "../api/productos"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDato, normalizeDatos} from "../normalizers/normalizeProductos";

//PRODUCTOS
export const REQUEST_PRODUCTOS = 'REQUEST_PRODUCTOS';
export const RECEIVE_PRODUCTOS = 'RECEIVE_PRODUCTOS';
export const INVALIDATE_PRODUCTOS = 'INVALIDATE_PRODUCTOS';
export const ERROR_PRODUCTOS = "ERROR_PRODUCTOS";
export const RESET_PRODUCTOS = "RESET_PRODUCTOS";


export function invalidateProductos() {
    return {
        type: INVALIDATE_PRODUCTOS
    }
}

function requestProductos() {
    return {
        type: REQUEST_PRODUCTOS,
    }
}

function receiveProductos(json) {
    return {
        type: RECEIVE_PRODUCTOS,
        productos: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorProductos(error) {
    return {
        type: ERROR_PRODUCTOS,
        error: error,
    }
}

export function resetProductos() {
    return {
        type: RESET_PRODUCTOS
    }
}

export function fetchProductos(filtros) {
    return dispatch => {
        dispatch(requestProductos());
        return productos.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProductos(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProductos(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProductos(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchProductos(state) {
    const productos = state.productos.byId;
    if (!productos) {
        return true
    } else if (productos.isFetching) {
        return false
    } else {
        return productos.didInvalidate;
    }
}

export function fetchProductosIfNeeded() {
    return (dispatch, getState) => {
        if (shouldFetchProductos(getState())) {
            return dispatch(fetchProductos())
        }
    }
}


//PRODUCTO
export const REQUEST_PRODUCTO = 'REQUEST_PRODUCTO';
export const RECEIVE_PRODUCTO = 'RECEIVE_PRODUCTO';
export const INVALIDATE_PRODUCTO = 'INVALIDATE_PRODUCTO';
export const ERROR_PRODUCTO = "ERROR_PRODUCTO";
export const RESET_PRODUCTO = "RESET_PRODUCTO";


export function invalidateProducto() {
    return {
        type: INVALIDATE_PRODUCTO
    }
}

function requestProducto() {
    return {
        type: REQUEST_PRODUCTO,
    }
}

function receiveProducto(json) {
    return {
        type: RECEIVE_PRODUCTO,
        producto: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorProducto(error) {
    return {
        type: ERROR_PRODUCTO,
        error: error,
    }
}

export function resetProducto() {
    return {
        type: RESET_PRODUCTO
    }
}

export function fetchProducto(idProducto) {
    return dispatch => {
        dispatch(requestProducto());
        return productos.getOne(idProducto)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveProducto(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorProducto(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorProducto(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}