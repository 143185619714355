import {RECEIVE_DETALLESREMITIDOS, RESET_DETALLESREMITIDOS} from '../actions/DetalleRemitidoActions';

import {
    CREATE_BULTO,
    DELETE_BULTO,
    ERROR_CREATE_BULTO,
    ERROR_DELETE_BULTO,
    ERROR_UPDATE_BULTO,
    REQUEST_CREATE_BULTO,
    REQUEST_DELETE_BULTO,
    REQUEST_UPDATE_BULTO,
    RESET_BULTOS,
    SUCCESS_CREATE_BULTO,
    SUCCESS_DELETE_BULTO,
    SUCCESS_UPDATE_BULTO,
    UPDATE_BULTO
} from "../actions/BultoActions";

import {combineReducers} from 'redux';
import normalizeDatos from "../normalizers/normalizeDetallesRemitidos";

import merge from "lodash/merge";
import pickBy from "lodash/pickBy";
import union from "lodash/union";
import remove from "lodash/remove";
import difference from "lodash/difference";

function bultosById(state = {
    isFetching: false,
    didInvalidate: true,
    bultos: {}
}, action) {
    switch (action.type) {
        case RECEIVE_DETALLESREMITIDOS:
            var bultos = normalizeDatos(action.detallesRemitidos).entities.bultos;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                bultos: bultos ? bultos : {},
                lastUpdated: action.receivedAt
            });
        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                bultos: {},
            });
        case CREATE_BULTO:
            var bulto = {};
            bulto[action.id] = action.bulto;
            return Object.assign({}, state, {
                bultos: merge({}, state.bultos, bulto)
            });
        case UPDATE_BULTO:
            var bulto = {};
            bulto[action.id] = action.bulto;
            return Object.assign({}, state, {
                bultos: merge({}, state.bultos, bulto)
            });
        case RESET_BULTOS:
            return Object.assign({}, state, {
                error: null,
                bultos: {},
            });
        case DELETE_BULTO:
            return Object.assign({}, state, {
                isFetching: false,
                error: null,
                bultos: pickBy(state.bultos, function (bulto, key) {
                    return key !== action.id;
                })
            });
        default:
            return state
    }
}


function allBultos(state = [], action) {
    switch (action.type) {
        case RECEIVE_DETALLESREMITIDOS:
            var bultos = normalizeDatos(action.detallesRemitidos).entities.bultos;
            return bultos ? Object.keys(bultos) : [];
        case CREATE_BULTO:
            return union({}, state, [action.id]);
        case RESET_DETALLESREMITIDOS:
            return [];
        // case SUCCESS_CREATE_BULTO:
        //     return [];
        case DELETE_BULTO:
            return difference(state, [action.id.toString()]);
        default:
            return state
    }

}


function create(state = {
    isCreating: false,
    bultos: []
}, action) {
    switch (action.type) {
        case RECEIVE_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isCreating: false,
                bultos: []
            });
        case CREATE_BULTO:
            return Object.assign({}, state, {
                isCreating: false,
                bultos: union({}, state.bultos, [action.id])
            });
        case REQUEST_CREATE_BULTO:
            return Object.assign({}, state, {
                isCreating: true
            });
        case SUCCESS_CREATE_BULTO:
            return Object.assign({}, state, {
                isCreating: false,
                bultos: []
            });
        case ERROR_CREATE_BULTO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_BULTOS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                bultos: []
            })
        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                bultos: []
            });
        case DELETE_BULTO:
            return Object.assign({}, state, {
                isCreating: false,
                bultos: difference(state.bultos, [action.id])
            });

        default:
            return state
    }

}

function update(state = {
    isUpdating: false,
    bultos: []
}, action) {
    switch (action.type) {
        case RECEIVE_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isUpdating: false,
                bultos: []
            });
        case UPDATE_BULTO:
            return Object.assign({}, state, {
                isUpdating: false,
                bultos: union({}, state.bultos, [action.id])
            });
        case REQUEST_UPDATE_BULTO:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case SUCCESS_UPDATE_BULTO:
            return Object.assign({}, state, {
                isUpdating: false,
                bultos: []
            });
        case ERROR_UPDATE_BULTO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_BULTOS:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                bultos: []
            })
        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isUpdating: false,
                bultos: [],
                error: ""
            });
        case DELETE_BULTO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                bultos: remove(state.bultos, function (packaginList) {
                    return packaginList !== action.id;
                })
            });
        case DELETE_BULTO:
            return Object.assign({}, state, {
                isCreating: false,
                bultos: difference(state.bultos, [action.id.toString()])
            });
        default:
            return state
    }

}

function deleter(state = {
    isDeleting: false,
    bultos: []
}, action) {
    switch (action.type) {
        case RECEIVE_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isDeleting: false,
                bultos: []
            });
        case REQUEST_DELETE_BULTO:
            return Object.assign({}, state, {
                isDeleting: true
            });
        case SUCCESS_DELETE_BULTO:
            return Object.assign({}, state, {
                isDeleting: false,
                bultos: [],
                error: null,
            });
        case ERROR_DELETE_BULTO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_BULTOS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                bultos: []
            });

        case RESET_DETALLESREMITIDOS:
            return Object.assign({}, state, {
                isDeleting: false,
                bultos: [],
                error: ""
            });
        case DELETE_BULTO:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                bultos: union({}, state.bultos, [action.id])
            });
        default:
            return state
    }

}


const bultos = combineReducers({
    byId: bultosById,
    allIds: allBultos,
    create: create,
    update: update,
    delete: deleter
});

export default bultos;