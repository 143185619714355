import {RECEIVE_PEDIDOS, RESET_PEDIDOS} from '../actions/PedidoActions';
import {
    CREATE_USUARIO,
    ERROR_CREATE_USUARIO,
    RECEIVE_USUARIO,
    REQUEST_CREATE_USUARIO,
    RESET_CREATE_USUARIO,
    SUCCESS_CREATE_USUARIO
} from '../actions/UsuarioActions';
import {combineReducers} from 'redux';
import merge from "lodash/merge";

function usuariosById(state = {
    isFetching: false,
    didInvalidate: true,
    usuarios: []
}, action) {
    switch (action.type) {
        case RECEIVE_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                usuarios: merge({}, state.usuarios, action.pedidos.entities.usuarios),
                lastUpdated: action.receivedAt
            });
        case RESET_PEDIDOS:
            return Object.assign({}, state, {
                isFetching: false,
                usuarios: [],
                error: null
            });
        default:
            return state
    }
}


function allUsuarios(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    error: null,
    mensaje: null
}, action) {
    switch (action.type) {
        case CREATE_USUARIO:
            return Object.assign({}, state, {
                isCreating: false,
                nuevo: merge({}, state.nuevo, action.usuario),
                error: null,
                mensaje: null
            });
        case REQUEST_CREATE_USUARIO:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
                mensaje: null
            });
        case SUCCESS_CREATE_USUARIO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                mensaje: action.mensaje ? action.mensaje : null
            });
        case ERROR_CREATE_USUARIO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error,
                mensaje: null
            });
        case RESET_CREATE_USUARIO:
            return Object.assign({}, state, {
                isCreating: false,
                nuevo: {},
                error: null,
                mensaje: null
            });
        // case LOGOUT_SUCCESS:
        //     return Object.assign({}, state, {
        //         didInvalidate: true,
        //         nuevo: [],
        //     });
        default:
            return state
    }

}

function update(state = {
    isUpdating: false,
    activo: {}
}, action) {
    switch (action.type) {
        case RECEIVE_USUARIO:
            let dato = action.usuario.entities.usuarios;
            let usuario = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: usuario ? usuario : [],
                lastUpdated: action.receivedAt
            });


        default:
            return state
    }
}


const usuarios = combineReducers({
    byId: usuariosById,
    allIds: allUsuarios,
    create: create,
    update: update
});

export default usuarios;