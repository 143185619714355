import React, {Component} from 'react';
//CSS
import './NavSocial.css';
import whatsapp from './wp.png';
import {connect} from "react-redux";
import {saveCreateAnalitica} from "../../../../actions/AnaliticaActions";
import * as TipoAnalitica from "../../../../constants/TipoAnalitica";

class NavSocial extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    // componentDidMount() {
    //     window.fbAsyncInit = function () {
    //         window.FB.init({
    //             xfbml: true,
    //             version: 'v7.0'
    //         });
    //     };
    //     (function (d, s, id) {
    //         var js, fjs = d.getElementsByTagName(s)[0];
    //         if (d.getElementById(id)) return;
    //         js = d.createElement(s);
    //         js.id = id;
    //         js.src = 'https://connect.facebook.net/es_ES/sdk/xfbml.customerchat.js';
    //         fjs.parentNode.insertBefore(js, fjs);
    //     }(document, 'script', 'facebook-jssdk'));
    // }

    render() {
        const tienda = this.props.tiendas.update.activo;
        return (
            <div>
                <div className="icon-container">
                    {
                        tienda && tienda.whatsappLink && tienda.whatsappLink !== "" &&
                        <a alt="Chatea con nosotros" href={tienda ? tienda.whatsappLink : ""} target="_blank" onClick={() => {
                            this.props.saveCreateAnalitica(null, TipoAnalitica.CLICK_WHATSAPP_ID);
                        }}>
                            <img className="img-responsive" src={whatsapp} alt=""/>
                        </a>
                    }
                    {/*{*/}
                    {/*    tienda && tienda.whatsappLink && tienda.whatsappLink !== "" &&*/}
                    {/*    <div className="messenger-facebook">*/}
                    {/*        <div id="fb-root"></div>*/}
                    {/*        <div className="fb-customerchat"*/}
                    {/*             attribution={this.setup_tool}*/}
                    {/*             page_id={tienda ? tienda.facebookChatId : ""}*/}
                    {/*             theme_color={tienda ? tienda.primaryColor : ""}*/}
                    {/*             logged_in_greeting="¡Hola! ¿Necesitas ayuda?"*/}
                    {/*             logged_out_greeting="¡Hola! ¿Necesitas ayuda?">*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(NavSocial);
