import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom'
import MetaTags from 'react-meta-tags';
import * as TipoAnalitica from "../../constants/TipoAnalitica";
//css
import '../../assets/css/ProductoPresentacion.css';
import '../../assets/css/Breadcrum.css';
//img
import logoMerca from "../../assets/images/logoMerca.png";
//Actions
import {fetchProducto, resetProducto} from "../../actions/ProductoActions";
import {fetchCategoriasIfNeeded} from "../../actions/CategoriaActions"
//Components
import {ReactComponent as Cart} from "../../assets/images/cart.svg";
import clone from "lodash/clone";
import {createDetallePedido, deleteDetallePedido} from "../../actions/DetallePedidoActions";
import Gallery from 'react-photo-gallery';
import CarrouselProductoPresentacion from "./Carruseles/CarrouselProductoPresentacion";
import {fetchPromocionesIfNeeded} from "../../actions/PromocionActions";
import {ReactComponent as Happy} from "../../assets/images/happy.svg";
import {saveCreateAnalitica} from "../../actions/AnaliticaActions";


var moment = require('moment');

class ProductoPresentacionCombo extends Component {

    constructor(props) {
        super(props);
        this.state = {currentImage: 0, modalIsOpen: false};
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);

        this.photos = [];

        this.lightbox = [];
    }

    openLightbox(event, obj) {
        this.setState({currentImage: obj.index + 1});
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    componentDidMount() {
        let idProducto = this.props.match.params.idProducto;
        this.props.fetchPromocionesIfNeeded({estado: true});
        //this.props.fetchProducto(idProducto);
        this.props.fetchCategoriasIfNeeded({orden: 1});
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        //this.props.resetProducto();
    }

    handleAgregarCantidad(idProducto) {
        let idPromocion = this.props.match.params.idPromocion;
        let promocion = this.props.promociones.byId.promociones[idPromocion];
        //Cantidad
        promocion.producto_origen_promocion.forEach((idProductoOrigenPromocion) => {
            let productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
            let nuevo = {};
            var detalle = clone(this.props.detallesPedido.create.detallesPedido[productoOrigenPromocion.idProducto + "-"]);
            if (!detalle) {
                detalle = {};
                detalle["idProducto"] = productoOrigenPromocion.idProducto;
                detalle["cantidad"] = productoOrigenPromocion.cantidad;
            } else {
                detalle.cantidad = detalle.cantidad + productoOrigenPromocion.cantidad;
            }
            let producto = this.props.productos.byId.productos[productoOrigenPromocion.idProducto];
            detalle.precioUnitario = producto.precio;
            nuevo[productoOrigenPromocion.idProducto + "-"] = detalle;
            this.props.createDetallePedido(nuevo);
        });
        if (!this.mobilecheck() && window.innerWidth > 850)
            this.props.toogleBarraCarrito(true);
    };

    handleQuitarCantidad(idProducto) {
        let idPromocion = this.props.match.params.idPromocion;
        let promocion = this.props.promociones.byId.promociones[idPromocion];
        //Cantidad
        promocion.producto_origen_promocion.forEach((idProductoOrigenPromocion) => {
            let productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
            let nuevo = {};
            var detalle = clone(this.props.detallesPedido.create.detallesPedido[productoOrigenPromocion.idProducto + "-"]);
            if (detalle && detalle.cantidad > productoOrigenPromocion.cantidad) {
                detalle.cantidad = detalle.cantidad - productoOrigenPromocion.cantidad;
                let producto = this.props.productos.byId.productos[productoOrigenPromocion.idProducto];
                detalle.precioUnitario = producto.precio;
                nuevo[productoOrigenPromocion.idProducto + "-"] = detalle;
                this.props.createDetallePedido(nuevo);
            } else {
                var cambio = {};
                cambio[productoOrigenPromocion.idProducto + "-"] = detalle;
                this.props.deleteDetallePedido(cambio);
            }

        });
        if (!this.mobilecheck() && window.innerWidth > 850)
            this.props.toogleBarraCarrito(true);
    };

    mobilecheck() {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
        })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    }

    render() {
        let idPromocion = this.props.match.params.idPromocion;
        let promocion = this.props.promociones.byId.promociones[idPromocion];
        if (!promocion)
            promocion = {};

        //Fotos
        this.photos = [];
        let Imagenes = [];
        if (promocion && promocion.imgPromocion)
            Imagenes.push(<div className="item">
                <Link to={"/producto/"}>
                    <img
                        src={process.env.PUBLIC_URL + "/productos/" + promocion.imgPromocion}
                        alt=""/>
                </Link>
            </div>);

        let vendible = true;
        if (promocion && promocion.producto_origen_promocion)
            promocion.producto_origen_promocion.forEach((idProductoOrigenPromocion) => {
                let productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                let producto = this.props.productos.byId.productos[productoOrigenPromocion.idProducto];
                if (producto.publicar === 0 || producto.noVendible === 1)
                    vendible = false;
                if (producto.imgProducto) {
                    this.photos.push({src: producto.imgProducto ? process.env.PUBLIC_URL + "/productos/" + producto.imgProducto : logoMerca,});
                    Imagenes.push(
                        <div className="item" key={producto.id}>
                            <Link to={"/producto/" + producto.id}>
                                <img
                                    src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}
                                    alt=""/>
                            </Link>
                        </div>
                    )
                }
            });

        //Total
        let total = 0;
        if (promocion && promocion.producto_origen_promocion)
            promocion.producto_origen_promocion.forEach((idProductoOrigenPromocion) => {
                let productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                let producto = this.props.productos.byId.productos[productoOrigenPromocion.idProducto];
                total = total + parseFloat(producto.precio * productoOrigenPromocion.cantidad);
            });
        total = total.toFixed(2);
        let precioProducto = total ? total.split('.') : null;


        //Detalle pedido
        let cantidadComboTotal = 0;
        let combo = true;
        if (promocion && promocion.producto_origen_promocion) {
            promocion.producto_origen_promocion.forEach((idProductoOrigenPromocion) => {
                let encontrado = false;
                let productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                Object.keys(this.props.detallesPedido.create.detallesPedido).forEach((idDetallePedido) => {
                    let detallePedidoGuardado = this.props.detallesPedido.create.detallesPedido[idDetallePedido];
                    if (detallePedidoGuardado.idProducto === productoOrigenPromocion.idProducto && !detallePedidoGuardado.idPromocion) {
                        if (detallePedidoGuardado.cantidad >= productoOrigenPromocion.cantidad) {
                            let cantidadCombo = Math.floor(detallePedidoGuardado.cantidad / productoOrigenPromocion.cantidad);
                            if (cantidadComboTotal === 0 || cantidadComboTotal > cantidadCombo)
                                cantidadComboTotal = cantidadCombo;
                            encontrado = true;
                        }
                    }
                });
                if (!encontrado)
                    combo = false;
            });
        }
        if (!combo)
            cantidadComboTotal = 0;

        //Promocion
        let Promociones = [];
        let Origenes = [];
        let Destino = [];
        if (promocion && promocion.producto_origen_promocion) {
            Origenes = promocion.producto_origen_promocion.map((idProductoOrigenPromocion) => {
                let productoOrigenPromocion = this.props.productosOrigenPromocion.byId.productosOrigenPromocion[idProductoOrigenPromocion];
                let producto = this.props.productos.byId.productos[productoOrigenPromocion.idProducto];
                return (
                    <li>
                        <img
                            src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}
                            alt="" style={{width: "100px"}}/>
                        {
                            promocion.formatoPromocion === "Combo" &&
                            <p>{productoOrigenPromocion.cantidad + " " + (producto.medida ? producto.medida : "unidad") + " de " + producto.nomProducto + " - " + producto.presentacion}</p>
                        }
                        {
                            promocion.formatoPromocion === "Producto individual" &&
                            <p>{producto.nomProducto + " - " + producto.presentacion}</p>
                        }

                    </li>
                )
            });
            if (promocion && promocion.producto_destino_promocion)
                Destino = promocion.producto_destino_promocion.map((idProductoDestinoPromocion) => {
                    let productoDestinoPromocion = this.props.productosDestinoPromocion.byId.productosDestinoPromocion[idProductoDestinoPromocion];
                    let producto = this.props.productos.byId.productos[productoDestinoPromocion.idProducto];
                    return (
                        <li>
                            <img
                                src={process.env.PUBLIC_URL + "/productos/" + producto.imgProducto}
                                alt="" style={{width: "100px"}}/>
                            <p>{promocion.tipoPromocion === "Descuento" ? productoDestinoPromocion.descuento + "% OFF en " + producto.nomProducto + " - " + producto.presentacion : productoDestinoPromocion.cantidad + " " + (producto.medida ? producto.medida : "unidad") + " de " + producto.nomProducto + " - " + producto.presentacion} </p>
                        </li>
                    )
                });
            Promociones.push(
                <div className="promocion">
                    <h4>{`${promocion.nomPromocion}`}</h4>
                    {
                        promocion.formatoPromocion === "Combo" &&
                        <p>Llevando los siguientes productos</p>
                    }
                    {
                        promocion.formatoPromocion === "Producto individual" &&
                        <p>{`Llevando ${promocion.cantidad} unidades de cualquiera de los siguientes productos`}</p>
                    }
                    <ul className="lista-promos">
                        {Origenes}
                    </ul>
                    {
                        promocion.tipoPromocion === "Descuento" &&
                        <p>Tenés descuento en</p>
                    }
                    {
                        promocion.tipoPromocion === "Sin cargo" &&
                        <p>Te regalamos</p>
                    }
                    <ul className="lista-promos">
                        {Destino}
                    </ul>
                </div>
            )
        }
        const tienda = this.props.tiendas.update.activo;
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{(tienda ? tienda.nombreEmpresa : "")} | {promocion.nomPromocion}</title>
                    <meta name="twitter:card" content={promocion.nomPromocion}/>
                    <meta name="twitter:title" content={(tienda ? tienda.nombreEmpresa : "") + ' | ' + promocion.nomPromocion}/>
                    <meta name="twitter:description" content={promocion.resultadoPromocion}/>
                    <meta name="twitter:image"
                          content={process.env.PUBLIC_URL + "/productos/" + promocion.imgPromocion}/>
                    <meta property="og:type" content="website"/>
                    <meta property="fb:app_id" content="659565587823933"/>
                    <meta property="og:title"
                          content={(tienda ? tienda.nombreEmpresa : "") + ' | ' + promocion.nomPromocion}/>
                    <meta property="og:description"
                          content={promocion.nomPromocion}/>
                    <meta property="og:image:width" content="240"/>
                    <meta property="og:image:height" content="240"/>
                    <meta property="og:image"
                          content={process.env.PUBLIC_URL + "/productos/" + promocion.imgPromocion}/>
                    <meta property="og:image"
                          content={process.env.PUBLIC_URL + "/productos/" + promocion.imgPromocion}/>
                    <meta property="og:url"
                          content={process.env.PUBLIC_URL + "/combo/" + promocion.id}/>
                </MetaTags>

                <section className="producto-presentacion" id="producto-presentacion">

                    <div className="container-limit">
                        <section className="container-producto-presentacion">
                            <div className="container-productos">
                                <div className="wrapper-principal">
                                    <div className="producto" key={promocion.id} style={{boxShadow: "none"}}>

                                        <div className="galeria-fotos">
                                            <Gallery columns={4} margin={0} photos={this.photos}
                                                     onClick={this.openLightbox}/>
                                        </div>
                                        <div className="container-img">
                                            {
                                                Imagenes.length > 0 ?
                                                    <CarrouselProductoPresentacion
                                                        idImagenSeleccionada={this.state.currentImage}>
                                                        {Imagenes}
                                                    </CarrouselProductoPresentacion>
                                                    :
                                                    <img src={logoMerca} alt=""/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="wrapper-secundario">
                                    <div className="container-titulo">
                                        <div className="nombre-producto">
                                            <p>{promocion.nomPromocion}</p>
                                        </div>
                                        <div className="detalle-producto">
                                            <p>Combo</p>
                                        </div>
                                    </div>
                                    <div className="container-precios">
                                        <div className="container-botones">
                                            <div className="precios-vigentes">
                                                {/*<div className="costo-inicial">*/}
                                                {/*    <b style={{*/}
                                                {/*        textDecoration: "line-through",*/}
                                                {/*    }}>{producto.precioAnterior && producto.precioAnterior != 0 ? "$" + producto.precioAnterior : ""}</b>*/}
                                                {/*</div>*/}
                                                <div className={"costo-final"}>
                                                    {
                                                        precioProducto && precioProducto[0] && precioProducto[1] &&
                                                        <b>{`$ ${precioProducto[0]}`}<span>{precioProducto[1]}</span></b>
                                                    }
                                                    {
                                                        (!precioProducto || !precioProducto[0] || !precioProducto[1]) &&
                                                        <Link className="registrate" to={"/login"}>Ver precio</Link>
                                                    }
                                                    <p>{(promocion.tipoPromocion === "Descuento" ? promocion.resultadoPromocion : "")}</p>
                                                </div>
                                                {/*<div className="container-stock">*/}
                                                {/*    {*/}
                                                {/*        <p>Stock disponible</p>*/}
                                                {/*    }*/}
                                                {/*</div>*/}
                                                {
                                                    (vendible === true && promocion.estado === 1) &&
                                                    <div className="mas-info">
                                                        <p className="etiqueta-promo">{promocion.resultadoPromocion}</p>
                                                        {
                                                            promocion.estado === 1 &&
                                                            <div className="cantidad-precio">
                                                                <p>Cantidad:</p>

                                                                <div className="container-cantidad">
                                                                    <div className="btn-restar"
                                                                         onClick={(a) => {
                                                                             a.stopPropagation();
                                                                             this.handleQuitarCantidad(promocion.id);
                                                                         }}>&#8722;</div>
                                                                    <input type="number" min={0}
                                                                           value={cantidadComboTotal ? cantidadComboTotal : 0}
                                                                           disabled={true}/>
                                                                    <div className="btn-sumar"
                                                                         onClick={(a) => {
                                                                             a.stopPropagation();
                                                                             this.handleAgregarCantidad(promocion.id)
                                                                         }}>&#43;</div>
                                                                </div>


                                                            </div>
                                                        }
                                                    </div>

                                                }
                                            </div>
                                            {
                                                (vendible === true && promocion.estado === 1) &&
                                                <button className="encargar"
                                                        onClick={() => this.handleAgregarCantidad(promocion.id)}>
                                                    COMPRAR <span><Cart/></span>
                                                </button>
                                            }
                                            {
                                                vendible === false &&
                                                <a alt="Chatea con nosotros"
                                                   href={`${tienda ? tienda.whatsappLink : ""}?text=Hola! Quisiera consultar por el combo ${promocion.nomPromocion}. Gracias!`}
                                                   target="_blank"
                                                   className={"button-consultar"}
                                                   onClick={() => {
                                                       this.props.saveCreateAnalitica("combo/" + promocion.id, TipoAnalitica.CLICK_WHATSAPP_ID);
                                                   }}>
                                                    <button className="encargar" disabled={false}
                                                            style={{height: "100%"}}>
                                                        Consultar <span><Happy height="15px"/></span>
                                                    </button>
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-productos">
                                <div className="wrapper-principal">
                                    <div className="container-precio-detalle">
                                        {/*<div className="container-detalle">*/}
                                        {/*    <div className="detalles-features">*/}
                                        {/*        <h3 className="titulo-secc-producto">*/}
                                        {/*            Características*/}
                                        {/*        </h3>*/}
                                        {/*        <ul>*/}
                                        {/*            <li>*/}
                                        {/*                <b>Presentación</b> <span>Caja</span>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <b>Contenido</b> <span>Caja</span>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <b>Tipo</b> <span>Caja</span>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <b>Presentación</b> <span>Caja</span>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <b>Presentación</b> <span>Caja</span>*/}
                                        {/*            </li>*/}
                                        {/*        </ul>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {
                                            Promociones.length > 0 &&

                                            <div className="descripcion-producto" style={{paddingTop: "32px"}}>
                                                <h3 className="titulo-secc-producto">
                                                    Promociones vigentes
                                                </h3>
                                                {Promociones}
                                            </div>
                                        }
                                        {/*<div className="opiones-producto" style={{paddingTop: "32px"}}>*/}
                                        {/*    <h3 className="titulo-secc-producto">*/}
                                        {/*        Opiniones sobre el producto*/}
                                        {/*    </h3>*/}
                                        {/*    <ListaReviews/>*/}
                                        {/*</div>*/}

                                        {/*<div className="comentarios-section" style={{paddingTop: "32px"}}>*/}
                                        {/*    <h3 className="titulo-secc-producto">*/}
                                        {/*        Preguntas y respuestas*/}
                                        {/*    </h3>*/}
                                        {/*    <ListaPreguntasRespuestas/>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                                <div className="wrapper-secundario">

                                </div>
                            </div>
                        </section>

                        {/*<section className="productos-relacionados">*/}
                        {/*    <h3>Productos relacionados</h3>*/}
                        {/*    <OwlCarousel options={{*/}
                        {/*        // items: 5,*/}
                        {/*        nav: true,*/}
                        {/*        navText: ["", ""],*/}
                        {/*        rewind: false,*/}
                        {/*        autoplay: false,*/}
                        {/*        loop: false,*/}
                        {/*        dots: false,*/}
                        {/*        responsive: {*/}
                        {/*            // breakpoint from 0 up*/}
                        {/*            0: {*/}
                        {/*                items: 2*/}
                        {/*            },*/}
                        {/*            // breakpoint from 480 up*/}
                        {/*            480: {*/}
                        {/*                items: 2*/}
                        {/*            },*/}
                        {/*            // breakpoint from 768 up*/}
                        {/*            768: {*/}
                        {/*                items: 3,*/}
                        {/*            },*/}
                        {/*            1000: {*/}
                        {/*                items: 4,*/}
                        {/*            },*/}
                        {/*            1300: {*/}
                        {/*                items: 5,*/}
                        {/*            }*/}
                        {/*        }*/}
                        {/*    }}>*/}
                        {/*        <ProductoRelacionado/>*/}
                        {/*        <ProductoRelacionado/>*/}
                        {/*        <ProductoRelacionado/>*/}
                        {/*        <ProductoRelacionado/>*/}
                        {/*        <ProductoRelacionado/>*/}
                        {/*        <ProductoRelacionado/>*/}
                        {/*        <ProductoRelacionado/>*/}
                        {/*    </OwlCarousel>*/}
                        {/*</section>*/}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        detallesPedido: state.detallesPedido,
        productos: state.productos,
        pedidos: state.pedidos,
        categorias: state.categorias,
        promociones: state.promociones,
        productosOrigenPromocion: state.productosOrigenPromocion,
        productosDestinoPromocion: state.productosDestinoPromocion,
        tiendas: state.tiendas

    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProducto: (idProducto) => {
            dispatch(fetchProducto(idProducto));
        },
        createDetallePedido: (detalle) => {
            dispatch(createDetallePedido(detalle))
        },
        resetProducto: () => {
            dispatch(resetProducto())
        },
        deleteDetallePedido: (detalle) => {
            dispatch(deleteDetallePedido(detalle))
        },
        fetchCategoriasIfNeeded: (filtros) => {
            dispatch(fetchCategoriasIfNeeded(filtros))
        },
        fetchPromocionesIfNeeded: (filtros) => {
            dispatch(fetchPromocionesIfNeeded(filtros))
        },
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductoPresentacionCombo));

