import {RECEIVE_PROMOCIONES, RESET_PROMOCIONES} from '../actions/PromocionActions';
import {combineReducers} from 'redux';

import merge from "lodash/merge";

function productosDestinoPromocionById(state = {
    isFetching: false,
    didInvalidate: true,
    productosDestinoPromocion: {}
}, action) {
    switch (action.type) {
        case RECEIVE_PROMOCIONES:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                productosDestinoPromocion: merge({}, state.productosDestinoPromocion, action.promociones.entities.productosDestinoPromocion),
                lastUpdated: action.receivedAt
            });
        case RESET_PROMOCIONES:
            return Object.assign({}, state, {
                isFetching: false,
                productosDestinoPromocion: {},
                error: null
            });
        default:
            return state
    }
}


function allProductosDestinoPromocion(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}


const productosDestinoPromocion = combineReducers({
    byId: productosDestinoPromocionById,
    allIds: allProductosDestinoPromocion
});

export default productosDestinoPromocion;