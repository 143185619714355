import {normalize, schema} from 'normalizr';

function normalizeDatos(myData) {
    const bulto = new schema.Entity('bultos', {}, {idAttribute: "id"});
    const remito = new schema.Entity('remitos', {bulto: [bulto]}, {idAttribute: "id"});
    const packingList = new schema.Entity('packingList', {bulto: bulto}, {idAttribute: "id"});
    const detallesRemitido = new schema.Entity('detallesRemitidos', {remito: remito, packing_list: [packingList]});
    const mySchema = [detallesRemitido];
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export default normalizeDatos;