import {RECEIVE_DETALLESPEDIDO, RESET_DETALLESPEDIDO} from '../actions/DetallePedidoActions';
import {
    CREATE_ESTADODETALLEPEDIDO,
    ERROR_CREATE_ESTADODETALLEPEDIDO,
    ERROR_UPDATE_ESTADODETALLEPEDIDO,
    REQUEST_CREATE_ESTADODETALLEPEDIDO,
    REQUEST_UPDATE_ESTADODETALLEPEDIDO,
    SUCCESS_CREATE_ESTADODETALLEPEDIDO,
    SUCCESS_UPDATE_ESTADODETALLEPEDIDO,
    UPDATE_ESTADODETALLEPEDIDO
} from '../actions/EstadoDetallePedidoActions';
import {combineReducers} from 'redux';

import merge from "lodash/merge";
import union from "lodash/union";

function estadosDetallePedidoById(state = {
    isFetching: false,
    didInvalidate: true,
    estadosDetallePedido: []
}, action) {
    switch (action.type) {
        case RECEIVE_DETALLESPEDIDO:
            var estadosDetallePedido = action.detallesPedido.entities.estadosDetallePedido;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                estadosDetallePedido: estadosDetallePedido ? estadosDetallePedido : [],
                lastUpdated: action.receivedAt
            });
        case RESET_DETALLESPEDIDO:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                estadosDetallePedido: [],
            });
        case CREATE_ESTADODETALLEPEDIDO:
            var estado = {};
            estado[action.id] = action.estadoDetallePedido;
            return Object.assign({}, state, {
                isFetching: false,
                estadosDetallePedido: merge({}, state.estadosDetallePedido, estado)
            });
        case UPDATE_ESTADODETALLEPEDIDO:
            var estado = {};
            estado[action.id] = action.estadoDetallePedido;
            return Object.assign({}, state, {
                isFetching: false,
                estadosDetallePedido: merge({}, state.estadosDetallePedido, estado)
            });
        default:
            return state
    }
}


function allEstadosDetallePedidoMon(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}

function create(state = {isCreating: false, estadosDetallePedido: [], error: ""}, action) {
    switch (action.type) {
        case CREATE_ESTADODETALLEPEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                estadosDetallePedido: union(state.estadosDetallePedido, [action.id])
            });
        case REQUEST_CREATE_ESTADODETALLEPEDIDO:
            return Object.assign({}, state, {
                isCreating: true
            });
        case SUCCESS_CREATE_ESTADODETALLEPEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                estadosDetallePedido: []
            });
        case ERROR_CREATE_ESTADODETALLEPEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_DETALLESPEDIDO:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                estadosDetallePedido: [],
            });
        default:
            return state
    }
}

function update(state = {isUpdating: false, estadosDetallePedido: [], error: ""}, action) {
    switch (action.type) {
        case UPDATE_ESTADODETALLEPEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                estadosDetallePedido: union(state.estadosDetallePedido, [action.id])
            });
        case REQUEST_UPDATE_ESTADODETALLEPEDIDO:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case SUCCESS_UPDATE_ESTADODETALLEPEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                estadosDetallePedido: []
            });
        case ERROR_UPDATE_ESTADODETALLEPEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_DETALLESPEDIDO:
            return Object.assign({}, state, {
                isUpdating: false,
                error: null,
                estadosDetallePedido: [],
            });
        default:
            return state
    }
}


const estadosDetallePedido = combineReducers({
    byId: estadosDetallePedidoById,
    allIds: allEstadosDetallePedidoMon,
    create: create,
    update: update
});

export default estadosDetallePedido;