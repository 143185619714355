import React, {Component} from 'react';
import {ConfiguracionContext} from "../../utils/Context"

import '../../assets/css/Faqs.css';

class Faqs extends Component {
    render() {
        let BLOQUE1 = [];
        let BLOQUE2 = [];
        let Configuracion = this.context;

        if(Configuracion.FAQS) {

            let cantPreguntas = Configuracion.FAQS.length;
            let cantPrimerBloque = parseInt(Math.floor(cantPreguntas / 2));


            Configuracion.FAQS.forEach((pregunta, i) => {
                if (i < cantPrimerBloque) {
                    BLOQUE1.push(
                        <li key={"faq"+i}>
                            <div className="pregunta">
                                <p>{pregunta.pregunta}</p>
                            </div>
                            <div className="respuesta">
                                <p>
                                    {pregunta.respuesta}
                                </p>
                            </div>
                        </li>
                    )
                } else {
                    BLOQUE2.push(
                        <li key={"faq"+i}>
                            <div className="pregunta">
                                <p>{pregunta.pregunta}</p>
                            </div>
                            <div className="respuesta">
                                <p>
                                    {pregunta.respuesta}
                                </p>
                            </div>
                        </li>
                    )
                }
            })
        }

        return (
            <section className="faqs" id="faqs">
                <div className="container-limit">
                    <h2 style={{margin: "0px", padding: "0px 5px"}} className="titulo-seccion titulo-home titulo-black">
                        PREGUNTAS <i>FRECUENTES</i>
                    </h2>
                    <div className="container-doble">
                        <div className="col-6">
                            <ul>
                                {BLOQUE1}
                            </ul>
                        </div>
                        <div className="col-6">
                            <ul>
                                {BLOQUE2}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}
Faqs.contextType = ConfiguracionContext;

export default Faqs;
