import React from 'react';

import c from '../constants/Constants';

require('es6-promise').polyfill();
require('isomorphic-fetch');

var estadosRemito = {
    save(estado, idRemito) {

        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json',
            body: JSON.stringify({desEstadoRemito: estado, idRemito: idRemito})
        };

        return fetch(c.BASE_URL + '/estadosRemito/', defaultOptions);
    },
    cerrar() {

        let defaultOptions = {
            url: '',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": "Bearer " + localStorage.token
            },
            // cache: false,
            dataType: 'json'
        };

        return fetch(c.BASE_URL + '/estadosRemito/all/', defaultOptions);
    }

};

export default estadosRemito;