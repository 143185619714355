//api
import zonasEntrega from "../api/zonas"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDato, normalizeDatos} from "../normalizers/normalizeZonasEntrega";

//ZONASENTREGA
export const REQUEST_ZONASENTREGA = 'REQUEST_ZONASENTREGA';
export const RECEIVE_ZONASENTREGA = 'RECEIVE_ZONASENTREGA';
export const INVALIDATE_ZONASENTREGA = 'INVALIDATE_ZONASENTREGA';
export const ERROR_ZONASENTREGA = "ERROR_ZONASENTREGA";
export const RESET_ZONASENTREGA = "RESET_ZONASENTREGA";


export function invalidateZonasEntrega() {
    return {
        type: INVALIDATE_ZONASENTREGA
    }
}

function requestZonasEntrega() {
    return {
        type: REQUEST_ZONASENTREGA,
    }
}

function receiveZonasEntrega(json) {
    return {
        type: RECEIVE_ZONASENTREGA,
        zonasEntrega: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorZonasEntrega(error) {
    return {
        type: ERROR_ZONASENTREGA,
        error: error,
    }
}

export function resetZonasEntrega() {
    return {
        type: RESET_ZONASENTREGA
    }
}

export function fetchZonasEntrega(filtros) {
    return dispatch => {
        dispatch(requestZonasEntrega());
        return zonasEntrega.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveZonasEntrega(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorZonasEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorZonasEntrega(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchZonasEntrega(state) {
    const zonasEntrega = state.zonasEntrega.byId;
    if (!zonasEntrega) {
        return true
    } else if (zonasEntrega.isFetching) {
        return false
    } else {
        return zonasEntrega.didInvalidate;
    }
}

export function fetchZonasEntregaIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchZonasEntrega(getState())) {
            return dispatch(fetchZonasEntrega(filtros))
        }
    }
}


//MODEL
export const REQUEST_ZONAENTREGA = 'REQUEST_ZONAENTREGA';
export const RECEIVE_ZONAENTREGA = 'RECEIVE_ZONAENTREGA';
export const INVALIDATE_ZONAENTREGA = 'INVALIDATE_ZONAENTREGA';
export const ERROR_ZONAENTREGA = "ERROR_ZONAENTREGA";
export const RESET_ZONAENTREGA = "RESET_ZONAENTREGA";


export function invalidateZonaEntrega() {
    return {
        type: INVALIDATE_ZONAENTREGA
    }
}

function requestZonaEntrega() {
    return {
        type: REQUEST_ZONAENTREGA,
    }
}

function receiveZonaEntrega(json) {
    return {
        type: RECEIVE_ZONAENTREGA,
        zonasEntrega: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorZonaEntrega(error) {
    return {
        type: ERROR_ZONAENTREGA,
        error: error,
    }
}

export function resetZona() {
    return {
        type: RESET_ZONAENTREGA
    }
}

export function fetchZona(idZona) {
    return dispatch => {
        dispatch(requestZonaEntrega());
        return zonasEntrega.getOne(idZona)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveZonaEntrega(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorZonaEntrega(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorZonaEntrega(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}