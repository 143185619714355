import {RECEIVE_PRODUCTO, RECEIVE_PRODUCTOS} from '../actions/ProductoActions';
import {RECEIVE_PEDIDO} from "../actions/PedidoActions"
import {combineReducers} from 'redux';
import merge from "lodash/merge"
import {loadFromLS} from "../localeStorage/ls"

function getInitialStateById() {
    let comprasComunitariasGuardados = loadFromLS("comprasComunitarias");
    if (comprasComunitariasGuardados)
        return comprasComunitariasGuardados.byId;
    else
        return {
            isFetching: false,
            didInvalidate: true,
            comprasComunitarias: {}
        }
}

function comprasComunitariasById(state = getInitialStateById(), action) {
    let dato = null;
    switch (action.type) {
        case RECEIVE_PRODUCTOS:
            dato = action.productos.entities.comprasComunitarias;
            return Object.assign({}, state, {
                comprasComunitarias: dato ? dato : [],
                lastUpdated: action.receivedAt
            });
        case RECEIVE_PRODUCTO:
            dato = action.producto.entities.comprasComunitarias;
            return Object.assign({}, state, {
                comprasComunitarias: merge({}, state.comprasComunitarias, dato),
                lastUpdated: action.receivedAt
            });
        case RECEIVE_PEDIDO:
            dato = action.pedido.entities.comprasComunitarias;
            return Object.assign({}, state, {
                comprasComunitarias: merge({}, state.comprasComunitarias, dato),
                lastUpdated: action.receivedAt
            });
        default:
            return state
    }
}


function allComprasComunitarias(state = [], action) {
    switch (action.type) {
        default:
            return state
    }
}


const comprasComunitarias = combineReducers({
    byId: comprasComunitariasById,
    allIds: allComprasComunitarias
});

export default comprasComunitarias;