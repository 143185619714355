import React, {useEffect, useState} from 'react';
import Swiper from 'react-id-swiper';
//css
import '../../../assets/css/CarrouselCategorias.css';

//Actions

//img


export default function CarrouselCategorias(props) {
    const [swiper, updateSwiper] = useState(null);
    const [isBegginig, updateIsBeggining] = useState(null);
    const [isEnd, updateIsEnd] = useState(null);

    // Swiper instance
    useEffect(
        () => {
            if (swiper !== null) {
                updateIsBeggining(swiper.isBeginning);
                updateIsEnd(swiper.isEnd);
                swiper.on("slideChange", () => {
                    updateIsBeggining(swiper.isBeginning);
                    updateIsEnd(swiper.isEnd);
                });
            }
        },
        [swiper && (swiper.isEnd || swiper.isBeggining)]
    );

    const params = {
        mousewheel: {
            invert: true,
            forceToAxis: true
        },
        speed: 200,
        freeMode: true,
        autoWidth: true,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        normalizeSlideIndex: false,
        getSwiper: updateSwiper, // Get swiper instance callback
    };

    // Manipulate swiper from outside
    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    return (
        <React.Fragment>
            <div className="container-limit">
                <Swiper {...params} shouldSwiperUpdate updateOnWindowResize>
                    {props.children}
                </Swiper>
            </div>
            <div className="carrousel-buttons">
                <div className={`swiper-button-prev ${isBegginig && "swiper-button-disabled"}`} onClick={goPrev}>
                </div>
                <div className={`swiper-button-next ${isEnd && "swiper-button-disabled"}`} onClick={goNext}>
                </div>
            </div>
        </React.Fragment>
    );
}