import {normalize, schema, denormalize} from 'normalizr';

export function normalizeDatos(myData) {
    const provincia = new schema.Entity('provincias', {}, {idAttribute: "id"});

    const mySchema = {provincias: [provincia]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}


export function normalizeDato(myData) {

    const provincia = new schema.Entity('provincias', {}, {idAttribute: "id"});

    const mySchema = provincia;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}

export function denormalizeDato(myData, store) {

    const provincia = new schema.Entity('provincias', {}, {idAttribute: "id"});

    const mySchema = provincia;

    const normalizedData = denormalize(myData, mySchema, store);

    return normalizedData;
}


