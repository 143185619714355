import React from "react";
import '../../../assets/css/Loader.css';


export default class Loader extends React.Component {

    render() {
        let {width} = this.props;
        if (!width)
            width = "40px";
        return (
            <div className="spinner-div">
                <svg className="spinner" width={width} height={width} viewBox="0 0 66 66"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33"
                            r="30"></circle>
                </svg>
            </div>
        )
    }
}
