import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {
    const stock = new schema.Entity('stocks', {}, {idAttribute: "id"});
    const stockVariedad = new schema.Entity('stocksVariedad', {stock: stock}, {idAttribute: "id"});
    const variedadCaracteristica = new schema.Entity('variedadesCaracteristica', {stock_variedad: [stockVariedad]}, {idAttribute: "id"});
    const caracteristicaProducto = new schema.Entity('caracteristicasProducto', {variedad_caracteristica: [variedadCaracteristica]}, {idAttribute: "id"});
    const producto = new schema.Entity('productos', {caracteristica_producto: [caracteristicaProducto]}, {idAttribute: "id"});
    const marca = new schema.Entity('marcas', {producto: [producto]}, {idAttribute: "id"});
    const mySchema = [marca];
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export function normalizeDato(myData) {
    const stock = new schema.Entity('stocks', {}, {idAttribute: "id"});
    const stockVariedad = new schema.Entity('stocksVariedad', {stock: stock}, {idAttribute: "id"});
    const variedadCaracteristica = new schema.Entity('variedadesCaracteristica', {stock_variedad: [stockVariedad]}, {idAttribute: "id"});
    const caracteristicaProducto = new schema.Entity('caracteristicasProducto', {variedad_caracteristica: [variedadCaracteristica]}, {idAttribute: "id"});
    const producto = new schema.Entity('productos', {caracteristica_producto: [caracteristicaProducto]}, {idAttribute: "id"});
    const marca = new schema.Entity('marcas', {producto: [producto]}, {idAttribute: "id"});
    const mySchema = marca;
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}