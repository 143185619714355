import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom"
//css
import '../../assets/css/LoginSignUp.css';
//Actions
import {activarCuenta} from '../../actions/AuthActions';
//components
import Loader from "./layout/Loader"
import authUtil from "../../utils/auth";


class ActivarCuenta extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.body.classList.add("no-scroll");
        window.scrollTo(0, 0);
        if (this.props.match.params.token)
            this.props.activarCuenta(this.props.match.params.token);
    }

    componentWillUnmount() {
        document.body.classList.remove("no-scroll");
    }

    render() {
        const tienda = this.props.tiendas.update.activo;
        return (
            <section className="login-sign-up">
                <div className="container-modal">
                    <div className="container-doble">
                        <div className="col-3 container-marca">
                            {
                                tienda && tienda.logoBlanco &&
                                <img src={`${process.env.PUBLIC_URL}/images/logos/${tienda && tienda.logoBlanco ? tienda.logoBlanco : ""}`}
                                    className="logo"/>
                            }
                            {
                                !authUtil.isLoggedIn() &&
                                <div className="container-yaregistrado">
                                    <p>
                                        Si ya estás registrado sólo inicia sesión
                                    </p>
                                    <Link to="/login">
                                        <button>
                                            INICIAR SESIÓN
                                        </button>
                                    </Link>
                                </div>
                            }
                        </div>
                        <div className="col-9">
                            <div className="container-datos">
                                <div className="cruz-cerrar" onClick={() => {
                                    this.props.history.push("/")
                                }}>&times;</div>
                                <h2>ACTIVAR <i>CUENTA</i></h2>
                                <p style={{
                                    padding: "2px 15px",
                                    margin: "0",
                                    color: "#ea4e69",
                                    fontWeight: "300"
                                }}>{this.props.auth.errorMessage}</p>
                                {
                                    this.props.auth.mensaje &&
                                    <div className="mensajeExito">
                                        <p>{this.props.auth.mensaje}</p>
                                        <Link to="/">
                                            <button>
                                                CONTINUAR
                                            </button>
                                        </Link>
                                    </div>
                                }
                                {
                                    this.props.auth.isFetching &&
                                    <Loader/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        usuarios: state.usuarios,
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        activarCuenta: (token) => {
            dispatch(activarCuenta(token))
        },

    }
};
// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(ActivarCuenta);

