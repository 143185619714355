import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom"
import '../../../../assets/css/LoginSignUp.css';
//Actions
import {createUsuario, resetCreateUsuario, saveCreateUsuario} from '../../../../actions/UsuarioActions';
import {saveCreateAnalitica} from "../../../../actions/AnaliticaActions";
//components
import Loader from "./../../layout/Loader"
//constants
import * as TipoAnalitica from "../../../../constants/TipoAnalitica";
import authUtil from "../../../../utils/auth";
import {resetCreateDireccionEntrega} from "../../../../actions/DireccionEntregaActions";


class SignUp extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.body.classList.add("no-scroll");
        // window.addEventListener('click', (e) => this.cierraModal(e));
        window.scrollTo(0, 0)
    }

    componentWillUnmount() {
        // window.removeEventListener('click', (e) => this.cierraModal(e));
        document.body.classList.remove("no-scroll");
        document.body.style.overflowY = "scroll";
        this.props.resetCreateUsuario();
        this.props.resetCreateDireccionEntrega();
    }

    onChangeUsuario(e) {
        var cambio = {};
        cambio[e.target.id] = e.target.value;
        this.props.createUsuario(cambio);
    }

    submitRegistro(e) {
        e.preventDefault();
        this.props.saveCreateUsuario(this.props.usuarios.create.nuevo);
        this.props.saveCreateAnalitica(null, TipoAnalitica.REGISTRO_ID);
    }

    cierraModal(e) {
        let modal = document.getElementById('modal-sign-up') ? document.getElementById('modal-sign-up').contains(e.target) : null
        if (!modal) {
            this.props.history.push("/")
        }
    }

    render() {
        const usuario = this.props.usuarios.create.nuevo;
        let tienda = this.props.tiendas.update.activo;

        //error de alta de usuario
        let Errores = [];
        let error = this.props.usuarios.create.error;
        try {
            let err = JSON.parse(error);
            if (err && typeof (err) == "object") {
                Errores = Object.values(err).map((er, index) => {
                    return <p key={er[0] + index}>{er[0]}</p>;
                });
            }
        } catch (e) {
            Errores = <p>{error}</p>
        }

        return (
            <section className="login-sign-up">
                <div className="container-modal" id={"modal-sign-up"}>
                    <div className="container-doble">
                        <div className="col-3 container-marca">
                            {
                                window.innerWidth < 714 &&
                                <div className="cruz-cerrar" onClick={() => {
                                    this.props.history.push("/")
                                }}>&times;</div>
                            }
                            {
                                tienda && tienda.logoBlanco &&
                                <img src={`${process.env.PUBLIC_URL}/images/logos/${tienda && tienda.logoBlanco ? tienda.logoBlanco : ""}`}
                                    className="logo"/>
                            }
                            {
                                !authUtil.isLoggedIn() &&
                                <div className="container-yaregistrado">
                                    <p>
                                        Si ya estás registrado sólo inicia sesión
                                    </p>
                                    <Link to="/login">
                                        <button>
                                            INICIAR SESIÓN
                                        </button>
                                    </Link>
                                </div>
                            }
                        </div>
                        <div className="col-9">
                            <div className="container-datos">
                                {
                                    window.innerWidth > 714 &&
                                    <div className="cruz-cerrar" onClick={() => {
                                        this.props.history.push("/")
                                    }}>&times;</div>
                                }
                                <h2>CREAR <i>CUENTA</i></h2>

                                {/*<div className="container-redes">*/}
                                {/*<FontAwesomeIcon icon={faFacebookF} color="black"/>*/}
                                {/*<FontAwesomeIcon icon={faGoogle} color="black"/>*/}
                                {/*</div>*/}
                                <form onSubmit={(e) => this.submitRegistro(e)}
                                      style={{display: this.props.usuarios.create.mensaje ? "none" : "flex"}}>
                                    <p>Usá tu e-mail para registrarte</p>
                                    <input placeholder="Nombre" type="text" id="nombre" name="nombre" required={true}
                                           value={usuario.nombre}
                                           onChange={(e) => this.onChangeUsuario(e)}/>
                                    <input placeholder="Apellido" type="text" id="apellido" name="apellido"
                                           required={true}
                                           value={usuario.apellido}
                                           onChange={(e) => this.onChangeUsuario(e)}/>
                                    <input placeholder="E-mail" type="text" id="email" name="email" required={true}
                                           value={usuario.email}
                                           onChange={(e) => this.onChangeUsuario(e)}/>
                                    <input placeholder="Contraseña" type="password" id="password" minLength="8"
                                           required={true}
                                           value={usuario.password}
                                           onChange={(e) => this.onChangeUsuario(e)}/>
                                    <input placeholder="Repetir contraseña" type="password" id="password_confirmation"
                                           minLength="8" required={true}
                                           value={usuario.password_confirmation}
                                           onChange={(e) => this.onChangeUsuario(e)}/>
                                    <div className="errores">{Errores}</div>
                                    {
                                        !this.props.usuarios.create.isCreating &&
                                        <button type="submit" name={"finalizarRegistro"}>REGISTRATE</button>
                                    }
                                    {
                                        this.props.usuarios.create.isCreating &&
                                        <Loader/>
                                    }

                                </form>
                                {
                                    this.props.usuarios.create.mensaje &&
                                    <div className="mensajeExito">
                                        <p>{this.props.usuarios.create.mensaje}</p>
                                        <Link to="/">
                                            <button>
                                                CONTINUAR
                                            </button>
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        catedras: state.catedras,
        usuarios: state.usuarios,
        tiendas: state.tiendas
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createUsuario: (usuario) => {
            dispatch(createUsuario(usuario))
        },
        saveCreateUsuario: (usuario) => {
            dispatch(saveCreateUsuario(usuario))
        },
        saveCreateAnalitica: (analitica, tipo) => {
            dispatch(saveCreateAnalitica(analitica, tipo))
        },
        resetCreateUsuario: () => {
            dispatch(resetCreateUsuario());
        },
        resetCreateDireccionEntrega: () => {
            dispatch(resetCreateDireccionEntrega());
        }
    }
};

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

