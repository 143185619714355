const Configuracion = (function () {
    var configuracion = null;

    function createConfiguracion() {
        return fetch(window.config.src)
            .then((r) => {
                return r.json();
            })
            .then((json) => {
                configuracion = json;
                return Promise.resolve(json);
            });
    }

    return {
         getConfiguracion: async function () {
            if (!configuracion) {
                return await createConfiguracion();

            } else
                return configuracion;
        }
    };
})();
export default Configuracion;