import {
    INVALIDATE_BANNERS,
    ERROR_BANNERS,
    RECEIVE_BANNERS,
    REQUEST_BANNERS,
    RESET_BANNERS,
    ERROR_BANNER,
    RECEIVE_BANNER,
    REQUEST_BANNER,
    UPDATE_BANNER,
    REQUEST_UPDATE_BANNER,
    SUCCESS_UPDATE_BANNER,
    ERROR_UPDATE_BANNER,
    RESET_UPDATE_BANNER,
    CREATE_BANNER,
    ERROR_CREATE_BANNER,
    REQUEST_CREATE_BANNER,
    RESET_CREATE_BANNER,
    SUCCESS_CREATE_BANNER,
    DELETE_BANNER,
    REQUEST_DELETE_BANNER,
    SUCCESS_DELETE_BANNER,
    ERROR_DELETE_BANNER,
    RESET_DELETE_BANNER,

} from '../actions/BannersActions';
import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import union from "lodash/union";
import {RECEIVE_PEDIDO, RECEIVE_PEDIDOS, SUCCESS_UPDATE_PEDIDO} from "../actions/PedidoActions";
import {SUCCESS_CREATE_PRODUCTO} from "../actions/ProductoActions";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        banners: {},
    }
}

function bannersById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_BANNERS:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_BANNERS:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_BANNERS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_BANNERS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                banners: {}
            });
        case RECEIVE_BANNERS:
            let dato = action.banners.entities.banners;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                banners: merge({}, state.banners, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_BANNER:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_BANNER:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_BANNER:
            let datoBanner = action.banner.entities.banners;
            return Object.assign({}, state, {
                banners: merge({}, state.banners, datoBanner),
                isFetching: false,
            });
        case SUCCESS_DELETE_BANNER:
            let datoBannerEliminado = action.banner.entities.banners;
            return Object.assign({}, state, {
                banners: merge({}, state.banners, datoBannerEliminado),
            });
        case SUCCESS_CREATE_BANNER:
            let datoBannerCreado = action.banner.entities.banners;
            return Object.assign({}, state, {
                banners: merge({}, state.banners, datoBannerCreado),
            });
        case SUCCESS_UPDATE_BANNER:
            let datoBannerActualizado = action.banner.entities.banners;
            return Object.assign({}, state, {
                banners: merge({}, state.banners, datoBannerActualizado),
            });
        default:
            return state
    }
}


function allBanners(state = [], action) {
    switch (action.type) {
        case RECEIVE_BANNERS:
            return action.banners.result.banners;
        case SUCCESS_CREATE_BANNER:
            let datoBannerCreado = action.banner.entities.banners;
            return union(state, [Object.values(datoBannerCreado)[0].id]);
        case RESET_BANNERS:
            return [];
        default:
            return state
    }
}

function totalBanners(state = null, action) {
    switch (action.type) {
        case RECEIVE_BANNERS:
            return action.banners && action.banners.result.total ? action.banners.result.total : 0;
        case RESET_BANNERS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {}
}, action) {
    switch (action.type) {
        case RECEIVE_BANNER:
            let dato = action.banner.entities.banners;
            let banner = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: banner ? banner : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_BANNER:
            return merge({}, state, {
                activo: action.banner,
                error: ""
            });
        case REQUEST_UPDATE_BANNER:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_BANNER:
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null
            });
        case ERROR_UPDATE_BANNER:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_UPDATE_BANNER:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        case SUCCESS_DELETE_BANNER:
            let datoBannerEliminado = action.banner.entities.banners;
            return Object.assign({}, state, {
                activo: Object.values(datoBannerEliminado).length > 0 ? Object.values(datoBannerEliminado)[0] : {},
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_BANNER:
            return merge({}, state, {
                isCreating: false,
                nuevo: action.banner,
                error: null,
            });
        case REQUEST_CREATE_BANNER:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_BANNER:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
            });
        case ERROR_CREATE_BANNER:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_BANNER:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_BANNER:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.banner,
                error: null,
            });
        case REQUEST_DELETE_BANNER:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_BANNER:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_BANNER:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_BANNER:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

const banners = combineReducers({
    byId: bannersById,
    allIds: allBanners,
    update: update,
    create: create,
    totalBanners: totalBanners,
    delete: deleter
});

export default banners;