import c from '../constants/Constants';

require('es6-promise').polyfill();
require('isomorphic-fetch');


var productos = {

    getAll(filtros) {
        var esc = encodeURIComponent;
        var query = "";
        if (filtros)
            query = Object.keys(filtros)
                .map(k => esc(k) + '=' + esc(filtros[k]))
                .join('&');

        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8"
            },
            // cache: false,
            dataType: 'json',
        };
        if (!!localStorage.token)
            defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
        return fetch(c.BASE_URL + '/productos/?' + query, defaultOptions);
    },

    getOne(idProducto) {
        let defaultOptions = {
            url: '',
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json;charset=UTF-8",
            },
            // cache: false,
            dataType: 'json',
        };
        if (!!localStorage.token)
            defaultOptions.headers["Authorization"] = "Bearer " + localStorage.token;
        return fetch(c.BASE_URL + '/productos/' + idProducto, defaultOptions);
    },
};

export default productos;