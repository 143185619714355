import {SERVICEWORKER_INIT, SERVICEWORKER_UPDATE} from '../actions/ServiceWorkerActions';
import {combineReducers} from 'redux';

function serviceWorkerReducer(state = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null,
}, action) {
    switch (action.type) {
        case SERVICEWORKER_INIT:
            return {
                ...state,
                serviceWorkerInitialized: !state.serviceWorkerInitialized,
            };
        case SERVICEWORKER_UPDATE:
            return {
                ...state,
                serviceWorkerUpdated: !state.serviceWorkerUpdated,
                serviceWorkerRegistration: action.payload,
            };
        default:
            return state;
    }
}

const serviceWorker = combineReducers({
    serviceWorker: serviceWorkerReducer
});

export default serviceWorker;