import formasPago from "../api/formasPago"
import * as errorMessages from '../constants/MessageConstants';
//normalizers
import {normalizeDatos} from "../normalizers/normalizeFormasPago";


//FORMASPAGO
export const REQUEST_FORMASPAGO = 'REQUEST_FORMASPAGO';
export const RECEIVE_FORMASPAGO = 'RECEIVE_FORMASPAGO';
export const INVALIDATE_FORMASPAGO = 'INVALIDATE_FORMASPAGO';
export const ERROR_FORMASPAGO = "ERROR_FORMASPAGO";


export function invalidateFormasPago(formaPago) {
    return {
        type: INVALIDATE_FORMASPAGO,
        formaPago
    }
}

function requestFormasPago() {
    return {
        type: REQUEST_FORMASPAGO,
    }
}

function receiveFormasPago(json) {
    return {
        type: RECEIVE_FORMASPAGO,
        formasPago: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorFormasPago(error) {
    return {
        type: ERROR_FORMASPAGO,
        error: error,
    }
}

function fetchFormasPago(filtros) {
    return dispatch => {
        dispatch(requestFormasPago());
        return formasPago.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveFormasPago(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorFormasPago(errorMessages.UNAUTHORIZED_TOKEN));
                        //dispatch(logout());
                        return;
                    default:
                        dispatch(errorFormasPago(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchFormasPago(state) {
    const formasPago = state.formasPago.byId;
    if (!formasPago) {
        return true
    } else if (formasPago.isFetching) {
        return false
    } else {
        return formasPago.didInvalidate;
    }
}

export function fetchFormasPagoIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchFormasPago(getState())) {
            return dispatch(fetchFormasPago(filtros))
        }
    }
}