import React, {useContext, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
//Css
import '../../assets/css/BarraCategorias.css';
//Actions
import {fetchCategorias, fetchCategoriasIfNeeded, invalidateCategorias} from "../../actions/CategoriaActions"
//Components
import Skelleton from "./componentes/Skelleton/Skelleton";
import CarrouselCategorias from "./Carruseles/CarrouselCategorias";
import {ConfiguracionContext} from "../../utils/Context";

export default function () {
    const Configuracion = useContext(ConfiguracionContext);
    const dispatch = useDispatch();
    const categorias = useSelector(state => state.categorias);
    const mounted = useRef();

    useEffect(() => {
        let orden = Configuracion && Configuracion.ORDEN_CATEGORIAS ? parseInt(Configuracion.ORDEN_CATEGORIAS) : 1;
        console.log("ORDEN", orden);
        dispatch(invalidateCategorias());
        dispatch(fetchCategoriasIfNeeded({orden: orden, publicar: true}));
    }, []);


    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else {
            let orden = Configuracion && Configuracion.ORDEN_CATEGORIAS ? parseInt(Configuracion.ORDEN_CATEGORIAS) : 1;
            console.log("ORDEN", orden);
            dispatch(fetchCategorias({orden: orden, publicar: true}));
        }
    }, [Configuracion]);

    let CategoriasArray = [];
    let orden = Configuracion && Configuracion.ORDEN_CATEGORIAS ? parseInt(Configuracion.ORDEN_CATEGORIAS) : 1;
    categorias.allIds.allIds.some((idCategoria, i) => {
        let categoria = categorias.byId.categorias[idCategoria];
        if (categoria.orden !== orden)
            return;
        CategoriasArray.push(
            <div className="item-categ" key={"categ-id-" + i}>
                <Link key={idCategoria} to={{pathname: "/search", search: "?cat=" + categoria.id}}>
                    <li>
                        {categoria.desCategoria}
                    </li>
                </Link>
            </div>
        );

    });

    return (
        <section className="barra-categorias">
            <div className="categorias-container">
                {
                    window.innerWidth >= 769 && <>
                        {categorias.byId.isFetching ?
                            <div className="container-limit">
                                <ul>
                                    <Skelleton tipo="categorias" key="0-invisible"/>
                                </ul>
                            </div> :
                            <CarrouselCategorias>
                                {CategoriasArray}
                            </CarrouselCategorias>
                        }
                    </>
                }

                {
                    window.innerWidth < 769 &&
                    <div className="container-limit">
                        <ul>
                            {
                                categorias.byId.isFetching ?
                                    <Skelleton tipo="categorias" key="0-invisible"/>
                                    : CategoriasArray
                            }
                        </ul>
                    </div>
                }

            </div>
        </section>
    )
}