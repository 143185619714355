import {
    ERROR_ZONAENTREGA,
    ERROR_ZONASENTREGA,
    INVALIDATE_ZONASENTREGA,
    RECEIVE_ZONAENTREGA,
    RECEIVE_ZONASENTREGA,
    REQUEST_ZONAENTREGA,
    REQUEST_ZONASENTREGA,
    RESET_ZONASENTREGA,
} from '../actions/ZonasEntregaActions';
import {combineReducers} from 'redux';

import merge from "lodash/merge";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        zonasEntrega: {},
    }
}

function zonasEntregaById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_ZONASENTREGA:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_ZONASENTREGA:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_ZONASENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_ZONASENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                zonasEntrega: {}
            });
        case RECEIVE_ZONASENTREGA:
            let dato = action.zonasEntrega.entities.zonasEntrega;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                zonasEntrega: merge({}, state.zonasEntrega, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_ZONAENTREGA:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_ZONAENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_ZONAENTREGA:
            let datoZona = action.zonasEntrega.entities.zonasEntrega;
            return Object.assign({}, state, {
                zonasEntrega: merge({}, state.zonasEntrega, datoZona),
                isFetching: false,
            });
        default:
            return state
    }
}


function allZonasEntrega(state = [], action) {
    switch (action.type) {
        case RECEIVE_ZONASENTREGA:
            return action.zonasEntrega.result;
        case RESET_ZONASENTREGA:
            return [];
        default:
            return state
    }
}

const zonasEntrega = combineReducers({
    byId: zonasEntregaById,
    allIds: allZonasEntrega,
});

export default zonasEntrega;