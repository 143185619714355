import React from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Autosuggest from 'react-autosuggest';
import {fetchProvinciasIfNeeded, resetProvincias} from "../../../../../actions/ProvinciaActions";
import "../../../../../assets/css/AutoSuggest.css";
import {
    fetchLocalidadesIfNeeded,
    invalidateLocalidades,
    resetLocalidades
} from "../../../../../actions/LocalidadActions";

class AutosuggestProvincia extends React.Component {
    constructor(props) {
        super(props);
        this.state = {suggestions: []};
    }

    componentDidMount() {
        this.props.fetchProvinciasIfNeeded({});
    }

    componentWillUnmount() {
        this.timer = null;
        this.props.resetProvincias();
        this.props.resetLocalidades();
    }

    escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    getSuggestions(value) {
        const escapedValue = this.escapeRegexCharacters(value.trim());

        if (escapedValue === '') {
            return [];
        }

        const regex = new RegExp('^' + escapedValue, 'i');

        let suggestionsProvincias = [];
        this.props.provincias.allIds.some((idProvincia) => {
            let provincia = this.props.provincias.byId.provincias[idProvincia];
            //.normalize('NFD').replace(/[\u0300-\u036f]/g, "") sirve para quitar tildes y caracteres especiales
            if (regex.test(`${provincia.nombreProvincia.normalize('NFD').replace(/[\u0300-\u036f]/g, "")}`)) {
                suggestionsProvincias.push(provincia);
            }
        });
        return suggestionsProvincias;
    }

    getSuggestionValue(suggestion) {
        return suggestion.nombreProvincia;
    }

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.nombreProvincia}</span>
        );
    }


    triggerChange = () => {
        this.props.resetLocalidades();
        this.props.invalidateLocalidades();

        this.props.fetchLocalidadesIfNeeded({
            nombreProvincia: this.props.value,
        });
    };

    onChange = (_, {newValue}) => {
        let e = {target: {id: this.props.id, value: newValue}};
        this.props.handleChange(e);

        clearTimeout(this.timer);
        if (newValue !== "" && newValue.length > 3)
            this.timer = setTimeout(() => this.triggerChange(), 500);
    };

    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const {value, id, nombre} = this.props;
        const {suggestions} = this.state;
        const inputProps = {
            placeholder: "",
            value,
            onChange: this.onChange,
            autocomplete: "peppapig"
        };

        return (
            <div className="autosuggest-container">
                <label>
                    <Autosuggest
                        id={id}
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={this.renderSuggestion}
                        inputProps={inputProps}
                    />
                    <span className={`placeholder ${!!value && value !== "" ? "show" : ""}`}>{nombre}</span>
                </label>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        provincias: state.provincias
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProvinciasIfNeeded: (filtros) => {
            dispatch(fetchProvinciasIfNeeded(filtros))
        },
        fetchLocalidadesIfNeeded: (filtros) => {
            dispatch(fetchLocalidadesIfNeeded(filtros))
        },
        invalidateLocalidades: () => {
            dispatch(invalidateLocalidades())
        },
        resetLocalidades: () => {
            dispatch(resetLocalidades())
        },
        resetProvincias: () => {
            dispatch(resetProvincias())
        },
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AutosuggestProvincia));