import React, {Component} from 'react';

import '../../assets/css/CompraExitosa.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretSquareLeft} from "@fortawesome/free-solid-svg-icons";
import compraExitosa from "../../assets/images/compraExitosa.png";
import history from '../../history';

class CompraExitosa extends Component {

    componentDidMount() {
        //Subo página
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <section className="compra-exitosa">
                <div className="container-limit">
                    <div className="imagen-circular">
                        <img src={compraExitosa} alt=""/>
                    </div>
                    <h2>¡Gracias por tu compra!</h2>
                    {/*<p>Compartí para llegar el objetivo más rápido y que tus*/}
                    {/*    amigos aprovechen el descuento!</p>*/}
                    <div className="container-botones">
                        {/*<button className="compartir" style={{outline:"none"}}*/}
                        {/*        onClick={() => {*/}
                        {/*            window.open("https://www.facebook.com/sharer/sharer.php?u=http%3A//mercadeando.com.ar/",'_blank');*/}
                        {/*        }}>*/}
                        {/*    COMPARTIR <span><FontAwesomeIcon icon={faShareAlt} color="white"/></span>*/}
                        {/*</button>*/}
                        <button className="encargar" style={{outline: "none"}} onClick={() => {
                            history.push("/");
                        }}>
                            VOLVER <span><FontAwesomeIcon icon={faCaretSquareLeft} color="white"/></span>
                        </button>
                    </div>
                </div>
            </section>

        );
    }
}

export default CompraExitosa;
