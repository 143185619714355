import * as errorMessages from '../constants/MessageConstants';
//Actions
import {fetchPedidosIfNeeded, invalidatePedidos} from "./PedidoActions";
import {logout} from "../actions/AuthActions";
import {saveEstadoRemito} from "../actions/EstadoRemitoActions";
//api
import remitos from "../api/remitos";
import * as estadoRemito from "../constants/EstadoRemito";

//REMITO
export const UPDATE_REMITO = 'UPDATE_REMITO';
export const REQUEST_UPDATE_REMITO = "REQUEST_UPDATE_REMITO";
export const SUCCESS_UPDATE_REMITO = "SUCCESS_UPDATE_REMITO";
export const ERROR_UPDATE_REMITO = "ERROR_UPDATE_REMITO";
export const RESET_UPDATE_REMITO = "RESET_UPDATE_REMITO";

//CREATE ORDEN PEDIDO ENVIO
export const REQUEST_CREATE_ORDENPEDIDOENVIO = "REQUEST_CREATE_ORDENPEDIDOENVIO";
export const SUCCESS_CREATE_ORDENPEDIDOENVIO = "SUCCESS_CREATE_ORDENPEDIDOENVIO";
export const ERROR_CREATE_ORDENPEDIDOENVIO = "ERROR_CREATE_ORDENPEDIDOENVIO";

//ULTIMO REMITO DE ESE USUARIO
export const REQUEST_ULTIMO_REMITO = 'REQUEST_ULTIMO_REMITO';
export const RECEIVE_ULTIMO_REMITO = 'RECEIVE_ULTIMO_REMITO';
export const INVALIDATE_ULTIMO_REMITO = 'INVALIDATE_ULTIMO_REMITO';
export const ERROR_ULTIMO_REMITO = "ERROR_ULTIMO_REMITO";

//REMITO POR NRO SERIE PRODUCTO
export const REQUEST_PRODUCTONROSERIE = "REQUEST_PRODUCTONROSERIE";
export const SUCCESS_PRODUCTONROSERIE = "SUCCESS_PRODUCTONROSERIE";
export const RECEIVE_PRODUCTONROSERIE = "RECEIVE_PRODUCTONROSERIE";
export const ERROR_PRODUCTONROSERIE = "ERROR_PRODUCTONROSERIE";
export const RESET_PRODUCTONROSERIE = "RESET_PRODUCTONROSERIE";

function requestUpdateRemito() {
    return {
        type: REQUEST_UPDATE_REMITO,
    }
}

function receiveUpdateRemito() {
    return {
        type: SUCCESS_UPDATE_REMITO,
        receivedAt: Date.now()
    }
}

function errorRemito(error) {
    return {
        type: ERROR_UPDATE_REMITO,
        error: error,
    }
}

export function resetUpdateRemito(error) {
    return {
        type: RESET_UPDATE_REMITO,
        error: error,
    }
}

function updateRemito(remito, idRemito) {
    return {
        type: UPDATE_REMITO,
        remito,
        idRemito
    }
}

export function changeRemito(remito, idRemito) {
    return dispatch => {
        dispatch(invalidatePedidos());
        dispatch(updateRemito(remito, idRemito));
    }
}

export function saveUpdateRemito(remito) {
    return (dispatch, getState) => {
        dispatch(requestUpdateRemito());
        return remitos.update(remito)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveUpdateRemito());
                    dispatch(invalidatePedidos());
                    dispatch(fetchPedidosIfNeeded({id: remito.idPedidoMon}));
                    //Refresco token
                    // auth.addToken(response.headers);
                }
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorRemito(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorRemito(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}


//Consulta remito por nro serie

function requestRemitoNroSerie() {
    return {
        type: REQUEST_PRODUCTONROSERIE,
    }
}

function errorRemitoNroSerie(error) {
    return {
        type: ERROR_PRODUCTONROSERIE,
        error: error,
    }
}

function receiveRemitoNroSerie(json) {
    return {
        type: RECEIVE_PRODUCTONROSERIE,
        busquedaRemitos: json,
        receivedAt: Date.now()
    }
}

export function resetRemitoNroSerie() {
    return {
        type: RESET_PRODUCTONROSERIE
    }
}


export function fetchRemitoNroSerie(nroSerie) {
    return (dispatch, getState) => {
        dispatch(requestRemitoNroSerie());
        return remitos.getRemitoByNroSerie(nroSerie)
            .then(function (response) {
                //Refresco token
                // auth.addToken(response.headers);
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(resetRemitoNroSerie());
                    return response.json();
                }
            })
            .then(function (data) {
                dispatch(receiveRemitoNroSerie(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorRemitoNroSerie(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorRemitoNroSerie(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

export function saveRecepcionRemito(remito) {
    return (dispatch, getState) => {
        dispatch(saveUpdateRemito(remito))
            .then(() => {
                let ultimoEstado = remitos.calcularUltimoEstadoRemito(remito, getState().estadosRemito.byId.estadosRemito);
                if (ultimoEstado.desEstadoRemito != remito.tipoProblema
                    && ultimoEstado.desEstadoRemito != estadoRemito.RESUELTO)
                    dispatch(saveEstadoRemito(remito.tipoProblema, remito.id));
                else
                    return Promise.resolve();
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorRemito(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorRemito(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}