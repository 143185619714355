import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {
    const stock = new schema.Entity('stocks', {}, {idAttribute: "id"});
    const stockVariedad = new schema.Entity('stocksVariedad', {stock: stock}, {idAttribute: "id"});
    const variedadCaracteristica = new schema.Entity('variedadesCaracteristica', {stock_variedad: [stockVariedad]}, {idAttribute: "id"});
    const caracteristicaProducto = new schema.Entity('caracteristicasProducto', {variedad_caracteristica: [variedadCaracteristica]}, {idAttribute: "id"});

    const productoOrigenPromocion = new schema.Entity('productosOrigenPromocion', {}, {idAttribute: "id"});
    const categoria = new schema.Entity('categorias', {}, {idAttribute: "id"});
    const marca = new schema.Entity('marcas', {}, {idAttribute: "id"});
    const producto = new schema.Entity('productos', {
        marca: marca,
        categoria: [categoria],
        producto_origen_promocion: [productoOrigenPromocion],
        caracteristica_producto: [caracteristicaProducto]
    }, {idAttribute: "id"});
    const mySchema = {productos: [producto], marcas: [marca], categorias: [categoria]};
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}
