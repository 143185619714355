import React, {useEffect, useState} from 'react';

import {useSelector} from "react-redux";

import "../../assets/css/CartelUpdate.css";
//Actions
//Components

//img

export default function CartelUpdate() {
    const serviceWorker = useSelector(state => state.serviceWorker);
    const [count, setCount] = useState(3);

    const updateServiceWorker = () => {
        const registrationWaiting = serviceWorker.serviceWorker.serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({type: 'SKIP_WAITING'});
            registrationWaiting.addEventListener('statechange', e => {
                if (e.target.state === 'activated') {
                    window.location.reload();
                }
            });
        }
    };

    useEffect(() => {
        if (serviceWorker.serviceWorker.serviceWorkerRegistration && serviceWorker.serviceWorker.serviceWorkerRegistration.waiting)
            if (count > 0)
                setTimeout(() => setCount(count - 1), 1000);
            else
                updateServiceWorker();
    }, [count, serviceWorker.serviceWorker.serviceWorkerRegistration]);


    return (
        <div>
            {serviceWorker.serviceWorker.serviceWorkerUpdated && (
                <div className="alerta-update">
                    <p>
                        Existe una nueva versión
                    </p>
                    <span>
                        <a href="" onClick={(e) => {
                            e.preventDefault();
                            updateServiceWorker();
                        }}>
                            {count}s
                        </a>
                    </span>
                </div>
            )}

        </div>

    )
}

