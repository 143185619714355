import React, {useEffect, useRef, useState} from 'react';
import '../../../assets/css/EstadoLabel.css';

var moment = require('moment');

export default function EstadoLabel(props) {
    const [showPopOver, setShowPopOver] = useState(false);
    const modalRef = useRef();

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = e => {
        if (!modalRef.current.contains(e.target)) {
            setShowPopOver(false)
        }
    };

    const {funcionEstados, listaIdEstados, estadoById, nombreDescripcion, mostrarInformacion} = props;
    var ultimoEstadoPedido = null;
    var maxFecha = null;

    var Filas = listaIdEstados.map(function (idEstado, i) {
        let estado = estadoById[idEstado];
        if (estado && (!maxFecha || estado.fechaEstado > maxFecha)) {
            maxFecha = estado.fechaEstado;
            ultimoEstadoPedido = estado;
        }
        return (
            <tr key={i}>
                <td>{estado && estado[nombreDescripcion]}</td>
                <td>{estado && moment(estado.fechaEstado).format('DD/MM/YYYY HH:mm')}</td>
                <td>{estado && estado.usuarioUltMdf}</td>
            </tr>)
    });

    const popoverEstados = (
        <div className={`pop-over ${showPopOver ? "show" : ""}`}>
            <h6>Estados</h6>
            <div className="body-modal">
                <table>
                    <thead>
                    <tr>
                        <th>Estado</th>
                        <th>Fecha</th>
                        <th>Usuario</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Filas}
                    </tbody>
                </table>
            </div>
        </div>
    );
    return (
        <div onClick={() => {
            setShowPopOver(true);
        }}>
            <div>
                {funcionEstados(ultimoEstadoPedido ? ultimoEstadoPedido[nombreDescripcion] : "")}
            </div>
            <div ref={modalRef} className={`container-popover ${showPopOver ? "show" : ""}`}>
                {popoverEstados}
            </div>
        </div>
    )
}