import {
    INVALIDATE_DIRECCIONESENTREGA,
    ERROR_DIRECCIONESENTREGA,
    RECEIVE_DIRECCIONESENTREGA,
    REQUEST_DIRECCIONESENTREGA,
    RESET_DIRECCIONESENTREGA,
    ERROR_DIRECCIONENTREGA,
    RECEIVE_DIRECCIONENTREGA,
    REQUEST_DIRECCIONENTREGA,
    UPDATE_DIRECCIONENTREGA,
    REQUEST_UPDATE_DIRECCIONENTREGA,
    SUCCESS_UPDATE_DIRECCIONENTREGA,
    ERROR_UPDATE_DIRECCIONENTREGA,
    RESET_UPDATE_DIRECCIONENTREGA,
    REQUEST_UPDATE_DIRECCIONESENTREGA,
    SUCCESS_UPDATE_DIRECCIONESENTREGA,
    ERROR_UPDATE_DIRECCIONESENTREGA,
    RESET_UPDATE_DIRECCIONESENTREGA,
    CREATE_DIRECCIONENTREGA,
    ERROR_CREATE_DIRECCIONENTREGA,
    REQUEST_CREATE_DIRECCIONENTREGA,
    RESET_CREATE_DIRECCIONENTREGA,
    SUCCESS_CREATE_DIRECCIONENTREGA,
    DELETE_DIRECCIONENTREGA,
    REQUEST_DELETE_DIRECCIONENTREGA,
    SUCCESS_DELETE_DIRECCIONENTREGA,
    ERROR_DELETE_DIRECCIONENTREGA,
    RESET_DELETE_DIRECCIONENTREGA,

} from '../actions/DireccionEntregaActions';

import {
    RECEIVE_PEDIDO,
    RECEIVE_PEDIDOS,
    SUCCESS_DELETE_PEDIDO,
    SUCCESS_CREATE_PEDIDO,
    SUCCESS_UPDATE_PEDIDO,
    SUCCESS_UPDATE_PEDIDOS

} from '../actions/PedidoActions';

import {combineReducers} from 'redux';
import {LOGOUT_SUCCESS} from "../actions/AuthActions";

import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import union from "lodash/union";
import clone from "lodash/clone";
import difference from "lodash/difference";
import omit from "lodash/omit";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        direccionesEntrega: {},
    }
}

function direccionesEntregaById(state = getInitialStateById(), action) {
    switch (action.type) {
        case INVALIDATE_DIRECCIONESENTREGA:
            return Object.assign({}, state, {
                didInvalidate: true
            });
        case REQUEST_DIRECCIONESENTREGA:
            return Object.assign({}, state, {
                isFetching: true,
                didInvalidate: false
            });
        case ERROR_DIRECCIONESENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: action.error
            });
        case RESET_DIRECCIONESENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                lastUpdated: null,
                direccionesEntrega: {}
            });
        case RECEIVE_DIRECCIONESENTREGA:
            let dato = action.direccionesEntrega.entities.direccionesEntrega;
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                direccionesEntrega: merge({}, state.direccionesEntrega, dato),
                lastUpdated: action.receivedAt
            });
        case REQUEST_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case ERROR_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error
            });
        case RECEIVE_DIRECCIONENTREGA:
            let datoDireccionEntrega = action.direccionEntrega.entities.direccionesEntrega;
            return Object.assign({}, state, {
                direccionesEntrega: merge({}, state.direccionesEntrega, datoDireccionEntrega),
                isFetching: false,
            });
        case CREATE_DIRECCIONENTREGA:
            let datoDireccionEntregaCreate = action.direccionEntrega;
            let idNuevoCreate = null;
            if (Object.values(datoDireccionEntregaCreate).length > 0)
                idNuevoCreate = Object.values(datoDireccionEntregaCreate)[0] && Object.values(datoDireccionEntregaCreate)[0].id ? Object.values(datoDireccionEntregaCreate)[0].id : null;
            if (idNuevoCreate)
                return Object.assign({}, state, {
                    direccionesEntrega: merge({}, state.direccionesEntrega, datoDireccionEntregaCreate)
                });
            else
                return state;
        case UPDATE_DIRECCIONENTREGA:
            let datoDireccionEntregaUpdate = action.direccionEntrega;
            let idNuevoUpdate = null;
            if (Object.values(datoDireccionEntregaUpdate).length > 0)
                idNuevoUpdate = Object.values(datoDireccionEntregaUpdate)[0] && Object.values(datoDireccionEntregaUpdate)[0].id ? Object.values(datoDireccionEntregaUpdate)[0].id : null;
            if (idNuevoUpdate)
                return Object.assign({}, state, {
                    direccionesEntrega: merge({}, state.direccionesEntrega, datoDireccionEntregaUpdate)
                });
            else
                return state;
        case DELETE_DIRECCIONENTREGA:
            let datoDireccionEntregaDelete = action.direccionEntrega;
            let idNuevoDelete = null;
            if (Object.values(datoDireccionEntregaDelete).length > 0)
                idNuevoDelete = Object.values(datoDireccionEntregaDelete)[0] && Object.values(datoDireccionEntregaDelete)[0].id ? Object.values(datoDireccionEntregaDelete)[0].id : null;
            if (idNuevoDelete)
                return Object.assign({}, state, {
                    direccionesEntrega: omit(clone(state.direccionesEntrega), Object.keys(datoDireccionEntregaDelete))
                });
            else
                return state;
        case SUCCESS_DELETE_DIRECCIONENTREGA:
            let datoDireccionEntregaEliminado = action.direccionEntrega.entities.direccionesEntrega;
            return Object.assign({}, state, {
                direccionesEntrega: mergeWith(clone(datoDireccionEntregaEliminado), state.direccionesEntrega, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_CREATE_DIRECCIONENTREGA:
            let datoDireccionEntregaCreado = action.direccionEntrega.entities.direccionesEntrega;
            return Object.assign({}, state, {
                direccionesEntrega: mergeWith(clone(datoDireccionEntregaCreado), state.direccionesEntrega, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_UPDATE_DIRECCIONENTREGA:
            let datoDireccionEntregaActualizado = action.direccionEntrega.entities.direccionesEntrega;
            return Object.assign({}, state, {
                direccionesEntrega: mergeWith(clone(datoDireccionEntregaActualizado), state.direccionesEntrega, (objValue, srcValue) => {return objValue;})
            });
        case SUCCESS_UPDATE_DIRECCIONESENTREGA:
            let datosDireccionEntregaActualizado = action.direccionesEntrega.entities.direccionesEntrega;
            return Object.assign({}, state, {
                direccionesEntrega: mergeWith(clone(datosDireccionEntregaActualizado), state.direccionesEntrega, (objValue, srcValue) => {return objValue;})
            });

        //PEDIDO
        case RECEIVE_PEDIDO:
            let pedido = action.pedido.entities && action.pedido.entities.direccionesEntrega ? action.pedido.entities.direccionesEntrega : {};
            return Object.assign({}, state, {
                direccionesEntrega: merge({}, state.direccionesEntrega, pedido),
            });
        case RECEIVE_PEDIDOS:
            let pedidos = action.pedidos.entities && action.pedidos.entities.direccionesEntrega ? action.pedidos.entities.direccionesEntrega : {};
            return Object.assign({}, state, {
                direccionesEntrega: merge({}, state.direccionesEntrega, pedidos),
            });
        case SUCCESS_CREATE_PEDIDO:
            let datopedidoCreado = action.pedido.entities && action.pedido.entities.direccionesEntrega ? action.pedido.entities.direccionesEntrega : {};
            return Object.assign({}, state, {
                direccionesEntrega: merge({}, datopedidoCreado, state.direccionesEntrega)
            });
        case SUCCESS_UPDATE_PEDIDO:
            let datopedidoActualizado = action.pedido.entities && action.pedido.entities.direccionesEntrega ? action.pedido.entities.direccionesEntrega : {};
            return Object.assign({}, state, {
                direccionesEntrega: merge({}, datopedidoActualizado, state.direccionesEntrega)
            });



        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: true,
                error: null,
                direccionesEntrega: {}
            });
        default:
            return state
    }
}


function allDireccionesEntrega(state = [], action) {
    switch (action.type) {
        case RECEIVE_DIRECCIONESENTREGA:
            return action.direccionesEntrega.result && action.direccionesEntrega.result.direccionesEntrega ? action.direccionesEntrega.result.direccionesEntrega : (action.direccionesEntrega.result ? action.direccionesEntrega.result : state) ;

        case CREATE_DIRECCIONENTREGA:
            let datoDireccionEntregaCreate = action.direccionEntrega;
            let idNuevoCreate = null;
            if (Object.values(datoDireccionEntregaCreate).length > 0)
                idNuevoCreate = Object.values(datoDireccionEntregaCreate)[0] && Object.values(datoDireccionEntregaCreate)[0].id ? Object.values(datoDireccionEntregaCreate)[0].id : null;
            if (idNuevoCreate)
                return union(state, [idNuevoCreate]);
            else
                return state;
        case UPDATE_DIRECCIONENTREGA:
            let datoDireccionEntregaUpdate = action.direccionEntrega;
            let idNuevoUpdate = null;
            if (Object.values(datoDireccionEntregaUpdate).length > 0)
                idNuevoUpdate = Object.values(datoDireccionEntregaUpdate)[0] && Object.values(datoDireccionEntregaUpdate)[0].id ?  Object.values(datoDireccionEntregaUpdate)[0].id : null;
            if (idNuevoUpdate)
                return union(state, [idNuevoUpdate]);
            else
                return state;
        case DELETE_DIRECCIONENTREGA:
            let datoDireccionEntregaDelete = action.direccionEntrega;
            let idNuevoDelete = null;
            if (Object.values(datoDireccionEntregaDelete).length > 0)
                idNuevoDelete = Object.values(datoDireccionEntregaDelete)[0] && Object.values(datoDireccionEntregaDelete)[0].id ? Object.values(datoDireccionEntregaDelete)[0].id : null;
            if (idNuevoDelete)
                return difference(state, [idNuevoDelete]);
            else
                return state;
        case SUCCESS_CREATE_DIRECCIONENTREGA:
            let datoDireccionEntregaSCreate = action.direccionEntrega.entities.direccionesEntrega;
            let idNuevoSCreate = null;
            if (Object.values(datoDireccionEntregaSCreate).length > 0)
                idNuevoSCreate = Object.values(datoDireccionEntregaSCreate)[0] && Object.values(datoDireccionEntregaSCreate)[0].id ? Object.values(datoDireccionEntregaSCreate)[0].id : null;
            if (idNuevoSCreate)
                return union(state, [idNuevoSCreate]);
            else
                return state;
        case RESET_DIRECCIONESENTREGA:
            return [];

        case RECEIVE_PEDIDO:
            let pedido = action.pedido.entities && action.pedido.entities.direccionesEntrega ? action.pedido.entities.direccionesEntrega : null ;
            return pedido ?
                union(state, Object.values(pedido).map((pedido) => {
                    return pedido.id
                })) : state;
        case RECEIVE_PEDIDOS:
            let pedidos = action.pedidos.entities && action.pedidos.entities.direccionesEntrega ? action.pedidos.entities.direccionesEntrega : null;
            return pedidos ?
                union(state, Object.values(pedidos).map((pedidos) => {
                    return pedidos.id
                })) : state;

        case SUCCESS_CREATE_PEDIDO:
            let pedidoCreate = action.pedido.entities && action.pedido.entities.direccionesEntrega ? action.pedido.entities.direccionesEntrega : null ;
            return pedidoCreate ?
                Object.values(pedidoCreate).map((pedido) => {
                    return pedido.id
                }) : state;
        case SUCCESS_UPDATE_PEDIDO:
            let pedidoUpdate = action.pedido.entities && action.pedido.entities.direccionesEntrega ? action.pedido.entities.direccionesEntrega : null ;
            return pedidoUpdate ?
                Object.values(pedidoUpdate).map((pedido) => {
                    return pedido.id
                }) : state;

        case LOGOUT_SUCCESS:
            return [];
        default:
            return state
    }
}

function totalDireccionesEntrega(state = null, action) {
    switch (action.type) {
        case RECEIVE_DIRECCIONESENTREGA:
            return action.direccionesEntrega && action.direccionesEntrega.result.total ? action.direccionesEntrega.result.total : 0;
        case RESET_DIRECCIONESENTREGA:
            return null;
        case LOGOUT_SUCCESS:
            return null;
        default:
            return state
    }
}

function update(state = {
    isUpdating: false,
    activo: {},
    activos: []
}, action) {
    switch (action.type) {
        case RECEIVE_DIRECCIONENTREGA:
            let dato = action.direccionEntrega.entities.direccionesEntrega;
            let direccionEntrega = dato && Object.keys(dato).length > 0 ? dato[Object.keys(dato)[0]] : {};
            return Object.assign({}, state, {
                isFetching: false,
                didInvalidate: false,
                activo: direccionEntrega ? direccionEntrega : [],
                lastUpdated: action.receivedAt
            });
        case UPDATE_DIRECCIONENTREGA:
            let idNuevo = null;
            if (Object.values(action.direccionEntrega).length > 0)
                idNuevo = Object.values(action.direccionEntrega)[0] && Object.values(action.direccionEntrega)[0].id ? Object.values(action.direccionEntrega)[0].id : null;
            return merge({}, state, {
                activo: action.direccionEntrega,
                activos: idNuevo ? union(state.activos, [idNuevo]) : state.activos,
                error: ""
            });
        case REQUEST_UPDATE_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_DIRECCIONENTREGA:
            let datoDireccionEntregaActualizado = {};
            if (Object.values(action.direccionEntrega.entities.direccionesEntrega).length > 0)
                datoDireccionEntregaActualizado = Object.values(action.direccionEntrega.entities.direccionesEntrega)[0];
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: datoDireccionEntregaActualizado
            });
        case ERROR_UPDATE_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case REQUEST_UPDATE_DIRECCIONESENTREGA:
            return Object.assign({}, state, {
                isUpdating: true,
                error: null
            });
        case SUCCESS_UPDATE_DIRECCIONESENTREGA:
            return Object.assign({}, state, {
                isUpdating: false,
                lastUpdated: action.receivedAt,
                error: null,
                activo: {},
                activos: []
            });
        case ERROR_UPDATE_DIRECCIONESENTREGA:
            return Object.assign({}, state, {
                isUpdating: false,
                error: action.error
            });
        case RESET_UPDATE_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });



        //PEDIDO
        case SUCCESS_CREATE_PEDIDO:
            return Object.assign({}, state, {
                activo: {},
                activos: []
            });
        case SUCCESS_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                activo: {},
                activos: []
            });

        case DELETE_DIRECCIONENTREGA:
            let datoDireccionEntregaDelete = action.direccionEntrega;
            let idNuevoDelete = null;
            if (Object.values(datoDireccionEntregaDelete).length > 0)
                idNuevoDelete = Object.values(datoDireccionEntregaDelete)[0] && Object.values(datoDireccionEntregaDelete)[0].id ? Object.values(datoDireccionEntregaDelete)[0].id : null;
            if (idNuevoDelete)
                return Object.assign({}, state, {
                    activo: omit(clone(state.activo), Object.keys(datoDireccionEntregaDelete)),
                    activos: difference(clone(state.activos), [idNuevoDelete])
                });
            else
                return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isUpdating: false,
                activo: {},
                error: ""
            });
        default:
            return state
    }
}

function create(state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ""
}, action) {
    switch (action.type) {
        case CREATE_DIRECCIONENTREGA:
            let idNuevo = null;
            if (Object.values(action.direccionEntrega).length > 0)
                idNuevo = Object.values(action.direccionEntrega)[0] && Object.values(action.direccionEntrega)[0].id ? Object.values(action.direccionEntrega)[0].id : null;
            return merge({}, state, {
                isCreating: false,
                nuevo: action.direccionEntrega,
                nuevos: idNuevo ? union(state.nuevos, [idNuevo]) : state.nuevos,
                error: null,
            });
        case REQUEST_CREATE_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isCreating: true,
                error: null,
            });
        case SUCCESS_CREATE_DIRECCIONENTREGA:
            let datoDireccionEntregaNuevo = {};
            if (Object.values(action.direccionEntrega.entities.direccionesEntrega).length > 0)
                datoDireccionEntregaNuevo = Object.values(action.direccionEntrega.entities.direccionesEntrega)[0];
            return Object.assign({}, state, {
                isCreating: false,
                lastUpdated: action.receivedAt,
                error: null,
                nuevo: datoDireccionEntregaNuevo,
                nuevos: []
            });
        case ERROR_CREATE_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isCreating: false,
                error: action.error
            });
        case RESET_CREATE_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });



        //PEDIDO
        case SUCCESS_CREATE_PEDIDO:
            return Object.assign({}, state, {
                nuevo: {},
                nuevos: []
            });
        case SUCCESS_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                nuevo: {},
                nuevos: []
            });

        case DELETE_DIRECCIONENTREGA:
            let datoDireccionEntregaDelete = action.direccionEntrega;
            let idNuevoDelete = null;
            if (Object.values(datoDireccionEntregaDelete).length > 0)
                idNuevoDelete = Object.values(datoDireccionEntregaDelete)[0] && Object.values(datoDireccionEntregaDelete)[0].id ? Object.values(datoDireccionEntregaDelete)[0].id : null;
            if (idNuevoDelete)
                return Object.assign({}, state, {
                    nuevo: omit(clone(state.nuevo), Object.keys(datoDireccionEntregaDelete)),
                    nuevos: difference(clone(state.nuevos), [idNuevoDelete])
                });
            else
                return state;
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isCreating: false,
                error: null,
                nuevo: {}
            });
        default:
            return state
    }
}

function deleter(state = {
    isDeleting: false,
    eliminado: {},
    error: ""
}, action) {
    switch (action.type) {
        case DELETE_DIRECCIONENTREGA:
            return merge({}, state, {
                isDeleting: false,
                eliminado: action.direccionEntrega,
                error: null,
            });
        case REQUEST_DELETE_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isDeleting: true,
                error: null,
            });
        case SUCCESS_DELETE_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
            });
        case ERROR_DELETE_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: action.error
            });
        case RESET_DELETE_DIRECCIONENTREGA:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        //PEDIDO
        case SUCCESS_CREATE_PEDIDO:
            return Object.assign({}, state, {
                eliminado: {},
            });
        case SUCCESS_UPDATE_PEDIDO:
            return Object.assign({}, state, {
                eliminado: {},
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isDeleting: false,
                error: null,
                eliminado: {}
            });
        default:
            return state
    }
}

const direccionesEntrega = combineReducers({
    byId: direccionesEntregaById,
    allIds: allDireccionesEntrega,
    update: update,
    create: create,
    totalDireccionesEntrega: totalDireccionesEntrega,
    delete: deleter
});

export default direccionesEntrega;