import * as errorMessages from '../constants/MessageConstants';
//Actions
import {logout, receiveLogin} from "../actions/AuthActions";
//utils
import usuarioApi from "../api/usuarios";
import clone from "lodash/clone";
//Normalizers
import {normalizeDato, normalizeDatos} from "../normalizers/normalizeUsuarios";
import jwt_decode from "jwt-decode";
import {invalidateProductos} from "./ProductoActions";
import {invalidateCategorias, invalidateCategoriasDestacadas} from "./CategoriaActions";
import {invalidatePromociones} from "./PromocionActions";
import {saveCreateAnalitica} from "./AnaliticaActions";
import * as TipoAnalitica from "../constants/TipoAnalitica";

//USUARIO
export const REQUEST_USUARIOS = 'REQUEST_USUARIOS';
export const RECEIVE_USUARIOS = 'RECEIVE_USUARIOS';
export const INVALIDATE_USUARIOS = 'INVALIDATE_USUARIOS';
export const ERROR_USUARIOS = "ERROR_USUARIOS";
export const RESET_USUARIOS = "RESET_USUARIOS";


export function invalidateUsuarios() {
    return {
        type: INVALIDATE_USUARIOS,
    }
}

function requestUsuarios() {
    return {
        type: REQUEST_USUARIOS,
    }
}

function receiveUsuarios(json) {
    return {
        type: RECEIVE_USUARIOS,
        usuarios: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorUsuarios(error) {
    return {
        type: ERROR_USUARIOS,
        error: error,
    }
}

function fetchUsuarios(filtros) {
    return dispatch => {
        dispatch(requestUsuarios());
        return usuarioApi.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    // auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveUsuarios(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUsuarios(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorUsuarios(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchUsuarios(state, filtros) {
    const usuarios = state.usuarios.byId;

    if (!usuarios) {
        return true;
    } else if (filtros && filtros.id) {
        return true
    } else if (usuarios.isFetching && (!filtros.searchPhase || filtros.searchPhase != "")) {
        return false;
    } else {
        return usuarios.didInvalidate;
    }
}

export function fetchUsuariosIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchUsuarios(getState(), filtros)) {
            return dispatch(fetchUsuarios(filtros))
        }
    }
}

export function resetUsuarios() {
    return {
        type: RESET_USUARIOS
    }
}

//CREATE
export const CREATE_USUARIO = 'CREATE_USUARIO';
export const REQUEST_CREATE_USUARIO = "REQUEST_CREATE_USUARIO";
export const SUCCESS_CREATE_USUARIO = "SUCCESS_CREATE_USUARIO";
export const ERROR_CREATE_USUARIO = "ERROR_CREATE_USUARIO";
export const RESET_CREATE_USUARIO = "RESET_CREATE_USUARIO";

function requestCreateUsuario() {
    return {
        type: REQUEST_CREATE_USUARIO,
    }
}

function receiveCreateUsuario(mensaje) {
    return {
        type: SUCCESS_CREATE_USUARIO,
        receivedAt: Date.now(),
        mensaje: mensaje
    }
}

export function errorCreateUsuario(error) {
    return {
        type: ERROR_CREATE_USUARIO,
        error: error,
    }
}

export function resetCreateUsuario() {
    return {
        type: RESET_CREATE_USUARIO
    }
}

export function createUsuario(usuario) {
    return {
        type: CREATE_USUARIO,
        usuario
    }
}

export function saveCreateUsuario() {
    return (dispatch, getState) => {
        dispatch(requestCreateUsuario());
        let usuario = clone(getState().usuarios.create.nuevo);
        if (!usuario.idDireccionEntrega)
            usuario.direccion_entrega = getState().direccionesEntrega.create.nuevo;
        return usuarioApi.saveCreate(usuario)
            .then(function (response) {
                //Refresco token
                //auth.addToken(response.headers);
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    dispatch(receiveCreateUsuario("Su cuenta ha sido creada correctamente. Ya podes empezar a comprar!"));
                    var data = response.json();
                    return data;
                }
            })
            .then((data) => {
                if (data && data.access_token) {
                    localStorage.token = data.access_token;
                    var datos = jwt_decode(localStorage.token);
                    dispatch(invalidateProductos());
                    dispatch(invalidateCategorias());
                    dispatch(invalidateCategoriasDestacadas());
                    dispatch(invalidatePromociones());
                    dispatch(saveCreateAnalitica(null, TipoAnalitica.INICIO_SESION_ID));
                    dispatch(receiveLogin(datos));
                }
            })
            .catch(function (error) {
                    switch (error.status) {
                        case 401:
                            dispatch(errorCreateUsuario(errorMessages.UNAUTHORIZED_TOKEN));
                            dispatch(logout());
                            return;
                        default:
                            error.json()
                                .then((error) => {
                                    if (error.message != "")
                                        dispatch(errorCreateUsuario(error.message));
                                    else
                                        dispatch(errorCreateUsuario(errorMessages.GENERAL_ERROR));
                                })
                                .catch((error) => {
                                    dispatch(errorCreateUsuario(errorMessages.GENERAL_ERROR));
                                });
                            return;
                    }
                }
            );
    }
}

//USUARIO
//MODEL
export const REQUEST_USUARIO = 'REQUEST_USUARIO';
export const RECEIVE_USUARIO = 'RECEIVE_USUARIO';
export const INVALIDATE_USUARIO = 'INVALIDATE_USUARIO';
export const ERROR_USUARIO = "ERROR_USUARIO";
export const RESET_USUARIO = "RESET_USUARIO";


export function invalidateUsuario() {
    return {
        type: INVALIDATE_USUARIO
    }
}

function requestUsuario() {
    return {
        type: REQUEST_USUARIO,
    }
}

function receiveUsuario(json) {
    return {
        type: RECEIVE_USUARIO,
        usuario: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorUsuario(error) {
    return {
        type: ERROR_USUARIO,
        error: error,
    }
}

export function resetUsuario() {
    return {
        type: RESET_USUARIO
    }
}

export function fetchUsuario(idUsuario) {
    return dispatch => {
        dispatch(requestUsuario());
        return usuarioApi.getOne(idUsuario)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveUsuario(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorUsuario(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorUsuario(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

