import {combineReducers} from 'redux';

import merge from "lodash/merge";
import union from "lodash/union";
import {RECEIVE_PRODUCTO, RECEIVE_PRODUCTOS} from "../actions/ProductoActions";
import {RECEIVE_CATEGORIAS_DESTACADAS} from "../actions/CategoriaActions";
import {RECEIVE_BUSQUEDA} from "../actions/BusquedaActions";

function getInitialStateById() {
    return {
        isFetching: false,
        didInvalidate: true,
        stocks: {},
    }
}

function stocksById(state = getInitialStateById(), action) {
    switch (action.type) {
        case RECEIVE_PRODUCTO:
            let datoProducto = action.producto.entities.stocks;
            return Object.assign({}, state, {
                stocks: merge({}, state.stocks, datoProducto),
                isFetching: false,
            });
        case RECEIVE_PRODUCTOS:
            let datoProductos = action.productos.entities.stocks;
            return Object.assign({}, state, {
                stocks: merge({}, state.stocks, datoProductos),
                isFetching: false,
            });
        case RECEIVE_CATEGORIAS_DESTACADAS:
            let datoCategorias = action.categorias.entities.stocks;
            return Object.assign({}, state, {
                stocks: merge({}, state.stocks, datoCategorias),
                isFetching: false,
            });
        case RECEIVE_BUSQUEDA:
            let datoBusqueda = action.busqueda.entities.stocks;
            return Object.assign({}, state, {
                stocks: merge({}, state.stocks, datoBusqueda),
                isFetching: false,
            });

        // case RESET_STOCKS:
        //     return Object.assign({}, state, {
        //         isFetching: false,
        //         didInvalidate: true,
        //         error: null,
        //         lastUpdated: null,
        //         stocks: {}
        //     });
        default:
            return state
    }
}


function allProductosOrigenProducto(state = [], action) {
    switch (action.type) {
        case RECEIVE_PRODUCTO:
            let result = [];
            if (action.producto.entities.stocks)
                result = Object.keys(action.producto.entities.stocks).map(function (x) {
                    return parseInt(x);
                });
            return action.producto.entities.stocks ? result : [];
        case RECEIVE_PRODUCTOS:
            let result2 = [];
            if (action.productos.entities.stocks)
                result2 = Object.keys(action.productos.entities.stocks).map(function (x) {
                    return parseInt(x);
                });
            return action.productos.entities.stocks ? union(state, result2) : [];
        case RECEIVE_CATEGORIAS_DESTACADAS:
            let resultsCat = [];
            if (action.categorias.entities.stocks)
                resultsCat = Object.keys(action.categorias.entities.stocks).map(function (x) {
                    return parseInt(x);
                });
            return action.categorias.entities.stocks ? union(state, resultsCat) : [];
        case RECEIVE_BUSQUEDA:
            let resultStock = [];
            if (action.busqueda.entities.stocks)
                resultStock = Object.keys(action.busqueda.entities.stocks).map(function (x) {
                    return parseInt(x);
                });
            return action.busqueda.entities.stocks ? union(state, resultStock) : [];
        // case RESET_STOCKS:
        //     return [];
        default:
            return state
    }
}

const stocks = combineReducers({
    byId: stocksById,
    allIds: allProductosOrigenProducto,
});

export default stocks;