import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom'

import '../../../assets/css/BuscadorDesplegable.css';
import ProductoBuscado from "./Productos/ProductoBuscado";

class BuscadorDesplegable extends Component {

    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        if (window.innerWidth > 714)
            document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        if (window.innerWidth > 714)
            document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if ((this.wrapperRef && !this.wrapperRef.contains(event.target))) {
            this.props.toogleVisibleBuscador(false);
        }
    }

    render() {
        const {visible} = this.props;
        let visibleBuscador = visible && this.props.productos.allIds.allIdsSugeridos.length > 0;
        let Productos = this.props.productos.allIds.allIdsSugeridos.map((idProducto, i) => {
            let producto = this.props.productos.byId.productos[idProducto];
            return <ProductoBuscado key={producto.id} producto={producto}/>
        });
        let Marcas = this.props.marcas.allIds.allIdsBuscados.map((idMarca, i) => {
            let marca = this.props.marcas.byId.marcas[idMarca];
            if (marca && marca.id) {
                return (<li key={marca.id}><Link
                    to={{pathname: "/search", search: "?marca=" + marca.descripcion}}>{marca.descripcion}</Link></li>);
            }
        });

        let Categorias = [];
        let categoriasAgregadas = [];
        this.props.categorias.allIds.allIdsBuscados.forEach((idCategoria, i) => {
            let categoria = this.props.categorias.byId.categorias[idCategoria];
            if (categoria && categoria.id && categoriasAgregadas.indexOf(categoria.id) == -1) {
                Categorias.push(<li key={categoria.id}><Link
                    to={{pathname: "/search", search: "?cat=" + categoria.id}}>{categoria.desCategoria}</Link>
                </li>);
                categoriasAgregadas.push(categoria.id);
                let categoriaPadre = this.props.categorias.byId.categorias[categoria.idCategoriaPadre];
                if (categoriaPadre && categoriaPadre.id && categoriasAgregadas.indexOf(categoriaPadre.id) == -1) {
                    Categorias.push(<li key={categoriaPadre.id}><Link to={{
                        pathname: "/search",
                        search: "?cat=" + categoriaPadre.id
                    }}>{categoriaPadre.desCategoria}</Link></li>);
                    categoriasAgregadas.push(categoriaPadre.id);
                }

            }
            return false;
        });
        let resultados = Categorias.length > 0 || Marcas.length > 0 || Productos.length > 0;

        // if (window.innerWidth < 714)
        //     if (document.getElementById("desplegable") &&
        //         document.getElementById("desplegable").classList.contains("activo")) {
        //         document.body.classList.add("no-scroll");
        //     } else {
        //         document.body.classList.remove("no-scroll");
        //     }

        return (
            <div id="desplegable"
                 className={visibleBuscador && resultados ? "buscador-desplegable activo" : "buscador-desplegable"}
                 ref={this.setWrapperRef}
            >

                <div className="container-buscador-desplegable">
                    {
                        Productos.length > 0 &&
                        <div>
                            <div className="container-productos">
                                {Productos}
                            </div>

                            <div className="container-ver-resultados"
                                 style={{position: "relative", marginBottom: "20px"}}>
                                <button
                                    onClick={() => this.props.history.push("/search?sp=" + this.props.searchPhase)}>VER
                                    TODOS LOS RESULTADOS
                                </button>
                            </div>
                        </div>
                    }
                    {
                        Marcas.length > 0 &&
                        <div className="container-letras-encontradas">
                            <h6>Marcas</h6>
                            <ul>
                                {Marcas}
                            </ul>
                        </div>
                    }
                    {
                        Categorias.length > 0 &&
                        <div className="container-letras-encontradas">
                            <h6>Categorías</h6>
                            <ul>
                                {Categorias}
                            </ul>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        productos: state.productos,
        marcas: state.marcas,
        categorias: state.categorias
    };
}

const mapDispatchToProps = (dispatch) => {
    return {}
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuscadorDesplegable));


