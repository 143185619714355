import React, {useContext, useEffect, useRef} from 'react';
import {useHistory, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import "../../../../../assets/css/ListadoDirecciones.css";
import {
    createDireccionEntrega,
    fetchDireccionesEntregaIfNeeded,
    invalidateDireccionesEntrega,
    resetCreateDireccionEntrega,
    resetUpdateDireccionEntrega,
    updateDireccionEntrega
} from "../../../../../actions/DireccionEntregaActions";
import {fetchZonasEntregaIfNeeded} from "../../../../../actions/ZonasEntregaActions";
import {createPedido, updatePedido} from "../../../../../actions/PedidoActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import fechasEntrega from "../../../../../utils/calculoFechaEntrega";
import moment from 'moment';
import clone from "lodash/clone";
import zonaEntregaUtil from "../../../../../utils/calculoZonaEntrega";
import {GoogleApiWrapper} from "google-maps-react";
import isEqual from "lodash/isEqual"
import Skelleton from "../../Skelleton/Skelleton";

require("moment/min/locales.min");
moment.locale('es');

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}


function ListadoDirecciones(props) {
    const dispatch = useDispatch();
    let {idPedido} = useParams();
    let history = useHistory();


    const mounted = useRef();
    //Store
    const direccionesEntrega = useSelector(state => state.direccionesEntrega);
    const pedidos = useSelector(state => state.pedidos);
    const pedido = idPedido ? pedidos.update.activo : pedidos.create.nuevo;
    const direccionEntrega = direccionesEntrega.byId.direccionesEntrega[pedido.idDireccionEntrega];
    const zonasEntrega = useSelector(state => state.zonasEntrega);
    const detallesPedido = useSelector(state => state.detallesPedido);
    const auth = useSelector(state => state.auth);

    const prevDireccionesEntrega = usePrevious(direccionesEntrega.allIds);


    //Effects
    useEffect(() => {
        dispatch(invalidateDireccionesEntrega());
        dispatch(fetchDireccionesEntregaIfNeeded({idUsuario: auth.usuarioLogueado.idUsuario}));
        dispatch(fetchZonasEntregaIfNeeded());

        let cambio = {};
        cambio.idDireccionEntrega = null;
        if (!idPedido)
            dispatch(createPedido(cambio));
        else
            dispatch(updatePedido(cambio));

        return function cleanup() {
            dispatch(resetCreateDireccionEntrega());
            dispatch(resetUpdateDireccionEntrega());
        }
    }, []);

    //Cuando recibe las direcciones actualiza zonas de entrega
    useEffect(() => {
        if (prevDireccionesEntrega && !isEqual(prevDireccionesEntrega, direccionesEntrega.allIds)) {
            direccionesEntrega.allIds.forEach((idDireccionEntrega) => {
                let direccionEntrega = direccionesEntrega.byId.direccionesEntrega[idDireccionEntrega];
                if (direccionEntrega.principal == true && pedido.idDireccionEntrega !== idDireccionEntrega) {
                    handleSetDireccionEntrega(idDireccionEntrega);
                }
                zonaEntregaUtil.identificarLugar(direccionEntrega, idDireccionEntrega, zonasEntrega, dispatch, updateDireccionEntrega, createDireccionEntrega);
            });
        }
    }, [direccionesEntrega.allIds]);

    //Cuando se actualiza la direccion del pedido recalcula fecha y total envio
    useEffect(() => {
        console.log("USEEFFECT")
        let idDireccionEntrega = pedido.idDireccionEntrega;
        let direccionEntrega = direccionesEntrega.byId.direccionesEntrega[idDireccionEntrega];
        let cambio = {};
        console.log(direccionEntrega, direccionesEntrega.byId.direccionesEntrega[idDireccionEntrega]);
        if (direccionEntrega && direccionEntrega.idZonaEntrega) {
            //total
            let total = 0;
            pedidos.create.nuevo.detalle_pedido.forEach((idDetallePedido) => {
                let detallePedido = clone(detallesPedido.create.detallesPedido[idDetallePedido]);
                total = total + detallePedido.precioUnitario * detallePedido.cantidad;
            });
            let zonaEntrega = zonasEntrega.byId.zonasEntrega[direccionEntrega.idZonaEntrega];
            if (zonaEntrega.montoMinimoEnvioGratis && zonaEntrega.montoMinimoEnvioGratis <= total)
                cambio.totalEnvio = 0;
            else
                cambio.totalEnvio = zonaEntrega.precio;
            let fechaEntrega = fechasEntrega.calcularFechaEntrega(zonaEntrega);
            cambio.fechaEntrega = fechaEntrega;
            console.log(fechaEntrega);
        } else {
            cambio.totalEnvio = null;
            cambio.fechaEntrega = null;
        }
        if (!idPedido)
            dispatch(createPedido(cambio));
        else
            dispatch(updatePedido(cambio));
    }, [pedido.idDireccionEntrega, direccionEntrega]);


    const handleSetDireccionEntrega = (idDireccionEntrega) => {
        props.refDireccionEntrega.current.classList.remove("visible");
        let direccionEntrega = direccionesEntrega.byId.direccionesEntrega[idDireccionEntrega];
        if (direccionEntrega)
            zonaEntregaUtil.identificarLugar(direccionEntrega, idDireccionEntrega, zonasEntrega, dispatch, updateDireccionEntrega, createDireccionEntrega);
        //Pedido
        let cambio = {};
        cambio.idDireccionEntrega = idDireccionEntrega;
        if (!idPedido)
            dispatch(createPedido(cambio));
        else
            dispatch(updatePedido(cambio));
    };

    return (
        <section className="listado-direccionesEntrega">
            <h2 className="titulo-seccion subtitulo-checkout">Tu dirección</h2>
            <p className="mensaje" ref={props.refDireccionEntrega}>Debe agregar una dirección de entrega</p>
            <p className="mensaje" ref={props.refFueraZona}>La dirección que ingresó está fuera de nuestra zona de entrega</p>
            <div className="container-direccionesEntrega">
                {
                    !direccionesEntrega.byId.isFetching &&
                    direccionesEntrega.allIds.map((idDireccionEntrega) => {
                        let direccionEntrega = direccionesEntrega.byId.direccionesEntrega[idDireccionEntrega];
                        let fechaEntrega = null;
                        let totalEnvio = null;

                        if (direccionEntrega.idZonaEntrega) {
                            //total
                            let total = 0;
                            pedidos.create.nuevo.detalle_pedido.forEach((idDetallePedido) => {
                                let detallePedido = clone(detallesPedido.create.detallesPedido[idDetallePedido]);
                                total = total + detallePedido.precioUnitario * detallePedido.cantidad;
                            });
                            //totalenvio
                            let zonaEntrega = zonasEntrega.byId.zonasEntrega[direccionEntrega.idZonaEntrega];
                            if (zonaEntrega && zonaEntrega.montoMinimoEnvioGratis && zonaEntrega.montoMinimoEnvioGratis <= total)
                                totalEnvio = 0;
                            else
                                totalEnvio = zonaEntrega ? zonaEntrega.precio : 0;
                            //fecha
                            fechaEntrega = fechasEntrega.calcularFechaEntrega(zonaEntrega);
                        }
                        return (
                            <div
                                key={"de" + idDireccionEntrega}
                                className={pedido && pedido.idDireccionEntrega === idDireccionEntrega ? "direccionFrecuente active" : "direccionFrecuente"}
                                onClick={() => handleSetDireccionEntrega(idDireccionEntrega)}>
                                <div>
                                    {
                                        direccionEntrega && !!direccionEntrega.calle &&
                                        <div>
                                            <b>{`${direccionEntrega.calle} ${direccionEntrega.numero} ${direccionEntrega.detalles ? " - " + direccionEntrega.detalles : ""}`}</b>
                                        </div>
                                    }
                                    {
                                        direccionEntrega && !direccionEntrega.calle &&
                                        <div>
                                            <p>Dirección:</p>
                                            <p>{direccionEntrega.direccionEntrega}</p>
                                        </div>
                                    }
                                    {
                                        direccionEntrega && !!direccionEntrega.localidad &&
                                        <div>
                                            <small>{`${direccionEntrega.codPostal ? "C.P. - " + direccionEntrega.codPostal : ""} ${direccionEntrega.localidad}, ${direccionEntrega.provincia}`}</small>
                                        </div>
                                    }
                                    {
                                        direccionEntrega && !!direccionEntrega.nombre &&
                                        <div>
                                            <small>{`${direccionEntrega.nombre} ${direccionEntrega.apellido} - ${direccionEntrega.celular}`}</small>
                                        </div>
                                    }
                                    {
                                        pedido && fechaEntrega &&
                                        <p className="fecha-entrega">Llega {moment().isSame(moment(fechaEntrega), "day") ? "hoy!" : moment(fechaEntrega).format("dddd DD [de] MMMM")}
                                        </p>
                                    }
                                    {
                                        pedido && !direccionEntrega.idZonaEntrega &&
                                        <p className="entrega-confirmar">Entrega a coordinar
                                        </p>
                                    }
                                </div>
                                <div>
                                    {
                                        totalEnvio !== null &&
                                        (totalEnvio === 0 ?
                                            <p className="costo-envio gratis"><small>Envío</small><p>Gratis!</p>
                                            </p> :
                                            <p className="costo-envio"><p>{"$" + totalEnvio}</p> <small>Costo
                                                envío</small></p>)
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                {
                    !!direccionesEntrega.byId.isFetching &&
                    <div key={"de"}
                        className="direccionFrecuente-loading">
                        <Skelleton tipo="direccionEntrega"/>
                    </div>
                }
                {
                    !direccionesEntrega.byId.isFetching &&
                    <div className="direccionFrecuente" onClick={() => history.push("/direccionesEntrega/nuevo")}>
                        <div className="nueva">
                            <p>Agregar nueva dirección</p>
                            <FontAwesomeIcon icon={faPlusCircle} size="2x"/>
                        </div>
                    </div>
                }

            </div>
            <br/>
        </section>
    )
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyANSH-395EpmTpki-222stF4V76Rp8KcZY"),
    libraries: ['places', 'geometry'],
})(ListadoDirecciones);