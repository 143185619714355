//api
import marcas from "../api/marcas"
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import {logout} from "../actions/AuthActions";
//normalizers
import {normalizeDato, normalizeDatos} from "../normalizers/normalizeMarcas";

//MARCAS
export const REQUEST_MARCAS = 'REQUEST_MARCAS';
export const RECEIVE_MARCAS = 'RECEIVE_MARCAS';
export const INVALIDATE_MARCAS = 'INVALIDATE_MARCAS';
export const ERROR_MARCAS = "ERROR_MARCAS";
export const RESET_MARCAS = "RESET_MARCAS";


export function invalidateMarcas() {
    return {
        type: INVALIDATE_MARCAS
    }
}

function requestMarcas() {
    return {
        type: REQUEST_MARCAS,
    }
}

function receiveMarcas(json) {
    return {
        type: RECEIVE_MARCAS,
        marcas: normalizeDatos(json),
        receivedAt: Date.now()
    }
}

function errorMarcas(error) {
    return {
        type: ERROR_MARCAS,
        error: error,
    }
}

export function resetMarcas() {
    return {
        type: RESET_MARCAS
    }
}

export function fetchMarcas(filtros) {
    return dispatch => {
        dispatch(requestMarcas());
        return marcas.getAll(filtros)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveMarcas(data));
            })
            .catch(function (error) {
                console.log(error);
                switch (error.status) {
                    case 401:
                        dispatch(errorMarcas(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorMarcas(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

function shouldFetchMarcas(state) {
    const marcas = state.marcas.byId;
    if (!marcas) {
        return true
    } else if (marcas.isFetching) {
        return false
    } else {
        return marcas.didInvalidate;
    }
}

export function fetchMarcasIfNeeded(filtros) {
    return (dispatch, getState) => {
        if (shouldFetchMarcas(getState())) {
            return dispatch(fetchMarcas(filtros))
        }
    }
}


//MODEL
export const REQUEST_MARCA = 'REQUEST_MARCA';
export const RECEIVE_MARCA = 'RECEIVE_MARCA';
export const INVALIDATE_MARCA = 'INVALIDATE_MARCA';
export const ERROR_MARCA = "ERROR_MARCA";
export const RESET_MARCA = "RESET_MARCA";


export function invalidateMarca() {
    return {
        type: INVALIDATE_MARCA
    }
}

function requestMarca() {
    return {
        type: REQUEST_MARCA,
    }
}

function receiveMarca(json) {
    return {
        type: RECEIVE_MARCA,
        marca: normalizeDato(json),
        receivedAt: Date.now()
    }
}

function errorMarca(error) {
    return {
        type: ERROR_MARCA,
        error: error,
    }
}

export function resetMarca() {
    return {
        type: RESET_MARCA
    }
}

export function fetchMarca(idMarca) {
    return dispatch => {
        dispatch(requestMarca());
        return marcas.getOne(idMarca)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(receiveMarca(data));
            })
            .catch(function (error) {
                switch (error.status) {
                    case 401:
                        dispatch(errorMarca(errorMessages.UNAUTHORIZED_TOKEN));
                        dispatch(logout());
                        return;
                    default:
                        dispatch(errorMarca(errorMessages.GENERAL_ERROR));
                        return;
                }
            });
    }
}

//UPDATE MODEL
export const UPDATE_MARCA = 'UPDATE_MARCA';
export const REQUEST_UPDATE_MARCA = "REQUEST_UPDATE_MARCA";
export const SUCCESS_UPDATE_MARCA = "SUCCESS_UPDATE_MARCA";
export const ERROR_UPDATE_MARCA = "ERROR_UPDATE_MARCA";
export const RESET_UPDATE_MARCA = "RESET_UPDATE_MARCA";

function requestUpdateMarca() {
    return {
        type: REQUEST_UPDATE_MARCA,
    }
}

function receiveUpdateMarca(marca) {
    return {
        type: SUCCESS_UPDATE_MARCA,
        receivedAt: Date.now(),
        marca: normalizeDato(marca)
    }
}

function errorUpdateMarca(error) {
    return {
        type: ERROR_UPDATE_MARCA,
        error: error,
    }
}

export function resetUpdateMarca() {
    return {
        type: RESET_UPDATE_MARCA,
    }
}

export function updateMarca(marca) {
    return {
        type: UPDATE_MARCA,
        marca
    }
}

export function saveUpdateMarca() {
    return (dispatch, getState) => {
        dispatch(requestUpdateMarca());

        return marcas.saveUpdate(getState().marcas.update.activo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveUpdateMarca(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                //console.log( error.status, error.json());
                switch (error.status) {
                    case 401:
                        dispatch(errorUpdateMarca(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorUpdateMarca(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorUpdateMarca(JSON.parse(error.message)));
                                });
                            } catch {
                                dispatch(errorUpdateMarca(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}

//ALTA MARCA
export const CREATE_MARCA = 'CREATE_MARCA';
export const REQUEST_CREATE_MARCA = "REQUEST_CREATE_MARCA";
export const SUCCESS_CREATE_MARCA = "SUCCESS_CREATE_MARCA";
export const ERROR_CREATE_MARCA = "ERROR_CREATE_MARCA";
export const RESET_CREATE_MARCA = "RESET_CREATE_MARCA";


//ALTA MARCA
function requestCreateMarca() {
    return {
        type: REQUEST_CREATE_MARCA,
    }
}

function receiveCreateMarca(marca) {
    return {
        type: SUCCESS_CREATE_MARCA,
        receivedAt: Date.now(),
        marca: normalizeDato(marca)
    }
}

export function errorCreateMarca(error) {
    return {
        type: ERROR_CREATE_MARCA,
        error: error,
    }
}

export function resetCreateMarca() {
    return {
        type: RESET_CREATE_MARCA
    }
}

export function createMarca(marca) {
    return {
        type: CREATE_MARCA,
        marca
    }
}

export function saveCreateMarca() {
    return (dispatch, getState) => {
        dispatch(requestCreateMarca());
        return marcas.saveCreate(getState().marcas.create.nuevo)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    return data;
                }
            })
            .then(function (json) {
                dispatch(receiveCreateMarca(json));
            })
            .catch(function (error) {
                console.log(error, error.status);
                //console.log( error.status, error.json());
                switch (error.status) {
                    case 401:
                        dispatch(errorCreateMarca(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorCreateMarca(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorCreateMarca(JSON.parse(error.message)));
                                });
                            } catch {
                                dispatch(errorCreateMarca(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}

//DELETE MARCA
export const DELETE_MARCA = 'DELETE_MARCA';
export const REQUEST_DELETE_MARCA = "REQUEST_DELETE_MARCA";
export const SUCCESS_DELETE_MARCA = "SUCCESS_DELETE_MARCA";
export const ERROR_DELETE_MARCA = "ERROR_DELETE_MARCA";
export const RESET_DELETE_MARCA = "RESET_DELETE_MARCA";

function requestDeleteMarca() {
    return {
        type: REQUEST_DELETE_MARCA,
    }
}

function receiveDeleteMarca(marca) {
    return {
        type: SUCCESS_DELETE_MARCA,
        receivedAt: Date.now(),
        marca: normalizeDato(marca)
    }
}

function errorDeleteMarca(error) {
    return {
        type: ERROR_DELETE_MARCA,
        error: error,
    }
}

export function resetDeleteMarca(error) {
    return {
        type: RESET_DELETE_MARCA,
        error: error,
    }
}

export function deleteMarca(marca) {
    return {
        type: DELETE_MARCA,
        marca
    }
}

export function saveDeleteMarca(marca) {
    return dispatch => {
        dispatch(requestDeleteMarca());
        return marcas.saveDelete(marca)
            .then(function (response) {
                if (response.status >= 400) {
                    return Promise.reject(response);
                } else {
                    var data = response.json();
                    //Refresco token
                    //auth.addToken(response.headers);
                    return data;
                }
            })
            .then(function (data) {
                dispatch(resetDeleteMarca());
                dispatch(receiveDeleteMarca(data));
            })
            .catch(function (error) {
                console.log(error, error.status);
                switch (error.status) {
                    case 401:
                        dispatch(errorDeleteMarca(errorMessages.UNAUTHORIZED_TOKEN));
                        return;
                    default:
                        if (error.responseJSON && error.responseJSON.message !== "")
                            dispatch(errorDeleteMarca(JSON.parse(error.responseJSON.message)));
                        else
                            try {
                                error.json().then((error) => {
                                    dispatch(errorDeleteMarca(JSON.parse(error.message)));
                                });
                            } catch {
                                dispatch(errorDeleteMarca(errorMessages.GENERAL_ERROR));
                            }


                        return;
                }
            });
    }
}