import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom'

import '../../../assets/css/DatosContacto.css';
//components
import CampoTexto from "../componentes/CampoTexto"
//img
import {ReactComponent as Smartphone} from "../../../assets/images/smartphone.svg";
import {ReactComponent as FirstName} from "../../../assets/images/firstname.svg";
//actions
import {createPedido} from "../../../actions/PedidoActions";

class DatosContacto extends Component {


    componentDidMount() {
        if (!this.props.match.params.idPedido) {
            let usuario = {};
            let usrLoged = this.props.auth.usuarioLogueado;
            usuario.nombre = usrLoged.nombre;
            usuario.apellido = usrLoged.apellido;
            usuario.email = usrLoged.email;
            usuario.idUsuario = usrLoged.idUsuario
            this.props.createPedido(usuario);
        }
    }

    render() {
        let {pedido} = this.props;
        return (
            <section className="datosContacto">
                <h2 className="titulo-seccion subtitulo-checkout">Datos de entrega</h2>
                <CampoTexto required={true} id="nombre" nombre="Nombre"
                            img={<FirstName style={{width: "15px", height: "15px"}}/>} type="text"
                            value={pedido.nombre ? pedido.nombre : ""}
                            handleChange={this.props.handleChangePedido.bind(this)}/>
                <CampoTexto required={true} id="apellido" nombre="Apellido"
                            img={<FirstName style={{width: "15px", height: "15px"}}/>} type="text"
                            value={pedido.apellido ? pedido.apellido : ""}
                            handleChange={this.props.handleChangePedido.bind(this)}/>
                <CampoTexto required={true} id="celular" nombre="Celular"
                            img={<Smartphone style={{width: "15px", height: "15px"}}/>} type="text"
                            value={pedido.celular ? pedido.celular : ""}
                            handleChange={this.props.handleChangePedido.bind(this)}/>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        usuarios: state.usuarios,
        pedidos: state.pedidos,
        auth: state.auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createPedido: (pedido) => {
            dispatch(createPedido(pedido))
        }
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatosContacto));
