import {normalize, schema} from 'normalizr';

export function normalizeDatos(myData) {
    const usuario = new schema.Entity('usuarios', {}, {idAttribute: "id"});

    const mySchema = [usuario];
    const normalizedData = normalize(myData, mySchema);
    return normalizedData;
}

export function normalizeDato(myData) {

    const usuario = new schema.Entity('usuarios', {}, {idAttribute: "id"});

    const mySchema = usuario;
    const normalizedData = normalize(myData, mySchema);

    return normalizedData;
}
